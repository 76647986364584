<template>
    <div>
        <div class="bg1">
            <div class="">
                <div class="p-t-3 p-b-5">
                    <nav class="">
                        <a href="https://www.eurekabookhouse.co.id/" class="center">
                            <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                                alt="IMG-LOGO" class="center">
                        </a>
                    </nav>
                </div>
            </div>
        </div>
            <section class="bg0 p-b-20 text-left">
                <div class="container p-lr-80-lg">
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            <h1 class="ltext-102 cl2 p-t-28">
                                SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </h1>
                            <div class="flex-m flex-sb m-tb-15">
                                <div class="flex-i flex-w flex-m stext-111 cl2">
                                    <span>
                                        <span class="cl4">Oleh</span> Eureka
                                        <span class="cl12 m-l-4 m-r-6">|</span>
                                    </span>
                                    <span>
                                        11 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                                    </span>
                                    <div id="share" class="m-l-50-lg">
                                        <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                            href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/segenggam-tanah-merah---juara-favorit-5-lomba-cerpen-eureka-bookhouse"
                                            target="_blank"><i class="fa fa-facebook"></i></a>
                                        <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                            href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/segenggam-tanah-merah---juara-favorit-5-lomba-cerpen-eureka-bookhouse&amp;text=Blog: SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE &amp;hashtags=eurekabookhouse"
                                            title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                        <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                            href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/segenggam-tanah-merah---juara-favorit-5-lomba-cerpen-eureka-bookhouse"
                                            title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                                    </div>
                                </div>
                                <div class="flex-r-m">
                                    <span class="stext-107 text-center"><i class="fa fa-eye"></i> 1994</span>
                                </div>
                            </div>
                            <div class="wrap-pic-w how-pos5-parent">
                                <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg" alt="IMG-BLOG"
                                    class="blog-read-pic">
                                <div class="flex-col-c-m size-123 bg9 how-pos5">
                                    <span class="ltext-107 cl2 txt-center">
                                        11 </span>
            
                                    <span class="stext-109 cl3 txt-center">
                                        11 2022 </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                    <div class="row p-t-35 p-b-80">
                        <div class="col-lg-2">
                        </div>
                        <div class="col-md-8 col-lg-8">
                            <div class="p-r-0-lg">
                                <div>
                                    <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                                        <p>Aku mengusap batu yang salah satu ujungnya menghadap ke arah timur. Permukaan benda
                                            berukuran sekepal tangan orang dewasa itu terasa hangat oleh pancaran mentari. Sekali
                                            lagi air mataku mengalir deras. Peristiwa beberapa hari silam telah membuat jiwaku
                                            gersang, berbanding terbalik dengan sepasang netraku. Ingin rasanya aku menggali
                                            gundukan tanah di hadapan, merangkulkan lengan pada tubuh yang terbujur kaku di bawah
                                            sana.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Bukannya aku tidak iba
                                            pada sakit yang mendera ayah. Aku tahu, Tuhan sudah memutuskan hal yang paling baik.
                                            Sisa hidup sungguh menyiksa ayah, kami yang menjadi saksinya secara bergantian. Sosok
                                            berjiwa pahlawan itu tak mampu menghabiskan makanannya, sendok yang kuarahkan
                                            berulangkali ditolak oleh gelengan lemah. Perihal orang sakit selalu tidak berselera
                                            makan sudah menjadi biasa. Terkadang makanan lain masih bisa meningkatkan nafsu makan,
                                            asalkan tidak berasal dari rumah sakit. Namun ayah tak ingin makan apapun.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aku masih ingat
                                            sepuluh hari sebelum kepergian ayah. Lelap kami terenggut oleh erangan menyayat. Aku
                                            bangkit dan tidak tahu harus berbuat apa. Sakit itu, dokter sudah berusaha maksimal,
                                            mengisyaratkan ayah harus rela menahannya. Akhirnya aku hanya mengusap punggung telapak
                                            tangan ayah. Kulit dengan pembuluh nadi dan tulang yang menyembul itu semakin tipis. Aku
                                            melafazkan doa sembari menatap perut ayah yang bengkak, berharap sakit itu berpindah
                                            padaku. Entah karena usapanku yang lembut atau telah lelah, ayah kembali memejamkan
                                            mata. Melupakan rasa sakitnya sejenak.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Bagaimana? Kita
                                            jual saja?&rdquo; Suara itu memecah lamunan.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Terserah, hal
                                            yang paling penting, jangan lupakan ayah.&rdquo; Aku memang tidak berselera untuk
                                            membahas masalah itu.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Selanjutnya hanya
                                            terdengar dengungan dari musyawarah internal. Telingaku tidak dapat menangkap hasil
                                            pembahasan malam ini dengan baik. Suara kedua sosok itu yang paling dominan, abang dan
                                            ibu tiriku.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Tanda tangan,
                                            Dik.&rdquo; Sebuah map dengan beberapa lembar surat disodorkan padaku. Aku hanya menatap
                                            benda itu dengan kebisuan.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Dik?&rdquo;
                                            Lelaki yang usianya terpaut delapan tahun di atasku itu menepuk pundakku pelan.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Bang, kenapa
                                            harus secepat ini?&rdquo; Tenggorokanku seolah tercekik saat menanyakannya.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Kenapa? Agar
                                            tidak terjadi konflik, Dik. Kau tahu &lsquo;kan, ayah mempunyai anak dari dua
                                            istri.&rdquo; Klise. Kalau saja mereka mempunyai rasa kehilangan seperti yang kurasakan,
                                            tentu tidak akan ada yang sempat memikirkan ini.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Nanti sajalah,
                                            Bang. Aku sedang tidak ingin mengungkit warisan.&rdquo; Aku menggeser map berwarna
                                            kuning itu ke arah abang.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Kenapa, Dik?
                                            Ini tidak boleh dibiarkan berlarut-larut. Jangan sampai si pembeli berubah pikiran. Ia
                                            sudah berulangkali menawarkan harga tertinggi. Mungkin saja ini adalah tawarannya yang
                                            terakhir.&rdquo; Nada suara lelaki itu terdengar penuh semangat. Seolah ia tengah tidak
                                            dirundung duka.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Ya, tidak
                                            apa-apa. Bukankah ayah juga tidak sempat menikmati hasilnya?&rdquo; Aku melangkah ke
                                            pintu. Air mata sudah menggenang di pelupuk. Aku butuh pergi ke suatu tempat untuk
                                            menceritakan keluh kesah.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Naungan pohon beringin
                                            cukup melindungiku dari sengatan sang surya. Aku mengambil sebongkah batu yang cukup
                                            besar sebagai alas duduk. Jemariku membelai benda yang bertugas sebagai pengganti nisan,
                                            ibarat aku menyalim tangan ayah. Aku mengambil segenggam tanah, mengamatinya lekat.
                                            Bahkan tanah makam ayah masih merah, mengapa mereka berani-beraninya membahas warisan?
                                        </p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aku sangat menyesali
                                            keputusan ayah yang enggan menjual tanah warisan itu. Beberapa calon pembeli kerap
                                            menjumpai ayah, berusaha memberikan&nbsp; penawaran. Alasan ayah selalu serupa, harganya
                                            terlalu murah untuk lahan yang memiliki potensi pariwisata. Padahal aku tahu, dibalik
                                            itu ayah tidak pernah berniat menjualnya. Akhirnya status mereka tidak pernah menjadi
                                            pembeli, semuanya tetap pada kategori calon.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Bukannya aku menyesal
                                            karena ayah tidak menjual tanah itu. Aku tidak gila harta. Aku hanya ingin ayah turut
                                            mengecap hasil penjualan tanah itu. Tidak seperti saat ini, hanya kami yang masih
                                            bernyawalah yang merasakan kesenangan.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ayah telah
                                            mengorbankan jiwa dan raganya untuk kehidupan kami. Tubuhnya semakin ringkih karena
                                            perjuangan. Aku tahu, penyebab kepergian ayah adalah kepedihan akan derita. Uang, sebuah
                                            kata yang menjadi alasan kurusnya tubuh ayah. Aku masih ingat, ketika masih jaya, tubuh
                                            ayah tampak bugar. Tapi saat-saat sekarang, ayah telah mempertaruhkan hidupnya demi
                                            kuliahku dan sekolah adik. Padahal jelas ada sebuah pilihan yang dapat membahagiakan
                                            ayah. Tapi beliau tetap saja bertahan, tidak memindahtangankan lahan itu.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Kau ingin apa,
                                            Nak?&rdquo; Percakapan kami tempo hari terbayang jelas, sesaat setelah calon pembeli
                                            menghubungi ayah.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Tidak ada,
                                            Yah.&rdquo; Aku memang belum memikirkan akan diapakan uang bagianku kelak.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Pikirkanlah.
                                            Kau ingin membeli sepeda motor?&rdquo; Ayah berkata dengan mata penuh binar.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Tidak usah
                                            sepeda motor, Yah. Daripada benda itu, lebih baik aku lanjut S2.&rdquo; Sontak aku
                                            menyesal setelah melihat sinar mata ayah yang meredup. Sepertinya, ayah baru tahu bahwa
                                            aku memiliki impian tersembunyi. Hanya saja, tidak berani mengutarakan karena keadaan
                                            ekonomi keluarga kami.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Bagus itu, Nak.
                                            Lebih baik seperti itu.&rdquo; Ayah mengacungkan jempol. Namun aku dapat melihat sebuah
                                            beban baru menumpuk di bahu ayah.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Tetiba angin berembus
                                            kencang, mengembalikan kesadaranku pada dunia nyata. Sekejap saja, dedaunan gugur ke
                                            permukaan tanah. Beberapa melewati kepalaku. Entah ke mana perginya matahari. Awan
                                            berkabut gelap menyelubungi bumi, siap menumpahkan air dengan deras.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aku harus menyudahi
                                            pertemuan dengan ayah. Tapi terlambat. Rinai jatuh dan semakin rapat. Kuyup di tubuhku
                                            pertanda bahwa beringin ini tidak mampu menghalangi tumpahan air dari langit. Aku
                                            terperangkap. Bukit ini tidak menyisakan seorang manusia pun yang dapat menolong.
                                            Bersandar pada tubuh pohon, tubuhku meringkuk pasrah.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pada gigil tubuh yang
                                            semakin hebat, sebuah tangan terulur di hadapanku. Aku menatap wajah tersebut, mataku
                                            yang pernuh air sulit mengenali orang. Aku berusaha untuk menyambut uluran tangan, namun
                                            kepalaku terasa berat.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Dik? Kau
                                            kenapa?&rdquo; Aku baru sadar, suara itu milik abangku.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Aku mau
                                            menandatangani, asalkan ayah kita berikan bagiannya.&rdquo; Susah payah lidahku
                                            mengucapkan kalimat tersebut.</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Iya, tenang
                                            saja. Bukankah hal ini sudah kita bicarakan tadi malam?&rdquo;</p>
            
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aku tidak ingat
                                            ataupun memang tidak menyimak. Tanganku menyambut uluran tersebut. Namun tubuhku terasa
                                            sulit untuk bangkit. Kepalaku bagaikan dihantam besi. Seketika semuanya menjadi
                                            gelap.&nbsp;&nbsp;&nbsp;</p>
            
                                        <p>*Tamat*</p>
            
                                        <p>&nbsp;</p>
            
                                        <p><strong>Biodata Penulis : GETI OKTARIA PULUNGAN</strong></p>
            
                                        <p>Penulis lahir di Padang Sidempuan dan kini berdomisili di Tapanuli Selatan, Sumatera
                                            Utara. Gemar mengikuti lomba menulis dan mengirimkan karya ke media. Beberapa naskahnya
                                            telah dimuat di Kompas, Analisa, Waspada, dan Padang Ekspress. Penulis juga beberapa
                                            kali mendapat penghargaan melalui lomba menulis cerpen, esai, karya tulis, dan fabel.
                                            Alamat e-mail : ghea_niz@yahoo.com.</p>
            
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                                <div class="media ava-bloger">
                                    <img class="mr-3 rounded-circle img-thumbnail"
                                        src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                                        alt="Generic placeholder image">
                                    <div class="media-body">
                                        <p class="stext-101 m-b-8">DITULIS OLEH</p>
                                        <h5 class="mt-0 mtext-111">Eureka Writer</h5>
                                        <p class="badge badge-primary">Content Writer</p>
                                        <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
                <section class="sec-relate-product bg6 p-t-20 text-left">
                    <div class="container p-tb-10">
                        <div class="p-b-15">
                            <h3 class="session-title mtext-106 cl5">New Entry</h3>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <router-link :to="'/Blog/kisahkasih'">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                                        </router-link>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1">
                                            <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                                        </div>
                                        <h4 class="p-b-32">
                                            <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                                KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                        </h4>
                                        <span class="stext-102 m-t-20">05 September 2022</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <router-link :to="'/Blog/rahasiadelia'">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                                        </router-link>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1">
                                            <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                                        </div>
                                        <h4 class="p-b-32">
                                            <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                                RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                        </h4>
                                        <span class="stext-102 m-t-20">05 September 2022</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <router-link :to="'/Blog/pohonmangga'">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                                        </router-link>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1">
                                            <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                                        </div>
                                        <h4 class="p-b-32">
                                            <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                                POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                                            </router-link>
                                        </h4>
                                        <span class="stext-102 m-t-20">05 September 2022</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <router-link :to="'/Blog/malaikat'">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                                        </router-link>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1">
                                            <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                                        </div>
                                        <h4 class="p-b-32">
                                            <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                                MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                        </h4>
                                        <span class="stext-102 m-t-20">05 September 2022</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <router-link :to="'/Blog/ttgayah'">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                                        </router-link>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1">
                                            <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                                        </div>
                                        <h4 class="p-b-32">
                                            <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                                KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                        </h4>
                                        <span class="stext-102 m-t-20">05 September 2022</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <router-link :to="'/Blog/merah'">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                                        </router-link>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                        </div>
                                        <h4 class="p-b-32">
                                            <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                                SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                        </h4>
                                        <span class="stext-102 m-t-20">05 September 2022</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <router-link :to="'/Blog/jejak'">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                                        </router-link>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1">
                                            <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                                        </div>
                                        <h4 class="p-b-32">
                                            <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                                JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                        </h4>
                                        <span class="stext-102 m-t-20">05 September 2022</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <router-link :to="'/Blog/pejuang'">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                                        </router-link>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1">
                                            <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                                        </div>
                                        <h4 class="p-b-32">
                                            <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                                PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                                            </router-link>
                                        </h4>
                                        <span class="stext-102 m-t-20">04 September 2022</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    </div>
</template>
<script>
export default {
    name: "merahPage",
}
</script>