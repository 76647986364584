<template>
    <div>
        <div class="bg1">
            <div class="">
                <div class="p-t-3 p-b-5">
                    <nav class="">
                        <a href="https://www.eurekabookhouse.co.id/" class="center">
                            <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                                alt="IMG-LOGO" class="center">
                        </a>
                    </nav>
                </div>
            </div>
        </div>
        <section class="bg0 p-b-20 text-left">
            <div class="container p-lr-80-lg">
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                        <h1 class="ltext-102 cl2 p-t-28">
                            PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE </h1>
                        <div class="flex-m flex-sb m-tb-15">
                            <div class="flex-i flex-w flex-m stext-111 cl2">
                                <span>
                                    <span class="cl4">Oleh</span> Eureka
                                    <span class="cl12 m-l-4 m-r-6">|</span>
                                </span>
                                <span>
                                    11 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                                </span>
                                <div id="share" class="m-l-50-lg">
                                    <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                        href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/perjuangan-ayahku--untuk-masa-depanku---juara-favorit-3-lomba-cerpen-eureka-bookhouse"
                                        target="_blank"><i class="fa fa-facebook"></i></a>
                                    <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                        href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/perjuangan-ayahku--untuk-masa-depanku---juara-favorit-3-lomba-cerpen-eureka-bookhouse&amp;text=Blog: PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE &amp;hashtags=eurekabookhouse"
                                        title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                    <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                        href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/perjuangan-ayahku--untuk-masa-depanku---juara-favorit-3-lomba-cerpen-eureka-bookhouse"
                                        title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                                </div>
                            </div>
                            <div class="flex-r-m">
                                <span class="stext-107 text-center"><i class="fa fa-eye"></i> 2680</span>
                            </div>
                        </div>
                        <div class="wrap-pic-w how-pos5-parent">
                            <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg" alt="IMG-BLOG"
                                class="blog-read-pic">
                            <div class="flex-col-c-m size-123 bg9 how-pos5">
                                <span class="ltext-107 cl2 txt-center">
                                    11 </span>
        
                                <span class="stext-109 cl3 txt-center">
                                    11 2022 </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div class="row p-t-35 p-b-80">
                    <div class="col-lg-2">
                    </div>
                    <div class="col-md-8 col-lg-8">
                        <div class="p-r-0-lg">
                            <div>
                                <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                                    <p>Kisah ini berasal dari keluarga sederhana yang hidup di sebuah desa yang terdiri dari
                                        ayah, ibu dan seorang anak.</p>
        
                                    <p>Ayah yang bernama Budiman biasa dipanggil pak Budi, ibu yang bernama Sriyani biasa
                                        dipanggil ibu Sri, dan anaknya yang bernama Ratna biasa dipanggil Rara.</p>
        
                                    <p>Ayah Rara bekerja sebagai penjual kain keliling dan Ratna masih duduk di bangku SMA.</p>
        
                                    <p>Suatu hari, ketika Ratna ingin berangkat sekolah</p>
        
                                    <p>&ldquo;Pak, Rara boleh tidak, minta dibelikan laptop, Rara sendirian tidak punya laptop
                                        di kelas pak, Rara selalu diejek temen&rdquo; selalu nyusahin temen Rara pak&rdquo; ucap
                                        Rara sambil mengelus Elis tas ranselnya yang sudah kusut sedikit rusak, sedangkan
                                        ayahnya sedang sibuk mengecek dagangannya.</p>
        
                                    <p>Tangan yang tiba-tiba menyentuh puncak kepaa Ratna sontak membuat Ratna menghadap
                                        ayahnya, ya itu tangan ayahnya.</p>
        
                                    <p>&ldquo;Nak, jika teman temanmu selalu mengejek kamu, kanan dengarkan nak, jangan lawan
                                        mereka, dengarkan saja mereka, jadikan motivasi mu untuk belajar, ayah akan usahakan
                                        agar kamu bisa punya laptop nak&rdquo; sambil mengelus Elis puncak kepala anaknya.</p>
        
                                    <p>Dibalik pembicaraan antara Rara dan ayahnya, ibu Rara sudah sedari tadi mengintip di
                                        belakang pintu mendengarkan pembicaraan keduanya tidak dirasakan air matanya menetes.
                                    </p>
        
                                    <p>&ldquo;Ya sudah sana berangkat, ntar telat loh sekolahnya&rdquo; ucap ayahnya yang
                                        membuat Rara mengangguk, Rara langsung memakai tas ranselnya.</p>
        
                                    <p>&ldquo; Rara berangkat dulu ya yah&rdquo; sambil tersenyum lalu menyakini tangan ayahnya.
                                    </p>
        
                                    <p>Ia Ratna biasa dipanggil Rara, ia sekarang duduk di bangku kelas 12, di SMA Bakti jaya.
                                        Rara termasuk siswa berprestasi, namun teman temannya sering mengejeknya karena ia anak
                                        orang kurang mampu, SMA Bakti jaya adalah sekolah favorit yang siswanya datang dari
                                        kalangan pejabat dan pembisnis.</p>
        
                                    <p>Sesampainya di sekolah Rara dikagetkan temannya yaa bisa dibilang itu teman satu satunya.
                                    </p>
        
                                    <p>&ldquo;Pagi Raraaaaa&rdquo; Ucap Jihan</p>
        
                                    <p>&ldquo;Astaga Jihan ngagetin aja kamu ya !&rdquo; ucap Rara dengan raut kesal</p>
        
                                    <p>&ldquo;Ya maaf hehe, oh iya Ra gimana beasiswa kamu amankan ??&rdquo; tanya Jihan dengan
                                        raut sedikit cemas</p>
        
                                    <p>&ldquo;Allhamdulillah aman ji, untung saja tidak ditarik karena nilaiku turun, bisa bisa
                                        aku akan tambah nyusahin ayahku&rdquo; ucap Rara kejihan yang berada di samping nya.</p>
        
                                    <p>Tidak terasa dengan pembicaraan mereka Jihan dan Rara sampi dikelas mereka iya kelas
                                        &ldquo;12 MIPA 2&rdquo;.</p>
        
                                    <p>Rara dikagetkan dengan Poto ayahnya dan dirinya yang dipampang di papan tulis dan teman
                                        temannya menertawakannya dengan keras.</p>
        
                                    <p>Dengan cepat Rara mengambilnya, ang diikuti Jihan.</p>
        
                                    <p>&ldquo;Ji, km kenapa mau berteman sama anak gembel kek dia&rdquo; kata Rozy samnil nunjuk
                                        Rara</p>
        
                                    <p>&ldquo;Karena dia sahabat gue, dia berbeda dari kalian dia gembel tapi etika rasa
                                        kemanusiaannya kaya jauh diatas kalian yang anak pejabat, kalian ga malu ya sama rata!
                                        Rara ga pernah buat jahat ke kalian, tapi kalian ngelakuin ini ke Rara, percuma yaaa
                                        kaya tapi miskin akan rasa kemanusiaan&rdquo; kata Jihan dengan tegas ke teman temannya
                                        yang menatap Jihan dan Rara didepan kelasnya.</p>
        
                                    <p>&lsquo;Yah, Rara janji bakalan berjuang demi ibu dan baoak&rdquo; batin Rara</p>
        
                                    <p>&nbsp;</p>
        
                                    <p>Detik demi detik berlalu jam menu jukkan pukul 13:30, saatnya bagi siswa SMA Bakti jaya
                                        bergegas pulang.</p>
        
                                    <p>&ldquo;Ra kamu mau barengan ga sama aku ?&rdquo; tanya Jihan</p>
        
                                    <p>&ldquo;Mmm ga deh ni aku jalan aja, ga enak repotin kamu terus&rdquo;</p>
        
                                    <p>&ldquo;Ya ampun ni anak kek ke siapa aja, yaudah barengan aja&rdquo; kata Jihan sambil
                                        menarik tangan Rara</p>
        
                                    <p>&ldquo;Ih Jihan gausah tarik tarik juga&rdquo; ucap Rara dengan nada kesal</p>
        
                                    <p>&ldquo;Kamu sih, diajak bareng nolak, gartis ras ga bayar aku bukan tukang ojek&rdquo;
                                    </p>
        
                                    <p>&ldquo;Yaudah ji, makasih ya&rdquo;</p>
        
                                    <p>&ldquo;Santai Ra, yuk&rdquo; kata Jihan sambil menyalakan motor hitam besarnya.</p>
        
                                    <p>&nbsp;</p>
        
                                    <p>Belum sampai rumah, Rara melihat ayahnya yang sedang berjualan dipinggir jalan, dibawah
                                        terik matahari yang sangat panas dan menyengat ke kulit</p>
        
                                    <p>&ldquo;Ji berenti ji, aku turun disini aja&rdquo; ucap Rara menepuk pundak Jihan</p>
        
                                    <p>&ldquo;Lah knp Ra ? Ini kan masih jauh dari rumahmu??&rdquo;</p>
        
                                    <p>&ldquo;Aku liat ayahku ji, aku mau bantuin ayah aja&rdquo;</p>
        
                                    <p>&ldquo;Beneran nih?&rdquo;</p>
        
                                    <p>&ldquo;Iya ji&rdquo; Rara turun dari motor dan melepaskan helmnya dan memberikannya ke
                                        Jihan</p>
        
                                    <p>&ldquo;Yaudah Ra, aku duluan ya daaa&rdquo; kata Jihan sambil melambaikan tangannya</p>
        
                                    <p>&ldquo;Dahh&rdquo; ucap Rara ke Jihan dengan balaik melambaikan tangannya</p>
        
                                    <p>Jihan sudah menjauh dari dirinya, Rara bergegas menyebrangi menemui ayahnya</p>
        
                                    <p>&ldquo;Yahh&rdquo;</p>
        
                                    <p>Suara itu sontak membuat pak Budi iya ayah Rara menoleh ke arah suara</p>
        
                                    <p>&ldquo;Astaga Ra, ngalakn kesini seharusnya kamu pulang istirahat, kamu kan capek baru
                                        puakbg sekolah nak&rdquo;</p>
        
                                    <p>&ldquo;Ga capek kok yah, aku mau bantu ayah ya&rdquo; ucap Rara sambil melepas ranselnya
                                    </p>
        
                                    <p>&ldquo;Duh nak ga usah, kamu duduk disana saja di bawah pohon itu biar ga kepanasan, biar
                                        ayah saja Disni&rdquo; suruhnya sambil menunjuk pohon besar yang tidak jauh dari mereka
                                    </p>
        
                                    <p>&ldquo;Tidak yah, Rara mau bantu ayah kok disuruh duduk&rdquo; jawab Rara dengan nada
                                        bersemangat</p>
        
                                    <p>&ldquo;Kain kain mba kainnya bagus bagus nih, mas mari lihat lihat motif kainnya
                                        bagus&rdquo; Rara aneh sontak menawarkan barang dagangan ayahnya ke orang orang yang
                                        melewati mereka.</p>
        
                                    <p>Dalam batin Rara mengatakan &ldquo; gini rasanya jadi ayah cari uang buat Rara, panas
                                        panasan , demi uang dmei masa depanku demi hidupku, aku sayang sayang, Rara janji
                                        bakalan belajar biar jadi orang sukses&nbsp; yah&rdquo;</p>
        
                                    <p>Tidak terasa waktu berjalan cepat hingga menunjukkan pukul 17:30</p>
        
                                    <p>&ldquo; Nak ayo pulang&rdquo; ajak ayah Rara kepada Rara yang sedang mengusap keringatnya
                                    </p>
        
                                    <p>&ldquo;Ayok&rdquo; jawab Rara sambil memakai tas ranselnya.</p>
        
                                    <p>Ditengah jalan diatas sebuah motor sederhana ayahnya Rara meneteskan air matanya
                                        mengingat teman temannya yang sering mengejeknya karena ayahnya seorang pedagang, bukan
                                        hanya itu Rara berfikir jika setiap hari ayahnya harus kepanasan seperti yang ia lihat
                                        tadi dan itu perjuangan ayahnya untuk masa depannya. Rara perlahan memeluk ayahnya dari
                                        belakang.</p>
        
                                    <p>&ldquo;Yah, Rara janji akan berjuang dan akan sukses&rdquo; batin rara.</p>
        
                                    <p>Waktu berjalan cepat mereka sudah sampai di sebuah rumah sederhana tempat keluarga pak
                                        Budi bisa tinggal.</p>
        
                                    <p>Rara langsung turun dari motornya dan langsung mengangkat barang ayahnya</p>
        
                                    <p>&ldquo;Jangan nak berat, nanti kamu jatuh&rdquo; ucap ayah Rara sambil menahan tangan
                                        anaknya</p>
        
                                    <p>&ldquo;Berat ?? Ngga ko&#39; yah, masih berat beban yang dipangkul ayah untuk keluarga
                                        dan juga masa depanku, ini cuman sedikit yah&rdquo;</p>
        
                                    <p>Ibu Rara yang sudah sedari tadi berdiri depan rumah langsung menyambut mereka.</p>
        
                                    <p>&ldquo;Assalamualaikum buk&rdquo; ucap Rara sambil mencium tangan ibunya</p>
        
                                    <p>&ldquo;Waalikumsalam&rdquo; ucap Suryani kepada anaknya dengan senyum</p>
        
                                    <p>&ldquo; Langsung mandi ya nak, langsung sholat&rdquo;</p>
        
                                    <p>&ldquo;Baik Bu&rdquo; dengan cepat Rara langsung masuk rumahnya.</p>
        
                                    <p>35 menit kemudian, Rara yang baru selesai sholat langsung keluar dari kamarnya ia
                                        melewati kamar orang tuanya ia melihat ayahnya yang sedang menghitung uang 10rb an hasil
                                        jualannya, kemudian dimasukkan ke dalam sebuah celengan yang tulisannya tidak jelas
                                        dilihat dari jauh oleh Rara. Ketika ayahnya ingin keluar kamarnya, Rara berlari untuk
                                        bersembunyi, saat ayahnya sudah keluar rara masuk ke kamar orang tuanya ia mengambil
                                        celengan yang tadi dipegang ayahnya, saat memegang celengan itu air mata Rara menetes
                                        deras. Bagaimana tidak Rara menangis dengan celengan dengan tulisan yang tertera<em>
                                            (Untuk masa depan Ratna)</em>.</p>
        
                                    <p>Rara tidak bisa menahan air matanya ketika melihat celengan itu.</p>
        
                                    <p>&ldquo;Yah, Rara sayang ayah&rdquo; batin Rara dengan memeluk erat celengan itu.</p>
        
                                    <p>&ldquo;Ra, sini makan dulu&rdquo; Sebuah suara ibunya yang memanggilnya untuk makan.</p>
        
                                    <p>&ldquo;Iya buk&rdquo; Rara langsung mengusap wajhnya membersihkan bekas tangisnya, lalu
                                        menaruh celengan ayahnya.</p>
        
                                    <p>.......</p>
        
                                    <p>&ldquo;Nanti ayah mau pergi sebentar ya buk&rdquo; ucap ayah Rara meminta izin ke
                                        istrinya</p>
        
                                    <p>&ldquo;Iya yah&rdquo; Jawab istrinya sambil mengambilkan nasi untuk anaknya</p>
        
                                    <p>&ldquo;Mau kemana yah?? Rara boleh ikut ?&rdquo;</p>
        
                                    <p>&ldquo;Keluar sebentar Ra, udah jangan ikut Ra ayah ada urusan sebentar kamu diam dirumah
                                        sama ibu ya&rdquo;</p>
        
                                    <p>Selesai mereka makan ayah Rara langsung pergi...</p>
        
                                    <p><em>Sebuah toko elektronik</em>............</p>
        
                                    <p>Iya, toko elektronik itu adalah tujuan ayah Rara, memang saat pagi tadi tepat saat rara
                                        sudah berangkat sekolah ayah dan ibunya Rara, memutuskan untuk membelikan anaknya laptop
                                        menggunakan uang tabungan haji mereka.</p>
        
                                    <p>&ldquo;Permisis mas&rdquo; Ucap ayah Rara</p>
        
                                    <p>&ldquo;Iya pak, ada yang bisa saya bantu? Bapak mau beli apa ?&rdquo; tanya seorang
                                        karyawan kepada pak Budi ayah Rara</p>
        
                                    <p>&ldquo;Saya mencari laptop untuk anak saya mas&rdquo;</p>
        
                                    <p>&ldquo;Ooh laptop pak, mari saya tunjukkan&rdquo;</p>
        
                                    <p>Pak Budi berjalan mengikuti karyawan toko itu sampai pada diruangan berbagai macam
                                        laptop.</p>
        
                                    <p>Pandangan ayah ras tertuju pada sebuah laptop berwana merah yang bermerk
                                        <em>&nbsp;ACER</em>.</p>
        
                                    <p>&ldquo;Mas harga laptop ini berapa?&rdquo;</p>
        
                                    <p>&ldquo;Kalo yang ini 3.500.000 pak&rdquo; ucap karyawan itu sambil mengambil laptop yang
                                        ditanyakan pak Budi</p>
        
                                    <p>&ldquo;Tidka bisa kurang mas? Uang saya Cuma 3.000.000 pas&rdquo;</p>
        
                                    <p>&ldquo;Waduh tidak bisa pak, itu sudah harga pasnya&rdquo;</p>
        
                                    <p>Setelah pak Budi dan karyawan itu bernegosiasi seorang bapak-bapak datang ke arah mereka.
                                    </p>
        
                                    <p>&ldquo;Sisanya saya yang bayar, bungkus aja laptopnya mas, bungkus rapi ya&rdquo;</p>
        
                                    <p>Pak Budi dan karyawan itu sontak melihat ke arah suara itu</p>
        
                                    <p>&ldquo;Pak Andi !!!&rdquo; ucap ayah Rara</p>
        
                                    <p>Tidak lama kemudian bapak bapak itu mendekat ke arah mereka</p>
        
                                    <p>&ldquo;Tolong bungkus rapi ya mas&rdquo; Ucap pak Andi memerintahkan ke pegawai itu.</p>
        
                                    <p>Pak Andi adalah seorang dianut beasiswa Rara sekaligus pembisnis yang kaya raya.</p>
        
                                    <p>&ldquo;Apa kabar pak Budi ?&rdquo; tanya pak Andi sambil menjabat tangannya ke pak budi
                                    </p>
        
                                    <p>&ldquo;Allhamdulillah baik pak, Bapak apa kabar? Dan kenapa bapak bisa ada disni?&rdquo;
                                        Jawab pak Andi sambil mengucapkan beberapa pertanyaan sekaligus menjabat tangannya.</p>
        
                                    <p>&ldquo;Saya tau Ratna sedang kesusahan dalam belajarnya, apalagi teman temannya yang
                                        sering sekali mengejeknya, Ratna tidak mempunyai handphone dan laptop, dan tadi saya
                                        sedang berada di cafe sebelah tidak sengaja melihat bapak masuk toko ini, giliran saya
                                        tidak lain bapak ingin membelikan Ratna barang elektronik, oleh karena itu saya ikuti
                                        bapak&rdquo;</p>
        
                                    <p>&ldquo;Iya pak tiada lain saya memecahkan celengan tabungan haji saya untuk Ratna untuk
                                        bisa membelikannya laptop demi masa depannya pak&rdquo;</p>
        
                                    <p>Tidak lama kemudian berbincang pegai itu membawa menenteng sebuah plastik yang isinya
                                        laptop</p>
        
                                    <p>&ldquo;Ini pak laptopnya&rdquo; ujar sang karyawan</p>
        
                                    <p>&ldquo;Terimakasih mas&rdquo; ucap pak Andi sambil mengambil laptop itu</p>
        
                                    <p>&ldquo;Sama sama pak proses pembayarannya dikasir ya pak&rdquo; kata pegawai</p>
        
                                    <p>&ldquo;Terimakasih mba&rdquo; ucap pak Budi ke kasir toko itu</p>
        
                                    <p>Kemudian pak Andi dan pak Budi keluar dari toko itu.</p>
        
                                    <p>&ldquo;Terimakasih banyak pak, saya tidak tau jika bapak tidak ada disana mungkin saya
                                        tidak akan dapat membeli laptop ini&rdquo; ujar pak Budi</p>
        
                                    <p>&ldquo;Sama sama pak, Ya sudah saya duluan ya pak Budi&rdquo;</p>
        
                                    <p>&ldquo;Iya hati hati pak Andi sekali lagi terimakasih&rdquo;</p>
        
                                    <p>Pak Andi mengangguk lalu menjauh dari pak Budi untuk memasuki mobilnya kemudian pergi.
                                    </p>
        
                                    <p>&ldquo;Ya Allah hamba bersyukur untuk nikmatmu dihari ini&rdquo; batin pak Budi sambil
                                        memeluk laptop yang ditangannya.</p>
        
                                    <p>......</p>
        
                                    <p>Tidak lama kemudian pak Budi sampai dirumahnya</p>
        
                                    <p>&ldquo;Assalamualaikum buk&rdquo;</p>
        
                                    <p>Ucap salam dari suaminya yang membuat Sriyani langsung menjawab nya</p>
        
                                    <p>&ldquo;Waalikumsalam pak&rdquo; jawabnya sambil membukakan pintu untuk suaminya.</p>
        
                                    <p>&ldquo;Rara mana buk?&rdquo;</p>
        
                                    <p>&ldquo;Dikamarnya yah, lagi belajar yah&rdquo;</p>
        
                                    <p>&ldquo;Raa! Kesini bentar ayah ada sesuatu untuk Rara nih&rdquo; ucap ayahnya memanggil
                                        anaknya</p>
        
                                    <p>&ldquo;Iya pak&rdquo; Ucap Rara, tidak lama kemudian Rara keluar dari kamarnya</p>
        
                                    <p>&ldquo;Nih buat Rara&rdquo; ujar ayahnya sambil menyerahkan sebuah kotak besar</p>
        
                                    <p>&ldquo;Apa ini yah??&rdquo; Rara yang bingung sambil memegang kotka besar itu</p>
        
                                    <p>&ldquo;Buka aja dulu nak&rdquo; ucap ibunya</p>
        
                                    <p>Rara sontak bahagia ketika ia melihat isi kotak tersebut yang isinya sebuah laptop yang
                                        ia impi impikan, dengan ceoat Rara memeluk kedua orang tuanya, Rara meneteskan air
                                        matanya dengan mengalir deras.</p>
        
                                    <p>&ldquo;Rara janji bu, yah akan berjuang untuk mengangkat keluarga ini akan mengganti
                                        kerja keras ayah&rdquo; hiks hiks hiks suara Isak tangis Rara yang membuat kedua orang
                                        tuanya semakin erat memeluk anaknya.</p>
        
                                    <p>&ldquo;Iya nak, ibu percaya kamu nak, terus belajar yaa!&rdquo; ucap ibunya kepada Rara
                                    </p>
        
                                    <p>&ldquo;Belajar rajin lagi ya nak, kejar cita citamu ayah dan ibuk akan selalu ada
                                        untukmu&rdquo; ujar ayahnya sambil mengelus&rdquo; kepala anaknya</p>
        
                                    <p>&ldquo;Iya yah,buk&rdquo;</p>
        
                                    <p>&ldquo;Aku sayang kalian&rdquo; .</p>
        
                                    <p>&nbsp;</p>
        
                                    <p>Dear ayah :</p>
        
                                    <p>Ayah... Sebuah kata yang aku ucapkan ketika aku merasakan lelah berjuang, kecewa dengan
                                        hidup ini. Ayah, sebuah kata sederhana dengan seribu keajaiban. Ayah memang bukan orang
                                        yang melahirkan aku ke dunia ini, namun dia yang berjuang untuk aku hidup di dunia ini,
                                        dia yang mengajarkanku akan hidup ini dia juga yang melindungi aku dari segala bahaya
                                        yang datang, iya dia adalah perisai hidupku, Ayah adalah pahlawan hidupku di dunia ini.
                                    </p>
        
                                    <p><strong><em>Terimakasih ayah atas perjuanganmu untuk masa depanku</em></strong></p>
        
                                    <p><strong><em>Aku sayang ayah</em></strong></p>
        
                                    <p>&nbsp;</p>
        
                                    <p>From : Ratna</p>
        
                                    <p>To: Ayah tercinta</p>
        
                                    <p>&nbsp;</p>
        
                                    <p>Penulis: <strong>ISWATUN HASANAH</strong></p>
                                </div>
                            </div>
                            <div class="media ava-bloger">
                                <img class="mr-3 rounded-circle img-thumbnail"
                                    src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                                    alt="Generic placeholder image">
                                <div class="media-body">
                                    <p class="stext-101 m-b-8">DITULIS OLEH</p>
                                    <h5 class="mt-0 mtext-111">Eureka Writer</h5>
                                    <p class="badge badge-primary">Content Writer</p>
                                    <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
            <section class="sec-relate-product bg6 p-t-20 text-left">
                <div class="container p-tb-10">
                    <div class="p-b-15">
                        <h3 class="session-title mtext-106 cl5">New Entry</h3>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-3 p-b-20">
                            <div class="blog-item bg0 bor8">
                                <div class="blog-pic hov-img0">
                                    <router-link :to="'/Blog/kisahkasih'">
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                                    </router-link>
                                </div>
                                <div class="p-all-15">
                                    <div class="stext-112 flex-w p-b-14 cl1">
                                        <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                                    </div>
                                    <h4 class="p-b-32">
                                        <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                            KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                    </h4>
                                    <span class="stext-102 m-t-20">05 September 2022</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-3 p-b-20">
                            <div class="blog-item bg0 bor8">
                                <div class="blog-pic hov-img0">
                                    <router-link :to="'/Blog/rahasiadelia'">
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                                    </router-link>
                                </div>
                                <div class="p-all-15">
                                    <div class="stext-112 flex-w p-b-14 cl1">
                                        <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                                    </div>
                                    <h4 class="p-b-32">
                                        <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                            RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                    </h4>
                                    <span class="stext-102 m-t-20">05 September 2022</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-3 p-b-20">
                            <div class="blog-item bg0 bor8">
                                <div class="blog-pic hov-img0">
                                    <router-link :to="'/Blog/pohonmangga'">
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                                    </router-link>
                                </div>
                                <div class="p-all-15">
                                    <div class="stext-112 flex-w p-b-14 cl1">
                                        <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                                    </div>
                                    <h4 class="p-b-32">
                                        <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                            POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                                        </router-link>
                                    </h4>
                                    <span class="stext-102 m-t-20">05 September 2022</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-3 p-b-20">
                            <div class="blog-item bg0 bor8">
                                <div class="blog-pic hov-img0">
                                    <router-link :to="'/Blog/malaikat'">
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                                    </router-link>
                                </div>
                                <div class="p-all-15">
                                    <div class="stext-112 flex-w p-b-14 cl1">
                                        <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                                    </div>
                                    <h4 class="p-b-32">
                                        <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                            MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                    </h4>
                                    <span class="stext-102 m-t-20">05 September 2022</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-3 p-b-20">
                            <div class="blog-item bg0 bor8">
                                <div class="blog-pic hov-img0">
                                    <router-link :to="'/Blog/ttgayah'">
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                                    </router-link>
                                </div>
                                <div class="p-all-15">
                                    <div class="stext-112 flex-w p-b-14 cl1">
                                        <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                                    </div>
                                    <h4 class="p-b-32">
                                        <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                            KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                    </h4>
                                    <span class="stext-102 m-t-20">05 September 2022</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-3 p-b-20">
                            <div class="blog-item bg0 bor8">
                                <div class="blog-pic hov-img0">
                                    <router-link :to="'/Blog/merah'">
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                                    </router-link>
                                </div>
                                <div class="p-all-15">
                                    <div class="stext-112 flex-w p-b-14 cl1"><a
                                            href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                    </div>
                                    <h4 class="p-b-32">
                                        <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                            SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                    </h4>
                                    <span class="stext-102 m-t-20">05 September 2022</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-3 p-b-20">
                            <div class="blog-item bg0 bor8">
                                <div class="blog-pic hov-img0">
                                    <router-link :to="'/Blog/jejak'">
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                                    </router-link>
                                </div>
                                <div class="p-all-15">
                                    <div class="stext-112 flex-w p-b-14 cl1">
                                        <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                                    </div>
                                    <h4 class="p-b-32">
                                        <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                            JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                    </h4>
                                    <span class="stext-102 m-t-20">05 September 2022</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-3 p-b-20">
                            <div class="blog-item bg0 bor8">
                                <div class="blog-pic hov-img0">
                                    <router-link :to="'/Blog/pejuang'">
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                                    </router-link>
                                </div>
                                <div class="p-all-15">
                                    <div class="stext-112 flex-w p-b-14 cl1">
                                        <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                                    </div>
                                    <h4 class="p-b-32">
                                        <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                            PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                                        </router-link>
                                    </h4>
                                    <span class="stext-102 m-t-20">04 September 2022</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    </div>
</template>
<script>
export default {
    name: "pejuangPage",
}
</script>