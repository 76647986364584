<template>
  <div>
    <section
      class="bg-img1 txt-center p-lr-15 p-t-50 p-b-30"
      style="
        /*background-image:url('');*/
        background-repeat: no-repeat;
        background-size: 100%;
        background-color: #f4d747;
      "
    >
      <h2 class="ltext-105 cl5 txt-center">SEO Tag</h2>
      <h5>Kategori Populer</h5>
    </section>
    <section class="bg0 p-t-75 p-b-120">
      <div class="container">
        <div class="row p-b-50">
          <div class="col-md-12 col-lg-12">
            Kami memberikan Anda kemudahan untuk membeli segala produk yang
            berkaitan dengan Buku, ATK, Gaya Hidup, Mainan & Hobi, Olahraga &
            Outdoor dan Handmade, hanya dengan mengakses ke situs
            Eurekabookhouse.co.id. Dengan fasilitas yang kami sediakan, Anda
            akan memiliki kemudahan mendapatkan barang- barang terbaru yang Anda
            inginkan. Dapatkan pula berbagai diskon dengan potongan harga
            termurah dan penawaran-penawaran yang pastinya menarik untuk Anda.
            Informasi mengenai segala produk yang Anda inginkan juga bisa Anda
            dapatkan dengan mudah di website kami untuk kenyamanan Anda
            berbelanja. Produk pesanan akan kami antar ke rumah Anda dengan
            pelayanan ekspedisi yang cepat dengan kualitas produk yang terbaru
            dan terbaik. Dengan pembayaran melalui kartu kredit, transfer antar
            bank, GoPay dan AkuLaku. kami menawarkan metode pembayaran yang
            pastinya mudah dan aman demi kenyamanan Anda berbelanja. Ada
            Pertanyaan? Temui kami di Jl. H. Baping No.100, Ciracas, Jakarta
            Timur, DKI Jakarta 087888337555 Kami menyediakan produk original
            dengan kategori: Buku, ATK, Gaya Hidup, Mainan & Hobi, Olahraga &
            Outdoor dan Handmade. Eurekabookhouse.co.id menyediakan segala
            produk yang anda butuhkan di tiap kategorinya. Untuk produk kategori
            Buku dan ATK, Eurekabookhouse.co.id menyediakan Novel, Buku
            Pelajaran, Buku Perguruan Tinggi, Buku Umum, Buku anak, dan berbagai
            macam ATK untuk memenuhi koleksi dan kebutuhan anda. Lengkapi
            kebutuhan Gaya hidup Anda sehari-hari, kami menawarkan pilihan
            Sepatu,Tumbler, Aksesoris gadget dan Voucher terbaik dari
            Eurekabookhouse.co.id. Jadikan Gaya Hidup Anda sehari-hari
            senantiasa semakin asik dengan produk- produk terbaru dari
            Eurekabookhouse.co.id. Tak hanya memenuhi kebutuhan Gaya Hidup,
            Eurekabookhouse.co.id juga menjawab kebutuhan aktivitas luar ruangan
            Anda. Ingin berolahraga atau aktivitas outdoor yang menantang?
            Temukan produk- produk kesayangan Anda seperti alat-alat olahraga,
            alat-alat outdoor dan ransel yang dapat menambah serunya pengalaman
            liburan Anda. Penuhi kebutuhan buah hati Anda atau koleksi anda
            dengan berbagai macam produk yang kami tawarkan.
            Eurekabookhouse.co.id menawarkan segala produk untuk kebutuhan
            Mainan dan Hobi mulai dari alat menggambar, puzzle, diecast, figure
            dan modelkit. Jadikan selalu Eurekabookhouse.co.id mitra utama
            kehidupan Anda. Kami menawarkan discount hingga 20% dan dengan harga
            yang menarik. Hanya di Eurekabookhouse.co.id kami menyediakan
            berbagai produk dengan harga spesial. Kami menawarkan harga yang
            kompetitif dibandingkan retailer lainnya. Eurekabookhouse.co.id
            sebagai pusat belanja online terlengkap dan terpercaya di Indonesia
            dengan kemudahan gratis ongkos kirim. Pengiriman barang profesional
            dan eksklusif kami akan mengantar barang pesanan Anda dengan cepat
            dan aman.
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
export default {
  name: "seoTag",
  components: {},
  data() {
    return {};
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  methods: {},
};
</script>
  
  <style>
</style>