<template>
    <div>
<div class="bg1">
    <div class="">
        <div class="p-t-3 p-b-5">
            <nav class="">
                <router-link :to="'/Blog/pejuang'" class="center">
                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                        alt="IMG-LOGO" class="center">
                </router-link>
            </nav>
        </div>
    </div>
</div>
<section class="bg0 p-t-20 text-left" style="min-height: 100%">
    <div class="container">
        <h3 class="ltext-101 p-tb-20">Promosi</h3>
        <div class="row">
            
        </div>
    </div>
</section>
<!-- <footer class="bgu1 p-t-15 p-b-15">
    <div class="container">
        <div class="p-t-10">
            <p class="stext-107 cl0 txt-center">
                Copyright &copy;Dikembangkan oleh Eureka IT Developer
            </p>
        </div>
    </div>
</footer> -->
    </div>
</template>
<script>
export default {
  name : "promosiPage"
}
</script>