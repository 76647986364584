<template>
    <div>
<div class="bg1">
    <div class="">
        <div class="p-t-3 p-b-5">
            <nav class="">
                <a href="https://www.eurekabookhouse.co.id/" class="center">
                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                        alt="IMG-LOGO" class="center">
                </a>
            </nav>
        </div>
    </div>
</div>
<section class="bg0 p-b-20 text-left">
    <div class="container p-lr-80-lg">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <h1 class="ltext-102 cl2 p-t-28">
                    4 Aplikasi untuk Baca Buku Online di Smartphonemu </h1>
                <div class="flex-m flex-sb m-tb-15">
                    <div class="flex-i flex-w flex-m stext-111 cl2">
                        <span>
                            <span class="cl4">Oleh</span> Eureka
                            <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <span>
                            18 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <div id="share" class="m-l-50-lg">
                            <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/4-aplikasi-untuk-baca-buku-online-di-smartphonemu"
                                target="_blank"><i class="fa fa-facebook"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/4-aplikasi-untuk-baca-buku-online-di-smartphonemu&amp;text=Blog: 4 Aplikasi untuk Baca Buku Online di Smartphonemu &amp;hashtags=eurekabookhouse"
                                title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/4-aplikasi-untuk-baca-buku-online-di-smartphonemu"
                                title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                        </div>
                    </div>
                    <div class="flex-r-m">
                        <span class="stext-107 text-center"><i class="fa fa-eye"></i> 5159</span>
                    </div>
                </div>
                <div class="wrap-pic-w how-pos5-parent">
                    <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/4_Aplikasi_untuk_Baca_Buku_Online_di_Smartphonemu.jpg"
                        alt="IMG-BLOG" class="blog-read-pic">
                    <div class="flex-col-c-m size-123 bg9 how-pos5">
                        <span class="ltext-107 cl2 txt-center">
                            18 </span>

                        <span class="stext-109 cl3 txt-center">
                            11 2022 </span>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row p-t-35 p-b-80">
            <div class="col-lg-2">
            </div>
            <div class="col-md-8 col-lg-8">
                <div class="p-r-0-lg">
                    <div>
                        <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                            <p>Di era yang serba digital seperti sekarang ini, kemudahan dalam memperoleh informasi
                                dengan sangat mudah bisa didapatkan. Termasuk didalamnya memperoleh bacaan-bacaan yang
                                berkualitas. Mari kita telisik lebih jauh. Berapa banyak orang yang masih mendapatkan
                                informasi dari bentuk fisik seperti koran, majalah, maupun buku? nyatanya banyak orang
                                yang memilih suatu yang simple dan mudah dibawa daripada membawa bentuk fisik sebuah
                                buku karena kini membaca buku online cukup melalui smartphonemu. <em>Nah</em>, untuk
                                kamu si pencinta buku, ini dia empat aplikasi untuk baca buku online di smartphonemu
                                yang dapat kamu miliki. <em>Yuk</em> simak!</p>

                            <p>&nbsp;</p>

                            <h2><strong>Wattpad</strong></h2>

                            <p>Siapa yang tak kenal dengan Wattpad. Aplikasi buatan Kanada ini memiliki ribuan novel
                                dengan berbagai macam genre yang bisa kamu baca dengan gratis. Untuk kamu yang suka
                                menulis, aplikasi Wattpad mungkin akan menjadi aplikasi yang tepat untukmu. Selain dapat
                                membaca buku secara gratis, kamu juga dapat menulis novelmu sendiri <em>lho</em>
                                sehingga novelmu dapat dinikmati oleh pengguna Wattpad lainnya. seru <em>kan</em>?</p>

                            <p>Kelebihan lainnya adalah kamu dapat berinteraksi dengan pengguna lainnya seperti sharing
                                mengenai novel, maupun ide-ide penulisan novel. Banyak <em>lho</em> bermunculan
                                penulis-penulis terkenal dari aplikasi Wattpad ini. Selain kelebihan ternyata aplikasi
                                ini juga memiliki kekurangan. Kamu akan menemui banyak sekali judul buku di sini
                                sehingga terkadang akan menyulitkanmu untuk menemukan buku yang kamu cari. Lalu, ada
                                juga beberapa seri buku yang tidak memiliki lanjutannya alias berhenti di tengah jalan.
                            </p>

                            <p>&nbsp;</p>

                            <h2><strong>Moon + reader</strong></h2>

                            <p>Moon + reader merupakan salah satu aplikasi ebook reader yang digemari oleh pengguna
                                android. Aplikasi ini mendukung beragam format ebook seperti pdf, epub, mobi, cbz, cbr,
                                umd, chm, html, zip, OPDS maupun rar. Moon + reader memiliki pengaturan yang terbilang
                                lengkap. Untuk kamu yang suka memberikan note saat membaca, aplikasi ini menyediakan
                                fitur tersebut. Kamu juga bisa menambahkan warna maupun garis pada kata yang ingin kamu
                                tandai termasuk mengatur <em>wallpaper</em>. Selain itu, dalam aplikasi ini juga
                                terdapat pengaturan <em>night mode</em> yang bisa kamu gunakan jika kamu ingin membaca
                                ditempat gelap. Ebook reader Moon + reader ini tersedia dalam versi gratis juga
                                berbayar.</p>

                            <p>&nbsp;</p>

                            <h2><strong>Ibooks </strong></h2>

                            <p>Khusus pengguna Iphone dan iPod Touch pasti sudah tak asing dengan ebook reader ini.
                                Ibooks merupakan aplikasi ebook reader untuk sistem operasi iOS dan
                                perangkat-perangkatnya. Aplikasi Ibooks bukanlah aplikasi bawaan dari perangkat iOS ya,
                                sehingga kamu harus mengunduhnya terlebih dahulu dari iTunes App Store-mu.</p>

                            <p>&nbsp;</p>

                            <h2><strong>Epic! Kids Book, Audio Books, &amp; Learning Videos</strong></h2>

                            <p>Aplikasi ini cocok untuk para orang tua yang mencari buku-buku berkualitas untuk bacaan
                                si buah hati. Epic! Merupakan aplikasi yang khusus dirancang untuk anak dengan umur 12
                                tahun ke bawah. Tak hanya menyediakan ebook, Epic! juga menyediakan audiobooks juga
                                learning videos dengan tema-tema menarik untuk si buah hati. Kamu dapat mengakses
                                <em>kids book</em>, <em>audio books</em>, maupun <em>learning videos</em> gratis selama
                                satu bulan. Setelah satu bulan berakhir, maka kamu akan dikenakan biaya $7.99 setiap
                                bulannya, atau setara dengan kira-kira Rp. 111.860 dengan hitungan satu dolar Rp.14.000.
                                Untuk para orangtua yang khawatir dengan bacaan anak-anak, aplikasi Epic! dapat dicoba
                                ya.</p>

                            <p>Ini dia empat aplikasi untuk membaca buku online yang bisa kamu unduh di smartphonemu.
                                Jika kamu masih bingung ingin menggunakan aplikasi yang mana, kamu bisa mencoba-coba
                                menggunakannya terlebih dahulu. Selamat membaca!</p>
                        </div>
                    </div>
                    <div class="media ava-bloger">
                        <img class="mr-3 rounded-circle img-thumbnail"
                            src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                            alt="Generic placeholder image">
                        <div class="media-body">
                            <p class="stext-101 m-b-8">DITULIS OLEH</p>
                            <h5 class="mt-0 mtext-111">Andaf Iman</h5>
                            <p class="badge badge-primary">Content Writer</p>
                            <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="sec-relate-product bg6 p-t-20 text-left">
    <div class="container p-tb-10">
        <div class="p-b-15">
            <h3 class="session-title mtext-106 cl5">New Entry</h3>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/kisahkasih'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/rahasiadelia'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pohonmangga'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/malaikat'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/ttgayah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/merah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/jejak'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pejuang'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">04 September 2022</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<footer class="bgu1 p-t-15 p-b-15">
    <div class="container">
        <div class="p-t-10">
            <p class="stext-107 cl0 txt-center">
                Copyright &copy; Dikembangkan oleh Eureka IT Developer
            </p>
        </div>
    </div>
</footer>
    </div>
</template>
<script>
export default {
    name : "aPage",
}
</script>