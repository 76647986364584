<template>
    <div>
      <section
        class="bg-img1 txt-center p-lr-15 p-t-50 p-b-30"
        style="
          /*background-image:url('');*/
          background-repeat: no-repeat;
          background-size: 100%;
          background-color: #f4d747;
        "
      >
        <h2 class="ltext-105 cl5 txt-center">Pembayaran succes</h2>
        <router-link
                  :to="'/Users/historyPesanan'"
                  class="stext-107 cl6 hov-cl1 trans-04"
                >
                  Kembali
                </router-link>
      </section>
    </div>
  </template>
    
    <script>
  export default {
    name: "paymentSucces",
    components: {},
    data() {
      return {};
    },
    mounted() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    methods: {},
  };
  </script>
    
    <style>
  </style>