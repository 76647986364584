<template>
    <div>
        <div class="bg1">
            <div class="">
                <div class="p-t-3 p-b-5">
                    <nav class="">
                        <router-link :to="'/Blog/pejuang'" class="center">
                            <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                                alt="IMG-LOGO" class="center">
                        </router-link>
                    </nav>
                </div>
            </div>
        </div>
        <section class="bg0 p-b-20 text-left">
            <div class="container p-lr-80-lg">
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                        <h1 class="ltext-102 cl2 p-t-28">
                            Inilah Best Seller Buku Soal Januari 2019 </h1>
                        <div class="flex-m flex-sb m-tb-15">
                            <div class="flex-i flex-w flex-m stext-111 cl2">
                                <span>
                                    <span class="cl4">Oleh</span> Eureka
                                    <span class="cl12 m-l-4 m-r-6">|</span>
                                </span>
                                <span>
                                    17 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                                </span>
                                <div id="share" class="m-l-50-lg">
                                    <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                        href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/inilah-best-seller-buku-soal-januari-2019"
                                        target="_blank"><i class="fa fa-facebook"></i></a>
                                    <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                        href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/inilah-best-seller-buku-soal-januari-2019&amp;text=Blog: Inilah Best Seller Buku Soal Januari 2019 &amp;hashtags=eurekabookhouse"
                                        title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                    <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                        href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/inilah-best-seller-buku-soal-januari-2019"
                                        title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                                </div>
                            </div>
                            <div class="flex-r-m">
                                <span class="stext-107 text-center"><i class="fa fa-eye"></i> 12004</span>
                            </div>
                        </div>
                        <div class="wrap-pic-w how-pos5-parent">
                            <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/1040157-riz--hari-pertama-ujian-nasional--780x390.jpg"
                                alt="IMG-BLOG" class="blog-read-pic">
                            <div class="flex-col-c-m size-123 bg9 how-pos5">
                                <span class="ltext-107 cl2 txt-center">
                                    17 </span>
        
                                <span class="stext-109 cl3 txt-center">
                                    11 2022 </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div class="row p-t-35 p-b-80">
                    <div class="col-lg-2">
                    </div>
                    <div class="col-md-8 col-lg-8">
                        <div class="p-r-0-lg">
                            <div>
                                <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                                    <p>Setiap pelajar pasti akan mempersiapkan diri untuk pendalaman materi. Berikut adalah
                                        buku-buku soal terlaris pada bulan Januari 2019 yang bisa jadi rekomendasi buat kamu:
                                    </p>
        
                                    <p>&nbsp;</p>
        
                                    <p><strong><a
                                                href="https://www.eurekabookhouse.co.id/product/detail/15737/spm-plus-usbn-sd-mi-2019-kisi-kisi">1.
                                                SPM PLUS USBN SD/MI 2019 KISI KISI</a></strong><br />
                                        <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-1025000341.jpg"
                                            style="height:313px; width:235px" /><br />
                                        <br />
                                        Rangkuman Materi Bahasa Indonesia, Matematika, dan Ilmu Pengetahuan Alam disusun sesuai
                                        dengan Lingkup Materi KisiKisi USBN 2019 disertai contoh soal dan pembahasannya.<br />
                                        Prediksi soal USBN 2019 Bahasa Indonesia, Matematika, dan Ilmu Pengetahuan Alam disusun
                                        sesuai dengan Lingkup Materi dan Level Kognitif (Pengetahuan dan Pemahaman, Aplikasi,
                                        serta Penalaran) KisiKisi USBN 2019. Komposisi soal terdiri atas Pilihan Ganda dan
                                        Uraian<br />
                                        Buku ini dilengkapi Strategi Sukses USBN 2019 yang berisi langkah-langkah apa saja yang
                                        sebaiknya dilakukan siswa untuk mempersiapkan diri menghadapi USBN 2019.<br />
                                        Dilengkapi media belajar digital berupa soal tryout USBN digital
                                    </p>
        
                                    <p><a
                                            href="https://www.eurekabookhouse.co.id/product/detail/15537/erlangga-fokus-un-2019-smp-mts"><strong>2.
                                                ERLANGGA FOKUS UN 2019 SMP/MTS</strong></a><br />
                                        <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-0033000500.jpg"
                                            style="height:415px; width:336px" /><br />
                                        <br />
                                        Erlangga Fokus UN SMP/MTs 2019 merupakan buku persiapan UN 2019. Buku ini dapat
                                        digunakan oleh semua sekolah, baik yang menggunakan KTSP maupun yang menggunakan
                                        Kurikulum 2013. Buku ini juga bisa digunakan untuk sekolah yang sudah melaksanakan UNBK
                                        karena dilengkapi CD yang berisi 12 paket soal UNBK (3 paket soal per mata pelajaran).
                                    </p>
        
                                    <p>Semakin dini persiapan dilakukan, semakin siap peserta didik menghadapi UN 2019. Buku ini
                                        menyajikan FORMULA KHUSUS agar peserta didik siap menghadapi UN 2019. Selain itu, buku
                                        ini juga ditujukan untuk peserta didik yang ingin meraih nilai terbaik di UN 2019.</p>
        
                                    <p><strong><a
                                                href="https://www.eurekabookhouse.co.id/product/detail/15827/detik-detik-usbn-sd-mi-2018-2019">3.
                                                DETIK USBN SD/MI 2018/2019</a></strong><br />
                                        <img alt=""
                                            src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-9789792827347.jpg"
                                            style="height:568px; width:400px" /><br />
                                        <br />
                                        Buku Detik-Detik USBN SD/MI Tahun 2018/2019 merupakan buku best seller yang diterbitkan
                                        oleh Intan Pariwara, buku ini mencakup :
                                    </p>
        
                                    <p>&nbsp;&nbsp;&nbsp; Materi USBN, Contoh Soal dan Pembahasan<br />
                                        &nbsp;&nbsp;&nbsp; Soal-Soal Latihan Standar USBN 2018/2019<br />
                                        &nbsp;&nbsp;&nbsp; 12 Paket Soal Tryout USBN 2018/2019<br />
                                        &nbsp;&nbsp;&nbsp; 9 Paket Soal Prediksi USBN 2018/2019</p>
        
                                    <p>Buku detik-detik disusun berdasarkan kisi-kisi USBN SD/MI tahun pelajaran 2018/2019 dari
                                        Badan Standar Nasional Pendidikan. Mata pelajaran matematika dengan lingkup materi
                                        bilangan, geometri dan pengukuran, serta pengolahan data. Mata pelajaran IPA dengan
                                        lingkup materi makhluk hidup dan lingkungannya, struktur dan fungsi makhluk hisup. Benda
                                        dan sifatnya, energi dan perubahannya, bumi dan alam semesta. Mata Pelajaran Matematika
                                        lingkup materi membaca nonsastra, membaca sastra, menulis terbatas, menyunting
                                        kata/istilah, frase, kalimat, paragraf, ejaan dan tanda baca.</p>
        
                                    <p><strong><a href="https://www.eurekabookhouse.co.id/product/detail/14887">4.&nbsp;&nbsp;
                                                &nbsp;BUPENA (BK. PENILAIAN) 5C/K13N</a></strong><br />
                                        <img alt="" src=" https://cdn.eurekabookhouse.co.id/ebh/product/all/0023713170.jpg"
                                            style="height:426px; width:336px" /><br />
                                        BUPENA Kurikulum 2013 Revisi berisi rangkaian proses pembelajaran yang disajikan dalam
                                        urutan yang sesuai dengan buku teks tematik pemerintah dengan mengakomodasi:
                                    </p>
        
                                    <p>&nbsp;&nbsp;&nbsp; Pendalaman Materi, berisi materi tambahan untuk mempertajam pemahaman
                                        siswa terhadap materi yang sedang dipelajari.<br />
                                        &nbsp;&nbsp;&nbsp; Kegiatan, disajikan bervariasi yang meliputi kinerja praktik, kinerja
                                        produk, proyek, atau portofolio. Disajikan pula contoh kegiatan terpilih yang dapat
                                        dijadikan instrument penilaian keterampilan dan sikap.<br />
                                        &nbsp;&nbsp;&nbsp; Ayo Berlatih, berisi soal-soal latihan untuk mengakomodasi penilaian
                                        aspek pengetahuan.<br />
                                        &nbsp;&nbsp;&nbsp; Tes Tertulis, berisi soal-soal pengetahuan yang disajikan sebagai
                                        contoh instrument penilaian ulangan harian.<br />
                                        &nbsp;&nbsp;&nbsp; Penugasan, berisi tugas untuk siswa yang dapat dijadikan sebagai
                                        komponen instrument penilaian ulangan harian.</p>
        
                                    <p><br />
                                        <strong><a
                                                href="https://www.eurekabookhouse.co.id/product/detail/15389/erlangga-x-press-usbn-sd-mi-2019-matematika">5.&nbsp;&nbsp;
                                                &nbsp;ERLANGGA X-PRESS USBN SD/MI 2019 MATEMATIKA</a></strong>
                                    </p>
        
                                    <p><br />
                                        <img alt=""
                                            src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-0025101340.jpg" /><br />
                                        <br />
                                        Dalam buku ini, kalian bisa mendapatkan fitur-fitur menarik, yaitu:<br />
                                        1. Pemantapan per Lingkup Materi, untuk memahami materi-materi yang akan diujikan pada
                                        USBN.<br />
                                        2. Telaah Soal Tipe USBN, untuk mengenali karakteristik soal-soal USBN yang pernah
                                        diujikan disertai pembahasannya.<br />
                                        3. Uji Kemampuan Soal Tipe USBN, untuk melatih diri menjawab soal tipe USBN per
                                        indikator dalam setiap kompetensinya.<br />
                                        4. Paket Simulasi USBN 2019, untuk mengukur kemampuan dalam mengerjakan soal tipe
                                        USBN.<br />
                                        5. Dilengkapi dengan LJK dan non LJK untuk berlatih mengisi jawaban seperti pada UBN
                                        sesungguhnya dan Kunci Jawaban untuk mengecek jawabanmu.
                                    </p>
        
                                    <p><br />
                                        <strong><a
                                                href="https://www.eurekabookhouse.co.id/product/detail/14915/bupena-bk-penilaian-2c-k13n">6.&nbsp;&nbsp;
                                                &nbsp;BUPENA (BK. PENILAIAN) 2C/K13N</a></strong>
                                    </p>
        
                                    <p><br />
                                        <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0023713050.jpg"
                                            style="height:434px; width:336px" /><br />
                                        <br />
                                        BUPENA Kurikulum 2013 Revisi berisi rangkaian proses pembelajaran yang disajikan dalam
                                        urutan yang sesuai dengan buku teks tematik pemerintah dengan mengakomodasi:
                                    </p>
        
                                    <p>Pendalaman Materi, berisi materi tambahan untuk mempertajam pemahaman siswa terhadap
                                        materi yang sedang dipelajari.<br />
                                        Kegiatan, disajikan bervariasi yang meliputi kinerja praktik, kinerja produk, proyek,
                                        atau portofolio. Disajikan pula contoh kegiatan terpilih yang dapat dijadikan instrument
                                        penilaian keterampilan dan sikap.<br />
                                        Ayo Berlatih, berisi soal-soal latihan untuk mengakomodasi penilaian aspek
                                        pengetahuan.<br />
                                        Tes Tertulis, berisi soal-soal pengetahuan yang disajikan sebagai contoh instrument
                                        penilaian ulangan harian.<br />
                                        Penugasan, berisi tugas untuk siswa yang dapat dijadikan sebagai komponen instrument
                                        penilaian ulangan harian.</p>
        
                                    <p><br />
                                        <strong><a
                                                href="https://www.eurekabookhouse.co.id/product/detail/15385/erlangga-x-press-usbn-sd-mi-2019-ipa">7.&nbsp;&nbsp;
                                                &nbsp;ERLANGGA X-PRESS USBN SD/MI 2019 IPA</a></strong>
                                    </p>
        
                                    <p><br />
                                        <img alt=""
                                            src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-0025700090.jpg" /><br />
                                        <br />
                                        Dalam buku ini, kalian bisa mendapatkan fitur-fitur menarik, yaitu:<br />
                                        1. Pemantapan per Lingkup Materi, untuk memahami materi-materi yang akan diujikan pada
                                        USBN.<br />
                                        2. Telaah Soal Tipe USBN, untuk mengenali karakteristik soal-soal USBN yang pernah
                                        diujikan disertai pembahasannya.<br />
                                        3. Uji Kemampuan Soal Tipe USBN, untuk melatih diri menjawab soal tipe USBN per
                                        indikator dalam setiap kompetensinya.<br />
                                        4. Paket Simulasi USBN 2019, untuk mengukur kemampuan dalam mengerjakan soal tipe
                                        USBN.<br />
                                        5. Dilengkapi dengan LJK dan non LJK untuk berlatih mengisi jawaban seperti pada UBN
                                        sesungguhnya dan Kunci Jawaban untuk mengecek jawabanmu.
                                    </p>
        
                                    <p><strong><a
                                                href="https://www.eurekabookhouse.co.id/product/detail/15527/erlangga-x-press-un-smk-mak-2019-b-inggris">8.
                                                ERLANGGA X-PRESS UN SMK/MAK 2019 B. INGGRIS</a></strong></p>
        
                                    <p><br />
                                        <img alt=""
                                            src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-produk09_30_32_Erlangga-X-Press-Un-Smk-Mak-2019-B--Inggris.jpg"
                                            style="height:420px; width:336px" /><br />
                                        <br />
                                        Erlangga X-Press UN SMK/MAK 2019 ini disusun oleh penulis yang berpengalaman dalam
                                        menyusun soal UN. Soal-soal yang disajikan sesuai dengan kisi-kisi Ujian Nasional.
                                        Selain diberikan 5 paket simulasi UN 2019, Erlangga X-Press UN SMK/MAK 2019&nbsp; ini
                                        juga dilengkapi dengan Lembar Jawaban Ujian Nasionalyang dapat membantu peserta didik
                                        untuk mengerjakan setiap paket simulasi UN. Diberikan juga kunci jawabanuntuk setiap
                                        paket simulasi UN dan uji kemampuan soal tipe UN.&nbsp;
                                    </p>
        
                                    <p><br />
                                        <strong><a
                                                href="https://www.eurekabookhouse.co.id/product/detail/14340/bupena-bk-penilaian-4d-k13n">9.&nbsp;&nbsp;
                                                &nbsp;BUPENA (BK. PENILAIAN) 4D/K13N</a></strong>
                                    </p>
        
                                    <p><br />
                                        <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0023713140.jpg"
                                            style="height:419px; width:336px" /><br />
                                        <br />
                                        BUPENA Kurikulum 2013 Revisi berisi rangkaian proses pembelajaran yang disajikan dalam
                                        urutan yang sesuai dengan buku teks tematik pemerintah dengan mengakomodasi:
                                    </p>
        
                                    <p>&nbsp;&nbsp;&nbsp; Pendalaman Materi, berisi materi tambahan untuk mempertajam pemahaman
                                        siswa terhadap materi yang sedang dipelajari.<br />
                                        &nbsp;&nbsp;&nbsp; Kegiatan, disajikan bervariasi yang meliputi kinerja praktik, kinerja
                                        produk, proyek, atau portofolio. Disajikan pula contoh kegiatan terpilih yang dapat
                                        dijadikan instrument penilaian keterampilan dan sikap.<br />
                                        &nbsp;&nbsp;&nbsp; Ayo Berlatih, berisi soal-soal latihan untuk mengakomodasi penilaian
                                        aspek pengetahuan.<br />
                                        &nbsp;&nbsp;&nbsp; Tes Tertulis, berisi soal-soal pengetahuan yang disajikan sebagai
                                        contoh instrument penilaian ulangan harian.<br />
                                        &nbsp;&nbsp;&nbsp; Penugasan, berisi tugas untuk siswa yang dapat dijadikan sebagai
                                        komponen instrument penilaian ulangan harian.</p>
        
                                    <p>&nbsp;</p>
        
                                    <p><strong><a
                                                href="https://www.eurekabookhouse.co.id/product/detail/14995/bupena-bk-penilaian-5d-k13n">10.
                                                BUPENA (BK. PENILAIAN) 5D/K13N</a></strong></p>
        
                                    <p><br />
                                        <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0023713180.jpg"
                                            style="height:445px; width:336px" /><br />
                                        &nbsp;
                                    </p>
        
                                    <p>BUPENA Kurikulum 2013 Revisi berisi rangkaian proses pembelajaran yang disajikan dalam
                                        urutan yang sesuai dengan buku teks tematik pemerintah dengan mengakomodasi: Pendalaman
                                        Materi, berisi materi tambahan untuk mempertajam pemahaman siswa terhadap materi yang
                                        sedang dipelajari. Kegiatan, disajikan bervariasi yang meliputi kinerja praktik, kinerja
                                        produk, proyek, atau portofolio. Disajikan pula contoh kegiatan terpilih yang dapat
                                        dijadikan instrument penilaian keterampilan dan sikap. Ayo Berlatih, berisi soal-soal
                                        latihan untuk mengakomodasi penilaian aspek pengetahuan. Tes Tertulis, berisi soal-soal
                                        pengetahuan yang disajikan sebagai contoh instrument penilaian ulangan harian.
                                        Penugasan, berisi tugas untuk siswa yang dapat dijadikan sebagai komponen instrument
                                        penilaian ulangan harian. Keunggulan produk: Model lembar penilaian disajikan dalam
                                        bentuk kegiatan terpilih yang mencakup kinerja praktik, kinerja produk, proyek, atau
                                        portofolio</p>
        
                                    <p>Sumber gambar header: <a
                                            href="https://edukasi.kompas.com/read/2014/03/15/1226131/UN.Terintegrasi.dengan.Seleksi.Perguruan.Tinggi"
                                            target="_blank"><span dir="ltr">edukasi.kompas.com</span></a></p>
                                </div>
                            </div>
                            <div class="media ava-bloger">
                                <img class="mr-3 rounded-circle img-thumbnail"
                                    src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                                    alt="Generic placeholder image">
                                <div class="media-body">
                                    <p class="stext-101 m-b-8">DITULIS OLEH</p>
                                    <h5 class="mt-0 mtext-111"></h5>
                                    <p class="badge badge-primary"></p>
                                    <p class="stext-107"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

<section class="sec-relate-product bg6 p-t-20 text-left">
    <div class="container p-tb-10">
        <div class="p-b-15">
            <h3 class="session-title mtext-106 cl5">New Entry</h3>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/kisahkasih'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/rahasiadelia'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pohonmangga'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/malaikat'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/ttgayah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/merah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/jejak'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pejuang'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">04 September 2022</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
        <footer class="bgu1 p-t-15 p-b-15">
            <div class="container">
                <div class="p-t-10">
                    <p class="stext-107 cl0 txt-center">
                        Copyright &copy; Dikembangkan oleh Eureka IT Developer
                    </p>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
    name: "limaPage",
}
</script>