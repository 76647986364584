<template>
    <div>
<div>
    <div class="bg1">
        <div class="">
            <div class="p-t-3 p-b-5">
                <nav class="">
                    <router-link :to="'/Blog/pejuang'" class="center">
                        <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                            alt="IMG-LOGO" class="center">
                    </router-link>
                </nav>
            </div>
        </div>
    </div>
<section class="bg0 p-b-20 text-left">
    <div class="container p-lr-80-lg">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <h1 class="ltext-102 cl2 p-t-28">
                    Buku TK Best seller Januari 2019 </h1>
                <div class="flex-m flex-sb m-tb-15">
                    <div class="flex-i flex-w flex-m stext-111 cl2">
                        <span>
                            <span class="cl4">Oleh</span> Eureka
                            <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <span>
                            17 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <div id="share" class="m-l-50-lg">
                            <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/buku-tk-best-seller-januari-2019"
                                target="_blank"><i class="fa fa-facebook"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/buku-tk-best-seller-januari-2019&amp;text=Blog: Buku TK Best seller Januari 2019 &amp;hashtags=eurekabookhouse"
                                title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/buku-tk-best-seller-januari-2019"
                                title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                        </div>
                    </div>
                    <div class="flex-r-m">
                        <span class="stext-107 text-center"><i class="fa fa-eye"></i> 4430</span>
                    </div>
                </div>
                <div class="wrap-pic-w how-pos5-parent">
                    <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/2014001631.jpg" alt="IMG-BLOG"
                        class="blog-read-pic">
                    <div class="flex-col-c-m size-123 bg9 how-pos5">
                        <span class="ltext-107 cl2 txt-center">
                            17 </span>

                        <span class="stext-109 cl3 txt-center">
                            11 2022 </span>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row p-t-35 p-b-80">
            <div class="col-lg-2">
            </div>
            <div class="col-md-8 col-lg-8">
                <div class="p-r-0-lg">
                    <div>
                        <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                            <h2><a
                                    href="https://www.eurekabookhouse.co.id/product/detail/13365/paket-anak-smart-plus-usia-4-5th-revisi-k2013"><strong>Paket
                                        Anak Smart Plus Usia 4-5Th/Revisi/K2013</strong></a></h2>

                            <p><strong><img alt=""
                                        src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2014001621.jpg"
                                        style="height:506px; width:300px" /></strong></p>

                            <p>Buku Anak Smart Plus disusun untuk mengembangkan kemampuan kognitif, afektif, berbahasa,
                                serta motorik halus.</p>

                            <p>Seluruh kegiatan dalam buku ini disesuaikan untuk menunjang kegiatan belajar anak dalam
                                mengenal huruf, angka, dan lingkungan sekitar melalui kegiatan belajar yang menyenangkan
                                sehingga anak semakin aktif dan cerdas.</p>

                            <p>Materi dalam buku ini dapat digunakan untuk menunjang proses belajar anak baik di sekolah
                                maupun di rumah.</p>

                            <p>&nbsp;</p>

                            <h2><a
                                    href="https://www.eurekabookhouse.co.id/product/detail/13366/paket-anak-smart-plus-usia-5-6th-revisi-k2013"><strong>Paket
                                        Anak Smart Plus Usia 5-6Th/Revisi/K2013</strong></a></h2>

                            <p><strong><img alt=""
                                        src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2014001631.jpg"
                                        style="height:508px; width:300px" /></strong></p>

                            <p>Buku Anak Smart Plus disusun untuk mengembangkan kemampuan kognitif, afektif, berbahasa,
                                serta motorik halus.</p>

                            <p>Seluruh kegiatan dalam buku ini disesuaikan untuk menunjang kegiatan belajar anak dalam
                                mengenal huruf, angka, dan lingkungan sekitar melalui kegiatan belajar yang menyenangkan
                                sehingga anak semakin aktif dan cerdas.</p>

                            <p>Materi dalam buku ini dapat digunakan untuk menunjang proses belajar anak baik di sekolah
                                maupun di rumah.</p>

                            <p>&nbsp;</p>

                            <h2><a
                                    href="https://www.eurekabookhouse.co.id/product/detail/15717/abacaga-cara-praktis-belajar-membaca-untuk-anak"><strong>Abacaga:
                                        Cara Praktis Belajar Membaca untuk Anak</strong></a></h2>

                            <p><img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-KP400006.jpg"
                                    style="height:448px; width:327px" /></p>

                            <p>Membaca adalah proses yang melibatkan aktivitas auditif (pendengaran) dan visual
                                (penglihatan). Dengan membaca, anak mampu memahami dan mencerna sebuah informasi.
                                Otomatis kemampuan ini merupakan kecakapan dasar yang wajib dikuasai oleh anak.
                                Bagaimana cara mengajari anak belajar membaca? Buku abacaga adalah jawabannya. Cukup 10
                                menit sehari! Buku ini menggunakan metode &ldquo;stepping stone&rdquo; yang mengajarkan
                                anak belajar membaca secara runut dan sistematis. Terdiri atas 6 tahapan belajar membaca
                                yang disusun sesuai dengan kemampuan anak untuk memahami kata, baik secara visual maupun
                                pelafalannya. Pola pembelajaran dan materinyapun lebih variatif, sehingga anak tidak
                                bosan dan secara tidak langsung penguasaan kosa kata anak akan bertambah. Dilengkapi
                                kartu &ldquo;abacaga&rdquo; yang digunakan sebagai pengetahuan dasar belajar membaca.
                                Kartu ini akan merangsang minat anak untuk belajar membaca melalui pengenalan abjad yang
                                dibantu dengan menganalogikan nama benda yang ada di sekeliling kita, sehingga akan
                                mudah untuk diingat.</p>

                            <p>&nbsp;</p>

                            <h2><a
                                    href="https://www.eurekabookhouse.co.id/product/detail/8221/paket-super-pintar-5-6-th-tk"><strong>Paket
                                        Super Pintar 5-6 Th/Tk</strong></a></h2>

                            <p><strong><img alt=""
                                        src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2014002040.jpg"
                                        style="height:300px; width:381px" /></strong></p>

                            <p>Seri Paket Super Pintar 5-6 Tahun disusun berdasarkan Tingkat Pencapaian Perkembangan
                                Anak usia 4-6 tahun sesuai tuntutan Permendiknas No. 58 Tahun 29. Seri ini juga
                                dilengkapi dengan nilai pendidikan dan karakter bangsa.Buku seri ini menekankan kegiatan
                                membaca, menulis, mendengar, dan berbicara agar anak semakin aktif terlibat dalam
                                keghiatan belajar. Dalam seri ini juga dilengkapi dengan kegiatan berhitung dan
                                kreativitas yang mengembangkan kemampuan kognitif dan logika anak. Rangkaian kegiatannya
                                dapat digunakan untuk menunjang proses belajar anak, baik di sekolah maupun di
                                rumah.Judul-judul dalam paket terdiri dari: Super Pintar Membaca 5-6 tahun semester 1
                                Super Pintar Membaca 5-6 tahun semester 2 Super Pintar Menulis 5-6 tahun semester 1
                                Super Pintar Menulis 5-6 tahun semester 2 Super Pintar Berhitung 5-6 tahun semester 1
                                Super Pintar Berhitung 5-6 tahun semester 2 Super Pintar Lembar Kegiatan Kerja 5-6
                                tahunKeunggulan produk: Ditulis oleh penulis yang berkompeten di bidangnya. Sesuai
                                dengan Permendiknas No. 58 Tahun 29 tentang pendidikan anak usia dini. Mencantumkan
                                NILAI PENDIDIKAN BUDAYA DAN KARAKTER BANGSA beserta pengembangannya.</p>

                            <p>&nbsp;</p>

                            <h2><a
                                    href="https://www.eurekabookhouse.co.id/product/detail/13339/4sahabat-anak-cerdas-berbahasa-usia-5-6th"><strong>4Sahabat-Anak
                                        Cerdas: Berbahasa Usia 4-5Th</strong></a></h2>

                            <p><img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2014002900.jpg"
                                    style="height:377px; width:285px" /></p>

                            <p>Seri Buku Anak Cerdas Usia 4-5 Tahun disusun berdasarkan standar tingkat pencapaian
                                perkembangan anak usia 4-5 tahun, sebagaimana tercantum dalam Kurikulum Pendidikan Anak
                                Usia Dini (PAUD). Setiap aktivitasnya dikembangkan berdasarkan Kompetensi
                                Inti/Kompetensi Dasar dalam Kurikulum PAUD, yang berguna untuk mengembangkan dan
                                mengasah aspek-aspek perkembangan anak, meliputi aspek afektif, kognitif, bahasa, dan
                                psikomotrik.</p>

                            <p>Aktivitas disajikan secara TEMATIK dengan pendekatan SAINTIFIK sehingga anak dapat
                                belajar dengan maksimal sesuai perkembangannya. Selain itu, seri buku ini juga
                                menanamkan nilai-nilai pendidikan budaya dan karakter bangsa yang terintegrasi dalam
                                setiap aktivitas.</p>

                            <p>&nbsp;</p>

                            <p><strong>Keunggulan produk:</strong></p>

                            <p>1. Buku aktivitas yang menggunakan karakter lokal 4 Sahabat.</p>

                            <p>2. Menggunakan pendekatan saintifik dan Kompetensi Inti dan Kompetensi Dasar sesuai
                                Kurikulum PAUD.</p>

                            <p>3. Dilengkapi stiker reward dan sertifikat.</p>

                            <p>4. Mencantumkan nilai-nilai pendidikan budaya dan karakter bangsa.</p>

                            <p>5. Merupakan buku aktivitas utama di sekolah.</p>

                            <p>&nbsp;</p>

                            <h2><a
                                    href="https://www.eurekabookhouse.co.id/product/detail/13364/paket-sbbaa-belajar-calistung-pend-saintifik-usia-5-6th"><strong>Paket
                                        Sbbaa: Belajar Calistung-Pend. Saintifik Usia 5-6Th</strong></a></h2>

                            <p><strong><img alt=""
                                        src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2014003040.jpg"
                                        style="height:267px; width:448px" /></strong></p>

                            <p>Dalam setiap paket produk ini terdiri atas:</p>

                            <p>Buku Pintar Membaca sms 1 dan 2, Pintar Menulis sms 1 dan 2, Pintar Berhitung sms 1 dan
                                2, Kreativitas, dan BONUS buku TABUNGAN SISWA. Aktivitas dalam setiap buku disajikan
                                untuk mengasah keterampilan BERBAHASA, KOGNITIF, MOTORIK, dan SENI anak, sesuai tingkat
                                pencapaian perkembangan anak usia 4 -5 tahun dan 5 - 6 tahun.</p>

                            <p>Materi disajikan dengan pendekatan SAINTIFIK-TEMATIK (sebagaimana hakikat pembelajaran
                                Kurikulum 2013 jenjang PAUD) dengan mengkolaborasikan sisi-sisi keagamaan, terutama
                                agama Islam. Materi dalam buku ini juga menyajikan aktivitas-aktivitas yang
                                mengintegrasikan pananaman Nilai Pendidikan Budaya &amp;, Karakter Bangsa.</p>

                            <p>&nbsp;</p>
                        </div>
                    </div>
                    <div class="media ava-bloger">
                        <img class="mr-3 rounded-circle img-thumbnail"
                            src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                            alt="Generic placeholder image">
                        <div class="media-body">
                            <p class="stext-101 m-b-8">DITULIS OLEH</p>
                            <h5 class="mt-0 mtext-111">Arif Hendras</h5>
                            <p class="badge badge-primary">Content Writer</p>
                            <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="sec-relate-product bg6 p-t-20 text-left">
    <div class="container p-tb-10">
        <div class="p-b-15">
            <h3 class="session-title mtext-106 cl5">New Entry</h3>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/kisahkasih'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/rahasiadelia'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pohonmangga'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/malaikat'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/ttgayah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/merah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/jejak'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pejuang'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">04 September 2022</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <footer class="bgu1 p-t-15 p-b-15">
        <div class="container">
            <div class="p-t-10">
                <p class="stext-107 cl0 txt-center">
                    Copyright &copy; Dikembangkan oleh Eureka IT Developer
                </p>
            </div>
        </div>
    </footer>
</div>
    </div>
</template>
<script>
export default {
    name: "enamPage",
}
</script>