<template>
    <div>
            <div class="bg1">
                <div class="">
                    <div class="p-t-3 p-b-5">
                        <nav class="">
                            <a href="https://www.eurekabookhouse.co.id/" class="center">
                                <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                                    alt="IMG-LOGO" class="center">
                            </a>
                        </nav>
                    </div>
                </div>
            </div>
        <section class="bg1" style="height: 300px">
            <div class="text-center">
                <h1 class="mtext-111 cl0" style="padding-top: 2%">Blog</h1>
                <p class="stext-102 cl0">Kumpulan post berisi berita/informasi menarik yang telah dirangkum oleh tim</p>
            </div>
        </section>
        <section class="bg6 p-b-60 m-t--100 text-left">
            <div class="container">
                <div class="row">
                    <div class="col-md-9 p-tb-30 bg0 bor4 m-t--60 bs1">
                        <div class="p-r-45 p-r-0-lg">
                                                    <div class="p-tb-30">
                                <router-link :to="'/Blog/kisahkasih'" class="hov-img0 how-pos5-parent"> 
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg" alt="IMG-BLOG">
        
                                        <div class="flex-col-c-m size-123 bg9 how-pos5">
                                            <span class="ltext-107 cl2 txt-center">
                                                16
                                            </span>
        
                                            <span class="stext-109 cl3 txt-center">
                                                30 August 2022									</span>
                                        </div>
                                    </router-link>>
        
                                    <div class="p-t-32">
                                        <h4 class="p-b-15">
                                            <router-link :to="'/Blog/kisahkasih'" class="ltext-108 cl2 hov-cl1 trans-04">
                                           
                                                KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE									</router-link>
                                        </h4>
        
                                        <p class="stext-117 cl6">
                                            &nbsp;
        
        Seorang gadis berambut hitam legam duduk termenung di depan foto mendiang ibunya. Ia terlihat menahan air matanya yang sudah siap jatuh itu. Gadis itu bernama Siti Maysaroh, siswi 								</p>
        
                                        <div class="flex-w flex-sb-m p-t-18">
                                            <span class="flex-w flex-m stext-111 cl2 p-r-30 m-tb-10">
                                                <span>
                                                    <span class="cl4">oleh</span> Eureka  
                                                    <span class="cl12 m-l-4 m-r-6">|</span>
                                                </span>
        
                                                <span>
                                                    Sahabat Eureka										</span>
                                            </span>
        
                                            <router-link :to="'/Blog/kisahkasih'" class="stext-101 btn btn-primary m-tb-10">
                                                Lanjut baca
                                                <i class="fa fa-long-arrow-right m-l-9"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                                    <div class="p-tb-30">
                                    <router-link :to="'/Blog/rahasiadelia'" class="hov-img0 how-pos5-parent">
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg" alt="IMG-BLOG">
        
                                        <div class="flex-col-c-m size-123 bg9 how-pos5">
                                            <span class="ltext-107 cl2 txt-center">
                                                16
                                            </span>
        
                                            <span class="stext-109 cl3 txt-center">
                                                30 August 2022									</span>
                                        </div>
                                    </router-link>
        
                                    <div class="p-t-32">
                                        <h4 class="p-b-15">
                                            <router-link :to="'/Blog/rahasiadelia'" class="ltext-108 cl2 hov-cl1 trans-04">
                                                RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE</router-link>
                                        </h4>
        
                                        <p class="stext-117 cl6">
                                            Aku sedang mengetik tugas kantor ketika Delia datang. &ldquo;Pa, minggu depan uang buku mau dibayar.&rdquo;&nbsp;
        
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aku te								</p>
        
                                        <div class="flex-w flex-sb-m p-t-18">
                                            <span class="flex-w flex-m stext-111 cl2 p-r-30 m-tb-10">
                                                <span>
                                                    <span class="cl4">oleh</span> Eureka  
                                                    <span class="cl12 m-l-4 m-r-6">|</span>
                                                </span>
        
                                                <span>
                                                    Sahabat Eureka										</span>
                                            </span>
        
                                            <router-link :to="'/Blog/rahasiadelia'" class="stext-101 btn btn-primary m-tb-10">
                                                Lanjut baca
                                                <i class="fa fa-long-arrow-right m-l-9"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                                    <div class="p-tb-30">
                                    <router-link :to="'/Blog/pohonmangga'" class="hov-img0 how-pos5-parent">
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg" alt="IMG-BLOG">
        
                                        <div class="flex-col-c-m size-123 bg9 how-pos5">
                                            <span class="ltext-107 cl2 txt-center">
                                                16
                                            </span>
        
                                            <span class="stext-109 cl3 txt-center">
                                                30 August 2022									</span>
                                        </div>
                                    </router-link>
        
                                    <div class="p-t-32">
                                        <h4 class="p-b-15">
                                            <router-link :to="'/Blog/pohonmangga'" class="ltext-108 cl2 hov-cl1 trans-04">
                                                POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE									</router-link>
                                        </h4>
        
                                        <p class="stext-117 cl6">
                                            Tiga bulan berturut-turut bapak keluar masuk rumah sakit. Mulai dari pertengahan&nbsp;bulan puasa pada bulan Mei, hingga bulan Juli lalu. Jauh sebelum itu, aku yang kala itu&nbsp;duduk dibangku SMA								</p>
        
                                        <div class="flex-w flex-sb-m p-t-18">
                                            <span class="flex-w flex-m stext-111 cl2 p-r-30 m-tb-10">
                                                <span>
                                                    <span class="cl4">oleh</span> Eureka  
                                                    <span class="cl12 m-l-4 m-r-6">|</span>
                                                </span>
        
                                                <span>
                                                    Sahabat Eureka										</span>
                                            </span>
        
                                             <router-link :to="'/Blog/pohonmangga'" class="stext-101 btn btn-primary m-tb-10">
                                                Lanjut baca
                                                <i class="fa fa-long-arrow-right m-l-9"></i>
                                             </router-link>
                                        </div>
                                    </div>
                                </div>
                                                    <div class="p-tb-30">
                                    <router-link :to="'/Blog/malaikat'" class="hov-img0 how-pos5-parent">
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg" alt="IMG-BLOG">
        
                                        <div class="flex-col-c-m size-123 bg9 how-pos5">
                                            <span class="ltext-107 cl2 txt-center">
                                                16
                                            </span>
        
                                            <span class="stext-109 cl3 txt-center">
                                                30 August 2022									</span>
                                        </div>
                                    </router-link>
        
                                    <div class="p-t-32">
                                        <h4 class="p-b-15">
                                            <router-link :to="'/Blog/malaikat'" class="ltext-108 cl2 hov-cl1 trans-04">
                                                MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE									</router-link>
                                        </h4>
        
                                        <p class="stext-117 cl6">
                                            Malam ini hujan deras melanda kota kelahiranku. Petir menyambar seakan Tuhan sedang murka dengan penduduk bumi. Hempasan angin dingin yang tiada henti perlahan menerpa kulit wajahku yang saat ini s								</p>
        
                                        <div class="flex-w flex-sb-m p-t-18">
                                            <span class="flex-w flex-m stext-111 cl2 p-r-30 m-tb-10">
                                                <span>
                                                    <span class="cl4">oleh</span> Eureka  
                                                    <span class="cl12 m-l-4 m-r-6">|</span>
                                                </span>
        
                                                <span>
                                                    Sahabat Eureka										</span>
                                            </span>
        
                                            <router-link :to="'/Blog/malaikat'" class="stext-101 btn btn-primary m-tb-10">
                                                Lanjut baca
                                                <i class="fa fa-long-arrow-right m-l-9"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                                    <div class="p-tb-30">
                                <router-link :to="'/Blog/ttgayah'" class="hov-img0 how-pos5-parent">
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg" alt="IMG-BLOG">
        
                                        <div class="flex-col-c-m size-123 bg9 how-pos5">
                                            <span class="ltext-107 cl2 txt-center">
                                                16
                                            </span>
        
                                            <span class="stext-109 cl3 txt-center">
                                                30 August 2022									</span>
                                        </div>
                                </router-link>
        
                                    <div class="p-t-32">
                                        <h4 class="p-b-15">
                                            <router-link :to="'/Blog/ttgayah'" class="ltext-108 cl2 hov-cl1 trans-04">
                                                KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE									</router-link>
                                        </h4>
        
                                        <p class="stext-117 cl6">
                                            Aku berjalan di lorong rumah sakit. Beberapa perawat dan dokter menyapaku. Hingga aku tiba di sebuah ruangan bertuliskan &ldquo;Ruangan Dr. Anatasya&rdquo;. Itu ruanganku. Aku beke								</p>
        
                                        <div class="flex-w flex-sb-m p-t-18">
                                            <span class="flex-w flex-m stext-111 cl2 p-r-30 m-tb-10">
                                                <span>
                                                    <span class="cl4">oleh</span> Eureka  
                                                    <span class="cl12 m-l-4 m-r-6">|</span>
                                                </span>
        
                                                <span>
                                                    Sahabat Eureka										</span>
                                            </span>
        
                                        <router-link :to="'/Blog/ttgayah'" class="stext-101 btn btn-primary m-tb-10">
                                                Lanjut baca
                                                <i class="fa fa-long-arrow-right m-l-9"></i>
                                        </router-link>
                                        </div>
                                    </div>
                                </div>
                                                    <div class="p-tb-30">
                                    <router-link :to="'/Blog/merah'" class="hov-img0 how-pos5-parent">
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg" alt="IMG-BLOG">
        
                                        <div class="flex-col-c-m size-123 bg9 how-pos5">
                                            <span class="ltext-107 cl2 txt-center">
                                                16
                                            </span>
        
                                            <span class="stext-109 cl3 txt-center">
                                                30 August 2022									</span>
                                        </div>
                                    </router-link>
        
                                    <div class="p-t-32">
                                        <h4 class="p-b-15">
                                        <router-link :to="'/Blog/merah'" class="ltext-108 cl2 hov-cl1 trans-04">
                                                SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE									</router-link>
                                        </h4>
        
                                        <p class="stext-117 cl6">
                                            Aku mengusap batu yang salah satu ujungnya menghadap ke arah timur. Permukaan benda berukuran sekepal tangan orang dewasa itu terasa hangat oleh pancaran mentari. Sekali lagi air mataku mengalir de								</p>
        
                                        <div class="flex-w flex-sb-m p-t-18">
                                            <span class="flex-w flex-m stext-111 cl2 p-r-30 m-tb-10">
                                                <span>
                                                    <span class="cl4">oleh</span> Eureka  
                                                    <span class="cl12 m-l-4 m-r-6">|</span>
                                                </span>
        
                                                <span>
                                                    Sahabat Eureka										</span>
                                            </span>
        
                                            <router-link :to="'/Blog/merah'" class="stext-101 btn btn-primary m-tb-10">
                                                Lanjut baca
                                                <i class="fa fa-long-arrow-right m-l-9"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                                    <div class="p-tb-30 padding: 15px;">
                                    <router-link :to="'/Blog/jejak'" class="hov-img0 how-pos5-parent">
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg" alt="IMG-BLOG">
        
                                        <div class="flex-col-c-m size-123 bg9 how-pos5">
                                            <span class="ltext-107 cl2 txt-center">
                                                16
                                            </span>
        
                                            <span class="stext-109 cl3 txt-center">
                                                30 August 2022									</span>
                                        </div>
                                    </router-link>
        
                                    <div class="p-t-32">
                                        <h4 class="p-b-15">
                                            <router-link :to="'/Blog/jejak'" class="ltext-108 cl2 hov-cl1 trans-04">
                                                JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE									</router-link>
                                        </h4>
        
                                        <p class="stext-117 cl6">
                                            Saya&nbsp; bersekolah di salah satu sekolah berasrama yang bergengsi di Kota Malang. Saya&nbsp; menjadi satu dari lima puluh siswa yang beruntung karena dapat sekolah di sana meskipun bukan dari go								</p>
        
                                        <div class="flex-w flex-sb-m p-t-18">
                                            <span class="flex-w flex-m stext-111 cl2 p-r-30 m-tb-10">
                                                <span>
                                                    <span class="cl4">oleh</span> Eureka  
                                                    <span class="cl12 m-l-4 m-r-6">|</span>
                                                </span>
        
                                                <span>
                                                    Sahabat Eureka										</span>
                                            </span>
        
                                            <router-link :to="'/Blog/jejak'" class="stext-101 btn btn-primary m-tb-10">
                                                Lanjut baca
                                                <i class="fa fa-long-arrow-right m-l-9"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                                    <div class="p-tb-30">
                                    <router-link :to="'/Blog/pejuang'" class="hov-img0 how-pos5-parent">
                                        <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg" alt="IMG-BLOG">
        
                                        <div class="flex-col-c-m size-123 bg9 how-pos5">
                                            <span class="ltext-107 cl2 txt-center">
                                                16
                                            </span>
        
                                            <span class="stext-109 cl3 txt-center">
                                                30 August 2022									</span>
                                        </div>
                                        
                                    </router-link>
        
                                    <div class="p-t-32">
                                        <h4 class="p-b-15">
                                            <router-link :to="'/Blog/pejuang'" class="ltext-108 cl2 hov-cl1 trans-04">
                                                PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE									</router-link>
                                        </h4>
        
                                        <p class="stext-117 cl6">
                                            Kisah ini berasal dari keluarga sederhana yang hidup di sebuah desa yang terdiri dari ayah, ibu dan seorang anak.
        
        Ayah yang bernama Budiman biasa dipanggil pak Budi, ibu yang bernama Sriy								</p>
        
                                        <div class="flex-w flex-sb-m p-t-18">
                                            <span class="flex-w flex-m stext-111 cl2 p-r-30 m-tb-10">
                                                <span>
                                                    <span class="cl4">oleh</span> Eureka  
                                                    <span class="cl12 m-l-4 m-r-6">|</span>
                                                </span>
        
                                                <span>
                                                    Acara Toko										</span>
                                            </span>
        
                                            <router-link :to="'/Blog/pejuang'" class="stext-101 btn btn-primary m-tb-10">
                                                Lanjut baca
                                                <i class="fa fa-long-arrow-right m-l-9"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            
                            <!-- Pagination -->
        <!-- 						<div class="flex-l-m flex-w w-full p-t-10 m-lr--7">
                                    <a href="#" class="flex-c-m how-pagination1 trans-04 m-all-7 active-pagination1">
                                        1
                                    </a>
        
                                    <a href="#" class="flex-c-m how-pagination1 trans-04 m-all-7">
                                        2
                                    </a>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-md-3 p-b-80 m-t--60">
                            <div class="side-menu bg0 bor4 p-all-15 bs1 d-none d-md-block d-lg-block d-xl-block">
                                <div class="">
                                    <h4 class="mtext-112 cl2 p-b-33">
                                        Kategori
                                    </h4>
                                    <ul>
                                                                            <li class="bor18">
                                                <router-link :to="'/Blog/acaratoko'" class="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4">
                                                    Acara Toko										</router-link>
                                            </li>
                                                                            <li class="bor18">
                                                <router-link :to="'/Blog/rekomondasi'" class="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4">
                                                    Rekomendasi Buku										</router-link>
                                            </li>
                                                                            <li class="bor18">
                                                <router-link :to="'/Blog/promosi'" class="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4">
                                                    Promosi										</router-link>
                                            </li>
                                                                            <li class="bor18">
                                                <router-link :to="'/Blog/review'" class="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4">
                                                    Review Buku										</router-link>
                                            </li>
                                                                            <li class="bor18">
                                                <router-link :to="'/Blog/triva'" class="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4">
                                                    Trivia										</router-link>
                                            </li>
                                                                            <li class="bor18">
                                                <router-link :to="'/Blog/fiksi'" class="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4">
                                                    Fiksi										</router-link>
                                            </li>
                                                                            <li class="bor18">
                                                <router-link :to="'/Blog/nonfiksi'" class="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4">
                                                    Non-Fiksi										</router-link>
                                            </li>
                                                                            <li class="bor18">
                                                <router-link :to="'/Blog/date'" class="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4">
                                                    Update										</router-link>
                                            </li>
                                                                            <li class="bor18">
                                                <router-link :to="'/Blog/allblog'" class="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4">
                                                    Sahabat Eureka										</router-link   >
                                            </li>
                                                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>	 
    </div>
</template>
<script scope>
    export default {
      name: "blogPage",
    }
    </script>