<template>
    <div>
<div>
    <div class="bg1">
        <div class="">
            <div class="p-t-3 p-b-5">
                <nav class="">
                    <router-link :to="'/Blog/pejuang'" class="center">
                        <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                            alt="IMG-LOGO" class="center">
                    </router-link>
                </nav>
            </div>
        </div>
    </div>
<section class="bg0 p-b-20 text-left">
    <div class="container p-lr-80-lg">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <h1 class="ltext-102 cl2 p-t-28">
                    10 Buku SMK Best Seller Januari 2019 </h1>
                <div class="flex-m flex-sb m-tb-15">
                    <div class="flex-i flex-w flex-m stext-111 cl2">
                        <span>
                            <span class="cl4">Oleh</span> Eureka
                            <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <span>
                            17 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <div id="share" class="m-l-50-lg">
                            <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/10-buku-smk-best-seller-januari-2019"
                                target="_blank"><i class="fa fa-facebook"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/10-buku-smk-best-seller-januari-2019&amp;text=Blog: 10 Buku SMK Best Seller Januari 2019 &amp;hashtags=eurekabookhouse"
                                title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/10-buku-smk-best-seller-januari-2019"
                                title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                        </div>
                    </div>
                    <div class="flex-r-m">
                        <span class="stext-107 text-center"><i class="fa fa-eye"></i> 3954</span>
                    </div>
                </div>
                <div class="wrap-pic-w how-pos5-parent">
                    <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/siswa-smk-57e49410719373ab26bf8458.jpg"
                        alt="IMG-BLOG" class="blog-read-pic">
                    <div class="flex-col-c-m size-123 bg9 how-pos5">
                        <span class="ltext-107 cl2 txt-center">
                            17 </span>

                        <span class="stext-109 cl3 txt-center">
                            11 2022 </span>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row p-t-35 p-b-80">
            <div class="col-lg-2">
            </div>
            <div class="col-md-8 col-lg-8">
                <div class="p-r-0-lg">
                    <div>
                        <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/15157/korespondensi-manajemen-perkantoran-smk-mak-kls-x-k13n">1.&nbsp;KORESPONDENSI
                                        MANAJEMEN PERKANTORAN SMK/MAK KLS.X</a></strong><br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0056510070.jpg"
                                    style="height:250px; width:176px" /><br />
                                <br />
                                Buku Korespondensi untuk SMK/MAK Kelas X ini disusun berlandaskan Kurikulum 2013 KI/KD
                                2017. Buku ini mencakup materi-materi tentang cara berkomunikasi dan melakukan
                                korespondensi atau kegiatan surat-menyurat di lingkungan perusahaan. Dengan mempelajari
                                buku ini, peserta didik diharapkan mampu memahami dan menerapkan tata cara berkomunikasi
                                dan berkorespondensi&nbsp; dengan baik.
                            </p>

                            <p><strong>2.&nbsp;PEND. PANCASILA &amp; KEWARGANEGARAAN SMK JL.3/K13N</strong><br />
                                <img alt=""
                                    src="https://s0.bukalapak.com/img/0581135903/w-300/PENDIDIKAN_PANCASILA_DAN_KEWARGANEGARAAN_SMK_JL_3_K13N.jpg.webp"
                                    style="height:250px; width:175px" />
                            </p>

                            <p><br />
                                Buku Pendidikan Pancasila dan Kewarganegaraan untuk SMK/MAK Kelas XII ini disusun
                                berdasarkan Kurikulum 2013 yang dimaksudkan untuk mengembangkan kesadaran dan wawasan
                                kebangsaan yang dilandasi nilai-nilai Pancasila. Sejalan dengan semangat pembelajaran
                                dalam kurilukum 2013, materi dalam buku ini disajikan menggunakan pendekatan ilmiah
                                (scientific approach), yaitu mengamati, menanya, mengumpulkan informasi,
                                mengasosiasikan, dan mengkomunikasikan.</p>

                            <p><br />
                                <strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/15729/sejarah-indonesia-smk-jl-1-kikd17">3.
                                        SEJARAH INDONESIA SMK JL.1/KIKD17</a></strong>
                            </p>

                            <p><br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-0059070013.jpg"
                                    style="height:250px; width:174px" /><br />
                                <br />
                                Buku ini disusun dengan mengacu pada Kurikulum 2013 (KI-KD 2017). Sebagaimana
                                diamanatkan pemerintah, mulai tahhun ajaran 2017/2018, mata pelajaran Sejarah Indonesia
                                diajarkan hanya di Kelas X. Materinya mencakup seluruh Kompetensi Dasar (KD) Kelas X,
                                XI, dan XII dari kurikulum sebelumnya. maka, tantangan utama dalam proses penulisan buku
                                ini adalah banyakny aKD yang harus dipelajari.
                            </p>

                            <p>Karena kepadatan materi tidak bisa dihindarkan, hal penting yang sangat kami perhatikan
                                adalah narasi yang mengalir, logis, dan mudah dipahami. Selain akan mendapatkan gambaran
                                yang utuh tentang persoalan masa lalu bangsa kita, narasi yang muidah dipahami
                                diharapkan membuat peserta didik dapat mengikuti dan mencerna setiap materi dengan
                                cepat. Dengan demikian, pada akhir tahun pelajaran seluruh KD dapat dikuasai dengan
                                baik.</p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/15097/fisika-bid-keahlian-teknologi-danamp-rekayasa-smk-kls-x-kikd17">4.
                                        FISIKA BID. KEAHLIAN TEKNOLOGI &amp; REKAYASA SMK KLS</a></strong></p>

                            <p><br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0055300071.jpg"
                                    style="height:250px; width:175px" /><br />
                                &nbsp;
                            </p>

                            <p>Info Fisika, berisi petunjuk ringkas tentang materi yang dibahas.<br />
                                Physicsnet, Berisi alamat situs internet yang menyajikan informasi yang berkaitan dengan
                                materi .<br />
                                Uji Kemampuan Diri, Berisi soal esai untuk menguji pemahaman peserta didik mengenai
                                konsep .<br />
                                Latihan Akhir Bab, Berisi soal-soal pilihan ganda dan esai untuk mengukur pengetahuan
                                peserta didik.</p>

                            <p>Target konsumen: Peserta didik di SMK/MAK Bidang Keahlian Teknologi dan Rekayasa, guru,
                                serta orang tua. Strategi promosi dan sosialisasi produk. Dilengkapi kunci jawaban, rpp
                                silabus dan media mengajar</p>

                            <p><br />
                                <strong>5. KEARSIPAN (AHLI BISNIS&amp;MANAJEMEN) KLS.X/KIKD17</strong>
                            </p>

                            <p><br />
                                <img alt=""
                                    src="https://ecs7.tokopedia.net/img/cache/700/product-1/2018/8/19/1381382/1381382_6056c0f2-7d37-4b29-a4de-aa53be73c3d2_500_718.jpg"
                                    style="height:250px; width:174px" />
                            </p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/14545/administrasi-pajak-smk-mak-kls-xii-k2013">6.
                                        ADMINISTRASI PAJAK SMK/MAK KLS.XII/K2013</a></strong><br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0056570100.jpg"
                                    style="height:250px; width:176px" /><br />
                                <br />
                                Buku Administrasi Pajak (Paket Keahlian Akuntansi) untuk SMK/MAK Kelas XII ini disusun
                                berdasarkan Kurikulum 2013. Buku ini menekankan baik kelengkapan materi atau konsep
                                maupun contoh-contoh kasus.&nbsp; Di bagian akhir setiap bab, tingkat pemahaman konsep
                                peserta didik diuji melalui beragam bentuk soal evaluasi. Dengan demikian, peserta didik
                                diharapkan dapat menerapkan berbagai hal terkait dengan administrasi pajak.
                            </p>

                            <p><strong>7. SISTEM KOMPUTER BID. TIK SMK/MAK KLS.X/KIKD17</strong></p>

                            <p><br />
                                <img alt="" src="http://erlangga.co.id/RAK/rak-penerbit-erlangga/rak4/0050040051.jpg"
                                    style="height:250px; width:177px" /><br />
                                BukuSistem Komputer untuk SMK/MAK Kelas X Program Kehlian Teknik Telekomunikasi serta
                                Teknik Komputer dan Informatika (C2) ini disusun berdasarkan Kurikulum 2013 KI KD 2017.
                                Buku ini menyajikan fitur pelengkap yang memudahkan peserta didik dalam memahami materi
                                dan mengembangkan wawasannya
                            </p>

                            <p><strong>8. MATEMATIKA SMK/MAK KLS.XI/KIKD17</strong><br />
                                <img alt=""
                                    src="http://www.erlangga.co.id/RAK/rak-penerbit-erlangga/rak4/0055100222.jpg"
                                    style="height:250px; width:176px" /><br />
                                Buku Matematika untuk SMK/MAK Kelas X ini disusun dengan mengacu pada Kurikulum 2013
                                (KI-KD 2017). Penjelasan dalam buku ini didesain sedemikian rupa sehingga peserta didik
                                dapat dengan mudah memahami materi yang dipelajari.
                            </p>

                            <p><strong><a href="https://www.eurekabookhouse.co.id/product/detail/15103/">9.&nbsp;&nbsp;
                                        &nbsp;IPA SMK BID. KEAHLIAN BISNIS &amp; MANAJEMEN KLS.X/KIK</a></strong><br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0055000040.jpg"
                                    style="height:250px; width:174px" /><br />
                                <br />
                                Buku IPA Bidang Keahlian Bisnis dan Manajemen untuk SMK/MAK kelas X ini disusun
                                berdasarkan Kurikulum 2013 KI-KD 2017
                            </p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/15091/biologi-smk-bid-kesehatan-jl-1-kikd17">10.
                                        BIOLOGI SMK BID. KESEHATAN JL.1/KIKD17</a></strong><br />
                                <br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0055700030.jpg"
                                    style="height:250px; width:174px" /><br />
                                Isi sangat jelas dan komprehensif dilengkapi praktik yang aplikatif.<br />
                                Konsep-konsep disajikan dengan pembahasan yang jelas dan lugas.<br />
                                Penjelasan disertai dengan gambar-gambar yang relevan sehingga peserta didik dapat lebih
                                cepat memahami materi.<br />
                                Soal-soal mengikuti rambu-rambu penyusunan soal yang baik.<br />
                                Terdapat fitur tambahan yang membantu peserta didik dalam mengembangkan wawasannya.
                            </p>

                            <p>&nbsp;</p>

                            <p>sumber gambar header: <a href="http://kompasiana.com">kompasiana.com</a></p>
                        </div>
                    </div>
                    <div class="media ava-bloger">
                        <img class="mr-3 rounded-circle img-thumbnail"
                            src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                            alt="Generic placeholder image">
                        <div class="media-body">
                            <p class="stext-101 m-b-8">DITULIS OLEH</p>
                            <h5 class="mt-0 mtext-111"></h5>
                            <p class="badge badge-primary"></p>
                            <p class="stext-107"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="sec-relate-product bg6 p-t-20 text-left">
    <div class="container p-tb-10">
        <div class="p-b-15">
            <h3 class="session-title mtext-106 cl5">New Entry</h3>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/kisahkasih'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/rahasiadelia'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pohonmangga'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/malaikat'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/ttgayah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/merah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/jejak'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pejuang'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">04 September 2022</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <footer class="bgu1 p-t-15 p-b-15">
        <div class="container">
            <div class="p-t-10">
                <p class="stext-107 cl0 txt-center">
                    Copyright &copy; Dikembangkan oleh Eureka IT Developer
                </p>
            </div>
        </div>
    </footer>
</div>
    </div>
</template>
<script>
export default {
    name: "tigaPage",
}
</script>