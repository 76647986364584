<template>
    
    <div>
        <div class="bg1">
            <div class="">
                <div class="p-t-3 p-b-5">
                    <nav class="">
                        <a href="https://www.eurekabookhouse.co.id/" class="center">
                            <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                                alt="IMG-LOGO" class="center">
                        </a>
                    </nav>
                </div>
            </div>
        </div>
        <div class="container mobile-blog-rapi-atas">
            <div class="bread-crumb flex-w p-tb-18 p-lr-0-lg">
                <a href="https://www.eurekabookhouse.co.id/" class="stext-109 cl8 hov-cl1 trans-04">
                    Home
                    <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
                </a>
    
                <a href="https://www.eurekabookhouse.co.id/blog" class="stext-109 cl8 hov-cl1 trans-04">
                    Blog
                    <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
                </a>
    
                <span class="stext-109 cl4">
                    RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE			</span>
            </div>
        </div>
    
        <!-- Content page -->
        <section class="bg0 p-b-20 text-left">
            <div class="container p-lr-80-lg">
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                        <h1 class="ltext-102 cl2 p-t-28">
                            RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE					</h1>
                        <div class="flex-m flex-sb m-tb-15">
                            <div class="flex-i flex-w flex-m stext-111 cl2">
                                <span>
                                    <span class="cl4">Oleh</span> Eureka 
                                    <span class="cl12 m-l-4 m-r-6">|</span>
                                </span>
                                <span>
                                    05 Sep, 2022								<span class="cl12 m-l-4 m-r-6">|</span>
                                </span>
                            <div id="share" class="m-l-50-lg">
                                <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share" href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/rahasia-delia---juara-favorit-9-lomba-cerpen-eureka-bookhouse" target="_blank"><i class="fa fa-facebook"></i></a>
                                <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share" href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/rahasia-delia---juara-favorit-9-lomba-cerpen-eureka-bookhouse&amp;text=Blog: RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE &amp;hashtags=eurekabookhouse" title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share" href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/rahasia-delia---juara-favorit-9-lomba-cerpen-eureka-bookhouse" title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                            </div>
                        </div>
                        <div class="flex-r-m">
                            <span class="stext-107 text-center"><i class="fa fa-eye"></i> 2563</span>
                        </div>
                    </div>
                    <div class="wrap-pic-w how-pos5-parent">
                        <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg" alt="IMG-BLOG" class="blog-read-pic">
                        <div class="flex-col-c-m size-123 bg9 how-pos5">
                            <span class="ltext-107 cl2 txt-center">
                                05						</span>
    
                            <span class="stext-109 cl3 txt-center">
                                09 2022						</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
            <div class="row p-t-35 p-b-80">
                <div class="col-lg-2">
                </div>
                <div class="col-md-8 col-lg-8 text-left">
                    <div class="p-r-0-lg">
                        <div>
                            <div class="isi_blog ctext-101 fs-18 cl2 p-b-26 ">
                                <p>Aku sedang mengetik tugas kantor ketika Delia datang. &ldquo;Pa, minggu depan uang buku mau dibayar.&rdquo;&nbsp;</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aku tercenung mendengar kalimat yang baru dilontarkan puteri sulungku. Kemarin adiknya, Dion, juga minta uang buku. Gajian belum tiba, tapi berbagai kebutuhan sudah mengantri.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Iya, nanti Papa kasih kalau sudah ada.&rdquo;</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Setelah Delia beranjak, aku berpikir ke mana mencari pinjaman menunggu awal bulan. Buku-buku itu harus segera dibayar atau anak-anakku terhalang mengikuti ujian.&nbsp; Sebaiknya pergi lagi ke tempat yang sama seperti kemarin. Hanya dia yang bisa memberi pinjaman cepat.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Maka siang ini, pas jam istirahat kantor, aku mengemudi mobil menuju ke rumah kakak tertuaku, Kak Hadi.&nbsp; Tadi kami sudah berkomunikasi untuk janji ketemu.&nbsp; Untung hari ini kakak ada waktu luang. Ketika tiba di sana, satpam penjaga rumah langsung mempersilakan masuk. Aku menunggu di ruang tamu yang luas.&nbsp; Berbagai keramik dan lukisan eksklusif menghiasi tempat ini.&nbsp; Lampu kristal menggantung memperindah suasana. Aku duduk di sofa yang empuk menunggu tuan rumah.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Ada apa, Ren?&rdquo;&nbsp; Kak Hadi muncul masih dalam balutan busana kerja.&nbsp; Mungkin dia juga baru sampai.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Eh, selamat siang, Kak.&nbsp; Ini ada sedikit keperluan.&rdquo;&nbsp; Aku menyalaminya.&nbsp; Aroma parfum mahal meliputi ruangan. Sekilas sempat kuamati kalau penampilan kakakku sangat berkelas. Kemejanya bukan merek sembarangan. Demikian juga dengan dasi dan jam tangan.&nbsp; Judulnya saja kami bersaudara kandung, tapi nasib berbeda.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Percakapan dimulai dengan mengobrol ngalur-ngidul. Biasalah, basa-basi menanyakan kabar keluarga masing-masing. Kemudian berbagi cerita tentang kegiatan sehari-hari, pekerjaan kantor, dan sebagainya. Hingga akhirnya sampai ke topik utama.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Jadi, Kak, ada sedikit keperluan untuk Delia dan Dion.&nbsp; Kalau boleh, aku pinjam dululah.&nbsp; Seperti biasa.&nbsp; Nanti bulan depan dikembalikan.&rdquo;&nbsp; Kuutarakan tujuan kemari.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Kak Hadi mengangguk paham.&nbsp; Dia pergi sebentar dan keluar membawa amplop. &ldquo;Ini jumlah yang kamu minta.&nbsp; Coba dicek dulu.&nbsp; Tahu peraturannya &lsquo;kan?&rdquo;</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aku mengangguk dan setelah itu pamit. Waktu istirahat sudah hampir habis dan &nbsp;harus segera ke kantor. Demikian juga dengan Kak Hadi.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sore hari dengan hati lapang, aku memanggil Delia dan Dion. Lega rasanya bisa memenuhi kebutuhan mereka.&nbsp; &ldquo;Ini uang buku kalian.&rdquo;</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Dari Paman&nbsp; Hadi lagi, Pa?&rdquo;&nbsp; tanya Delia.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aku kaget dan melirik Mamanya yang sedang melipat kain. Dia melihat sekilas namun kembali melanjutkan pekerjaannya.&nbsp;</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Biasa &lsquo;kan kalau perlu apa-apa Papa datang ke sana. Dari dulu juga begitu&rdquo;&nbsp; Aku berusaha menenangkan puteriku karena tahu dia kurang harmonis dengan pamannya sendiri.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Maaf, Pa, aku bukan mau mencampuri urusan Papa Mama.&nbsp; Tapi, kalau memang harus pinjam uang, kok nggak ke orang lain saja?&rdquo; Delia jelas-jelas menunjukkan ketidaksukaannya.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Kenapa rupanya dengan Paman Hadi?&rdquo;</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Delia menghela nafas. &ldquo;Kami keponakannya, Pa, tapi kenapa setiap Papa Mama minjam uang untuk sekolah kami, dia tega memberikan bunga uang.&rdquo;</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aku terdiam.&nbsp; Mamanya juga membisu.&nbsp; Si bungsu Dion hanya menunduk.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Bukannya membantu, dia malah memberatkan kita.&nbsp; Apa itu namanya saudara?&rdquo;&nbsp; Si sulung kali ini benar-benar kesal.</p>
    
    <p>&ldquo;Sudahlah, Delia, yang penting uang bukumu terbayar,&rdquo;&nbsp; hiburku.</p>
    
    <p>&ldquo;Jangan sampai segitulah, Pa.&nbsp; Namanya saja keluarga, tapi bisnis tetap bisnis.&rdquo;</p>
    
    <p>&ldquo;Nak, Papamu sudah capek. Lain kali kita ngobrol panjang lebar.&rdquo;&nbsp; Mamanya berusaha menengahi.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Delia terdiam. Mungkin dia sadar kalau kurang etis beragumen dengan orang tua.&nbsp; Bersama Dion, akhirnya mereka kembali ke kamar masing-masing. Aku memandang Mamanya tanpa mampu berucap apapun.&nbsp; Tak menyangka kata-kata seperti itu bisa keluar dari si sulung yang masih duduk di bangku SMA.</p>
    
    <p>==&nbsp; 0 ==</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Papa, Mama, Paman Riko datang!&rdquo;&nbsp; Dion memberitahu kami yang sedang duduk di halaman belakang.&nbsp; Ada apa sore begini adikku itu datang?</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Tumben mereka datang.&nbsp; Saat kita susah jarang muncul.&nbsp; Tapi, kalau ada perlunya pasti muncul.&rdquo;&nbsp; Delia yang sedang membaca buku berbisik sinis.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Delia!&rdquo;&nbsp; Mama langsung menegur.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Gadis itu cemberut dan sambil terus membaca.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Hai, lagi sibuk semua?&rdquo;&nbsp; Om Riko dan istrinya, Tante Lila menyusul ke halaman belakang.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Nggaklah.&nbsp; Kami lagi bersantai kok.&rdquo;&nbsp; Mama menjawab sambil tersenyum.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Delia lagi ngapain tuh?&rdquo; tanya Tante Lila sambil memandang buku di tangan puteriku.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Baca, Tante,&rdquo;&nbsp; jawab Delia singkat.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Aduh, Anak Gadis, jangan baca melulu.&nbsp; Keluar rumah, bergaul, jangan temannya hanya buku ... laptop ... buku ... laptop ... !&rdquo;&nbsp; Tante Lila mulai lagi dengan kebiasaannya.&nbsp; Semua orang dikomentari.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aku melirik istriku.&nbsp; Dia juga kelihatan cemas.&nbsp; Kami sudah hafal kebiasaan Delia kalau tersinggung.&nbsp;</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Gadis itu membungkam, tapi segera beranjak pergi. &ldquo;Pa, pinjam laptop. Mau menyelesaikan tugas sekolah.&rdquo;</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aku mengangguk.&nbsp; &ldquo;Ambil saja di kamar Papa.&rdquo;</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Setelah Delia berlalu, Tante Lila mulai mengomel. &ldquo;Anak sekarang memang kurang sopan.&nbsp; Dikasih tahu bukannya berterima kasih, malah ngeloyor gitu aja.&rdquo;</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Agar suasana mendingin, segera kuganti topik pembicaraan.&nbsp; &ldquo;Ehm, ada apa, ya, datang kemari?&rdquo;</p>
    
    <p>==&nbsp; 0 ==</p>
    
    <p>Aku memandang Delia yang asyik mengetik dengan laptop. Puteriku ini memang berbeda. Kalau bicara terus-terang dan langsung tepat sasaran. Jangan harap mau berbasa-basi. Gadis rumahan yang hobi membaca berbagai jenis buku yang dipinjam dari teman-teman. Cenderung pendiam, tapi jangan coba-coba mengusik kesenangannya.&nbsp;</p>
    
    <p>Hanya saja, sejak dulu dia kurang serasi dengan paman dan tantenya. &ldquo;Nak, kalau nggak suka dengan omongan mereka, janganlah sampai ketus begitu.&rdquo;&nbsp;</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Gimana nggak kesal? Mereka hanya tahu mengkritik saja, Pa.&nbsp; Ini salah, itu salah.&nbsp; Harus begini, harus begitu.&nbsp; Kenapa kalau aku suka baca buku?&nbsp; Katanya ketinggalan zaman.&nbsp; Aneh!&rdquo;&nbsp; Dia mengomel panjang-lebar</p>
    
    <p>&ldquo;Kalau kita berbeda ada saja komentar orang yang nyelekit.&nbsp; Biasa itu. Tapi, janganlah pasang wajah cemberut. Kurang nyaman lihatnya lho.&rdquo; Aku mengingatkan karena bagaimana pun dia sebaiknya mengerti tata krama.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Delia terdiam sebentar, kemudian menjawab walau agak lama.&nbsp; &ldquo;Iya, Pa.&rdquo;</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aku menepuk bahunya. Mudah-mudahan dia bisa bersikap lebih baik lain waktu.</p>
    
    <p>==&nbsp; 0&nbsp; ==</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sore ini aku pulang dengan wajah letih.&nbsp; Banyak pekerjaan di kantor yang harus diselesaikan.&nbsp; Pikiranku tersita dengan tugas yang datang silih berganti.&nbsp; Badan pegal karena selalu bergerak cepat menemui pelanggan. Belum lagi ketemu orang-orang rewel yang banyak maunya. &nbsp;Begitu jam kerja usai, ingin sekali segera sampai di rumah dan beristirahat.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sesampai di rumah kulihat Delia dan Mamanya sudah menunggu di teras. Wajah mereka tampak sumringah.&nbsp; Melihat kedatanganku, Dion juga keluar sambil tertawa lebar.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Tebak, Pa, ada berita apa?&rdquo;&nbsp; Delia langsung menyambutku setelah turun dari mobil, seperti tak sabar menyampaikan sesuatu.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Apa tuh?&rdquo;&nbsp; Aku benar-benar malas berpikir karena kelelahan.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Ya ... Papa!&nbsp; Kok nggak mau nebak sih? Okelah, aku menang lomba menulis esai antar sekolah, Pa. Tingkat propinsi!&rdquo;&nbsp; Dia tersenyum riang.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Kupandang mereka bertiga untuk meyakinkan.&nbsp; Mamanya mengangguk pasti.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Selamat, Nak!&rdquo;&nbsp; Aku memeluknya bangga.</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ldquo;Asyik, kita makan-makan!&rdquo;&nbsp; Dion melompat gembira.</p>
    
    <p>&ldquo;Berarti selama ini kamu pinjam laptop Papa untuk mengerjakan esai?&rdquo;&nbsp; tanyaku.</p>
    
    <p>&ldquo;Tugas sekolah sekalian mengerjakan esai ini, Pa. Aku nggak berani cerita karena belum tahu hasilnya.&rdquo;&nbsp;</p>
    
    <p>&ldquo;Tidak sia-sia hobi membaca anakku ini.&rdquo;&nbsp; Mamanya memuji.</p>
    
    <p>&ldquo;Ehm .. ada satu hal lagi, Pa, aku punya usul. Gimana kalau uang hasil lomba dipakai sebagian untuk membantu melunasi utang ke Paman Hadi?&nbsp; Aku mau bantu. Lumayan lho hadiahnya.&rdquo;&nbsp; Delia&nbsp; menyampaikan dengan penuh semangat.</p>
    
    <p>Aku dan Mamanya terperanjat. Lama kami saling bertatapan. Kemudian segera kugelengkan kepala.&nbsp; &ldquo;Tidak, itu tanggung-jawab Papa dan Mama.&nbsp; Simpan saja uang itu untukmu.&nbsp; Siapa tahu suatu hari nanti bisa beli laptop supaya nulisnya makin lancar.&nbsp; Kami masih sanggup membayar utang.&rdquo;</p>
    
    <p>&ldquo;Iya, itu hasil usahamu.&nbsp; Masa dipakai untuk utang,&rdquo; ujar Mamanya.</p>
    
    <p>Dia berkata pelan. &ldquo;Maaf ya, Pa, Ma, selama ini aku bukan mau memberontak pada paman dan tante.&nbsp; Aku kesal karena kulihat Papa Mama sudah capek, tapi mereka terkesan acuh. Meminjamkan uang saja pakai bunga. Yang lain hanya tahu mengkritik. Seperti meremehkan keluarga kita.&rdquo;</p>
    
    <p>Aku menepuk bahunya lembut.&nbsp; &ldquo;Nggak usah dibawa ke hati.&nbsp; Yang penting kita di rumah ini baik-baik saja, ya.&nbsp; Sehat-sehat.&nbsp; Ada selalu rezeki.&rdquo;</p>
    
    <p>Delia mengangguk dengan mata berbinar. Hilang sudah letihku karena ukiran prestasi si sulung. Tak terlukiskan rasa bangga padanya.</p>
    
    <p>Penulis: <strong>Friska Julia</strong></p>
    
    <p>IG&nbsp;: <strong>@tgfairy</strong></p>
    
    <p>&nbsp;</p>
    
    <p>&nbsp;</p>
    
    <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="media ava-bloger">
                            <img class="mr-3 rounded-circle img-thumbnail" src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png" alt="Generic placeholder image">
            <div class="media-body">
                                <p class="stext-101 m-b-8">DITULIS OLEH</p>
                                <h5 class="mt-0 mtext-111">Eureka Writer</h5>
                                <p class="badge badge-primary">Content Writer</p>
                                <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        <section class="sec-relate-product bg6 p-t-20 text-left">
            <div class="container p-tb-10">
                <div class="p-b-15">
                    <h3 class="session-title mtext-106 cl5">New Entry</h3>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/kisahkasih'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/rahasiadelia'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/pohonmangga'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                                    </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/malaikat'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/ttgayah'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/merah'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1"><a
                                        href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/jejak'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/pejuang'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                                    </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">04 September 2022</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
<script scope>
    export default {
      name: "rahasiadeliaPage",
    }
    </script>