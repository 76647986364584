<template>
    <section class="sec-product-detail bg6 p-t-15">
        <input type="hidden" id="isbn" name="" value="" disabled>
        <div class="container">
            <div class="bg0 bor4 mb-3 bs1">
                <div class="row">
                    <div class="p-all-20 col-12 col-md-4 col-lg-4"><div class="zoom-gallery">
                                                        <div class="detail-book">
                                    <a href="https://cdn.eurekabookhouse.co.id/ebh/product/all/2014005020.jpg">
    <img src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2014005020.jpg" style="width: 300px; height: 390px;"/>
                                </a>
                                </div>
                                                </div>
                                                    </div>
                    <div class="col-md-6 col-lg-8 p-b-10">
                        <div class="p-all-20 m-b-10">
                            <a href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=we-bare-bears" class="stext-101"><span class="ctext-101 fs-18"><b>Buku Erlangga</b></span></a>
                            <h1 class="ctext-102 cl2 js-name-detail p-b-10" style="font-size: 30px">
                                Kartu Angka & Huruf Hijaiah						</h1>
    
                            <div class="flex-w flex-m p-b-20">
                                <div id="share" class="flex-i m-r-10">
                                    <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share" href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/product/we-bare-bears-pocket-backpack-brown" target="_blank"><i class="fa fa-facebook"></i></a>
                                    <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share" href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/product/we-bare-bears-pocket-backpack-brown&amp;text=Jual We Bare Bears Pocket Backpack Brown di Eureka Bookhouse &amp;hashtags=eurekabookhouse" title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                    <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share" href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/product/we-bare-bears-pocket-backpack-brown" title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
    </div>
                            <span class="stext-107"><i class="fa fa-eye"></i> Dilihat: 141</span>
                        </div>
    
                        <div class="bg6 bor8 p-all-5">
                            <span class="cl2">
                                    <span class="stext-106s"><s>Rp.65.000</s></span>								
                                    <div class="flex-m">
                                        <span class="cl13 ltext-106">Rp.52.200</span>
                                        <span class="badge bgu2 stext-106 cl0 ml-3">20% OFF</span>
                                    </div>
                                                        </span>
                        </div>
                        <div class="m-t-20">
                                                                <div class="flex-w flex-l-m p-b-10">
                                        <div class="size-204 flex-w flex-m">
                                            <div class="flex-w m-r-20 m-tb-10">
                                                <span class="stext-107">Kuantitas</span>
                                            </div>
                                            <div class="wrap-num-product flex-w m-r-20 m-tb-10">
                                                <div class="tbl-turun cl8 hov-btn3 trans-04 flex-c-m">
                                                    <i class="fa fa-minus"></i>
                                                </div>
                                                <input class="mtext-104 cl3 txt-center num-product" id="num-product" type="text" name="num-product" value="1">
                                                <div class="tbl-naik cl8 hov-btn3 trans-04 flex-c-m">
                                                    <i class="fa fa-plus"></i>
                                                </div>
                                            </div>
                                            <div class="flex-w m-r-20 m-tb-10">
                                                <span class="stext-107">Stok <span class="stext-101 cl1 berapastok">49</span> unit</span>
                                            </div>
                                            <div class="flex-w m-r-20 m-tb-10">
                                                <span class="cl1 stext-100">Tersedia</span>
                                            </div>
                                            
                                                    <a href="https://wa.me/6287888337555" class=" 	btn-success"> Tanya Stok</a>
                                        </div>
                                    </div>
                                                        </div>
    
                                                        <div class="baris-tbl-cart">
                                    <div class="row">
                                        <div class="col-6 col-md-6 p-lr-5">
                                            <router-link :to="'/cart'" id="21539" class="flex-c-m stext-100 cl0 size-100 bg1 bor3 hov-btn1 p-lr-10 js-addcart-checkout  btn-block">
                                                <i class="fa fa-shopping-bag"></i> <small class="p-l-5">Pesan Sekarang</small>
                                            </router-link>	
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 p-lr-5">
                                            <router-link :to="'/cart'" id="21539" class="flex-c-m stext-100 size-100 bor3 hov-btn1 p-lr-10 js-addcart-detail btn-block btn-warning">
                                                <i class="fa fa-shopping-cart"></i> <small class="p-l-5">Tambah ke Cart</small>
                                            </router-link>	
                                        </div>					
                                    </div>
                                </div>
                                                </div>
                    </div>
                </div>
            </div>
    
            <div class="bg0 bor4 mb-3 p-all-20 col-md-12 flex-w">
                <div class="bor9 col-md-8 p-lr-0">
                    <div class="flex-m">
                        <img class="mr-2" src="https://cdn.eurekabookhouse.co.id/ebh/mall/Buku-Erlangga_icon_mall10_18_24.png" style="max-width: 100px;">
                        <div class="detail-mall">
                            <h4 class="mtext-108">Buku Erlangga</h4>
                            <p class="stext-107 cl1"><i class="fa fa-diamond m-r-10"></i>Aktif</p> 
                        </div>
                    </div>
                </div>
                <div class="desk-toko text-center bor9 col-md-2 col-4 flex-c-m">
                    <p class="stext-101 cl1"><i class="fa fa-inbox"></i> 8930<br> <span class="cl8">Produk</span></p>
                </div>
                <div class="desk-toko text-center col-md-2 col-4 flex-c-m">
                    <p class="stext-101 cl1"><i class="fa fa-star"></i> 3.3<br> <span class="cl8">Penilaian</span></p>
                </div>
                </div>
    
                <div>
                    <div class="bor4 mb-3 bg0" style="min-height:447px;">
                    <div class="panel-body p-all-20">
                    <b-tabs content-class="mt-3" fill>
                        <b-tab title="Deskripsi" active>
                            <div class="tab-pane fade in active" id="tab1default"> 
                                <div class="ctext-101 fs-18 cl2 text-left">
                                    <div class="cl2">
                                        <h4 class="mtext-112 cl2 m-b-15"> Deskripsi </h4> 
                                        <p>Kartu ini dapat dipakai untuk membantu belajar anak mengenak huruf dan angka hijaiah. Di dalam kemasannya, terdapat 50 kartu huruf dan angka hijaiah yang dilengkapi dengan kata dalam bahasa arab pada setiap kartunya serta cara menulis huruf dan angka hijaiah. Selain itu, kartu ini juga dilengkapi dengan cara menulis angka dan huruf hijaiah yang dapat ditulis menggunakan spidol yang dapat dihapus atau pun dengan cara menggerakkan jari sesuai bentuknya.</p>
        
        <p><strong>Keunggulan Produk:</strong></p>
        
            <li>1. Terdiri atas 50 kartu</li>
            <li>2. Berisi mengenal huruf Hijaiah dan Angka Hijaiah</li>
            <li>3. Dilengkapi ilustrasi gambar yang menarik</li>
            <li>4. Tampilan <em>full color</em></li>
        
                                        <br>
                                        <div class="m-t-20">
                                                                                    <span class="badge badge-primary">kartu</span>
                                                                                    <span class="badge badge-primary">angka</span>
                                                                                    <span class="badge badge-primary"></span>
                                                                                    <span class="badge badge-primary">huruf</span>
                                                                                    <span class="badge badge-primary">hijaiah</span>
                                                                            </div>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Spesifikasi">	
                                <h4 class="mtext-112 cl2 m-b-15"> Spesifikasi </h4> 
                                <table class="table text-left">
                                    <tr>
                                        <td><span class="stext-101 cl2">Kode</span></td>
                                        <td>:</td>
                                        <td><a href="#" class="stext-103 cl6">2014005020</a></td>
                                    </tr>
                                    <tr>
                                        <td><span class="stext-101 cl2">Pengarang</span></td>
                                        <td>:</td>
                                        <td><a href="https://www.eurekabookhouse.co.id/cari/result?author=Tim Efk" class="stext-103 cl6">Tim Efk</a></td>
                                    </tr>
                                    <tr>
                                        <td><span class="stext-101 cl2">Penerbit</span></td>
                                        <td>:</td>
                                        <td><a href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=buku-erlangga" class="stext-103 cl6">Buku Erlangga</a></td>
                                    </tr>
                                    <tr>
                                        <td><span class="stext-101 cl2">Kategori</span></td>
                                        <td>:</td>
                                        <td><a href="https://www.eurekabookhouse.co.id/cari/result?category=buku-tk" class="stext-103 cl6">Buku TK</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span class="stext-101 cl2">Minimum</span></td>
                                        <td>:</td>
                                        <td><span class="stext-103 cl6 berapaminimum">0</span></td>
                                        </tr>
                                    </table>				
                    </b-tab>
                    
                    <b-tab title="Ulasan">
						<h4 class="mtext-112 cl2 m-b-15 text-left"> Ulasan </h4>
						Belum Ada Ulasan Untuk Produk Ini
							
					</b-tab>

				</b-tabs>
                </div>
    
    </div>
            </div>
            </div>
    
    
            <!-- Related Products -->
    
            <form id="beli_saiki" method="POST" action="https://www.eurekabookhouse.co.id/frontend/pemesanan/cart/page_cart/">
                <input type="hidden" name="terpilih" value="21539">
            </form>
    
            <div id="myModal" class="modal fade" role="dialog">
                <div class="modal-dialog">
    
                    <!-- Modal content-->
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                            <h5 class="modal-title">@EurekaBookhouse-21539</h5>
                        </div>
                        <div class="modal-body">
                            <div id="listz" style="height: 450px;overflow: scroll"></div>
                        </div>
                        <div class="modal-footer">
                            <input type="text" class="form-control write_msg" placeholder="Type a message" id="write_msg"/>
                            <button class="btn btn-flat btn-primary" type="button" id="send" onclick="sends()" >
                                <i class="fa fa-paper-plane-o" aria-hidden="true"></i></button>
                            </div>
                        </div>
    
                    </div>
                </div>
                </section>
    </template>
    <script scope>
    export default {
      name: "kartuangkaprodukPage",
    }
    </script>
    <style type="text/css">
                            /* external css: flickity.css */
                            .carousel-main .carousel-cell {
                                width: 100%;
                                height: 300px;
                                margin-right: 10px;
                                border-radius: 5px;
                                background-size: cover;
                                counter-increment: carousel-cell;
                            }
                            .carousel-nav .carousel-cell {
                                height: 80px;
                                width: 80px;
                            }
    
                            .carousel-nav .carousel-cell:before {
                                font-size: 50px;
                                line-height: 80px;
                            }
    
                            .carousel-nav .carousel-cell.is-nav-selected {
                                background: #ED2;
                            }
                            .detail-book img{max-height: 400px;max-width: 100%}
                        </style>
    <style>
            .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
                color: white;
                background: none;
                border-bottom: 5px solid #ffb031;
            }
            .nav-pills .nav-link{
                color: #e1e1e1;
                border-radius: 0;
            }
            .panel-heading{padding: 0;}
            /*		.pic-multi{height: 400px;position: relative;line-height:400px;}*/
            .pic-multi img{object-fit: cover;}
            .gbr-toko {height: 24px;margin-right: 10px;width:80px;height:80px;margin-right:15px;}
            .gbr-toko img{object-fit: cover; height: 100%;width: 100%;}
            .author-pic{height: 210px;width:100%}
            .flickity-page-dots{bottom: 45px !important}
            @media (max-width: 575px) {
                .pic-multi{height: 210px;position: relative;line-height:210px;}
                .pic-multi{width: 50%;margin: 0 auto}
                .flickity-page-dots{bottom: 15px !important}
                .desk-toko{display: none;}
                .author-pic{height: 60px;width:100%}
                .nav-detail-produk{position: fixed;bottom: 0;background-color: #fff;width: 100%; z-index: 2000000001;}
                .baris-tbl-cart{display: none;}
            }
            @media (min-width: 575px) {
                .nav-detail-produk{display: none;}
            }
            .inbox_people {
                background: #f8f8f8 none repeat scroll 0 0;
                float: left;
                overflow: hidden;
                width: 30%; border-right:1px solid #c4c4c4;
            }
            .inbox_msg {
                border: 1px solid #c4c4c4;
                clear: both;
                overflow: hidden;
            }
            .top_spac{ margin: 20px 0 0;}
    
    
            .recent_heading {float: left; width:40%;}
            .srch_bar {
                display: inline-block;
                text-align: right;
                width: 60% padding;
            }
            .headind_srch{ padding:10px 29px 10px 20px; overflow:hidden; border-bottom:1px solid #c4c4c4;}
    
            .recent_heading h4 {
                color: #05728f;
                font-size: 21px;
                margin: auto;
            }
            .srch_bar input{ border:1px solid #cdcdcd; border-width:0 0 1px 0; width:80%; padding:2px 0 4px 6px; background:none;}
            .srch_bar .input-group-addon button {
                background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
                border: medium none;
                padding: 0;
                color: #707070;
                font-size: 18px;
            }
            .srch_bar .input-group-addon { margin: 0 0 0 -27px;}
    
            .chat_ib h5{ font-size:15px; color:#464646; margin:0 0 8px 0;}
            .chat_ib h5 span{ font-size:13px; float:right;}
            .chat_ib p{ font-size:14px; color:#989898; margin:auto}
            .chat_img {
                float: left;
                width: 11%;
            }
            .chat_ib {
                float: left;
                padding: 0 0 0 15px;
                width: 88%;
            }
    
            .chat_people{ overflow:hidden; clear:both;}
            .chat_list {
                border-bottom: 1px solid #c4c4c4;
                margin: 0;
                padding: 18px 16px 10px;
            }
            .inbox_chat { height: 400px; overflow-y: scroll;}
    
            .active_chat{ background:#ebebeb;}
    
            .incoming_msg_img {
                display: inline-block;
                width: 6%;
            }
            .received_msg {
                display: inline-block;
                padding: 0 0 0 10px;
                vertical-align: top;
                width: 92%;
            }
            .received_withd_msg p {
                background: #ebebeb none repeat scroll 0 0;
                border-radius: 3px;
                color: #646464;
                font-size: 14px;
                margin: 0;
                padding: 5px 10px 5px 12px;
                width: 150%;
            }
            .time_date {
                color: #747474;
                display: block;
                font-size: 9px;
                margin: 5px 0 15px 0;
            }
            .received_withd_msg { width: 57%;}
            .mesgs {
                float: left;
                padding: 30px 15px 0 25px; 
            }
    
            .sent_msg p {
                background: #05728f none repeat scroll 0 0;
                border-radius: 3px;
                font-size: 14px;
                margin: 0; color:#fff;
                padding: 5px 10px 5px 12px;
                width:100%;
            }
            .outgoing_msg{ overflow:hidden; margin:26px 0 26px;}
            .sent_msg {
                float: right;
                width: 76%;
            }
            .input_msg_write input {
                background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
                border: medium none;
                color: #4c4c4c;
                font-size: 15px;
                min-height: 48px;
                width: 100%;
            }
    
            .type_msg {border-top: 1px solid #c4c4c4;position: relative;}
            .msg_send_btn {
                background: #05728f none repeat scroll 0 0;
                border: medium none;
                border-radius: 50%;
                color: #fff;
                cursor: pointer;
                font-size: 17px;
                height: 33px;
                position: absolute;
                right: 0;
                top: 11px;
                width: 33px;
            }
            .messaging { padding: 0;background-color: #FFF;}
            .msg_history {
                height: 400px;
                overflow-y: auto;
            }
    
    
            .product_msg_img {
                display: inline-block;
                width: 10%;
            }
            .product_msg {
                background: #b2b2b2;
                float: right;
                width: 76%;
            }
            .carousel-cell img{
                height: 100%;
                width: auto;
            }
            .garis-kanan{border-right: 1px solid #076aab}
            .carousel-cell{background-size: contain !important}
        </style>                                                                                                                         