<template>
  <section class="sec-product-detail bg6 p-t-15">
    <input type="hidden" id="isbn" name="" value="" disabled />
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-8">
          <div
            class="bg0 bor4 mb-3 bs1"
            style="border-radius: 10px; background: var(--neutral-01, #fefefe)"
          >
            <div class="row">
              <div class="p-all-20 col-12 col-md-6 col-lg-4">
                <div class="zoom-gallery">
                  <div class="detail-book">
                    <a
                      href="https://cdn.eurekabookhouse.co.id/ebh/product/all/We bare bear POCKET BACKPACK BROWN.jpg"
                    >
                      <img
                        :src="
                          'https://cdn.eurekabookhouse.co.id/ebh/product/all/' +
                          productDetail.image
                        "
                        style="width: 300px; height: 350px"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-8 p-b-10">
                <div class="p-all-20 m-b-10 text-left">
                  <b-badge
                    class="cradori"
                    style="
                      background: linear-gradient(
                        266deg,
                        #108cff 22.39%,
                        #013685 100%
                      );
                    "
                  >
                    <span
                      style="
                        color: var(--neutral-01, #fefefe);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 15.4px;
                        border-radius: 0px 10px 0px 0px;
                      "
                    >
                      Original 100%
                    </span>
                  </b-badge>
                  <br />
                  <br />
                  <h1
                    style="
                      color: #000;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 18px;
                      letter-spacing: -0.16px;
                    "
                  >
                    {{ productDetail.db_product_description.name }}
                  </h1>
                  <div class="flex-w flex-m p-b-20">
                    <div id="share" class="flex-i m-r-10">
                      <span
                        style="
                          color: var(--primary-04, #3860a8);
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 15.4px;
                        "
                      >
                        Terjual 100+
                      </span>
                      <span
                        style="
                          color: var(--primary-04, #3860a8);
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 15.4px;
                        "
                      >
                        Rating 4.8
                      </span>
                      <span
                        style="
                          color: var(--primary-04, #3860a8);
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 15.4px;
                        "
                      >
                        {{ productDetail.viewed }}
                      </span>
                    </div>
                  </div>
                  <b-badge style="background: var(--danger-03, #ffd7d7)">
                    <span
                      style="
                        color: var(--danger-01, #e91313);
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 13.2px;
                      "
                    >
                      Super Diskon 20%
                    </span>
                  </b-badge>
                  <div class="p-all-5 mt-3">
                    <span class="cl2">
                      <span style="color: #989898">
                        <s>Rp.249.000</s>
                      </span>
                      <div class="flex-m">
                        <span
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 28px;
                            font-weight: 600;
                            line-height: 30.8px;
                            letter-spacing: -0.28px;
                          "
                        >
                          Rp.199.200
                        </span>
                      </div>
                    </span>
                  </div>
                  <br />
                  <span
                    style="
                      color: var(--neutral-07, #191717);
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 13.2px;
                    "
                  >
                    Informasi Toko
                  </span>
                  <div
                    style="
                      display: flex;
                      padding: 10px;
                      max-width: 100%;
                      justify-content: space-between;
                      align-items: center;
                      border-radius: 5px;
                      border: 0.5px solid var(--neutral-03, #cbcbcb);
                    "
                  >
                    <div class="bor9 col-md-8 p-lr-0">
                      <div class="flex-m">
                        <img
                          class="mr-2"
                          src="https://cdn.eurekabookhouse.co.id/ebh/mall/EurekaBookhouse_foto18_37_57.png"
                          style="max-width: 65px"
                        />
                        <div class="detail-mall">
                          <span
                            style="
                              color: #000;
                              font-size: 16px;
                              font-weight: 600;
                              line-height: 18px;
                              letter-spacing: -0.16px;
                            "
                          >
                            EurekaBookhouse
                          </span>
                          <p
                            class="stext-107 cl1 text-left"
                            style="
                              color: #18943b;
                              font-size: 12px;
                              font-weight: 400;
                              line-height: 13.2px;
                            "
                          >
                            Aktif
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <button
                          style="
                            display: flex;
                            padding: 10px;
                            justify-content: center;
                            align-items: center;
                            border-radius: 5px;
                            background: var(--secondary-02, #ffebc1);
                          "
                          class="d-none d-lg-block"
                        >
                          <span
                            style="
                              color: var(--secondary-07, #bb891d);
                              font-size: 12px;
                              font-weight: 400;
                              line-height: 13.2px;
                            "
                            >Toko</span
                          >
                        </button>
                      </div>
                      <div class="col-6">
                        <button
                          style="
                            display: flex;
                            padding: 10px;
                            justify-content: center;
                            align-items: center;
                            border-radius: 5px;
                            background: var(--primary-02, #b3ceff);
                          "
                          class="d-none d-lg-block"
                        >
                          <span
                            style="
                              color: var(--primary-07, #091e42);
                              font-size: 12px;
                              font-weight: 400;
                              line-height: 13.2px;
                            "
                            >Chat</span
                          >
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-12 col-lg-4">
          <div
            class="bg0 bor4 mb-3 bs1"
            style="border-radius: 10px; background: var(--neutral-01, #fefefe)"
          >
            <br />
            <div class="d-flex jarak">
              <div
                class="p-2"
                style="
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 18px;
                  letter-spacing: -0.16px;
                "
              >
                Kuantitas
              </div>
              <div
                class="ml-auto p-2"
                style="
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 18px;
                  letter-spacing: -0.16px;
                "
              >
                <b-form-spinbutton
                  id="sb-inline"
                  v-model="value"
                  inline
                ></b-form-spinbutton>
              </div>
            </div>
            <div class="d-flex jarak">
              <div
                class="p-2"
                style="
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 15.4px;
                  letter-spacing: -0.14px;
                "
              >
                Sub Total
              </div>
              <div
                class="ml-auto p-2"
                style="
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 18px;
                  letter-spacing: -0.16px;
                "
              >
                Rp. 24.000
              </div>
            </div>
            <div class="d-flex jarak">
              <div class="p-2">
                <router-link :to="'/cart'">
                  <button
                    id="21671"
                    style="
                      display: flex;
                      padding: 10px;
                      border-radius: 50px;
                      background: var(--secondary-05, #ffc341);
                    "
                  >
                    <span
                      class="p-l-5"
                      style="
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 15.4px;
                        letter-spacing: -0.14px;
                      "
                      >Beli Sekarang</span
                    >
                  </button>
                </router-link>
              </div>
              <div class="ml-auto p-2">
                <router-link :to="'/cart'">
                  <button
                    id="21671"
                    style="
                      display: flex;
                      padding: 10px;
                      border-radius: 50px;
                      background: var(--primary-04, #3860a8);
                    "
                  >
                    <i class="fa fa-plus" style="color: white"></i>
                    <span
                      class="p-l-5"
                      style="
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 15.4px;
                        letter-spacing: -0.14px;
                        color: white;
                      "
                      >Keranjang</span
                    >
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-12">
        <div
          class="bg0 bor4 mb-3 bs1"
          style="border-radius: 10px; background: var(--neutral-01, #fefefe)"
        >
          <div class="panel-body p-all-20">
            <b-tabs content-class="mt-3" fill>
              <b-tab title="Deskripsi" active
                ><div class="tab-pane fade in active" id="tab1default">
                  <div class="ctext-101 fs-18 cl2 text-left">
                    <div class="cl2">
                      <h4 class="mtext-112 cl2 m-b-15">Deskripsi</h4>
                      <p>
                        Tas Ransel Backpack Anak We Bare Bears<br />
                        100% brand new & high quality<br />
                        Lovely cute design
                      </p>

                      <p>
                        READYSTOCK !!!<br />
                        warna : Brown
                      </p>

                      <p>Size : 38 x 30 x 12 cm</p>
                      <br />
                      <div class="m-t-20">
                        <span class="badge badge-primary mr-2">we</span>
                        <span class="badge badge-primary mr-2">bare</span>
                        <span class="badge badge-primary mr-2">bears</span>
                        <span class="badge badge-primary mr-2">pocket</span>
                        <span class="badge badge-primary mr-2">backpack</span>
                        <span class="badge badge-primary mr-2">brown</span>
                      </div>
                    </div>
                  </div>
                </div></b-tab
              >
              <b-tab title="Spesifikasi">
                <h4 class="mtext-112 cl2 m-b-15 text-left">Spesifikasi</h4>
                <table class="table text-left">
                  <tr>
                    <td><span class="stext-101 cl2">Kode</span></td>
                    <td>:</td>
                    <td><a href="#" class="stext-103 cl6">2014005020</a></td>
                  </tr>
                  <tr>
                    <td><span class="stext-101 cl2">Pengarang</span></td>
                    <td>:</td>
                    <td>
                      <a
                        href="https://www.eurekabookhouse.co.id/cari/result?author=Tim Efk"
                        class="stext-103 cl6"
                        >Tim Efk</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <td><span class="stext-101 cl2">Penerbit</span></td>
                    <td>:</td>
                    <td>
                      <a
                        href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=buku-erlangga"
                        class="stext-103 cl6"
                        >Buku Erlangga</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <td><span class="stext-101 cl2">Kategori</span></td>
                    <td>:</td>
                    <td>
                      <a
                        href="https://www.eurekabookhouse.co.id/cari/result?category=buku-tk"
                        class="stext-103 cl6"
                        >Buku TK</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <td><span class="stext-101 cl2">Minimum</span></td>
                    <td>:</td>
                    <td><span class="stext-103 cl6 berapaminimum">0</span></td>
                  </tr>
                </table>
              </b-tab>
              <b-tab title="Ulasan">
                <h4 class="mtext-112 cl2 m-b-15 text-left">Ulasan</h4>

                <div class="responsive-container" style="padding: 15px">
                  <div class="row green-card">
                    <div class="col-4">
                      <div class="mt-4">
                        <h6
                          style="
                            color: #765100;

                            /* Style/Title 1 */
                            font-family: Montserrat;
                            font-size: 28px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 30.8px; /* 110% */
                            letter-spacing: -0.28px;
                          "
                        >
                          4.3/5.0
                        </h6>
                        <span
                          style="
                            color: #765100;

                            /* Style/Body */
                            font-family: Montserrat;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 15.4px; /* 110% */
                            letter-spacing: -0.14px;
                          "
                          >217 Ulasan</span
                        >
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="row responsive-buttons">
                        <button
                          class="mr-3"
                          style="
                            display: flex;

                            width: 110px;
                            padding: 10px;
                            align-items: flex-start;
                            gap: 5px;
                            border-radius: 5px;
                            background: var(--secondary-06, #dda62d);
                          "
                        >
                          <span
                            style="
                              color: var(--secondary-01, #fff9ec);
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 15.4px; /* 110% */
                              letter-spacing: -0.14px;
                            "
                            >Semua (217)</span
                          >
                        </button>
                        <button class="mr-3 btn-review">
                          <span class="txt-review">Dengan Komentar</span>
                        </button>
                        <button class="mr-3 btn-review">
                          <span class="txt-review">Dengan Media</span>
                        </button>
                        <button class="mr-3 btn-review">
                          <span class="txt-review">Ulasan Terbaik</span>
                        </button>
                      </div>
                      <div class="row responsive-buttons mt-3">
                        <button class="mr-3 btn-review">
                          <span class="txt-review"
                            ><svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="15"
                              viewBox="0 0 16 15"
                              fill="none"
                            >
                              <path
                                d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                fill="#BB891D"
                              />
                            </svg>
                            Bintang 5
                          </span>
                        </button>
                        <button class="mr-3 btn-review">
                          <span class="txt-review"
                            ><svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="15"
                              viewBox="0 0 16 15"
                              fill="none"
                            >
                              <path
                                d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                fill="#BB891D"
                              />
                            </svg>
                            Bintang 4
                          </span>
                        </button>
                        <button class="mr-3 btn-review">
                          <span class="txt-review"
                            ><svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="15"
                              viewBox="0 0 16 15"
                              fill="none"
                            >
                              <path
                                d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                fill="#BB891D"
                              />
                            </svg>
                            Bintang 3
                          </span>
                        </button>
                        <button class="mr-3 btn-review">
                          <span class="txt-review"
                            ><svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="15"
                              viewBox="0 0 16 15"
                              fill="none"
                            >
                              <path
                                d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                fill="#BB891D"
                              />
                            </svg>
                            Bintang 2
                          </span>
                        </button>
                        <button class="mr-3 btn-review">
                          <span class="txt-review"
                            ><svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="15"
                              viewBox="0 0 16 15"
                              fill="none"
                            >
                              <path
                                d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                fill="#BB891D"
                              />
                            </svg>
                            Bintang 1
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div style="margin-left: 22px">
                  <div class="row">
                    <img
                      src="../assets/images/home/avatar.png"
                      alt="Image"
                      style="width: 50px; height: 50px"
                      class="mr-2"
                    />
                    <span
                      style="
                        color: var(--neutral-07, #191717);
                        padding-top: 20px;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 13.2px;
                        letter-spacing: -0.12px;
                      "
                      >Stephanie Gabriela Putri</span
                    >
                  </div>
                  <span class="mt-1" style="display: block; text-align: left">
                    Lorem ipsum dolor sit amet consectetur. Tellus fusce viverra
                    convallis faucibus ac fames pellentesque. Aliquet eget
                    ullamcorper pellentesque velit pulvinar. Viverra leo amet
                    quis luctus a eget. Porttitor eu sit blandit massa integer
                    dignissim ultrices fringilla.
                  </span>
                  <div class="row mt-2">
                    <img
                      src="../assets/images/home/pa.png"
                      alt="Image"
                      style="width: 50px; height: 50px"
                      class="mr-2"
                    />
                    <img
                      src="../assets/images/home/pb.png"
                      alt="Image"
                      style="width: 50px; height: 50px"
                      class="mr-2"
                    />
                    <img
                      src="../assets/images/home/pc.png"
                      alt="Image"
                      style="width: 50px; height: 50px"
                      class="mr-2"
                    />
                    <img
                      src="../assets/images/home/pd.png"
                      alt="Image"
                      style="width: 50px; height: 50px"
                      class="mr-2"
                    />
                  </div>
                </div>
                <br />
                <br />
                <br />
                <div style="margin-left: 22px">
                  <div class="row">
                    <img
                      src="../assets/images/home/avatar.png"
                      alt="Image"
                      style="width: 50px; height: 50px"
                      class="mr-2"
                    />
                    <span
                      style="
                        color: var(--neutral-07, #191717);
                        padding-top: 20px;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 13.2px;
                        letter-spacing: -0.12px;
                      "
                      >Stephanie Gabriela Putri</span
                    >
                  </div>
                  <span class="mt-1" style="display: block; text-align: left">
                    Lorem ipsum dolor sit amet consectetur. Tellus fusce viverra
                    convallis faucibus ac fames pellentesque. Aliquet eget
                    ullamcorper pellentesque velit pulvinar. Viverra leo amet
                    quis luctus a eget. Porttitor eu sit blandit massa integer
                    dignissim ultrices fringilla.
                  </span>
                  <div class="row mt-2">
                    <img
                      src="../assets/images/home/pa.png"
                      alt="Image"
                      style="width: 50px; height: 50px"
                      class="mr-2"
                    />
                    <img
                      src="../assets/images/home/pb.png"
                      alt="Image"
                      style="width: 50px; height: 50px"
                      class="mr-2"
                    />
                    <img
                      src="../assets/images/home/pc.png"
                      alt="Image"
                      style="width: 50px; height: 50px"
                      class="mr-2"
                    />
                    <img
                      src="../assets/images/home/pd.png"
                      alt="Image"
                      style="width: 50px; height: 50px"
                      class="mr-2"
                    />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <!-- Related Products -->

    <form
      id="beli_saiki"
      method="POST"
      action="https://www.eurekabookhouse.co.id/frontend/pemesanan/cart/page_cart/"
    >
      <input type="hidden" name="terpilih" value="21539" />
    </form>

    <div id="myModal" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
            <h5 class="modal-title">@EurekaBookhouse-21539</h5>
          </div>
          <div class="modal-body">
            <div id="listz" style="height: 450px; overflow: scroll"></div>
          </div>
          <div class="modal-footer">
            <input
              type="text"
              class="form-control write_msg"
              placeholder="Type a message"
              id="write_msg"
            />
            <button
              class="btn btn-flat btn-primary"
              type="button"
              id="send"
              onclick="sends()"
            >
              <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";

export default {
  name: "testdetailPage",
  data() {
    return {
      idproduk: null,
      productDetail: null,
      value: 1,
    };
  },
  mounted() {
    this.idproduk = this.$route.params.product_id;
    this.getdetail();
  },
  methods: {
    getdetail() {
      axios
        .get(`http://192.168.1.44:3000/product/detail/${this.idproduk}`)
        .then((response) => {
          // console.log(response.data);
          this.productDetail = response.data.data.order;
        })
        .catch((error) => {
          console.error("Terjadi kesalahan saat mengambil data produk:", error);
        });
    },
  },
};
</script>

<style type="text/css">
.green-card {
  background-image: url("../assets/images/home/Rating.png"); /* Gambar sebagai latar belakang */
  background-size: cover; /* Menutupi seluruh area kartu dengan gambar */
  padding: 20px; /* Padding sesuai kebutuhan Anda */
  border-radius: 10px; /* Sudut bulat sesuai kebutuhan Anda */
  margin: 10px; /* Margin sesuai kebutuhan Anda */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Efek bayangan kartu */
  color: white; /* Warna teks pada kartu */
  text-align: center; /* Pusatkan teks dalam kartu */
}
@media (max-width: 768px) {
  .responsive-container {
    max-width: 100%;
  }

  .responsive-span {
    position: relative;
    top: auto;
    left: auto;
    text-align: center;
  }

  .responsive-buttons {
    position: relative;
    top: auto;
    left: auto;
    text-align: center;
    margin-top: 20px;
  }
}
.toko-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 5px;
  border: 0.5px solid var(--neutral-03, #cbcbcb);
}

.toko-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--neutral-03, #cbcbcb);
}

.toko-header img {
  max-width: 65px;
}

.toko-body {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.toko-buttons {
  display: flex;
  gap: 10px;
}

.toko-buttons button {
  padding: 10px;
  border-radius: 5px;
}

/* Media queries untuk perangkat berbeda */
@media (max-width: 768px) {
  .toko-header img {
    max-width: 50px;
  }

  .toko-buttons button {
    padding: 8px;
    font-size: 10px;
  }
}
.btn-review {
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 5px;
  border-radius: 5px;
  background: var(--secondary-01, #fff9ec);
}
.txt-review {
  color: var(--secondary-07, #bb891d);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}
.cradori {
  background: linear-gradient(266deg, #108cff 22.39%, #013685 100%);
}
.jarak {
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
/* external css: flickity.css */
.carousel-main .carousel-cell {
  width: 100%;
  height: 300px;
  margin-right: 10px;
  border-radius: 5px;
  background-size: cover;
  counter-increment: carousel-cell;
}
.carousel-nav .carousel-cell {
  height: 80px;
  width: 80px;
}

.carousel-nav .carousel-cell:before {
  font-size: 50px;
  line-height: 80px;
}

.carousel-nav .carousel-cell.is-nav-selected {
  background: #ed2;
}
.detail-book img {
  max-height: 400px;
  max-width: 100%;
}
</style>
<style>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: white;
  background: none;
  border-bottom: 5px solid #ffb031;
}
.nav-pills .nav-link {
  color: #e1e1e1;
  border-radius: 0;
}
.panel-heading {
  padding: 0;
}
/*		.pic-multi{height: 400px;position: relative;line-height:400px;}*/
.pic-multi img {
  object-fit: cover;
}
.gbr-toko {
  height: 24px;
  margin-right: 10px;
  width: 80px;
  height: 80px;
  margin-right: 15px;
}
.gbr-toko img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.author-pic {
  height: 210px;
  width: 100%;
}
.flickity-page-dots {
  bottom: 45px !important;
}
@media (max-width: 575px) {
  .pic-multi {
    height: 210px;
    position: relative;
    line-height: 210px;
  }
  .pic-multi {
    width: 50%;
    margin: 0 auto;
  }
  .flickity-page-dots {
    bottom: 15px !important;
  }
  .desk-toko {
    display: none;
  }
  .author-pic {
    height: 60px;
    width: 100%;
  }
  .nav-detail-produk {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    z-index: 2000000001;
  }
  .baris-tbl-cart {
    display: none;
  }
}
@media (min-width: 575px) {
  .nav-detail-produk {
    display: none;
  }
}
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 30%;
  border-right: 1px solid #c4c4c4;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}
.top_spac {
  margin: 20px 0 0;
}

.recent_heading {
  float: left;
  width: 40%;
}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60% padding;
}
.headind_srch {
  padding: 10px 29px 10px 20px;
  overflow: hidden;
  border-bottom: 1px solid #c4c4c4;
}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input {
  border: 1px solid #cdcdcd;
  border-width: 0 0 1px 0;
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 0 0 8px 0;
}
.chat_ib h5 span {
  font-size: 13px;
  float: right;
}
.chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto;
}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}
.inbox_chat {
  height: 400px;
  overflow-y: scroll;
}

.active_chat {
  background: #ebebeb;
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}
.received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 150%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 9px;
  margin: 5px 0 15px 0;
}
.received_withd_msg {
  width: 57%;
}
.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
}

.sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}
.sent_msg {
  float: right;
  width: 76%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}
.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}
.messaging {
  padding: 0;
  background-color: #fff;
}
.msg_history {
  height: 400px;
  overflow-y: auto;
}

.product_msg_img {
  display: inline-block;
  width: 10%;
}
.product_msg {
  background: #b2b2b2;
  float: right;
  width: 76%;
}
.carousel-cell img {
  height: 100%;
  width: auto;
}
.garis-kanan {
  border-right: 1px solid #076aab;
}
.carousel-cell {
  background-size: contain !important;
}
</style>
