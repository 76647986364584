<template>
    <div>
        <div class="bg1">
            <div class="">
                <div class="p-t-3 p-b-5">
                    <nav class="">
                        <router-link :to="'/Blog/pejuang'" class="center">
                            <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                                alt="IMG-LOGO" class="center">
                        </router-link>
                    </nav>
                </div>
            </div>
        </div>
<section class="bg0 p-b-20 text-left">
    <div class="container p-lr-80-lg">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <h1 class="ltext-102 cl2 p-t-28">
                    Apa Saja Jenis Buku Generasi Milenial yang Paling Diminati? </h1>
                <div class="flex-m flex-sb m-tb-15">
                    <div class="flex-i flex-w flex-m stext-111 cl2">
                        <span>
                            <span class="cl4">Oleh</span> Eureka
                            <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <span>
                            17 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <div id="share" class="m-l-50-lg">
                            <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/apa-saja-jenis-buku-generasi-milenial-yang-paling-diminati"
                                target="_blank"><i class="fa fa-facebook"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/apa-saja-jenis-buku-generasi-milenial-yang-paling-diminati&amp;text=Blog: Apa Saja Jenis Buku Generasi Milenial yang Paling Diminati? &amp;hashtags=eurekabookhouse"
                                title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/apa-saja-jenis-buku-generasi-milenial-yang-paling-diminati"
                                title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                        </div>
                    </div>
                    <div class="flex-r-m">
                        <span class="stext-107 text-center"><i class="fa fa-eye"></i> 3636</span>
                    </div>
                </div>
                <div class="wrap-pic-w how-pos5-parent">
                    <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/Apa_Saja_Jenis_Buku_Generasi_Milenial_yang_Paling_Diminati.jpg"
                        alt="IMG-BLOG" class="blog-read-pic">
                    <div class="flex-col-c-m size-123 bg9 how-pos5">
                        <span class="ltext-107 cl2 txt-center">
                            17 </span>

                        <span class="stext-109 cl3 txt-center">
                            11 2022 </span>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row p-t-35 p-b-80">
            <div class="col-lg-2">
            </div>
            <div class="col-md-8 col-lg-8">
                <div class="p-r-0-lg">
                    <div>
                        <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                            <p>Apa yang terlintas dipikiaran mu ketika mendengar istilah generasi milenial? Sebagian
                                orang mengatakan bahwa generasi milenial adalah remaja tanggung yang dekat dengan
                                teknologi, senang segala sesuatunya dapat dikerjakan secara praktis, menjadikan media
                                sosial sebagai media informasi, dan dikenal tidak suka membaca buku. Namun, siapa bilang
                                milenial tidak suka membaca buku? buku-buku dengan jenis tertentu masih menjadi daya
                                tarik untuk kamu milenial lho! Mau tau apa saja jenis buku yang paling digemari generasi
                                milenial? Yuk simak lebih jauh. &nbsp;</p>

                            <h2><strong>1. Buku Tentang Pengembangan Diri</strong></h2>

                            <p>&nbsp;</p>

                            <p><img alt="Buku-Tentang-Pengembangan-Diri-Eureka-Blog"
                                    src="https://i.ibb.co/GFDBQTc/Buku-Tentang-Pengembangan-Diri-Eureka-Blog.jpg" /></p>

                            <p>Generasi milenial selalu ingin melakukan pengembangan-pengembangan pada dirinya. Salah
                                satu karakteristik generasi milenial adalah generasi yang suka dengan kebebasan dan
                                menyukai waktu yang fleksibel dalam bekerja. Dengan karakteristik seperti ini, generasi
                                milenial akan melihat situasi dan peluang apa yang dapat dimanfaatkan. Buku-buku
                                pengembangan diri menjadi hal yang dicari, seperti buku tentang bisnis <em>online</em>
                                maupun buku-buku tentang <em>startup</em>.</p>

                            <p>&nbsp;</p>

                            <h2><strong>2. Buku Puisi </strong></h2>

                            <p>&nbsp;</p>

                            <p><img alt="Buku-Puisi-Eureka-Blog"
                                    src="https://i.ibb.co/hLsb9GS/Buku-Puisi-Eureka-Blog.jpg" /></p>

                            <p>Membaca buku-buku puisi masih diminati oleh generasi milenial. Buku-buku puisi dengan
                                tema cinta, persahabatan, perjalanan hidup, maupun keluarga menjadi tema-tema yang
                                dicari. Saat sedang dihadapkan dengan permasalahan cinta, buku puisi dengan tema cinta
                                akan menjadi teman yang baik untuk mu menghargai perasaan. Tema lainnya yang banyak
                                diminati oleh generasi milenial adalah tenang perjalanan hidup. Kamu akan merasa
                                terhanyut dalam buku puisi ini apabila sejalan dengan emosi pembacanya. Ada yang pernah
                                merasa demikian?</p>

                            <p>&nbsp;</p>

                            <h2><strong>3. Buku Travel </strong></h2>

                            <p>&nbsp;</p>

                            <p><img alt="Buku-Travel-Eureka-Blog"
                                    src="https://i.ibb.co/3MmSYXC/Buku-Travel-Eureka-Blog.jpg" /></p>

                            <p>Traveling menjadi kegiatan yang digemari oleh generasi milenial. Kini melakukan
                                perjalanan ke daerah-daerah wisata sudah sangat mudah. Ditambah mulai bermunculan
                                <em>influencer</em> yang berhasil menceritakan kisah perjalanannya dengan baik. Beberapa
                                buku yang membahas perjalanan sudah banyak digemari, salah satunya adalah buku <em>Naked
                                    Traveler</em> karya Ade Perucha Hutagaol atau yang dikenal dengan Trinity.
                            </p>

                            <p>&nbsp;</p>

                            <h2><strong>4. Buku Sastra</strong></h2>

                            <p>&nbsp;</p>

                            <p><img alt="Buku-Sastra-Eureka-Blog"
                                    src="https://i.ibb.co/3yNnnkf/Buku-Sastra-Eureka-Blog.jpg" /></p>

                            <p>Buku sastra masih menjadi pilihan untuk generasi milenial. Membaca buku-buku sastra
                                dinilai dapat memberikan manfaat, seperti memperkaya diksi dalam berbahasa juga sebagai
                                sarana mempelajari sejarah dengan lebih mudah. Buku-buku karya Seno Gumira Ajidarma
                                maupun Pramoedya Ananta Toer dapat menjadi pilihan untuk kamu yang menyukasi buku-buku
                                sastra. Dengan membaca buku-buku sastra, selain akan memperkaya kosakata dan imajinasi,
                                juga akan mengasah kreatifitas pembacanya.</p>

                            <p>&nbsp;</p>

                            <h2><strong>5. Komik </strong></h2>

                            <p>&nbsp;</p>

                            <p><img alt="Buku-Komik-Eureka-Blog"
                                    src="https://i.ibb.co/xDJfhDW/Buku-Komik-Eureka-Blog.jpg" /></p>

                            <p>Keberadaan komik masih digemari generasi milenial sampai saat ini. Gambar menarik yang
                                mendominasi dengan percakapan-percakapan singkat merupakan hal yang sangat disukai.
                                Generasi milenial dapat dengan mudah mengerti alur cerita tanpa harus berpikir keras.
                                Aktivitas membaca komik menjadi hal yang sangat menyenangkan. Membaca komik dilakukan
                                generasi milenial biasanya dilakukan pada waktu-waktu senggang dan saat bersantai di
                                rumah.</p>

                            <p>Itulah beberapa buku yang digemari oleh generasi milenial. Perlu kamu ketahui bahwa trend
                                serta pergaulan menjadi salah satu faktor yang memengaruhi buku-buku bacaan yang
                                digemari generasi milenial ini lho! Kalau kamu saat ini sedang membaca buku apa
                                <em>nih</em>?
                            </p>
                        </div>
                    </div>
                    <div class="media ava-bloger">
                        <img class="mr-3 rounded-circle img-thumbnail"
                            src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                            alt="Generic placeholder image">
                        <div class="media-body">
                            <p class="stext-101 m-b-8">DITULIS OLEH</p>
                            <h5 class="mt-0 mtext-111">Andaf Iman</h5>
                            <p class="badge badge-primary">Content Writer</p>
                            <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <section class="sec-relate-product bg6 p-t-20 text-left">
        <div class="container p-tb-10">
            <div class="p-b-15">
                <h3 class="session-title mtext-106 cl5">New Entry</h3>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/kisahkasih'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/rahasiadelia'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/pohonmangga'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                                </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/malaikat'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/ttgayah'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/merah'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1"><a
                                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/jejak'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/pejuang'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                                </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">04 September 2022</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer class="bgu1 p-t-15 p-b-15">
        <div class="container">
            <div class="p-t-10">
                <p class="stext-107 cl0 txt-center">
                    Copyright &copy; Dikembangkan oleh Eureka IT Developer
                </p>
            </div>
        </div>
    </footer>
    </div>
</template>
<script>
export default {
    name: "satuPage",
}
</script>