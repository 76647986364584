var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"bg6 p-t-10 p-b-10"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-7 col-xl-3 m-lr-auto m-b-50 d-none d-lg-block"},[_c('sidebarPage')],1),_c('div',{staticClass:"col-lg-10 col-xl-9 m-b-25 mt-4"},[_c('div',{staticClass:"bg4 m-b-10",staticStyle:{"margin-top":"25px"}},[_vm._l((_vm.addressList),function(address,index){return _c('div',{key:index,staticClass:"mt-3 card m-b-5",staticStyle:{"border-radius":"15px"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","align-self":"stretch"}},[_c('div',{staticStyle:{"display":"flex","background-color":"#ffebc1","color":"#bb891d","padding":"8px","justify-content":"center","align-items":"center","gap":"8px","border-radius":"10px","font-size":"14px","font-weight":"400"}},[_c('button',{class:{
                        'star-button-active': address.status === 0,
                        'star-button-inactive': address.status === 1,
                      },on:{"click":() => _vm.editAlamatFavorite(address.address_id)}},[_vm._m(0,true)]),_c('span'),_vm._v(" Alamat Pengiriman ")]),_c('div',[_c('button',{staticClass:"mr-2",staticStyle:{"width":"50px","padding":"10px","justify-content":"center","align-items":"center","gap":"10px","border-radius":"30px","background":"var(--primary-05, #244786)"},on:{"click":function($event){return _vm.editAddress(address)}}},[_vm._m(1,true)]),_c('button',{staticClass:"mr-3",staticStyle:{"width":"50px","padding":"10px","justify-content":"center","align-items":"center","gap":"10px","border-radius":"30px","background":"#ff0000"},on:{"click":function($event){return _vm.deleteAddress(address.address_id)}}},[_vm._m(2,true)])])]),_c('div',{staticClass:"mr-3 mt-3"},[_c('p',{staticClass:"title-name text-left"},[_vm._v(" "+_vm._s(address.firstname)+" "+_vm._s(address.lastname)+" ")]),_c('p',{staticClass:"text-number text-left"},[_vm._v(_vm._s(address.telephone))]),_c('p',{staticClass:"text-alamat text-left"},[_vm._v(" "+_vm._s(address.address_2)+", "+_vm._s(address.city)+", "+_vm._s(address.province)+", "+_vm._s(address.postcode)+" ")])])])])}),_c('br'),_c('router-link',{staticClass:"btn-tambah",attrs:{"to":'/Users/tambahalamat',"role":"button"}},[_c('span',{staticStyle:{"color":"#000","font-size":"16px","font-style":"normal","font-weight":"600","line-height":"18px","/* 112.5% */\n                  letter-spacing":"-0.16px"}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Tambah Alamat Pengiriman Lain")])])],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"color":"var(--neutral-01, #fefefe)","font-size":"20px","font-style":"normal","font-weight":"400","line-height":"13.2px","/* 110% */\n                          letter-spacing":"-0.12px"}},[_c('i',{staticClass:"fa fa-star",attrs:{"aria-hidden":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"color":"var(--neutral-01, #fefefe)","font-size":"20px","font-style":"normal","font-weight":"400","line-height":"13.2px","/* 110% */\n                          letter-spacing":"-0.12px"}},[_c('i',{staticClass:"fa fa-pencil",attrs:{"aria-hidden":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"color":"var(--neutral-01, #fefefe)","font-size":"20px","font-style":"normal","font-weight":"400","line-height":"13.2px","/* 110% */\n                          letter-spacing":"-0.12px"}},[_c('i',{staticClass:"fa fa-trash",attrs:{"aria-hidden":"true"}})])
}]

export { render, staticRenderFns }