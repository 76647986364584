<template>
  <div>
    <section style="margin-bottom: 0px" class="sec-relate-product bg6">
      <div
        class="container bguk p-tb-15 p-lr-15 p-lr-5-sm"
        style="padding: 0px"
      >
        <div>
          <a
            class="title-semua a.stext-101 cl0 pull-right"
            style="
              font-size: 14px;
              line-height: 32px;
              font-weight: 400;
              color: #3860a8;
              padding-right: 15px;
            "
            href="https://www.eurekabookhouse.co.id/cari/result/?category=novel"
            >Lihat semua</a
          >
          <h3
            class="fs mtext-105 cl0 mb-2 text-left mr-2"
            style="
              font-size: 20px;
              font-weight: 600;
              color: #32599f;
              padding-left: 25px;
            "
          >
            Mall & Toko Online
          </h3>
          <div class="mt-2 position-relative">
            <div
              class="d-none d-lg-block flickity-prev-next-button flickity-prev-button"
              style="
                position: absolute;
                top: 50%;
                left: 5px;
                transform: translateY(-50%);
                z-index: 1;
              "
            >
              <button
                @click="prevSlide"
                style="
                  background-color: rgba(255, 255, 255, 0.85);
                  border-radius: 50%;
                  width: 50px;
                  font-size: 32px;
                  color: black;
                "
              >
                <i class="fa fa-angle-left" aria-hidden="true"></i>
              </button>
            </div>
            <flickity
              v-if="produkMaster.length > 0"
              ref="flickity"
              class="flickity"
              :options="flickityOptions"
            >
              <div v-for="(produk, index) in produkMaster" :key="index">
                <div class="mt-3">
                  <div class="row" style="padding: 0">
                    <div class="col mr-2 mb-4">
                      <div
                        class="carousel-cell eurekabookmart is-selected"
                        style="background-color: white"
                      >
                        <div class="mall_index">
                          <a
                            href="https://www.eurekabookhouse.co.id/eurekamart"
                          >
                            <img
                              class="lazy mall_img_index"
                              style="
                                padding: 12px;
                                width: 230px;
                                object-fit: contain;
                              "
                              :src="
                                produk.image
                                  ? `https://cdn.eurekabookhouse.co.id/ebh/mall/${produk.image}`
                                  : require('@/assets/images/detail/aktif.png')
                              "
                              alt="Product Image"
                              @error="handleImageError"
                            />
                          </a>
                        </div>
                        <div
                          style="background: #ea212b"
                          class="card-title-mall"
                        >
                          <a href="https://www.eurekabookhouse.co.id/eurekamart"
                            ><span class="title-mall">{{
                              produk.name
                            }}</span></a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </flickity>
            <div
              class="d-none d-lg-block flickity-prev-next-button flickity-next-button"
              style="
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
              "
            >
              <button
                @click="nextSlide"
                style="
                  background-color: rgba(255, 255, 255, 0.85);
                  border-radius: 50%;
                  width: 50px;
                  font-size: 32px;
                  color: black;
                "
              >
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Vue from "vue";
import numeral from "numeral";
import Flickity from "vue-flickity";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "mallPage",
  components: {
    Flickity,
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
        wrapAround: false,
        freeScroll: false,
      },
      produkMaster: [], // Menyimpan data produk dari API
    };
  },
  mounted() {
    this.getProduk();
  },

  methods: {
    handleImageError(event) {
      event.target.src = require("@/assets/images/detail/aktif.png");
    },
    prevSlide() {
      this.$refs.flickity.previous();
    },

    nextSlide() {
      this.$refs.flickity.next();
    },

    async getProduk() {
      try {
        const response = await axios.get(
          "https://stagingapi.eurekabookhouse.co.id/mall?limit=10"
        );
        if (response.data.status.code === 200) {
          // Ubah struktur data yang diterima dari API
          this.produkMaster = response.data.data.order.map((produk) => {
            return {
              mall_id: produk.mall_id,
              name: produk.name,
              image: produk.image,
              link: `https://www.eurekabookhouse.co.id/mall/detail/${produk.slug}`,
            };
          });
        } else {
          throw new Error(response.data.status.message);
        }
      } catch (error) {
        console.error("Error fetching produk:", error);
        Swal.fire({
          confirmButtonColor: "#3860A8",
          icon: "error",
          title: "Error",
          text: "Gagal memuat produk. Silakan coba lagi nanti.",
        });
      }
    },
  },
};
</script>

<style>
.card-title-mall {
  display: flex;
  width: 100%;
  margin-top: 11px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 0px 0px 10px 10px;
}

.title-mall {
  color: var(--neutral-01, #fefefe);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}

.card-title-mall {
  display: flex;
  width: 100%;
  margin-top: 11px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 0px 0px 10px 10px;
}
/* Responsive Styles */
@media (max-width: 770px) {
  .card-title-mall {
    width: 100%; /* Full width on small screens */
  }

  .title-mall {
    font-size: 12px; /* Adjust font size for small screens */
  }
}

/* Additional Bootstrap Classes for Responsiveness */
@media (max-width: 770px) {
  .card-title-mall {
    padding: 8px; /* Adjust padding for medium screens */
  }

  .title-mall {
    font-size: 13px; /* Adjust font size for medium screens */
  }
}
</style>
  