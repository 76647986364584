<template>
    <div>
<div>
    <div class="bg1">
        <div class="">
            <div class="p-t-3 p-b-5">
                <nav class="">
                    <router-link :to="'/Blog/pejuang'" class="center">
                        <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                            alt="IMG-LOGO" class="center">
                    </router-link>
                </nav>
            </div>
        </div>
    </div>
<section class="bg0 p-b-20 text-left">
    <div class="container p-lr-80-lg">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <h1 class="ltext-102 cl2 p-t-28">
                    Sepuluh Buku Anak Terlaris Januari 2019 </h1>
                <div class="flex-m flex-sb m-tb-15">
                    <div class="flex-i flex-w flex-m stext-111 cl2">
                        <span>
                            <span class="cl4">Oleh</span> Eureka
                            <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <span>
                            17 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <div id="share" class="m-l-50-lg">
                            <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/sepuluh-buku-anak-terlaris-januari-2019"
                                target="_blank"><i class="fa fa-facebook"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/sepuluh-buku-anak-terlaris-januari-2019&amp;text=Blog: Sepuluh Buku Anak Terlaris Januari 2019 &amp;hashtags=eurekabookhouse"
                                title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/sepuluh-buku-anak-terlaris-januari-2019"
                                title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                        </div>
                    </div>
                    <div class="flex-r-m">
                        <span class="stext-107 text-center"><i class="fa fa-eye"></i> 3291</span>
                    </div>
                </div>
                <div class="wrap-pic-w how-pos5-parent">
                    <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/mengajarkan-anak.jpg" alt="IMG-BLOG"
                        class="blog-read-pic">
                    <div class="flex-col-c-m size-123 bg9 how-pos5">
                        <span class="ltext-107 cl2 txt-center">
                            17 </span>

                        <span class="stext-109 cl3 txt-center">
                            11 2022 </span>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row p-t-35 p-b-80">
            <div class="col-lg-2">
            </div>
            <div class="col-md-8 col-lg-8">
                <div class="p-r-0-lg">
                    <div>
                        <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                            <p>Awal tahun 2019 telah kita lalui. Tentu saja target pencapaian yang dikejar tahun ini
                                juga baru. Memulai karir baru, memulai usaha, menikah, memiliki anak pertama. Ataupun
                                yang sedang mulai mengenalkan anak dengan tanah ibu pertiwi beserta kekayaannya maupun
                                dunia.<br />
                                Anak memang penuh dengan keingintahuan. Untuk itu kami merangkum 10 buku terlaris
                                sepanjang bulan Januari 2019 untuk anda. Barangkali bisa menjadi referensi untuk si
                                kecil anda berikutnya.</p>

                            <p><strong>1. <a
                                        href="https://www.eurekabookhouse.co.id/product/detail/15361/ulysses-moore-8-penguasa-petir">ULYSSES
                                        MOORE 8: PENGUASA PETIR</a></strong></p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/15361/ulysses-moore-8-penguasa-petir"><img
                                            alt="ULYSSES MOORE 8: PENGUASA PETIR"
                                            src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-2004570020.jpg"
                                            style="height:448px; width:296px" /></a></strong><br />
                                Ulysses Moore adalah novel fantasi yang menceritakan petualangan Jason, Julia dan Rick
                                dalam mengungkap teka-teki pintu waktu dan kunci-kuncinya.<br />
                                Buku seri Ulysses Moore telah diterjemahkan dalam beberapa bahasa dan sudah dikenal para
                                penggemar novel fantasi. Jilid ke-8 ini sudah dinantikan pembaca buku Ulysses Moore
                                sebelumnya.<br />
                                Pengarangnya, Pierdomenico Baccalario merupakan pengarang buku fantasi best seller.
                                Ulysses Moore adalah salah satu dari beberapa buku fantasi karangannya.<br />
                                Pengkarakteran tokoh yang kuat, alur yang tidak bisa ditebak, gaya penulisan deskriptif
                                yang menarik, serta memiliki nilai moral keberanian dan persahabatan.</p>

                            <p><strong>2. <a
                                        href="https://www.eurekabookhouse.co.id/product/detail/4147/stiker-baju-boneka-jalan-jalan">STIKER
                                        BAJU BONEKA: JALAN-JALAN</a></strong></p>

                            <p><img alt="Stiker Baju Boneka: Jalan-Jalan "
                                    src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2007070110.jpg" /><br />
                                Bergabung bersama Tilly, Lola, dan Erin. Mereka mengajak anak-anak untuk berkeliling
                                dunia! Dandani mereka saat mereka berada di Bandara Udara, bertualang naik kuda, naik
                                kereta dalam kota, menunggu bus.<br />
                                Seri ini sangat terkenal dan laris di pasaran. Melatih kreativitas anak dan motorik
                                halus dengan beraktivitas menempel. Melatih koordinasi antara cerita dengan pakaian yang
                                akan dipakai.</p>

                            <p><strong>3. <a
                                        href="https://www.eurekabookhouse.co.id/product/detail/1908/atlas-pelajar-indonesia-dan-dunia-rev">ATLAS
                                        PELAJAR INDONESIA &amp; DUNIA</a></strong></p>

                            <p><img alt="Atlas Pelajar Indonesia &amp; Dunia/Rev"
                                    src="https://cdn.eurekabookhouse.co.id/ebh/product/all/61-19-090-0.jpg"
                                    style="height:325px; width:230px" /><br />
                                Buku Atlas ini berisi peta-peta 34 provinsi di Indonesia, benua-benua, dan beberapa
                                negara di Asia Tenggara dan dunia yang dapat menunjang pembelajaran IPS bagi siswa SD,
                                khususnya kelas 4, 5, dan 6. Peta dalam atlas ini dibuat dengan sistem digital sehingga
                                tingkat ketepatan dan kerapiannya jauh lebih akurat. Hal tersebut akan memudahkan para
                                siswa dalam memahami keseluruhan isi peta. Di dalam atlas ini juga dilengkapi dengan
                                daftar indeks yang sangat membantu dan memudahkan pengguna mencari letak objek tertentu.
                            </p>

                            <p><strong>4. <a href="https://www.eurekabookhouse.co.id/product/detail/15479">SEGALA HAL
                                        TENTANG DINOSAURUS</a></strong></p>

                            <p><img alt="Segala Hal Tentang Dinosaurus "
                                    src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2006030010.jpg"
                                    style="height:448px; width:281px" /><br />
                                Dinosaurus adalah salah satu topik paling menarik dalam ilmu pengetahuan, terutama bagi
                                anak-anak. Buku Segala Hal Tentang Dinosaurus ini memberikan informasi mendetail
                                mengenai berbagai genus dinosaurus, baik yang dikenal secara luas maupun yang tidak
                                terlalu familier, mulai dari penemuan fosil pertama, etimologi, ciri-ciri fisik, hingga
                                makanan dan habitat mereka. Selain itu, buku ini juga menyajikan informasi tambahan,
                                seperti skala waktu masa prasejarah dan berbagai lokasi fosil dinosaurus terkenal di
                                dunia.</p>

                            <p><strong>5. APE: MARI MENJAHIT BUAH</strong><br />
                                Alat peraga ini merupakan cara efektif untuk membantu anak mempersiapkan diri memasuki
                                jenjang sekolah, termasuk cakap menulis. Kemampuan kelenturan tangan ini adalah dasar
                                bagi anak dalam belajar menulis. Cara memegang pensil dalam menulis hampir sama dengan
                                memegang jarum pada saat menjahit.<br />
                                Permainan ini bisa diperkenalkan kepada anak usia di atas 2 tahun karena kemampuan
                                motorik halus anak sudah berkembang dengan baik.</p>

                            <p><strong>6. KISAH 25 NABI DAN RASUL (PELANGI)</strong><br />
                                Teman-Teman, lihat susunan nama nabi di isi buku. Urutan nama nabi yang pertama hingga
                                terakhir, sama dengan urutan gambar di sampul depan dari kiri ke kanan, kecuali kotak
                                logo Pelangi Mizan.<br />
                                Selanjutnya, Teman-Teman bisa mengingat nama-nama nabi dengan mengingat gambar-gambar di
                                sampul depan. Misalnya, gambar buah berarti Nabi Adam, gambar unta berarti Nabi Shaleh,
                                dan begitu seterusnya.</p>

                            <p><strong>7. SERI ANAK SEHAT: P3K UNTUK ANAK</strong><br />
                                https://www.eurekabookhouse.co.id/product/detail/15635<br />
                                P3K merupakan sebuah pengatahuan dasar yang perlu diketahui oleh semua orang, termasuk
                                anak. Cerita-cerita dalam buku ini mengajak anak untuk mengenal serta mempelajari
                                teknik-teknik sederhana dalam P3K. Keunggulan produk: 1. Memberikan pengetahuan pada
                                orang tua/guru untuk mengatasi cedera pada anak. 2. Anak akan menyukai ceritanya yang
                                lucu dan ilustrasinya yang menarik. 3. Dilengkapi tahap-tahap dalam melakukan teknik
                                pertolongan pertama 4. Dilengkapi dengan lembar aktivitas (digital) untuk anak.</p>

                            <p><strong>8. BELAJAR MATEMATIKA (USIA 6+)</strong><br />
                                Latih kemampuan dasar anak Anda dalam matematika dengan buku aktivitas matematika dari
                                seri Aku Suka Belajar.</p>

                            <p><strong>9. MINNIE MOUSE: BUTIK CANTIK MINNIE</strong><br />
                                Minnie sudah siap ke butiknya yang cantik dan melewati hari yang menyenangkan di
                                sana.<br />
                                Bantu Minnie dan teman-temannya membuat pita, merancang gaun untuk bintang film terkenal
                                Penelope Poodle, merencanakan piknik yang menyenangkan di taman, dan lain-lain!<br />
                                Bersiap-siaplah menemukan kejutan menyenangkan di dalam buku lift the flap ini.</p>

                            <p><strong>10. <a
                                        href="https://www.eurekabookhouse.co.id/product/detail/15619/paket-imc-little-scientist">PAKET
                                        IMC LITTLE SCIENTIST</a></strong><br />
                                Cerita bergambar yang terdiri atas 4 judul dengan tema Sains yang mengajak anak memahami
                                objek alam dalam kehidupan sehari-hari dengan cara yang menyenangkan. Ditulis oleh
                                Elvina Lim Kusumo, founder IMC (Indonesia Montessori.com)<br />
                                Dilengkapi dengan kegiatan montesori dan percobaan sains di akhir cerita Dengan
                                pendekatan STEAM dapat memicu anak menjadi individu yang kreatif, kritis, dan memiliki
                                kemampuan analitis. Rekomendasi tepat untuk sekolah dan perpustakaan untuk jenjang PAUD
                                dan SD. Judul di dalam Paket: Bermain dengan Awan Menangkap Matahari Bagaimana Bentuk
                                Bulan Berapa Warna Pelangi</p>

                            <p>&nbsp;</p>
                        </div>
                    </div>
                    <div class="media ava-bloger">
                        <img class="mr-3 rounded-circle img-thumbnail"
                            src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                            alt="Generic placeholder image">
                        <div class="media-body">
                            <p class="stext-101 m-b-8">DITULIS OLEH</p>
                            <h5 class="mt-0 mtext-111"></h5>
                            <p class="badge badge-primary"></p>
                            <p class="stext-107"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="sec-relate-product bg6 p-t-20 text-left">
    <div class="container p-tb-10">
        <div class="p-b-15">
            <h3 class="session-title mtext-106 cl5">New Entry</h3>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/kisahkasih'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/rahasiadelia'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pohonmangga'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/malaikat'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/ttgayah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/merah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/jejak'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pejuang'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">04 September 2022</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <footer class="bgu1 p-t-15 p-b-15">
        <div class="container">
            <div class="p-t-10">
                <p class="stext-107 cl0 txt-center">
                    Copyright &copy; Dikembangkan oleh Eureka IT Developer
                </p>
            </div>
        </div>
    </footer>
</div>
    </div>
</template>
<script>
export default {
    name: "sembilanPage",
}
</script>