<template>
    <div>
<div>
    <div class="bg1">
        <div class="">
            <div class="p-t-3 p-b-5">
                <nav class="">
                    <a href="https://www.eurekabookhouse.co.id/" class="center">
                        <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                            alt="IMG-LOGO" class="center">
                    </a>
                </nav>
            </div>
        </div>
    </div>
    <section class="bg0 p-b-20 text-left">
        <div class="container p-lr-80-lg">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <h1 class="ltext-102 cl2 p-t-28">
                        Setelah Lulus Mau Ngapain? Ini 5 Hal Yang Bisa Kamu Lakukan Setelah Lulus SMA </h1>
                    <div class="flex-m flex-sb m-tb-15">
                        <div class="flex-i flex-w flex-m stext-111 cl2">
                            <span>
                                <span class="cl4">Oleh</span> Eureka
                                <span class="cl12 m-l-4 m-r-6">|</span>
                            </span>
                            <span>
                                18 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                            </span>
                            <div id="share" class="m-l-50-lg">
                                <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                    href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/setelah-lulus-mau-ngapain--ini-5-hal-yang-bisa-kamu-lakukan-setelah-lulus-sma-"
                                    target="_blank"><i class="fa fa-facebook"></i></a>
                                <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                    href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/setelah-lulus-mau-ngapain--ini-5-hal-yang-bisa-kamu-lakukan-setelah-lulus-sma-&amp;text=Blog: Setelah Lulus Mau Ngapain? Ini 5 Hal Yang Bisa Kamu Lakukan Setelah Lulus SMA  &amp;hashtags=eurekabookhouse"
                                    title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                    href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/setelah-lulus-mau-ngapain--ini-5-hal-yang-bisa-kamu-lakukan-setelah-lulus-sma-"
                                    title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                            </div>
                        </div>
                        <div class="flex-r-m">
                            <span class="stext-107 text-center"><i class="fa fa-eye"></i> 3533</span>
                        </div>
                    </div>
                    <div class="wrap-pic-w how-pos5-parent">
                        <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/Hal_Yang_Bisa_Kamu_Lakukan_Setelah_Lulus_SMA.jpg"
                            alt="IMG-BLOG" class="blog-read-pic">
                        <div class="flex-col-c-m size-123 bg9 how-pos5">
                            <span class="ltext-107 cl2 txt-center">
                                18 </span>
    
                            <span class="stext-109 cl3 txt-center">
                                11 2022 </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
            <div class="row p-t-35 p-b-80">
                <div class="col-lg-2">
                </div>
                <div class="col-md-8 col-lg-8">
                    <div class="p-r-0-lg">
                        <div>
                            <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                                <p>Beberapa waktu lalu hasil Seleksi Nasional Masuk Perguruan Tinggi Negeri (SNMPTN)
                                    diumumkan. Adanya kuota pada setiap universitas membuat tidak semua siswa yang mengikuti
                                    seleksi ini bisa masuk ke dalam universitas yang diinginkannya. Ada tawa dan tangis yang
                                    mengisi saat pengumuman diumumkan. Tangis bahagia maupun tangis sedih karena belum bisa
                                    lolos ke universitas yang diinginkan melalui jalur SNMPTN ini. Namun, tenang masih
                                    banyak cara dan jalan yang bisa kamu lakukan untuk meraih cita-citamu. Kamu bisa
                                    melakukan berbagai macam pengembangan diri <em>lho</em>! <em>Yuk</em> simak beberapa hal
                                    yang bisa kamu lakukan berikut setelah lulus Sekolah Menengah Atas (SMA). &nbsp;</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>1. Persiapkan Diri untuk Ujian Selanjutnya</strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><strong><img alt="persiapan ujian"
                                            src="https://cdn.eurekabookhouse.co.id/ebh/blog/galeri/persiapan_ujian.jpg"
                                            style="height:426px; width:640px" /></strong></p>
    
                                <p>SNMPTN bukanlah segalanya, setuju? Setalah tahap ini, kamu akan dihadapkan dengan
                                    beberapa ujian lainnya, seperti Seleksi Bersama Masuk Perguruan Tinggi Negeri (SBMPTN).
                                    Hal yang bisa kamu lalukan setelah lulus SMA adalah pacu dan jaga terus semangatmu untuk
                                    mempersiapkan diri menghadapi ujian selanjutnya. Kamu bisa mengikuti bimbel-bimbel
                                    intensif yang disediakan oleh berbagai kursusan di luar sana atau kamu bisa belajar
                                    sendiri dengan memantapkan materi ujian. Latih dan biasakan dirimu mengerjakan soal-soal
                                    ujian SBMPTN sebelumnya. Kamu juga harus ingat, beberapa universitas akan membuka jalur
                                    ujian mandiri atau yang disebut UM. Kamu bisa mengikuti ujian ini ya jika kamu berminat.
                                </p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>2. Ikuti Lokakarya Yang Kamu Sukai</strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><strong><img alt="lokakarya"
                                            src="https://cdn.eurekabookhouse.co.id/ebh/blog/galeri/lokakarya.jpg" /></strong>
                                </p>
    
                                <p>Hal berikutnya yang bisa kamu lakukan setelah lulus adalah mengikuti lokakarya atau
                                    seminar dengan tema-tema yang kamu sukai. Kamu bisa terus meningkatkan pengetahuanmu
                                    akan suatu hal yang kamu sukai. Informasi mengenai lokakarya bisa kamu cari melalui
                                    pencarian di internet maupun sosial mediamu. Sudah banyak <em>lho</em> akun-akun yang
                                    memberikan informasi tentang lokakarya. Dengan mengikuti lokakarya, pengetahuan dan
                                    skillmu akan terus terasah dan bertambah.</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>3. Gabung dengan Komunitas Yang Kamu Inginkan</strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><strong><img alt="komunitas"
                                            src="https://cdn.eurekabookhouse.co.id/ebh/blog/galeri/komunitas.jpg" /></strong>
                                </p>
    
                                <p>Bergabung dengan sebuah komunitas adalah salah satu hal yang bisa kamu lakukan setelah
                                    lulus SMA. Jika kamu tahu, banyak sekali komunitas-komunitas di luar sana dengan
                                    berbagai kegiatan-kegiatan rutin yang selalu dilakukan. Biasanya setiap komunitas
                                    memiliki waktu berkumpul dan jadwal kegiatannya masing-masing. Dengan mengikuti kegiatan
                                    dalam komunitas, selain kamu akan mendapatkan ilmu, kamu juga akan memperluas relasi mu.
                                    Kamu bisa mencari informasi mengenai komunitas-komunitas yang ada melalui akun media
                                    sosialmu atau kamu bisa langsung datang ke tempat kominitas tersebut beraktivitas.</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>4. Ikut Kompetisi Sesuai Apa Yang Kamu Sukai</strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><strong><img alt="kompetisi"
                                            src="https://cdn.eurekabookhouse.co.id/ebh/blog/galeri/kompetisi.jpg" /></strong>
                                </p>
    
                                <p>Kamu memiliki hobi bernyanyi? Atau hal-hal lain yang kamu sukai? mengikuti
                                    kompetisi-kompetisi adalah salah satu hal yang bisa kamu lakukan setelah lulusSMA. Ada
                                    banyak kompetisi yang disediakan di luar sana <em>lho</em>! Hadiahnya beragam, mulai
                                    dari barang, beasiswa, sampai jalan-jalan ke luar negeri. Menarik bukan?</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>5. Daftar Kerelawanan</strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><strong><img alt="relawan"
                                            src="https://cdn.eurekabookhouse.co.id/ebh/blog/galeri/relawan.jpg" /></strong>
                                </p>
    
                                <p>Untuk kamu yang suka bersosialisasi dan terlibat dalam kegiatan kemanusiaan, mendaftar
                                    program kerelawanan bisa kamu lakukan setelah lulus SMA. Di sini kamu akan membuat
                                    berbagai program kegiatan yang akan kamu realisasikan saat menjadi relawan nantinya.
                                    Kamu akan mengunjungi tempat-tempat di luar sana yang membutuhkan bantuanmu. Informasi
                                    mengenai program kerelawanan sangat banyak tersebar di media sosialmu. Ayo di cek ya!
                                    Untuk mengikuti program ini biasanya kamu akan dihadapkan dengan beberapa seleksi
                                    seperti membuat esai juga wawancara.</p>
    
                                <p><em>Nah</em>, ini dia beberapa hal yang bisa kamu lakukan setelah lulus SMA. Tanamkan
                                    kalimat ini dalam dirimu bahwa &ldquo;di setiap pengalaman pasti akan ada pelajaran yang
                                    akan kamu dapat dan pelajari. Akan banyak pelajaran yang bisa kamu dapakan, bukan satu,
                                    bukan dua, tapi banyak.&rdquo; Terus semangat ya untuk mengejar mimpi-mimpimu!</p>
                            </div>
                        </div>
                        <div class="media ava-bloger">
                            <img class="mr-3 rounded-circle img-thumbnail"
                                src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                                alt="Generic placeholder image">
                            <div class="media-body">
                                <p class="stext-101 m-b-8">DITULIS OLEH</p>
                                <h5 class="mt-0 mtext-111">Andaf Iman</h5>
                                <p class="badge badge-primary">Content Writer</p>
                                <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="sec-relate-product bg6 p-t-20 text-left">
        <div class="container p-tb-10">
            <div class="p-b-15">
                <h3 class="session-title mtext-106 cl5">New Entry</h3>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/kisahkasih'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/rahasiadelia'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/pohonmangga'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                                </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/malaikat'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/ttgayah'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/merah'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1"><a
                                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat
                                    Eureka</a>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/jejak'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/pejuang'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA
                                    BOOKHOUSE
                                </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">04 September 2022</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer class="bgu1 p-t-15 p-b-15">
        <div class="container">
            <div class="p-t-10">
                <p class="stext-107 cl0 txt-center">
                    Copyright &copy; Dikembangkan oleh Eureka IT Developer
                </p>
            </div>
        </div>
    </footer>
</div>
    </div>
</template>
<script>
export default {
    name: "cPage",
}
</script>