<template>
    <div>
        <div class="bg1">
            <div class="">
                <div class="p-t-3 p-b-5">
                    <nav class="">
                        <a href="https://www.eurekabookhouse.co.id/" class="center">
                            <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                                alt="IMG-LOGO" class="center">
                        </a>
                    </nav>
                </div>
            </div>
        </div>
        <div class="container mobile-blog-rapi-atas">
            <div class="bread-crumb flex-w p-tb-18 p-lr-0-lg">
                <a href="https://www.eurekabookhouse.co.id/" class="stext-109 cl8 hov-cl1 trans-04">
                    Home
                    <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
                </a>
    
                <a href="https://www.eurekabookhouse.co.id/blog" class="stext-109 cl8 hov-cl1 trans-04">
                    Blog
                    <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
                </a>
    
                <span class="stext-109 cl4 text-left">
                    POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE			</span>
            </div>
        </div>
    
        <!-- Content page -->
        <section class="bg0 p-b-20">
            <div class="container p-lr-80-lg">
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                        <h1 class="ltext-102 cl2 p-t-28 text-left">
                            POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE					</h1>
                        <div class="flex-m flex-sb m-tb-15">
                            <div class="flex-i flex-w flex-m stext-111 cl2">
                                <span>
                                    <span class="cl4">Oleh</span> Eureka 
                                    <span class="cl12 m-l-4 m-r-6">|</span>
                                </span>
                                <span>
                                    05 Sep, 2022								<span class="cl12 m-l-4 m-r-6">|</span>
                                </span>
                            <div id="share" class="m-l-50-lg">
                                <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share" href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/pohon-mangga-itu-berbuah-kembali---juara-favorit-8-lomba-cerpen-eureka-bookhouse" target="_blank"><i class="fa fa-facebook"></i></a>
                                <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share" href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/pohon-mangga-itu-berbuah-kembali---juara-favorit-8-lomba-cerpen-eureka-bookhouse&amp;text=Blog: POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE &amp;hashtags=eurekabookhouse" title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share" href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/pohon-mangga-itu-berbuah-kembali---juara-favorit-8-lomba-cerpen-eureka-bookhouse" title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                            </div>
                        </div>
                        <div class="flex-r-m">
                            <span class="stext-107 text-center"><i class="fa fa-eye"></i> 2824</span>
                        </div>
                    </div>
                    <div class="wrap-pic-w how-pos5-parent">
                        <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg" alt="IMG-BLOG" class="blog-read-pic">
                        <div class="flex-col-c-m size-123 bg9 how-pos5">
                            <span class="ltext-107 cl2 txt-center">
                                05						</span>
    
                            <span class="stext-109 cl3 txt-center">
                                09 2022						</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
            <div class="row p-t-35 p-b-80">
                <div class="col-lg-2">
                </div>
                <div class="col-md-8 col-lg-8">
                    <div class="p-r-0-lg">
                        <div>
                            <div class="isi_blog ctext-101 fs-18 cl2 p-b-26 text-left">
                                <p>Tiga bulan berturut-turut bapak keluar masuk rumah sakit. Mulai dari pertengahan&nbsp;bulan puasa pada bulan Mei, hingga bulan Juli lalu. Jauh sebelum itu, aku yang kala itu&nbsp;duduk dibangku SMA harus bisa membagi waktu untuk mengurus bapak, juga&nbsp;mempersiapkan UTBK guna seleksi SBMPTN. Ketika aku hendak berangkat ke Malang untuk melaksanakan UTBK, aku meminta restu kepada bapak agar dipermudah dalam&nbsp; mengerjakan soal nantinya. Tak lupa, aku juga menghubungi ibu yang tengah merantau ke&nbsp;luar negeri. Setibanya di Malang, aku belajar sedikit untuk me-review materi yang sudah aku&nbsp; pelajari. Dan akhirnya tibalah sesi dimana aku harus bertempur dengan soal UTBK untuk&nbsp;<br />
    mencapai PTN impian. Waktu berjalan begitu cepat, 105 menit pun tak terasa sudah lenyap.&nbsp;</p>
    
    <p><br />
    Aku bergegas berkemas untuk segera pulang ke kota asal. Setibanya dirumah, aku mencari bapak untuk mengetahui kondisi bapak kala itu,</p>
    
    <p>&ldquo;Pak, Silvi, pulang!&rdquo; teriakku samar..</p>
    
    <p>Namun bapak tidak menyaut ucapanku. Aku diarahkan Mbak Afi, kakak pertamaku&nbsp;bahwa bapak tengah tidur di ruang tamu. Langsung saja aku melangkahkan kaki kesana dan&nbsp;menggoyang-goyangkan kecil badan bapak.</p>
    
    <p>&ldquo;Eh, Nduk, sudah pulang,&rdquo; sambil meraih tangan bapak, beliau melempar senyum&nbsp;<br />
    hangat kepadaku, &ldquo;Alhamdulillaah sudah pak.&rdquo; Balasku dengan segera.</p>
    
    <p>Aku melihat lekat wajah bapak. Nampak ada yang berbeda. Wajahnya. Pipinya. Ya,&nbsp;tidak salah lagi, wajahnya nampak lebih berisi, namun seperti bengkak. Aku hanya dapat&nbsp;menduga-nduga prasangka yang aku tuduhkan padaku sendiri. Sebelumnya, bapak sudah menderita penyakit diabetes kurang lebih 20 tahun lamanya. Dan hingga pada akhirnya,&nbsp;dugaanku benar. Tak lama setelah kejadian tersebut bapak harus segera dilarikan ke rumah&nbsp;sakit terdekat karena mengalami panas, dingin, dan diare berkepanjangan. Aku dan bapak diantar oleh tetangga terdekat menuju rumah sakit swasta yang berada&nbsp;tak jauh dari rumah. Keadaan bapak kala itu sudah lemas dan pucat pasi. Sesampainya di&nbsp;<br />
    UGD, aku segera menjelaskan gejala-gejala apa saja yang dialami bapak. Dokter dan perawat&nbsp;yang ada didalam ruang langsung melakukan penanganan pertama untuk memperbaiki&nbsp;kondisi bapak terlebih dahulu. Syukurlah keadaan bapak berangsur membaik, dan dokter&nbsp; menyarankan agar melakukan opname untuk perawatan selanjutnya, dan bapak meng-iyakan&nbsp;tawaran tersebut. Dan untung saja bapak memakai BPJS. Kala itu Mbak Silva, yakni kakak&nbsp;kedua atau kembaranku datang tepat waktu untuk membantu membawa barang-barang juga&nbsp;<br />
    merawat bapak di rumah sakit kedepannya.</p>
    
    <p>Sesampainya didalam ruang, aku melihat wajah bapak kembali membengkak tanpa&nbsp;sebab. Aku mengajukan pertanyaan kecil untuk bapak, &ldquo;Pak, apa bapak tidak merasakan pipi&nbsp;sebelah kanan bapak yang membengkak?&rdquo; bapak hanya menggeleng beberapa kali. Entah&nbsp;<br />
    menyembunyikan rasa sakit atau memang bapak tidak merasakan apapun, yang jelas setiap&nbsp;aku menanyakan hal yang sama, bapak akan selalu menggeleng ringan. Dihari kedua, perawat mengatakan bahwa bapak mengalami kekurangan albumin atau hipoalbuminemia&nbsp;yang menyebabkan pembengkakan pada bagian tertentu dan harus mendapatkan obat khusus.&nbsp;Pada rumah sakit swasta, obat tersebut harus ditebus dengan biaya yang cukup mahal.</p>
    
    <p>Setelah pemakaian obat tersebut, aku dan Mbak Silva merasa bahwa belum ada&nbsp;perubahan yang cukup. Bahkan kadar gula bapak pada saat itu terus mengalami penurunan.&nbsp;Akhirnya, perawat menyarankan melakukan rujuk ke rumah sakit yang berada di kota,&nbsp;dengan segala fasilitas dan obat yang lebih lengkap disana. Aku dan mbak memiliki tanggung&nbsp;jawab yang besar dalam mengambil keputusan kala itu. Ya, karena aku dan mbak masih&nbsp;berumur 19 tahun dan di tuntut lebih dewasa diumur yang masih belia. Pukul 22.30 kami&nbsp;mendapatkan persetujuan dari pihak rumah sakit negeri di pusat kota, dan saat itu juga kita&nbsp;berangkat. Sesampainya di IGD, bapak harus menunggu cukup lama untuk mendapatkan kamar.&nbsp;Kami harus menunggu kurang lebih satu hari lamanya agar bapak segera mendapatkan&nbsp;penanganan intensif. Saat itu aku menemani bapak di dalam IGD hingga akhirnya bapak&nbsp;harus dipindahkan ke dalam ruang isolasi untuk pengambilan swab tes. Aku dan Mbak Silva&nbsp;tidak diperbolehkan masuk untuk menemani bapak. Suasana pecah.</p>
    
    <p>&ldquo;Mbak, bapak nanti&nbsp;gimana di dalam kamar sendirian?&rdquo; tanyaku sesenggukan pada Mbak Silva.</p>
    
    <p>Kami tetap menjenguk bapak meskipun hanya lewat perawat yang berjaga. Hingga akhirnya kami mendapat kabar bahwa hasil swab tes bapak menunjukkan negatif dan dapat dipindahkan ke kamar intensif. Namun sayangnya hanya tersisa kamar biasa, padahal&nbsp;seharusnya bapak mendapatkan penanganan intensif saat itu juga. Tanpa pikir panjang, aku&nbsp;meng-iyakan agar bapak segera mendapatkan penanganan yang cukup. Akhirnya, pada pukul&nbsp;24.00 WIB kami dapat melihat wajah bapak kembali. Namun yang cukup menyayat hati&nbsp;adalah kami mendapati bapak mengalami sesak napas hingga harus dibantu oleh oksigen. Keesokan harinya, aku dan Mbak Silva mendapati bahwa ingatan serta penglihatan&nbsp; bapak mulai menurun. Beliau tak sadar bahwa yang ada dihadapannya adalah kedua anak&nbsp;kembarnya. Namun kejadian tersebut tak berangsur lama.</p>
    
    <p>Tak lama kemudian bapak&nbsp;merengek minta makan, &ldquo;Nduk, bapak laper. Sama haus juga.&rdquo;</p>
    
    <p>Aku dan mbak bergantian&nbsp;menyuapi dan mengelap sisa makanan maupun minuman yang ada disekitar mulut bapak.&nbsp;Beberapa suap dirasa cukup, bapak kembali berbaring diatas ranjang sambil sesekali&nbsp; membenahkan oksigen yang menempel dihidungnya. Pengap. Mungkin itu yang dirasakan&nbsp;bapak kala itu. Melihat kondisi bapak yang semakin menurun, beliau tidak pernah mengeluh tentang&nbsp;apa yang dirasakannya. Padahal napasnya terengah-engah, beberapa bagian tubuhnya&nbsp;membengkak, sebagian lagi berbintik dan berair. Namun sekalipun beliau tidak pernah&nbsp;mengeluh. Banyak saudara yang menjenguk, mendoakan, serta turut memberi semangat&nbsp; untuk kesembuhan bapak. Aku, pun saudara sebenarnya sudah memiliki firasat tak enak.&nbsp;Apalagi yang mengurus bapak hanya dua anak kecil yang harus bertanggung jawab penuh&nbsp;<br />
    atas semua prosedur rumah sakit. Akhirnya bulik menyuruh adiknya untuk bermalam satu&nbsp;hari saja di rumah sakit. Jaga-jaga jika tenaga dua bocah perempuan itu perlu diisi, sedangkan&nbsp;bapak harus selalu ada yang menemani.&nbsp;</p>
    
    <p>Syukurlah, paklik datang untuk menjenguk sekaligus menjaga bapak. Mbak Silva juga&nbsp;meminta izin untuk pulang karena tak enak badan dan digantikan oleh Mbak Afi. Sebelum&nbsp;itu, setiap harinya aku menghubungi ibuk, memberi kabar bagaimana kondisi bapak terkini&nbsp;dengan air mata yang enggan pergi setiap kali aku bersua dengan ibuk. Selain itu aku juga&nbsp; memohon agar ibuk segera pulang mengingat kondisi bapak yang semakin memburuk.&nbsp;Namun ternyata sulit. Majikan ibuk tetap kekeh bahwa bapak pasti akan segera sembuh. Dan&nbsp;ya, aku selalu berkata</p>
    
    <p>&ldquo;Yaudah buk, ndakpapa,&rdquo; dengan menahan suara agar tidak goyang.&nbsp;</p>
    
    <p>Aku yang sedari enam hari lalu menjaga penuh bapak, akhirnya kuwalahan dan tertidur&nbsp;dengan pulas. Sebelum itu, Mbak Afi yang menjaga bapak, namun karena sama-sama&nbsp;mengantuk akhirnya aku dan mbak memilih untuk istirahat sejenak. Paklik menunggu diluar&nbsp;karena memang keterbatasan tempat kala itu. Aku yang berkeinginan segera bangun dari tidur, ternyata hanyut bersama rasa kantuk&nbsp;dan rasa lelah yang tak dapat terbendung. Paklik membangunkanku dan mbak dengan hati-hati seraya berucap lirih. Ucapan tersebut tak dapat aku mengerti maksudnya. Sontak aku dan&nbsp;mbak langsung menuju kearah ranjang bapak. Dapat aku lihat bahwa sesak yang diderita&nbsp;bapak sudah tak terdengar, bapak terlihat lebih tenang dan, dan aku belum mengerti kenapa&nbsp; bapak hanya diam kala aku menggoyangkan dan menyebut nama bapak kala itu.</p>
    
    <p>Aku, mbak,&nbsp;dan paklik langsung menuju ruang perawat. Benar saja. Perawat memperjelas apa yang&nbsp;dikatakan paklik sebelumnya.</p>
    
    <p>&ldquo;Mbak, yang ikhlas ya, insyaaAllah bapak sudah lebih tenang&nbsp;disana.&rdquo; Ucap perawat tersebut dengan penuh hati-hati.</p>
    
    <p><br />
    Sontak air mataku pecah dan memeluk Mbak Afi dengan erat. Aku langsung&nbsp;menghubungi saudara terdekat dan juga Mbak Silva. Aku tak tega menghubungi ibuk yang&nbsp;tengah berada jauh dari keluarga. Dan ketika aku menghubungi ibuk, tangis ibuk pecah. Aku&nbsp;pun tak dapat membendung pedihnya kehilangan seseorang yang amat aku cintai. Tak lama, ketika azan subuh mulai samar terdengar, saudaraku datang menenangkan. Jenazah bapak&nbsp;dibawa menuju ruang jenazah dan kemudian bersiap untuk dibawa ke rumah duka. Aku&nbsp; dengan bulik masuk ke dalam mobil jenazah dengan suasana duka mendalam.</p>
    
    <p>Sesampainya mobil jenazah dirumah, aku memeluk Mbak Silva dan saudara yang ada&nbsp;disana. Kami hanyut bersama air mata yang tak dapat dibendung. Setelahnya, aku dan kedua&nbsp;mbakku memandikan jenazah bapak. Aku berusaha menahan tangis kala itu. Dan ketika pelaksanaan sholat jenazah, syukur alhadulillaah banyak jamaah yang hadir untuk melepas&nbsp; bapak yang terakhir kalinya. Setelah itu, prosesi pelepasan keluarga selesai dilaksanakan.&nbsp;Jenazah harus segera dimakamkan. Saat prosesi pemakaman, aku tak kuasa menahan air&nbsp;mata. Tumpah. Sungguh kepergian yang teramat cepat sebagai sosok yang sangat aku cintai.<br />
    ***<br />
    Singkatnya, ibuk bisa pulang tepat hari ke delapan setelah kepergian bapak karena&nbsp;harus menjalankan swab tes di Jakarta yang cukup lama. Ah, lagi-lagi suasana pecah dan air&nbsp;mata berjatuhan tanpa aba. Aku dan mbak berusaha menenagkan ibuk. Setelahnya, kami&nbsp;<br />
    beristirahat bersama dan melanjutkan kegiatan esok hari.&nbsp;Pak, putri bungsumu ingin bercerita. Setelah kepergianmu dunia banyak berubah.&nbsp; Aku, ibuk, dan juga kedua kakakku banyak belajar pengalaman pahit manisnya hidup darimu.&nbsp;Kini, kolam ikan gurami yang menjadi aset jangka panjang telah kami rawat bersama. Sejak&nbsp;kecil, Mbak Afi, Mbak Silva, dan juga aku, Silvi, tak jarang ditempa dengan harus memberi&nbsp;makan ikan, entah saat terik maupun saat hujan. Pak, aku ingat betul engkau menanam banyak buah di kolam.</p>
    
    <p>Dulu engkau pernah&nbsp;berkata, &ldquo;Nanti kalau berbuah banyak kalian tidak usah beli. Tidak usah. Kadang buah yang&nbsp;kalian beli banyak mengandung obat. Jadi, bisa deh, langsung metik buah di kolam.&rdquo;</p>
    
    <p>Ah, pak,&nbsp;mulia sekali engkau. Membahagiakan anakmu dengan pengalaman dan juga penanaman ilmu&nbsp;yang tak akan aku dapat ketika berada di sekolah atau dimanapun nantinya. Dan, pak, kini&nbsp;buah mangga itu sudah berbuah kembali. Manis. Buahnya ranum. Sekarang ibuk yang&nbsp;<br />
    mengupaskan buahnya, dulu kan, bapak, hehe. Aku merindukan mu pak. Kalau Silvi rindu, Silvi kerap main ke kolam. Mengingat&nbsp;banyak kenang, banyak buah juga pastinya. Semoga putri bungsumu bisa menanamkan&nbsp;banyak kebaikan seperti bapak yang menanam benih kebaikan di setiap tanahnya. Silvi&nbsp;benar-benar merindukan bapak.</p>
    
    <p>Penulis:<strong> SILVI NUR S.</strong></p>
                            </div>
                        </div>
                        <div class="media ava-bloger">
                            <img class="mr-3 rounded-circle img-thumbnail" src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png" alt="Generic placeholder image">
            <div class="media-body text-left">
                                <p class="stext-101 m-b-8">DITULIS OLEH</p>
                                <h5 class="mt-0 mtext-111">Eureka Writer</h5>
                                <p class="badge badge-primary">Content Writer</p>
                                <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        <section class="sec-relate-product bg6 p-t-20 text-left">
            <div class="container p-tb-10">
                <div class="p-b-15">
                    <h3 class="session-title mtext-106 cl5">New Entry</h3>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/kisahkasih'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/rahasiadelia'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/pohonmangga'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                                    </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/malaikat'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/ttgayah'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/merah'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1"><a
                                        href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/jejak'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/pejuang'">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                                    </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">04 September 2022</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script scope>
    export default {
      name: "pohonmanggaPage",
    }
    </script>