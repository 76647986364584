<template>
  <section class="bg6 p-t-15 p-b-10">
    <form class="p-b-85">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-xl-3 m-lr-auto d-none d-lg-block">
            <sidebarPage />
          </div>

          <div class="col-lg-2 col-xl-3 m-lr-auto m-b-50 mt-5">
            <div
              class="bg0 bor10 p-all-15 m-b-10 p-lr-15-sm"
              style="border-radius: 15px"
            >
              <span
                class="text-left"
                style="
                  color: var(--primary-05, #244786);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 18px; /* 112.5% */
                  letter-spacing: -0.16px;
                "
                >Foto Profil</span
              >

              <div class="mt-2 ava-preview flex-c-m">
                <img
                  class="img-thumbnail"
                  style="border-radius: 15px"
                  ref="avatarPreview"
                  :src="getAvatarUrl()"
                />
              </div>

              <div class="edit-ava m-t-20">
                <input
                  type="file"
                  name="foto"
                  ref="avatarInput"
                  @change="handleFileChange"
                />
              </div>

              <div style="color: red; font-size: 10px">
                <span>File yang diizinkan berformat jpg / png</span>
                <br />
                <span>Ukuran file maksimal 100kb</span>
                <br />
                <span>Ukuran panjang dan lebar maksimal 500px</span>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-xl-6 m-lr-auto m-b-50 mt-5">
            <div
              class="bg0 bor10 p-all-15 m-b-15 p-lr-15-sm"
              style="border-radius: 15px; max-height: 100vh; overflow: auto"
            >
              <div>
                <div>
                  <div style="padding: 3%">
                    <div class="profile">
                      <div>
                        <span class="txt-title-profile">Biodata Diri</span>
                      </div>
                      <div>
                        <b-button
                          style="border-radius: 20px; background-color: #244786"
                          @click="updateProfile"
                          >Update</b-button
                        >
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="bg-profile">
                    <div class="profile">
                      <div>
                        <p class="text-nama text-left">Nama Depan</p>
                        <b-form-input
                          class="mb-2 mr-sm-2 mb-sm-0"
                          style="width: 100%"
                          type="text"
                          placeholder="Nama Depan"
                          v-model="customer.firstname"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="profile">
                      <div>
                        <p class="text-nama text-left">Nama Belakang</p>
                        <b-form-input
                          type="text"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          placeholder="Nama Belakang"
                          v-model="customer.lastname"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="profile">
                      <div>
                        <p class="text-nama text-left">E-mail</p>
                        <b-form-input
                          type="email"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          placeholder="Email"
                          v-model="customer.email"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="profile">
                      <div>
                        <p class="text-nama text-left">Nomor Telepon</p>
                        <b-form-input
                          type="number"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          placeholder="No Telp"
                          v-model="customer.telephone"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>
<script>
import Swal from "sweetalert2";
import axios from "axios"; // Import axios library
import sidebarPage from "@/components/Users/sidebar.vue";

export default {
  name: "editProfilePage",
  components: {
    sidebarPage,
  },
  data() {
    return {
      customer: {
        firstname: "",
        lastname: "",
        email: "",
        telephone: "",
        avatar: "",
      },
    };
  },
  mounted() {
    // Panggil metode fetchCustomerDetails saat komponen dimuat
    this.fetchCustomerDetails();
  },
  methods: {
    // Metode untuk memuat detail pelanggan dari backend
    fetchCustomerDetails() {
      const token = localStorage.getItem("token");
      const idCustomer = localStorage.getItem("id_customer");
      const apiUrl = `https://stagingapi.eurekabookhouse.co.id/customer/detail?customer_id=${idCustomer}`;

      axios
        .get(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          if (response.data.data) {
            this.customer = response.data.data;
          } else {
            console.error(
              "Failed to fetch customer details:",
              response.data.message
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching customer details:", error);
        });
    },
    getAvatarUrl() {
      // Check if customer.avatar exists
      if (this.customer.avatar) {
        // If it exists, return the full URL
        return `https://stagingapi.eurekabookhouse.co.id/${this.customer.avatar}`;
      } else {
        // If it doesn't exist, return the default avatar URL
        return "https://www.eurekabookhouse.co.id/assets/uplod/default-avatar.png";
      }
    },
    handleFileChange() {
      const fileInput = this.$refs.avatarInput;

      if (fileInput.files.length > 0) {
        const file = fileInput.files[0];
        const imageUrl = URL.createObjectURL(file);

        // Set the image source
        this.$refs.avatarPreview.src = imageUrl;
      }
    },
    updateProfile() {
      const token = localStorage.getItem("token");
      const idCustomer = localStorage.getItem("id_customer");
      const apiUrl = `https://stagingapi.eurekabookhouse.co.id/customer/edit`;

      // Create FormData object
      const formData = new FormData();
      formData.append("customer_id", idCustomer);
      formData.append("firstname", this.customer.firstname);
      formData.append("lastname", this.customer.lastname);
      formData.append("email", this.customer.email);
      formData.append("telephone", this.customer.telephone);

      // Get the file input element
      const fileInput = this.$refs.avatarInput;

      // Check if a file is selected
      if (fileInput.files.length > 0) {
        formData.append("avatar", fileInput.files[0]);
      } else {
        // If no file is selected, add an empty string for the avatar key
        formData.append("avatar", "");
      }

      axios
        .put(apiUrl, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.data.status.code === 200) {
            Swal.fire({
              confirmButtonColor: "#3860A8",
              title: "Success",
              text: response.data.status.message,
              icon: "success",
            }).then(() => {
              // Redirect to the profile page
              this.$router.push({ path: "/Users/Profile" });
            });
          } else {
            Swal.fire({
              confirmButtonColor: "#3860A8",
              title: "Error",
              text: "Failed to update profile",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          console.error("Error updating profile: ", error);
        });
    },
  },
};
</script>