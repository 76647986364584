<template>
    <div>
<div>
    <div class="bg1">
        <div class="">
            <div class="p-t-3 p-b-5">
                <nav class="">
                    <a href="https://www.eurekabookhouse.co.id/" class="center">
                        <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                            alt="IMG-LOGO" class="center">
                    </a>
                </nav>
            </div>
        </div>
    </div>
    <section class="bg0 p-b-20 text-left">
        <div class="container p-lr-80-lg">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <h1 class="ltext-102 cl2 p-t-28">
                        Cara Meningkatkan Daya Ingat dan Konsentrasi yang Terbukti Efektif </h1>
                    <div class="flex-m flex-sb m-tb-15">
                        <div class="flex-i flex-w flex-m stext-111 cl2">
                            <span>
                                <span class="cl4">Oleh</span> Eureka
                                <span class="cl12 m-l-4 m-r-6">|</span>
                            </span>
                            <span>
                                18 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                            </span>
                            <div id="share" class="m-l-50-lg">
                                <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                    href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/cara-meningkatkan-daya-ingat-dan-konsentrasi-yang-terbukti-efektif"
                                    target="_blank"><i class="fa fa-facebook"></i></a>
                                <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                    href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/cara-meningkatkan-daya-ingat-dan-konsentrasi-yang-terbukti-efektif&amp;text=Blog: Cara Meningkatkan Daya Ingat dan Konsentrasi yang Terbukti Efektif &amp;hashtags=eurekabookhouse"
                                    title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                    href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/cara-meningkatkan-daya-ingat-dan-konsentrasi-yang-terbukti-efektif"
                                    title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                            </div>
                        </div>
                        <div class="flex-r-m">
                            <span class="stext-107 text-center"><i class="fa fa-eye"></i> 3148</span>
                        </div>
                    </div>
                    <div class="wrap-pic-w how-pos5-parent">
                        <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/cara_meningkatkan_daya_ingat.jpg"
                            alt="IMG-BLOG" class="blog-read-pic">
                        <div class="flex-col-c-m size-123 bg9 how-pos5">
                            <span class="ltext-107 cl2 txt-center">
                                18 </span>
    
                            <span class="stext-109 cl3 txt-center">
                                11 2022 </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
            <div class="row p-t-35 p-b-80">
                <div class="col-lg-2">
                </div>
                <div class="col-md-8 col-lg-8">
                    <div class="p-r-0-lg">
                        <div>
                            <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                                <p>Pernah gak sih saat kamu membutuhkan suatu barang dan kamu malah lupa dimana
                                    meletakkannya? Atau saat kamu lagi membaca buku, lalu konsentrasimu tiba-tiba hilang dan
                                    malah memikirkan hal-hal lainnya?</p>
    
                                <p>Daya ingat dan konsentrasi seseorang bisa saja menurun karena beberapa faktor. Daya ingat
                                    yang menurun sering dialami banyak orang meskipun masih berusia muda. Tentu hal ini akan
                                    cukup membuatmu kesulitan jika kamu sedang tergesa-gesa maupun dalam situasi yang
                                    menuntutmu untuk berkonsentrasi. Jika kamu merasa daya ingat dan konsentrasimu mulai
                                    menurun, yuk coba cara-cara berikut untuk meningkatkan daya ingat dan konsentrasimu.</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>1. Konsumsi Makanan yang Sehat</strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><img alt="makanan sehat"
                                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/galeri/makanan_bergizi.jpg"
                                        style="height:337px; width:640px" /></p>
    
                                <p>Untuk meningkatkan daya ingat dan konsentrasi, beri nutrisi dan asupan yang baik untuk
                                    menunjang kerja otakmu. Setiap bahan makanan memiliki kandungan nutrisi yang
                                    berbeda-beda <em>lho</em>! Untuk meningkatkan daya ingat dan konsentrasimu, pilihlah
                                    bahan-bahan makanan yang mengandung banyak protein dan lemak sehat. Kamu bisa memilih
                                    memakan kedelai, salmon, kacang-kacangan, alpukat, pisang dan bahan makanan lainnya yang
                                    terbukti memiliki manfaat untuk fungsi otak. Selain itu, kamu juga dapat mengonsumsi
                                    vitamin tambahan seperti omega-3.</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>2. Teratur dalam Berolahraga</strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><strong><img alt="teratur dalam berolahraga"
                                            src="https://cdn.eurekabookhouse.co.id/ebh/blog/galeri/olahraga_teratur.jpg"
                                            style="height:427px; width:640px" /></strong></p>
    
                                <p>Cara kedua untuk meningkatkan daya ingat dan konsentrasi mu adalah dengan berolahraga.
                                    Dengan berolahraga kemampuan otak akan mengalami peningkatan. Selain itu, otak akan
                                    bekerja secara maksimal yang menyebakan sel saraf otakmu akan meningkat jumlahnya. Sel
                                    saraf yang meningkat akan memperkuat ikatan antar sel otak dan memperkuatnya. Kamu bisa
                                    melakukan olahraga apapun yang kamu sukai dan bisa kamu lakukan di dalam maupun luar
                                    rumah, seperti zumba, cardio, bersepeda, maupun berenang. Tak hanya meningkatkan daya
                                    ingat dan konsentrasi, olahraga juga akan membuat tubuhmu menjadi sehat dan bugar.</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>3. Mainkan Permainan yang Membutuhkan Konsentrasi</strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><strong><img alt="permainan konsentrasi"
                                            src="https://cdn.eurekabookhouse.co.id/ebh/blog/galeri/permainan_konsentrasi.jpg"
                                            style="height:331px; width:640px" /></strong></p>
    
                                <p>Hal berikutnya yang dapat kamu lakukan untuk meningkatkan daya ingat dan konsentrasimu
                                    adalah dengan melatih otakmu. Kamu bisa gunakan cara-cara yang menyenangkan seperti
                                    melakukan permainan-permainan yang membuatmu berpikir, misalnya teka-teki silang atau
                                    permainan &ldquo;ware wolf&rdquo; yang membuatmu dapat berpikir dan menyusun strategi
                                    dalam bermain. Kamu juga dapat menggunakan media lainnya untuk membantu meningkatkan
                                    daya ingat dan konsentrasimu, seperti mendengarkan musik juga membaca buku-buku
                                    kesukaanmu.</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>4. Istirahat Yang Cukup</strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><strong><img alt="isitirahat yang cukup"
                                            src="https://cdn.eurekabookhouse.co.id/ebh/blog/galeri/Istirahat_cukup.jpg"
                                            style="height:426px; width:640px" /></strong></p>
    
                                <p>Daya ingat dan konsentrasimu akan meningkat jika kamu mendapatkan tidur yang cukup dan
                                    berkualitas. Jika kamu mulai merasa kelelahan, ada baiknya kamu beristirahat sejenak.
                                    Memaksa otak bekerja terus-menerus hanya akan membuat pikiranmu menjadi kacau. Pastikan
                                    kamu memiliki waktu istirahat yang cukup dan berkualitas. Kamu juga dapat melakukan
                                    perjalanan berlibur untuk menyegarkan pikiranmu.</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>5. Lakukan Meditasi </strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><strong><img alt="meditasi"
                                            src="https://cdn.eurekabookhouse.co.id/ebh/blog/galeri/meditasi.jpg"
                                            style="height:426px; width:640px" /></strong></p>
    
                                <p>Hal berikutnya yang bisa kamu lakukan untuk meningkatkan daya ingat dan konsentrasi
                                    adalah dengan melakukan meditasi. Dengan bermeditasi dapat meningkatkan kinerja otakmu
                                    <em>lho</em>! Bermeditasi akan membuat dirimu rileks. Banyaknya kegiatan yang membuat mu
                                    stress dapat memengaruhi daya ingat juga konsentrasi. <em>Yuk</em> sempatkan dirimu
                                    untuk melakukan meditasi. Selain akan meningkatkan daya ingat dan konsentrasimu,
                                    bermeditasi juga akan memperbaiki <em>mood</em>-mu J</p>
    
                                <p>&nbsp;</p>
    
                                <p>Ini dia beberapa cara efektif yang akan meningkatkan daya ingat dan konsentrasimu.
                                    Selamat mencoba!</p>
                            </div>
                        </div>
                        <div class="media ava-bloger">
                            <img class="mr-3 rounded-circle img-thumbnail"
                                src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                                alt="Generic placeholder image">
                            <div class="media-body">
                                <p class="stext-101 m-b-8">DITULIS OLEH</p>
                                <h5 class="mt-0 mtext-111">Andaf Iman</h5>
                                <p class="badge badge-primary">Content Writer</p>
                                <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="sec-relate-product bg6 p-t-20 text-left">
        <div class="container p-tb-10">
            <div class="p-b-15">
                <h3 class="session-title mtext-106 cl5">New Entry</h3>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/kisahkasih'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/rahasiadelia'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/pohonmangga'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                                </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/malaikat'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/ttgayah'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/merah'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1"><a
                                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat
                                    Eureka</a>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/jejak'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/pejuang'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA
                                    BOOKHOUSE
                                </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">04 September 2022</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer class="bgu1 p-t-15 p-b-15">
        <div class="container">
            <div class="p-t-10">
                <p class="stext-107 cl0 txt-center">
                    Copyright &copy; Dikembangkan oleh Eureka IT Developer
                </p>
            </div>
        </div>
    </footer>
</div>
    </div>
</template>
<script>
export default {
    name: "dPage",
}
</script>