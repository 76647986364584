<template>
  <div>
    <section class="bg6 p-t-10 p-b-10">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 col-xl-8 m-b-15 p-lr-0-sm text-left">
            <div class="card m-b-10 bs1" style="border-radius: 10px">
              <div class="card-order">
                <p class="text-order">Order Canceled!</p>
                <p class="text-invoice">Invoice <b>EBHD18823102</b></p>
                <p class="text-invoice">
                  Silahkan melakukan pembayaran agar penjual dapat memproses
                  pesanan anda.
                </p>
              </div>
              <div class="card-body">
                <p class="text-alamat">Alamat Pengiriman</p>
                <p class="text-nama">Tasya Khaerani</p>
                <p class="text-almt">
                  JL PENGADEGAN UTARA NO 20A PANCORAN, JAKARTA SELATAN Pancoran
                  , Jakarta Selatan Kota , DKI Jakarta, 12770
                </p>
                <p class="text-almt">085771942063</p>
              </div>
            </div>
            <div
              class="cart-card1 mr-2"
              style="background-color: #989898; width: 100%"
            >
              <div class="d-flex">
                <span
                  style="
                    color: white;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 15.4px; /* 110% */
                    letter-spacing: -0.14px;
                  "
                  >Eureka Bookhouse</span
                >
              </div>
            </div>
            <div style="width: 100%; height: 1px; background: #cbcbcb"></div>
            <div class="cart-card2 mr-2" style="width: 100%; opacity: 0.5">
              <div class="d-flex">
                <img
                  style="width: 60px; height: 80px; margin-right: 10px"
                  src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2014005020.jpg"
                  alt="kartu-angka-dan-huruf-hijaiah"
                  onerror="this.src='https://www.eurekabookhouse.co.id/assets/front/images/eureka-bookhouse-bg-grey.jpg';"
                />
                <td>
                  <span
                    style="
                      color: var(--neutral-07, #191717);

                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 15.4px; /* 110% */
                      letter-spacing: -0.14px;
                    "
                    >Kartu Angka & Huruf Hijaiah</span
                  >

                  <div class="col-3 col-md-2 px-2 mb-2 text-center">
                    <p
                      style="
                        color: var(--primary-04, #3860a8)
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 18px; /* 112.5% */
                        letter-spacing: -0.16px;
                      "
                    >
                      1x
                    </p>
                    <span
                      style="
                        color: var(--primary-05, #244786);

                        /* Style/Title 2 */
            
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 18px; /* 112.5% */
                        letter-spacing: -0.16px;
                      "
                      class="text-left"
                    >
                      Rp.52.000</span
                    >
                  </div>
                  <input
                    class="harga"
                    type="hidden"
                    name="harga[]"
                    value="52000"
                  />
                  <input
                    class="berat"
                    type="hidden"
                    name="berat[]"
                    value="0.15"
                  />
                </td>
              </div>
            </div>
            <div style="width: 100%; height: 1px; background: #cbcbcb"></div>
            <div class="cart-card2 mr-2" style="width: 100%; opacity: 0.5">
              <div class="d-flex">
                <img
                  style="width: 60px; height: 80px; margin-right: 10px"
                  src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2014005020.jpg"
                  alt="kartu-angka-dan-huruf-hijaiah"
                  onerror="this.src='https://www.eurekabookhouse.co.id/assets/front/images/eureka-bookhouse-bg-grey.jpg';"
                />
                <td>
                  <span
                    style="
                      color: var(--neutral-07, #191717);

                      /* Style/Body */
          
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 15.4px; /* 110% */
                      letter-spacing: -0.14px;
                    "
                    >Kartu Angka & Huruf Hijaiah</span
                  >

                  <div class="col-3 col-md-2 px-2 mb-2 text-center">
                    <p
                      style="
                        color: var(--primary-04, #3860a8)
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 18px; /* 112.5% */
                        letter-spacing: -0.16px;
                      "
                    >
                      1x
                    </p>
                    <span
                      style="
                        color: var(--primary-05, #244786);

                        /* Style/Title 2 */
            
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 18px; /* 112.5% */
                        letter-spacing: -0.16px;
                      "
                      class="text-left"
                    >
                      Rp.52.000</span
                    >
                  </div>
                  <input
                    class="harga"
                    type="hidden"
                    name="harga[]"
                    value="52000"
                  />
                  <input
                    class="berat"
                    type="hidden"
                    name="berat[]"
                    value="0.15"
                  />
                </td>
              </div>
            </div>

            <!-- <div class="card-catatan mr-2" style="width: 100%; opacity: 0.5">
              <h6 class="judul-catatan">Notes Pesanan</h6>
              <span class="mr-2 text-catatan"
                >Tolong dipacking rapih ya min!</span
              >
            </div> -->
            <br />
          </div>
          <div class="col-sm-10 col-lg-7 col-xl-4 p-lr-0-sm">
            <div
              class="card p-all-15 p-lr-15-sm mb-3 bgu3 bs1"
              style="border-radius: 10px; background: #cbcbcb; width: 100%"
            >
              <div class="text-center">
                <p class="stext-103" style="color: #656464">
                  Pesanan dibuat pada
                </p>
                <p class="mtext-101" style="color: #656464">
                  <b>19:30, 12 Oktober 2023</b>
                </p>
                <p class="text-waktu" style="color: #656464">
                  Pembayaran pesanan ini berlaku 24 jam, silahkan melakukan
                  transfer sebelum waktu yang ditentukan
                </p>
              </div>
            </div>
            <div class="card-co">
              <span
                class="mt-2"
                style="
                  color: #989898;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 18px; /* 112.5% */
                  letter-spacing: -0.16px;
                  text-align: left;
                "
              >
                Ringkasan Pesanan Anda
              </span>

              <div class="d-flex justify-content-between mt-5">
                <span
                  style="
                    color: #989898;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 15.4px; /* 110% */
                    letter-spacing: -0.14px;
                  "
                  >Subtotal Produk</span
                ><span style="color: white">haha</span
                ><span
                  style="
                    color: #989898;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 15.4px; /* 110% */
                    letter-spacing: -0.14px;
                  "
                  >Rp 48.000</span
                >
              </div>
              <div class="d-flex justify-content-between mt-3">
                <span
                  style="
                    color: #989898;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 15.4px; /* 110% */
                    letter-spacing: -0.14px;
                  "
                  >Subtotal Pengiriman</span
                ><span style="color: white">haha</span
                ><span
                  style="
                    color: #989898;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 15.4px; /* 110% */
                    letter-spacing: -0.14px;
                  "
                  >Rp 12.000</span
                >
              </div>
              <div class="d-flex justify-content-between mt-3">
                <span
                  style="
                    color: #989898;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 15.4px; /* 110% */
                    letter-spacing: -0.14px;
                  "
                  >Voucher</span
                ><span style="color: white">haha</span
                ><span
                  style="
                    color: #989898;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 15.4px; /* 110% */
                    letter-spacing: -0.14px;
                  "
                  >Tidak Digunakan</span
                >
              </div>
              <div
                class="mt-2"
                style="width: 99%; height: 1px; background: #cbcbcb"
              ></div>

              <div class="d-flex justify-content-between mt-4">
                <span
                  style="
                    color: #989898;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18px; /* 112.5% */
                    letter-spacing: -0.16px;
                  "
                  >Total Pesanan</span
                ><span style="color: white">haha</span
                ><span
                  style="
                    color: #989898;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18px; /* 112.5% */
                    letter-spacing: -0.16px;
                  "
                  >Rp 60.000</span
                >
              </div>
              <div class="mt-3">
                <router-link :to="'/pesanan'">
                  <button
                    style="
                      display: flex;
                      width: 100%;
                      height: 50px;
                      padding: 10px;
                      justify-content: center;
                      align-items: center;
                      gap: 10px;
                      align-self: stretch;
                      border-radius: 50px;
                      background: var(--primary-05, #244786);
                    "
                  >
                    <span
                      style="
                        color: var(--neutral-01, #fefefe);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 18px; /* 112.5% */
                        letter-spacing: -0.16px;
                      "
                      >Pesan Lagi</span
                    >
                  </button>
                </router-link>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script scope>
export default {
  name: "pesananPage",
};
</script>
<style>
.card-order {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 10px 10px 0px 0px;
  background: var(--neutral-04, #989898);
}
.text-order {
  color: var(--neutral-01, #fefefe);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
}
.text-invoice {
  color: var(--neutral-01, #fefefe);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}
.text-alamat {
  color: var(--neutral-06, #4c4949);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
}
.text-nama {
  color: var(--neutral-06, #4c4949);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}
.text-almt {
  color: var(--neutral-06, #4c4949);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}
.judul-catatan {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
}
.text-catatan {
  color: var(--neutral-06, #4c4949);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}
.text-waktu {
  color: var(--neutral-07, #191717);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.2px; /* 110% */
  letter-spacing: -0.12px;
}
</style>
