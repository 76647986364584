<template>
<div>
        <div class="bg1">
            <div class="">
                <div class="p-t-3 p-b-5">
                    <nav class="">
                        <router-link :to="'/Blog/pejuang'" class="center">
                            <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                                alt="IMG-LOGO" class="center">
                        </router-link>
                    </nav>
                </div>
            </div>
        </div>
        <section class="bg0 p-b-20 text-left">
            <div class="container p-lr-80-lg">
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                        <h1 class="ltext-102 cl2 p-t-28">
                            SEKOTAK SNACK DARI AYAH - JUARA 2 LOMBA CERPEN </h1>
                        <div class="flex-m flex-sb m-tb-15">
                            <div class="flex-i flex-w flex-m stext-111 cl2">
                                <span>
                                    <span class="cl4">Oleh</span> Eureka
                                    <span class="cl12 m-l-4 m-r-6">|</span>
                                </span>
                                <span>
                                    12 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                                </span>
                                <div id="share" class="m-l-50-lg">
                                    <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                        href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/sekotak-snack-dari-ayah---juara-2-lomba-cerpen"
                                        target="_blank"><i class="fa fa-facebook"></i></a>
                                    <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                        href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/sekotak-snack-dari-ayah---juara-2-lomba-cerpen&amp;text=Blog: SEKOTAK SNACK DARI AYAH - JUARA 2 LOMBA CERPEN &amp;hashtags=eurekabookhouse"
                                        title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                    <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                        href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/sekotak-snack-dari-ayah---juara-2-lomba-cerpen"
                                        title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                                </div>
                            </div>
                            <div class="flex-r-m">
                                <span class="stext-107 text-center"><i class="fa fa-eye"></i> 1444</span>
                            </div>
                        </div>
                        <div class="wrap-pic-w how-pos5-parent">
                            <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/2-min.jpg" alt="IMG-BLOG"
                                class="blog-read-pic">
                            <div class="flex-col-c-m size-123 bg9 how-pos5">
                                <span class="ltext-107 cl2 txt-center">
                                    12 </span>
        
                                <span class="stext-109 cl3 txt-center">
                                    11 2022 </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div class="row p-t-35 p-b-80">
                    <div class="col-lg-2">
                    </div>
                    <div class="col-md-8 col-lg-8">
                        <div class="p-r-0-lg">
                            <div>
                                <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                                    <p><strong>Sekotak Snack Dingin </strong></p>
        
                                    <p><em>Ana Anggi Anggraini</em></p>
        
                                    <p>&nbsp;Sinar bulan purnama menemaniku yang sedang beres-beres tanda toko brownies ini akan
                                        tutup. Hari ini toko ramai sekali, banyak pelanggan yang datang membuat kami para
                                        karyawan kelelahan dan harus pulang larut malam. Jujur penat sekali rasanya, apalagi aku
                                        yang seharian berkutat di meja kasir menuntut kemampuan berkonsentrasi yang tinggi.</p>
        
                                    <p>Saat kami sedang berbincang seraya menghitung penjualan hari ini, terlihat seseorang
                                        datang dan berdiri di depan toko. Kepalanya melihat ke dalam dan seperti mencari
                                        sesuatu. Aku melirik teman kerjaku mengisyaratkan untuk memandang keluar. Ia juga kaget,
                                        seorang bapak-bapak paruh baya dengan jaket hitam sedang tangannya terlipat memeluk
                                        dirinya sendiri. Kami sempat berpikir untuk menelpon bos, tapi mengurungkan lagi niat
                                        kami. Jika dilihat-lihat bapak ini bukan orang jahat.</p>
        
                                    <p>Kami berdua lantas menghampiri bapak tersebut. Rupanya bapak tersebut enggan masuk karena
                                        merasa tidak enak karena beliau hendak beli namun sudah larut malam. Disisi lain beliau
                                        tidak ingin mengurungkan niatnya, karena ingin membelikan kue untuk putrinya yang sedang
                                        duduk di bangku SMA. Lalu kami bertanya darimana asalnya, Ia mengaku dari luar kota ini.
                                        Jawaban tersebut cukup menjawab rasa penasaran kami melihat kondisinya yang kebasahan
                                        padahal diluar tidak sedang hujan.</p>
        
                                    <p>Aku dan rekan kerjaku saling pandang dan berusaha berkomunikasi melalui mata. Bapak ini
                                        dari luar kota dan memaksakan ingin membeli kue disini. Katanya, kue disini sedang
                                        populer di kalangan anak muda. Ia tidak ingin membeli melalui aplikasi pesan-antar
                                        makanan karena tidak memiliki cukup uang jadi ia terpaksa menghampiri toko. Bapak
                                        tersebut pernah mendengar jika putrinya ulang tahun, ia ingin dibelikan kue di tempat
                                        ini. Ia baru ingat jika hari ulang tahun putrinya adalah besok. Jika aku adalah
                                        putrinya, aku akan memeluk ayahku dan mengomelinya untuk tidak memaksakan pergi
                                        sekaligus beterima kasih akan perhatiannya. Sayangnya, ayahku jarang sekali memberikan
                                        sesuatu yang spesial seperti ini.</p>
        
                                    <p>Temanku menyinggung pelan lenganku hingga perhatianku teralih kembali. Akhirnya kami
                                        memilih untuk melayaninya. Bapak itu terlihat memilih kue yang sebenarnya hanya tinggal
                                        tiga potong namun berbeda variasi rasa.</p>
        
                                    <p>&ldquo;Pak, apa tidak apa-apa? &lsquo;kan itu kuenya sudah dingin.&rdquo; Akhirnya aku
                                        mengeluarkan rasa penasaranku.</p>
        
                                    <p>&ldquo;engga apa-apa, teh. Saya pesan semuanya boleh kan?&rdquo; Waw, tentu saja kami
                                        setuju dan senang mengingat akan dapat bonus nanti.</p>
        
                                    <p>Bapak itu pamit setelah mendapatkan pembeliannya. Ia memeluk kue itu dengan erat dan
                                        senyuman lebar menghiasi parasnya. Perlahan suara hujan menimpa atap toko terdengar dan
                                        bapak itu masih memilih pergi seperti tidak sabar untuk pulang. Jujur aku tersentuh
                                        melihatnya.</p>
        
                                    <p>Bapak itu adalah pengunjung terakhir hari ini dan kami benar-benar menutup toko. Aku
                                        pulang dengan beribu rasa iri. Bapak tersebut pergi larut malam seorang diri demi
                                        membeli kue disini disaat mungkin putrinya hanya tinggal klik di aplikasi pesan-antar
                                        makanan. Anaknya harus bersyukur.</p>
        
                                    <p>Tiba di rumah perutku masih keroncongan. Aku kira setelah mengendarai motor perutku akan
                                        kenyang diisi angin. Rumahku gelap dan hanya lampu luar yang menyala. Pasti orang-orang
                                        rumah sudah tidur. Aku memarkirkan motor, masuk ke dalam rumah, dan pergi ke dapur.</p>
        
                                    <p>Aku membuka tudung saji dan kecewa tidak menemukan apa pun disana. Perutku sudah tawuran
                                        di dalam sana. Mirisnya aku lupa membeli mie instan dan cemilan di jalan. Lampu dapur
                                        tiba-tiba menyala membuatku sedikit terlonjak kaget. Aku melihat ayahku membawa sesuatu
                                        di tangannya. Seperti biasa, gayanya kikuk dan canggung khas ayahku. Ia menyimpan benda
                                        tadi di atas meja.</p>
        
                                    <p>&ldquo;Kata ibumu, kamu suka lupa makan kalo pulang. Maafin ibumu mungkin dia lupa
                                        masak.&rdquo; lantas berlalu begitu selesai bicara. Aku kira ayahku sudah di rumah,
                                        rupanya ia baru pulang. Seragam satpam kantor masih melekat di tubuhnya yang sudah
                                        berumur namun tetap gagah di mataku.</p>
        
                                    <p>Aku tahu, kotak snack ini mungkin sisa kantor yang kelebihan dan akhirnya diberikan pada
                                        pegawai lain, salah satunya ayahku. Harusnya snack ini sudah berada dalam perutnya.</p>
        
                                    <p>Aku membuka kotak snack, dan tersenyum begitu melihatnya. Bahkan, snack ini masih
                                        tersusun rapi tanda disimpan dengan baik. Aku tersenyum dan sesuatu mengingatkanku pada
                                        kejadian tadi. Dadaku menghangat dengan sendirinya. Kendati snack ini sudah dingin,
                                        namun ia mampu menghangatkan hatiku. Mungkin putri bapak tadi turut merasakan hal yang
                                        sama.</p>
        
                                    <p>&nbsp;</p>
        
                                    <p><strong>Biodata diri</strong></p>
        
                                    <p>Nama&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : Ana
                                        Anggi Anggraini</p>
        
                                    <p>Status&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :
                                        Mahasiswa UPI Kampus Tasikmalaya</p>
        
                                    <p>Hobi&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        : Membaca</p>
                                </div>
                            </div>
                            <div class="media ava-bloger">
                                <img class="mr-3 rounded-circle img-thumbnail"
                                    src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                                    alt="Generic placeholder image">
                                <div class="media-body">
                                    <p class="stext-101 m-b-8">DITULIS OLEH</p>
                                    <h5 class="mt-0 mtext-111">Eureka Writer</h5>
                                    <p class="badge badge-primary">Content Writer</p>
                                    <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="sec-relate-product bg6 p-t-20">
            <div class="container p-tb-10">
                <div class="p-b-15">
                    <h3 class="session-title mtext-106 cl5">New Entry</h3>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <a
                                    href="https://www.eurekabookhouse.co.id/blog/read/kisah-kasih---juara-favorit-10-lomba-cerpen-eureka-bookhouse">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                                </a>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1"><a
                                        href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                </div>
                                <h4 class="p-b-32">
                                    <a href="https://www.eurekabookhouse.co.id/blog/read/kisah-kasih---juara-favorit-10-lomba-cerpen-eureka-bookhouse"
                                        class="mtext-101 cl2 hov-cl1 trans-04">
                                        KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </a>
                                </h4>
                                <span class="stext-102 m-t-20">12 November 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <a
                                    href="https://www.eurekabookhouse.co.id/blog/read/rahasia-delia---juara-favorit-9-lomba-cerpen-eureka-bookhouse">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                                </a>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1"><a
                                        href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                </div>
                                <h4 class="p-b-32">
                                    <a href="https://www.eurekabookhouse.co.id/blog/read/rahasia-delia---juara-favorit-9-lomba-cerpen-eureka-bookhouse"
                                        class="mtext-101 cl2 hov-cl1 trans-04">
                                        RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </a>
                                </h4>
                                <span class="stext-102 m-t-20">12 November 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <a
                                    href="https://www.eurekabookhouse.co.id/blog/read/pohon-mangga-itu-berbuah-kembali---juara-favorit-8-lomba-cerpen-eureka-bookhouse">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                                </a>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1"><a
                                        href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                </div>
                                <h4 class="p-b-32">
                                    <a href="https://www.eurekabookhouse.co.id/blog/read/pohon-mangga-itu-berbuah-kembali---juara-favorit-8-lomba-cerpen-eureka-bookhouse"
                                        class="mtext-101 cl2 hov-cl1 trans-04">
                                        POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE </a>
                                </h4>
                                <span class="stext-102 m-t-20">11 November 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <a
                                    href="https://www.eurekabookhouse.co.id/blog/read/malaikat-penjagaku---juara-favorit-7-lomba-cerpen-eureka-bookhouse">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                                </a>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1"><a
                                        href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                </div>
                                <h4 class="p-b-32">
                                    <a href="https://www.eurekabookhouse.co.id/blog/read/malaikat-penjagaku---juara-favorit-7-lomba-cerpen-eureka-bookhouse"
                                        class="mtext-101 cl2 hov-cl1 trans-04">
                                        MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </a>
                                </h4>
                                <span class="stext-102 m-t-20">11 November 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <a
                                    href="https://www.eurekabookhouse.co.id/blog/read/kenangan-tentang-ayah---juara-favorit-6-lomba-cerpen-eureka-bookhouse">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                                </a>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1"><a
                                        href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                </div>
                                <h4 class="p-b-32">
                                    <a href="https://www.eurekabookhouse.co.id/blog/read/kenangan-tentang-ayah---juara-favorit-6-lomba-cerpen-eureka-bookhouse"
                                        class="mtext-101 cl2 hov-cl1 trans-04">
                                        KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </a>
                                </h4>
                                <span class="stext-102 m-t-20">11 November 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <a
                                    href="https://www.eurekabookhouse.co.id/blog/read/segenggam-tanah-merah---juara-favorit-5-lomba-cerpen-eureka-bookhouse">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                                </a>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1"><a
                                        href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                </div>
                                <h4 class="p-b-32">
                                    <a href="https://www.eurekabookhouse.co.id/blog/read/segenggam-tanah-merah---juara-favorit-5-lomba-cerpen-eureka-bookhouse"
                                        class="mtext-101 cl2 hov-cl1 trans-04">
                                        SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </a>
                                </h4>
                                <span class="stext-102 m-t-20">11 November 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <a
                                    href="https://www.eurekabookhouse.co.id/blog/read/jejak-sandal-ayah---juara-favorit-4-lomba-cerpen-eureka-bookhouse">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                                </a>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1"><a
                                        href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                </div>
                                <h4 class="p-b-32">
                                    <a href="https://www.eurekabookhouse.co.id/blog/read/jejak-sandal-ayah---juara-favorit-4-lomba-cerpen-eureka-bookhouse"
                                        class="mtext-101 cl2 hov-cl1 trans-04">
                                        JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </a>
                                </h4>
                                <span class="stext-102 m-t-20">12 November 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <a
                                    href="https://www.eurekabookhouse.co.id/blog/read/perjuangan-ayahku--untuk-masa-depanku---juara-favorit-3-lomba-cerpen-eureka-bookhouse">
                                    <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                                </a>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1"><a
                                        href="https://www.eurekabookhouse.co.id/blog/category/acara-toko">Acara Toko</a></div>
                                <h4 class="p-b-32">
                                    <a href="https://www.eurekabookhouse.co.id/blog/read/perjuangan-ayahku--untuk-masa-depanku---juara-favorit-3-lomba-cerpen-eureka-bookhouse"
                                        class="mtext-101 cl2 hov-cl1 trans-04">
                                        PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                                    </a>
                                </h4>
                                <span class="stext-102 m-t-20">12 November 2022</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <!-- Footer -->
        <footer class="bgu1 p-t-15 p-b-15">
            <div class="container">
                <div class="p-t-10">
                    <p class="stext-107 cl0 txt-center">
                        Copyright &copy; Dikembangkan oleh Eureka IT Developer
                    </p>
                </div>
            </div>
        </footer>
    </div>
    
</template>
<script>
export default {
name : "snackPage",
}
</script>
