<template>
    <div>
        <section class="popular_categories sec-relate-product bg6 p-tb-30 text-left">
            <div class="container">
                <div class="card-columns">
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/buku-pelajaran" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/010-book.svg"
                                        class="card-img-top" alt="buku-pelajaran">
                                </div>
                                <h4 class="mtext-106">Buku Pelajaran</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-sd">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            SD (59)</p>
                                    </a>
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/ti-dan-komunikasi-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i> TI
                                                    & Komunikasi SD (24)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/penjas-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Penjas SD (13)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seni-rupa-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seni Rupa SD (10)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/kewarganegaraan-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Kewarganegaraan SD (35)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seni-budaya-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seni Budaya SD (18)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/matematika-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Matematika SD (53)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/agama-islam-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Agama Islam SD (149)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/kurikulum-2013-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Kurikulum 2013,K13N SD (456)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/ips-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    IPS SD (56)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/ipa-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    IPA SD (20)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/bahasa-inggris-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Bahasa Inggris SD (84)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/bilingual-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Bilingual SD (38)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/bahasa-indonesia-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Bahasa Indonesia SD (50)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/sains-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    SAINS SD (23)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/plh-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    PLH SD (6)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/plbj-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    PLBJ SD (18)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/mandarin-sd">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    MANDARIN SD (34)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-smp">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            SMP (11)</p>
                                    </a>
                                    <ul>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/bhs-inggris-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Bhs Inggris SMP (49)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/ti-dan-komunikasi-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i> Ti
                                                    & Komunikasi SMP (10)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seni-budaya-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seni Budaya SMP (3)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/sejarah-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Sejarah SMP (5)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seni-musik-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seni Musik SMP (4)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/bahasa-dan-sastra-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Bahasa & Sastra SMP (10)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/penjas-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Penjas SMP (8)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/kewarganegaraan-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Kewarganegaraan SMP (19)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/matematika-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Matematika SMP (20)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/agama-kristen-dan-katolik-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Agama Kristen & Katolik SMP (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/agama-islam-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Agama Islam SMP (51)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/kurikulum-2013-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Kurikulum 2013,K13N SMP (229)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/ipa-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    IPA SMP (52)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/ips-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    IPS SMP (45)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/bimb-konseling-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Bimb.Konseling SMP (8)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/bilingual-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Bilingual SMP (35)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/plh-smp">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    PLH SMP (3)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-sma">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            SMA (13)</p>
                                    </a>
                                    <ul>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seni-musik-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seni Musik SMA (6)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/kewarganegaraan-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Kewarganegaraan SMA (8)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/bahasa--inggris-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Bahasa Inggris SMA (30)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/sejarah-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Sejarah SMA (18)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seni-budaya-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seni Budaya SMA (3)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/kimia-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Kimia SMA (31)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/fisika-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Fisika SMA (37)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/matematika-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Matematika SMA (50)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/geografi-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Geografi SMA (19)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/ekonomi-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Ekonomi SMA (18)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/biologi-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Biologi SMA (20)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/sma-kurikulum-2013-/-k13n">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    SMA Kurikulum 2013 / K13N (275)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/agama-islam-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Agama Islam SMA (23)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/antropologi-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Antropologi SMA (5)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/akuntansi-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Akuntansi SMA (1)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/bahasa--indonesia-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Bahasa Indonesia SMA (20)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/penjaskes-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Penjaskes SMA (4)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/bilingual-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Bilingual SMA (61)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/bimb-konseling-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Bimb.Konseling SMA (3)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/bhs-mandarin-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Bhs Mandarin SMA (3)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/sosiologi-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Sosiologi SMA (30)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/ti-dan-komunikasi-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i> Ti
                                                    & Komunikasi SMA (19)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seni-rupa-sma">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seni Rupa SMA (6)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-smk">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            SMK (5)</p>
                                    </a>
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/komputer-akuntansi-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Komputer Akuntansi SMK (3)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/penjaskes-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Penjaskes SMK (3)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/agama-islam-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Agama Islam SMK (4)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/matematika-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Matematika SMK (13)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/kimia-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Kimia SMK (3)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/kewirausahaan-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Kewirausahaan SMK (3)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/kewarganegaraan-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Kewarganegaraan SMK (3)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/ilmu-komputer-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Ilmu Komputer SMK (6)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/ipa-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    IPA SMK (3)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/bahasa--inggris-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Bahasa Inggris SMK (9)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/fisika-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Fisika SMK (4)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/smk-kurikulum-2013">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Kurikulum 2013,K13N SMK (81)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/biologi-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Biologi SMK (3)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/bahasa--indonesia-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Bahasa Indonesia SMK (6)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/kompetensi-inti-dan-kompetensi-dasar-(-kikd17-)">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Kompetensi Inti dan Kompetensi Dasar ( KIKD17 ) (115)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/modul-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Modul SMK (67)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/ips-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    IPS SMK (2)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seni-budaya-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seni Budaya SMK (3)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/sejarah-smk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Sejarah SMK (1)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-tk">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            TK (47)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-tematik">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            Tematik (64)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-mi">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            MI (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/buku-umum" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/023-planet.svg"
                                        class="card-img-top" alt="buku-umum">
                                </div>
                                <h4 class="mtext-106">Buku Umum</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/masakan-dan-minuman">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Masakan dan Minuman (38)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/hobi-dan-hastakarya">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Hobi
                                            dan Hastakarya (2)</p>
                                    </a>
                                    <ul>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/agro-bisnis">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Agro Bisnis (0)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/komputer">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Komputer (4)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/parenting">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Parenting (8)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/pendidikan-umum">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Pendidikan Umum (3)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/peta-dan-atlas">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Peta
                                            Dan Atlas (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/komik">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Komik
                                            (55)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/seri-karakter">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Seri
                                            Karakter (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/kamus-dan-bahasa">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Kamus
                                            dan Bahasa (9)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-seri">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            Seri (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/fashion-dan-kecantikan">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Fashion dan kecantikan (1)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-umum-lainnya">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            Umum Lainnya (8)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-agama">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            Agama (269)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/buku-soal" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/044-blackboard.svg"
                                        class="card-img-top" alt="buku-soal">
                                </div>
                                <h4 class="mtext-106">Buku Soal</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/soal-sd">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Soal
                                            SD (300)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/soal-smp">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Soal
                                            SMP (184)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/soal-sma">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Soal
                                            SMA (261)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/soal-smk">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Soal
                                            SMK (69)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-soal-ujian">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            Soal Ujian UN (230)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/buku-import" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/010-book.svg"
                                        class="card-img-top" alt="buku-import">
                                </div>
                                <h4 class="mtext-106">Buku Import</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/fiction">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Fiction (2)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/gaya-hidup" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/038-battery.svg"
                                        class="card-img-top" alt="gaya-hidup">
                                </div>
                                <h4 class="mtext-106">Gaya Hidup</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/tas">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Tas
                                            (44)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/sepatu">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Sepatu
                                            (19)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/tumbler">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Tumbler (5)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/aksesoris">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Aksesoris (0)</p>
                                    </a>
                                    <ul>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Masker anak (3)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/voucher">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Voucher (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/mainan-dan-hobi" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/007-control.svg"
                                        class="card-img-top" alt="mainan-dan-hobi">
                                </div>
                                <h4 class="mtext-106">Mainan & Hobi</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/perlengkapan-menggambar">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Perlengkapan Menggambar (116)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/puzzle">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Puzzle
                                            (69)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/diecast-dan-model-kit">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Diecast & Model Kit (13)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/figur">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Figur
                                            (31)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/buku-anak" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/030-doll.svg"
                                        class="card-img-top" alt="buku-anak">
                                </div>
                                <h4 class="mtext-106">Buku Anak</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/ensiklopedia">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Ensiklopedia (43)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/paket-buku-spesial">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Paket
                                            Buku Spesial (1)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/menggambar-dan-mewarnai">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Menggambar Dan Mewarnai (14)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/lift-the-flap">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Lift
                                            The Flap (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/keterampilan-anak">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Keterampilan Anak (20)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/creative-writing">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Creative Writing (4)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/cerita-rakyat-nusantara">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Cerita
                                            Rakyat Nusantara (1)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-stiker">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            Stiker (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/atlas-dan-geografi">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Atlas
                                            dan Geografi (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/membaca-dan-menulis">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Membaca Dan Menulis (13)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-anak-lainnya">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            Anak Lainnya (35)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/edukasi-anak">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Edukasi Anak (32)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/cerita-anak">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Cerita
                                            Anak (16)</p>
                                    </a>
                                    <ul>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/populer-lainnya">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Populer Lainnya (2)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/cerita-anak">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Cerita Anak (5)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/creativity-series">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Creativity Series (16)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-seri">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            Seri (2)</p>
                                    </a>
                                    <ul>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-yuk-menempel">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Yuk Menempel (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-topik-seru">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Topik Seru (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-tiger">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Tiger (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-stiker-baju-boneka">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Stiker Baju Boneka (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-pendidikan-karakter">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Pendidikan Karakter (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-pak-jk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Pak JK (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-anak-sehat">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Anak Sehat (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-see-inside">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri See Inside (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-ruby">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Ruby (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-puzzle-raksasa">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Puzzle Raksasa (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-puzzle">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Puzzle (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-pemanasan-global">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Pemanasan Global (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-pertualangan">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Pertualangan (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-zoe">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Zoe (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-toffee">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Toffee (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/segala-hal-tentang-alam">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Segala Hal Tentang Alam (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/segala-hal-tentang-uang">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Segala Hal Tentang Uang (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-pop-up-junior">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri POP-UP Junior (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-opi">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Opi (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-dive-olly-dive">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Dive Olly Dive (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-kisah-nabi-as">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Kisah Nabi AS (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-maukah-kamu">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Maukah Kamu (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-mania">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Mania (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/kisah-si-pemberani">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Kisah si Pemberani (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-just-the-facts">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Just The Facts (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-julie">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Julie (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-pengembangan-diri">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Pengembangan Diri (21)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-hewan-peliharaan">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Hewan Peliharaan (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-harimau-cilik">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Harimau Cilik (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-ensiklopedia-mini">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Ensiklopedia Mini (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-fakta-top">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Fakta Top (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-mini-library">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Mini Library (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-buku-ajaib">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Buku Ajaib (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-picture-books-dan-kreativitas">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Picture Books &amp; Kreativitas (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-ayo-berkreasi">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Ayo Berkreasi (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-disney">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Disney (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-mewarnai">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Mewarnai (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-natal-istimewa">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Natal Istimewa (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-apa-pendapatmu">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Apa Pendapatmu (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-bilingual">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Bilingual (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-pengenalan-profesi">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Pengenalan Profesi (0)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/seri-karakter">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Seri
                                            Karakter (0)</p>
                                    </a>
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-kung-fu-panda">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Kung Fu Panda (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/little-einsteins">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Little Einsteins (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-nihao-kai-lan">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Nihao, Kai-Lan (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-mickey-mouse">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Mickey Mouse (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/seri-go-diego-go">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Go Diego Go! (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-strawberry-shortcake">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Strawberry Shortcake (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/seri-thomas-dan-friends">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Seri Thomas &amp; Friends (0)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/seri-ulysses-moore">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Seri
                                            Ulysses Moore (1)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/novel" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/022-masks.svg"
                                        class="card-img-top" alt="novel">
                                </div>
                                <h4 class="mtext-106">Novel</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/novel-remaja">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Novel
                                            Remaja (133)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/novel-romance">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Novel
                                            Romance (244)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/novel-horor">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Novel
                                            Horor (23)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/terjemahan">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Terjemahan (133)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/lainnya">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Lainnya (29)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/buku-murah" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/010-book.svg"
                                        class="card-img-top" alt="buku-murah">
                                </div>
                                <h4 class="mtext-106">Buku Murah</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/agro-group">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Agro
                                            Group (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/bhuana-lmu-populer">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Bhuana
                                            lmu Populer (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/elex-media">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Elex
                                            Media (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/gramedia">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Gramedia (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/mizan-media-utama">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Mizan
                                            Media Utama (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/promo-sales">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Promo
                                            Sales (0)</p>
                                    </a>
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/promo-buku-paket-25">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Promo Buku Paket 25% (15)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/spesial-hari-kartini">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Spesial Hari Kartini (0)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/perguruan-tinggi" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/009-microscope.svg"
                                        class="card-img-top" alt="perguruan-tinggi">
                                </div>
                                <h4 class="mtext-106">Perguruan Tinggi</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/administrasi">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Administrasi (3)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/akuntansi">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Akuntansi (7)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/at-a-glance">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> At a
                                            Glance (22)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/bahasa-dan-sastra">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Bahasa
                                            &amp; Sastra (3)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/ekonomi">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Ekonomi (13)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/hukum">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Hukum
                                            (8)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/kedokteran">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Kedokteran (7)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/keperawatan">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Keperawatan (2)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/lecture-notes">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Lecture Notes (6)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/manajamen">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Manajamen (6)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/mipa">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> MIPA
                                            (7)</p>
                                    </a>
                                    <ul>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/matematika">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Matematika (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/biologi">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Biologi (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/fisika">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Fisika (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/kimia">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Kimia (0)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/teknik">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Teknik
                                            (1)</p>
                                    </a>
                                    <ul>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/komputer">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Komputer (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/arsitektur">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Arsitektur (1)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/mesin">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Mesin (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/sipil">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Sipil (1)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/teknik-umum">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Teknik Umum (1)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/teknik-sipil">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Teknik Sipil (0)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/psikologi">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Psikologi (12)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/sospol">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Sospol
                                            (2)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/statistik">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Statistik (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/schaum-outline">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Schaum
                                            Outline (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/jurnalistik">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Jurnalistik (2)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/perti-lainnya">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> PERTI
                                            Lainnya (4)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/mesin">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Mesin
                                            (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/manajemen">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Manajemen (1)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/komputer">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Komputer (2)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/islam">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Islam
                                            (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/olahraga-dan-outdoor"
                                class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/046-football-award.svg"
                                        class="card-img-top" alt="olahraga-dan-outdoor">
                                </div>
                                <h4 class="mtext-106">Olahraga dan Outdoor</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/gym-dan-fitness">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Gym &
                                            Fitness (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/basket">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Basket
                                            (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/sepakbola-dan-futsal">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Sepakbola dan Futsal (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/atk" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/010-book.svg"
                                        class="card-img-top" alt="atk">
                                </div>
                                <h4 class="mtext-106">ATK</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/perlengkapan-sekolah">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Alat
                                            dan buku tulis (83)</p>
                                    </a>
                                    <ul>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/buku-tulis">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Buku tulis (7)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.eurekabookhouse.co.id/category/menu/penghapus-dan-penggaris">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Penghapus dan penggaris (13)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/pulpen-dan-pensil">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Pulpen dan pensil (38)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/atk-lainnya">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Lainnya (18)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    buku agenda (13)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/correction-tape">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Correction tape (12)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/perlengkapan-kantor">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Perlengkapan Kantor (95)</p>
                                    </a>
                                    <ul>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/kertas">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Kertas (16)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/penjilidan">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Penjilidan (10)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/penyimpanan">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Penyimpanan (11)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/lainnnyaa">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Lainnya (11)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/organizer">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> IT
                                            Supply (17)</p>
                                    </a>
                                    <ul>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/flashdisk">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    flashdisk (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/mouse">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Mouse (6)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/lainnya3">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Lainnya (0)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/printer">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Printer (6)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.eurekabookhouse.co.id/category/menu/monitor">
                                                <p class="stext-107 tab text1l cl2 hov-cl1"><i
                                                        class="fa fa-caret-right"></i>
                                                    Monitor (3)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/sayuran-organik" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/010-book.svg"
                                        class="card-img-top" alt="sayuran-organik">
                                </div>
                                <h4 class="mtext-106">Sayuran Organik</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/serba-15rb" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/024-confetti.svg"
                                        class="card-img-top" alt="serba-15rb">
                                </div>
                                <h4 class="mtext-106">Buku Flat</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/off-merchandise" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/010-book.svg"
                                        class="card-img-top" alt="off-merchandise">
                                </div>
                                <h4 class="mtext-106">Off. Merchandise</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/local-merch">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Local
                                            Merch (20)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/import-merch">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Import
                                            Merch (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/010-book.svg"
                                        class="card-img-top" alt="">
                                </div>
                                <h4 class="mtext-106">ACTION FIGURE</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/ebook" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/010-book.svg"
                                        class="card-img-top" alt="ebook">
                                </div>
                                <h4 class="mtext-106">eBook</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-umum-elib">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            Umum (1)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/buku-sekolah">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Buku
                                            Sekolah (2)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/koran">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i> Koran
                                            (0)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                                <li>
                                    <a href="https://www.eurekabookhouse.co.id/category/menu/majalah">
                                        <p class="stext-101 text1l cl2 hov-cl1"><i
                                                class="fa fa-caret-square-o-down"></i>
                                            Majalah (2)</p>
                                    </a>
                                    <ul>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/010-book.svg"
                                        class="card-img-top" alt="">
                                </div>
                                <h4 class="mtext-106">DIY</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                            </ul>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-all-15 tempat-gambar">
                            <a href="https://www.eurekabookhouse.co.id/category/menu/ebook-elib" class="flex-l-m">
                                <div class="text-center">
                                    <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/010-book.svg"
                                        class="card-img-top" alt="ebook-elib">
                                </div>
                                <h4 class="mtext-106">ebook-elib</h4>
                            </a>
                        </div>
                        <div class="card-body">
                            <ul>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script scope>
export default {
    name: "semuakategoriPage",
}
</script>
<style type="text/css">
.js-show-cart {
    cursor: pointer;
}

#snap-midtrans {
    z-index: 2000000000 !important
}
</style>
<style type="text/css">
.tempat-gambar {
    background-color: #ffc107;
}

.card {
    border: none;
}

.card-img-top {
    height: 40px;
    margin-right: 10px;
}

.tab {
    display: inline-block;
    margin-left: 40px;
}
</style>