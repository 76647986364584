<template>
  <div>
    <section class="bg6 p-t-15 m-b-100">
      <div class="container">
        <div class="text-center">
          <h3 class="mtext-103 cl5 m-b-10">About</h3>
        </div>
        <div id="isi_cart">
          <div class="card m-t-10">
            <div class="card-body">
              <br /><br />
              <div class="text-center">
                <img
                  src="https://www.eurekabookhouse.co.id/assets/front/images/icons/keranjang_kosong.png"
                />
                <br />
                <p class="stext-101 cl2 m-t-15">
                  Keranjang Belanja Anda Kosong
                </p>
                <br />
                <a
                  href="https://www.eurekabookhouse.co.id/"
                  class="btn btn-primary"
                  ><i class="fa fa-home"></i> Kembali Ke Home</a
                >
              </div>
              <br /><br />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script scope>
export default {
  name: "aboutPage",
};
</script>