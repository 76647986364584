// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    product_id: null,
  },
  mutations: {
    setProductId(state, productId) {
      state.product_id = productId;
    },
  },
  actions: {
    setProductId({ commit }, productId) {
      commit('setProductId', productId);
    },
  },
  getters: {
    getProductById: (state) => (id) => {
      // Add any additional logic you need here
      return state.products.find(product => product.id === id);
    },
  },
});
