<template>
    <div>
<div>
    <div class="bg1">
        <div class="">
            <div class="p-t-3 p-b-5">
                <nav class="">
                    <router-link :to="'/Blog/pejuang'" class="center">
                        <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                            alt="IMG-LOGO" class="center">
                    </router-link>
                </nav>
            </div>
        </div>
    </div>
<section class="bg0 p-b-20 text-left">
    <div class="container p-lr-80-lg">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <h1 class="ltext-102 cl2 p-t-28">
                    Buku SMA Best seller Januari 2019 </h1>
                <div class="flex-m flex-sb m-tb-15">
                    <div class="flex-i flex-w flex-m stext-111 cl2">
                        <span>
                            <span class="cl4">Oleh</span> Eureka
                            <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <span>
                            17 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <div id="share" class="m-l-50-lg">
                            <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/buku-sma-best-seller-januari-2019"
                                target="_blank"><i class="fa fa-facebook"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/buku-sma-best-seller-januari-2019&amp;text=Blog: Buku SMA Best seller Januari 2019 &amp;hashtags=eurekabookhouse"
                                title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/buku-sma-best-seller-januari-2019"
                                title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                        </div>
                    </div>
                    <div class="flex-r-m">
                        <span class="stext-107 text-center"><i class="fa fa-eye"></i> 4018</span>
                    </div>
                </div>
                <div class="wrap-pic-w how-pos5-parent">
                    <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/0045400360.jpg" alt="IMG-BLOG"
                        class="blog-read-pic">
                    <div class="flex-col-c-m size-123 bg9 how-pos5">
                        <span class="ltext-107 cl2 txt-center">
                            17 </span>

                        <span class="stext-109 cl3 txt-center">
                            11 2022 </span>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row p-t-35 p-b-80">
            <div class="col-lg-2">
            </div>
            <div class="col-md-8 col-lg-8">
                <div class="p-r-0-lg">
                    <div>
                        <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                            <h2><a
                                    href="https://www.eurekabookhouse.co.id/product/detail/15031/esps-kimia-sma-ma-kls-x-k13n"><strong>Esps
                                        Kimia Sma/Ma Kls.X/K13N </strong></a></h2>

                            <p><strong><img alt=""
                                        src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0045400360.jpg"
                                        style="height:437px; width:336px" /></strong></p>

                            <p><strong><em>Erlangga Straight Point Series </em></strong><strong>(ESPS) Kimia SMA/MA
                                </strong>disusun berdasarkan Kurikulum 2013 Edisi Revisi 2016 dengan karakteristik
                                sebagai berikut.</p>

                            <p><strong>Materi pembelajaran <em>simple</em> </strong>dan
                                <strong><em>modern</em></strong><br />
                                <strong><em>Simple</em></strong>, materi disajikan langsung pada intinya dengan
                                visualisasi yang menonjol dan jelas sehingga siswa dapat memahami Kimia dengan mudah.
                            </p>

                            <ul>
                                <li><strong><em>Modern</em></strong>, buku ini didukung oleh media pembelajaran visual
                                    yang menarik dan sumber belajar-mengajar yang disajikan dalam bentuk digital
                                    sehingga menjadi sumber belajar terlengkap untuk siswa.</li>
                                <li><strong>Mengacu pada penilaian pengetahuan dan keterampilan</strong></li>
                            </ul>

                            <p><strong>Latihan Pengembangan Kompetensi </strong>dan<strong> Uji Kompetensi
                                </strong>disajikan sebagai sarana untuk menilai aspek pengetahuan.</p>

                            <p><strong>Aktivitas </strong>disajikan sebagai sarana untuk menilai aspek keterampilan.</p>

                            <p>Dengan sumber belajar ESPS, siswa mendapat kemudahan menguasai secara tuntas kompetensi
                                dasar dalam pembelajaran Kimia sehingga dapat mengaplikasikannya dalam kehidupan
                                sehari-hari.</p>

                            <p><strong>Keunggulan produk:</strong>.</p>

                            <ol>
                                <li><strong><em>Full colour</em></strong>, gambar menarik, detail, dan mudah dipahami.
                                </li>
                                <li>Dilengkapi <strong>QR Code</strong> berisi <strong>video apersepsi</strong> dan
                                    <strong>soal-soal CBT</strong> yang dapat diakses dengan memindai QR code
                                    menggunakan Erlangga Reader.</li>
                                <li>Dilengkapi <strong>media mengajar visual</strong> yang menarik.</li>
                                <li>Dilengkapi <strong>soal latihan</strong> pada setiap akhir subbab, akhir bab, dan
                                    akhir semester.</li>
                            </ol>

                            <p>&nbsp;</p>

                            <h2><a href="https://bukuerlangga.co.id/product/detail/15031/esps-kimia-sma-ma-kls-x-k13n"><strong>MATEMATIKA
                                        SMA/MA KEL. PEMINATAN JL.2/K13N</strong></a></h2>

                            <p><img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0045100531.jpg"
                                    style="height:448px; width:317px" /></p>

                            <p>Buku Matematika SMA/MA Jilid 2 ini disusun berdasarkan Kurikulum 2013 Edisi Revisi 2016.
                                Buku ini ditujukan untuk peserta didik SMA dan MA Kelompok Peminatan Matematika dan Ilmu
                                Alam yang ingin memahami matematika secara baik dan mendalam. Materinya disajikan
                                sedemikian rupa sehingga dapat diterapkan dan dimanfaatkan dalam kehidupan sehari-hari.
                            </p>

                            <p><strong>Keunggulan produk:</strong></p>

                            <ol>
                                <li>Awal bab selalu dimulai dengan ilustrasi kehidupan nyata dan Imabel (Inspirasi
                                    Materi Pembelajaran) pada tiap bab.</li>
                                <li>Setiap bab akan dipaparkan dengan&nbsp;peta konsep dan kata kunci agar memudahkan
                                    guru dalam menjelaskan isi materi.</li>
                                <li>Isi dari bab dan subbab selalu menyajikan konsep -konsep dasar matematis yang
                                    diuraikan dan dijelaskan secara sistematis, jelas, dan padat melalui metode
                                    pendekatan induktif maupun deduktif. Selain itu, isi buku disertai juga contoh soal
                                    yang sudah dilabeli tujuan pembelajaran untuk mencapai penguasaan keempat
                                    kompetensi, yaitu:
                                    <ul>
                                        <li>kompetensi sikap spiritual,</li>
                                        <li>kompetensi sikap sosial,</li>
                                        <li>kompetensi pengetahuan,</li>
                                        <li>kompetensi keterampilan.</li>
                                    </ul>
                                </li>
                                <li>Latihan dalam buku ini diberi nama LKS (Latihan Kompetensi Siswa) yang disusun dalam
                                    tiga tingkatan daya serap siswa, yaitu evaluasi pengertian atau ingatan dalam bentuk
                                    pilihan ganda, evaluasi pemahaman dan penguasaan materi dalam bentuk uraian, dan
                                    evaluasi kemampuan analisis dalam bentuk uraian berstruktur. Sebagai pendamping LKS
                                    diberikan Tupok (Tugas Kelompok) untuk melatih siswa dengan berbagai tujuan
                                    pembelajaran agar siswa siap dalam berbagai ajang kompetisi matematika, seperti
                                    kompetisi regional, kompetisi nasional (OSK, OSP, dan OSN), dan sebagai persiapan
                                    dini menuju jenjang seleksi masuk PTN/PTS.&nbsp;</li>
                                <li>Pada&nbsp;setiap akhir bab selalu ditutup dengan Rangkuman Materi dan Ruko
                                    (<em>Review</em>&nbsp;Uji Kompetensi Siswa) disertai dengan Soal untuk Penggemar
                                    Matematika sebagai alat ukur pemahaman dan penguasaan siswa akan bahan ajar yang
                                    telah dipelajari dan mempermudah guru dalam memberi penilaian atas pencapaian siswa
                                    di kelas.&nbsp;</li>
                            </ol>

                            <h2>&nbsp;</h2>

                            <h2><a
                                    href="https://www.eurekabookhouse.co.id/product/detail/14468/cerdas-berbhs-indonesia-sma-ma-kls-xi-k13n-wajib"><strong>Cerdas
                                        Berbhs Indonesia Sma/Ma Kls.Xi/K13N Wajib</strong></a></h2>

                            <p><img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0044900200.jpg"
                                    style="height:448px; width:312px" /></p>

                            <p>Buku ini disusun berdasarkan Kurikulum 2013 (Revisi 2016) yang mengintegrasikan materi,
                                pendidikan karakter, dan keterampilan secara apik. Siswa akan dipandu secara alamiah
                                melalui pendekatan menarik sehingga proses belajar mengalir secara menyenangkan. Untuk
                                mencapai tujuan itu, buku ini dilengkapi fitur-fitur berikut:</p>

                            <p>1. Kajian Kelompok, berupa fitur yang memancing kreativitas dan pemahaman siswa melalui
                                diskusi dalam kelompok.</p>

                            <p>2. Ungkap Persepsi dan Tugas Individu, berupa penerapan suatu konsep dengan penugasan
                                spesifik agar siswa dapat mengaplikasikan pengetahuan dalam kehidupan sehari-hari.</p>

                            <p>3. Khazanah bahasa, berupa seputar informasi menarik yang berkaitan dengan materi untuk
                                menambah wawasan.</p>

                            <p>4. Karakterku, berupa pembentukan sikap yang menunjukkan nilai-nilai positif yang harus
                                dimiliki siswa.</p>

                            <p>5. Rangkuman, berupa ringkasan garis-garis besar materi untuk membentuk memori jangka
                                panjang siswa.</p>

                            <p>6. Tes Formatif, berupa uji kompetensi untuk mengevaluasi tingkat penguasaan materi.</p>

                            <p>&nbsp;</p>

                            <h2><a
                                    href="https://www.eurekabookhouse.co.id/product/detail/15061/matematika-sma-jl-2b-k13n-wajib"><strong>MATEMATIKA
                                        SMA JL.2B/K13N-WAJIB </strong></a></h2>

                            <p><strong><img alt=""
                                        src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0045100401.jpg" /></strong>
                            </p>

                            <p>Buku Matematika SMA/MA Jilid 2B ini disusun berdasarkan Kurikulum 2013 Edisi Revisi 2016.
                                Buku ini ditujukan untuk peserta didik SMA dan MA yang ingin memahami matematika secara
                                baik dan mendalam. Materinya disajikan sedemikian rupa sehingga dapat diterapkan dan
                                dimanfaatkan dalam kehidupan sehari-hari.&nbsp;</p>

                            <p>&nbsp;<strong>Keunggulan produk:</strong></p>

                            <ol>
                                <li>Awal bab selalu dimulai dengan ilustrasi kehidupan nyata dan Imabel (Inspirasi
                                    Materi Pembelajaran) pada tiap bab.</li>
                                <li>Setiap bab akan dipaparkan dengan&nbsp;peta konsep dan kata kunci agar memudahkan
                                    guru dalam menjelaskan isi materi.</li>
                                <li>Isi dari bab dan subbab selalu menyajikan konsep -konsep dasar matematis yang
                                    diuraikan dan dijelaskan secara sistematis, jelas, dan padat melalui metode
                                    pendekatan induktif maupun deduktif. Selain itu, isi buku disertai juga contoh soal
                                    yang sudah dilabeli tujuan pembelajaran untuk mencapai penguasaan keempat
                                    kompetensi, yaitu:
                                    <ul>
                                        <li>kompetensi sikap spiritual,</li>
                                        <li>kompetensi sikap sosial,</li>
                                        <li>kompetensi pengetahuan,</li>
                                        <li>kompetensi keterampilan.</li>
                                    </ul>
                                </li>
                                <li>Latihan dalam buku ini diberi nama LKS (Latihan Kompetensi Siswa) yang disusun dalam
                                    tiga tingkatan daya serap siswa, yaitu evaluasi pengertian atau ingatan dalam bentuk
                                    pilihan ganda, evaluasi pemahaman dan penguasaan materi dalam bentuk uraian, dan
                                    evaluasi kemampuan analisis dalam bentuk uraian berstruktur sebagai tolok ukur
                                    penilaian terhadap siswa. Selain itu, dilengkapi juga dengan Bekel (Belajar
                                    Kelompok)sebagai pembentukan karakter siswa.&nbsp;</li>
                                <li>Pada&nbsp;setiap akhir bab diberikan Rangkuman Materi dan dilengkapi dengan Ruko
                                    (<em>Review</em>&nbsp;Uji Kompetensi Siswa). Setelah Ruko, disediakan Quiz Bab yang
                                    diberi batasan waktu, sebagai alat ukur pemahaman siswa dalam bentuk soal terhadap
                                    materi yang telah dipelajari sehingga diperoleh kualitas pembelajaran di
                                    kelas.&nbsp;</li>
                            </ol>

                            <h2>&nbsp;</h2>

                            <h2><a
                                    href="https://www.eurekabookhouse.co.id/product/detail/7114/pathway-to-english-jl-2-k2013pathway-to-english-jl-2-k2013"><strong>Pathway
                                        To English Jl.2/K2013Pathway To English Jl.2/K2013</strong></a></h2>

                            <p><strong><img alt=""
                                        src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0043730070.jpg"
                                        style="height:448px; width:270px" /></strong></p>

                            <p><strong>Pathway to English </strong>menawarkan pengalaman belajar Bahasa Inggris yang
                                menarik dan variatif berdasarkan Kurikulum 213. Selama pembelajaran, para siswa akan
                                belajar dalam kegiatan-kegiatan yang menunjang mata pelajaran lain sePerguruan Tinggi
                                ilmu pengetahuan alam dan ilmu pengetahuan sosial. Para siswa juga akan dilatih
                                mengembangkan empat kecakapan mendengarkan, berbicara, membaca, dan menulis yang akan
                                membantu mengembangkan kompetensi mereka. Buku ini memberikan kepada para siswa
                                kesempatan untuk menggunakan kecakapan berBahasa Inggris mereka ke dalam serangkaian
                                aktivitas yang akan mendorong penggunaan Bahasa Inggris.</p>

                            <p>Setiap bab buku ini terdiri unit berikut.</p>

                            <ul>
                                <li>Empat kecakapan berBahasa : Listening, Speaking, Reading, Writing</li>
                                <li>Grammar dan teks genre</li>
                                <li>Values (Nilai karakter)</li>
                                <li>Cultural awareness (kesadaran lintas budaya)</li>
                                <li>Ways to Say It (berisi ungkapan-ungkapan)</li>
                            </ul>

                            <h2>&nbsp;</h2>

                            <h2><a
                                    href="https://www.eurekabookhouse.co.id/product/detail/7114/pathway-to-english-jl-2-k2013pathway-to-english-jl-2-k2013"><strong>Pathway
                                        To English Jl.2/K2013Pathway To English Jl.2/K2013</strong></a></h2>

                            <h2><strong><img alt=""
                                        src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0043730070.jpg"
                                        style="height:448px; width:270px" /></strong></h2>

                            <p><strong>Pathway to English </strong>menawarkan pengalaman belajar Bahasa Inggris yang
                                menarik dan variatif berdasarkan Kurikulum 213. Selama pembelajaran, para siswa akan
                                belajar dalam kegiatan-kegiatan yang menunjang mata pelajaran lain sePerguruan Tinggi
                                ilmu pengetahuan alam dan ilmu pengetahuan sosial. Para siswa juga akan dilatih
                                mengembangkan empat kecakapan mendengarkan, berbicara, membaca, dan menulis yang akan
                                membantu mengembangkan kompetensi mereka. Buku ini memberikan kepada para siswa
                                kesempatan untuk menggunakan kecakapan berBahasa Inggris mereka ke dalam serangkaian
                                aktivitas yang akan mendorong penggunaan Bahasa Inggris.</p>

                            <p>Setiap bab buku ini terdiri unit berikut.</p>

                            <ul>
                                <li>Empat kecakapan berBahasa : Listening, Speaking, Reading, Writing</li>
                                <li>Grammar dan teks genre</li>
                                <li>Values (Nilai karakter)</li>
                                <li>Cultural awareness (kesadaran lintas budaya)</li>
                                <li>Ways to Say It (berisi ungkapan-ungkapan)<strong> </strong></li>
                            </ul>

                            <h2>&nbsp;</h2>

                            <h2><a
                                    href="https://www.eurekabookhouse.co.id/product/detail/14449/biologi-sma-ma-kls-xi-jl-2-k13n"><strong>Biologi
                                        Sma/Ma Kls.Xi Jl.2/K13N</strong></a></h2>

                            <p><strong><img alt=""
                                        src="https://cdn.eurekabookhouse.co.id/ebh/product/all/04-34-051-1.jpg" /></strong>
                            </p>

                            <p>Buku Biologi SMA/MA&nbsp;<em>Kelompok Peminatan Matematika dan Ilmu-Ilmu
                                    Alam</em>&nbsp;ini ditujukan bagi peserta didik SMA dan MA yang ingin memahami
                                Biologi secara lebih mendalam. Buku ini mengacu pada Kurikulum 2013 Edisi Revisi 2016.
                                Di dalamnya terdapat fitur-fitur seperti:</p>

                            <ul>
                                <li><strong>Peta Konsep</strong>: berisi peta pemahaman materi bab.</li>
                                <li><strong>Kata Kunci</strong>: berisi kata-kata kunci yang dibahas pada setiap bab.
                                </li>
                                <li><strong>Diskusi</strong>: merupakan tugas diskusi untuk kelompok yang membahas
                                    aplikasi biologi dalam lingkungan, teknologi, dan masyarakat..</li>
                                <li><strong>Kegiatan</strong>: berupa kegiatan praktikum di kelas, di laboratorium,
                                    maupun di lapangan untuk mengaplikasikan materi yang dipelajari.</li>
                                <li><strong>Kuis Bio</strong>: berisi pertanyaan-pertanyaan singkat untuk melatih
                                    kekritisan peserta didik.</li>
                                <li><strong>Konsep Bio</strong>: berisi rangkuman singkat mengenai materi-materi yang
                                    penting.</li>
                                <li><strong>Tugas Mandiri</strong>: merupakan tugas perorangan dalam bentuk pembuatan
                                    laporan.</li>
                                <li><strong>Rangkuman</strong>: berisi&nbsp; konsep-konsep penting dalam setiap bab yang
                                    harus diperhatikan.</li>
                                <li><strong>Bio Suplemen</strong>: berisi pengembangan konsep, artikel, atau informasi
                                    dari media cetak maupun internet untuk menambah wawasan tentang perkembangan
                                    biologi.</li>
                                <li><strong>Uji Kompetensi</strong>: memiliki beberapa bentuk soal, antara lain pilihan
                                    ganda, pilihan ganda bervariasi, soal hubungan sebab akibat, dan uraian.</li>
                            </ul>

                            <p><strong>Keunggulan produk:</strong>.</p>

                            <p>1. Dilengkapi soal latihan pada setiap akhir subbab, akhir bab, dan akhir&nbsp; semester.
                            </p>

                            <p>2. Kegiatan sederhana yang bervariasi.</p>

                            <p>3. Dilengkapi dengan informasi-informasi tambahan untuk menambah wawasan.</p>

                            <p>&nbsp;</p>

                            <h2><a
                                    href="https://www.eurekabookhouse.co.id/product/detail/13213/langkah-awal-menuju-olimpiade-matematika-geometri"><strong>Langkah
                                        Awal Menuju Olimpiade Matematika: Geometri</strong></a></h2>

                            <p><strong><img alt=""
                                        src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0045100510.jpg" /></strong>
                            </p>

                            <p>Pengalaman menunjukkan bahwa siswa yang berasal dari daerah memiliki potensi yang sama
                                dengan siswa dari kota untuk bisa menjadi juara olimpiade matematika. Akan tetapi karena
                                akses terhadap materi pelatihan olimpiade yang terbatas, maka mereka sering kali kalah
                                bersaing dengan siswa dari kota yang aksesnya memadai.</p>

                            <p>Buku ini disusun untuk mengatasi hal di atas. Dengan memberikan bahan bacaan tentang
                                matematika olimpiade secara luas, diharapkan akan banyak muncul jagoan-jagoan baru dari
                                daerah. Walaupun materi buku ini lebih tinggi dari materi di kurikulum, tetapi soal-soal
                                yang ada dibuat cukup sederhana agar guru dan siswa mampu mengerjakan soal-soal standar
                                olimpiade matematika ini. Kemudian, setelah kepercayaan diri dari siswa muncul, di SMA
                                mereka dapat dilatih dengan soal-soal setingkat Olimpiade Matematika Internasional (IMO
                                = <em>International Mathematics Olympiad</em>).</p>

                            <p>Selain tujuan di atas, buku ini juga dapat digunakan oleh guru SMP dan SMA untuk lebih
                                memperdalam pengetahuan dan pemahaman tentang matematika. Berbeda dengan pemahaman yang
                                ada, bahwa setelah rumus matematikanya diketahui, maka siswa dapat mengerjakan soal. Di
                                tingkat olimpiade hal ini tidak selalu terjadi. Seringkali kita tidak mengerti tentang
                                rumusnya, atau teknik yang harus dipakai. Keterampilan menemukan pemecahan masalah
                                inilah yang menjadi tujuan penulisan buku ini.</p>

                            <p>Guru juga merupakan kunci keberhasilan dari kesuksesan tim Olimpiade Matematika karena
                                gurulah yang berinteraksi dengan siswa. Jika guru diberi bekal pengetahuan yang lebih
                                baik, maka guru dapat mendampingi siswa. Interaksi guru dan siswa dapat diibaratkan
                                sebagai klub sepakbola sebelum pemain menjadi tim nasional.</p>

                            <p>Buku ini merupakan seri kedua dari 7 seri buku <em>Langkah Awal Menuju Olimpiade
                                    Matematika</em>. Buku seri kedua ini berisi tentang geometri. Materi yang dibahas
                                sebenarnya dapat dipelajari mulai dari sekolah menengah lanjutan pertama sampai dengan
                                sekolah menengah atas. Tetapi pada buku ini pembahasan ditekankan pada pemahaman yang
                                lebih menyeluruh teknik penyelesaian yang lebih umum. Sebagai contoh, teknik menghitung
                                potongan garis di dalam segitiga menggunakan teknik pemotongan segitiga sembarang
                                menjadi segitiga siku-siku. Rumus-rumus yang berkaitan muncul dengan sendirinya dalam
                                soal latihan. Pendekatan yang dipakai pada bab ini sangat sederhana sehingga dapat
                                dipelajari oleh murid SMP.</p>

                            <p>Secara lengkap, judul-judul seri <em>Langkah Awal Menuju Olimpiade Matematika</em>
                                adalah:</p>

                            <p>1.&nbsp;&nbsp;&nbsp; Teori Bilangan</p>

                            <p>2.&nbsp;&nbsp;&nbsp; Geometri</p>

                            <p>3.&nbsp;&nbsp;&nbsp; Kombinatorik</p>

                            <p>4.&nbsp;&nbsp;&nbsp; Pertidaksamaan</p>

                            <p>5.&nbsp;&nbsp;&nbsp; Problem Solving</p>

                            <p>6.&nbsp;&nbsp;&nbsp; Vektor</p>

                            <p>7.&nbsp;&nbsp;&nbsp; Bilangan Kompleks</p>

                            <p>Semoga hadirnya buku ini dapat memberikan manfaat bagi siapa pun yang berkait dengan
                                kegiatan Olimpiade Matematika tingkat SMP/SMA, yaitu siswa, guru pembimbing, dan orang
                                tua yang ingin anaknya menjadi juara Olimpiade Matematika.</p>

                            <p>&nbsp;</p>
                        </div>
                    </div>
                    <div class="media ava-bloger">
                        <img class="mr-3 rounded-circle img-thumbnail"
                            src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                            alt="Generic placeholder image">
                        <div class="media-body">
                            <p class="stext-101 m-b-8">DITULIS OLEH</p>
                            <h5 class="mt-0 mtext-111">Arif Hendras</h5>
                            <p class="badge badge-primary">Content Writer</p>
                            <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="sec-relate-product bg6 p-t-20 text-left">
    <div class="container p-tb-10">
        <div class="p-b-15">
            <h3 class="session-title mtext-106 cl5">New Entry</h3>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/kisahkasih'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/rahasiadelia'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pohonmangga'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/malaikat'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/ttgayah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/merah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/jejak'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pejuang'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">04 September 2022</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <footer class="bgu1 p-t-15 p-b-15">
        <div class="container">
            <div class="p-t-10">
                <p class="stext-107 cl0 txt-center">
                    Copyright &copy; Dikembangkan oleh Eureka IT Developer
                </p>
            </div>
        </div>
    </footer>
</div>
    </div>
</template>
<script>
export default {
    name: "delapanPage",
}
</script>