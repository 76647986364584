<template>
    <div>
        <div class="bg1">
            <div class="">
                <div class="p-t-3 p-b-5">
                    <nav class="">
                        <router-link :to="'/Blog/pejuang'" class="center">
                            <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                                alt="IMG-LOGO" class="center">
                        </router-link>
                    </nav>
                </div>
            </div>
        </div>
    <section class="bg0 p-t-20 text-left" style="min-height: 100%">
        <div class="container">
            <h3 class="ltext-101 p-tb-20">Trivia</h3>
            <div class="row">
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/alltrivia/a'">
                                <img class="blog-read-pic"
                                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/4_Aplikasi_untuk_Baca_Buku_Online_di_Smartphonemu.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1"><router-link :to="'/Blog/alltrivia/a'">Trivia</router-link></div>
                            <h4 class="p-b-32">
                            <router-link :to="'/Blog/alltrivia/a'"
                                    class="mtext-101 cl2 hov-cl1 trans-04">
                                    4 Aplikasi untuk Baca Buku Online di Smartphonemu </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">10 November 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/alltrivia/b'">
                                <img class="blog-read-pic"
                                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/Ingin_Jadi_Penulis_Ini_Dia_Cara_Menjadi_Seorang_Penulis_untuk_Pemula.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1"><router-link :to="'/Blog/trivia'">Trivia</router-link></div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/alltrivia/b'"
                                    class="mtext-101 cl2 hov-cl1 trans-04">
                                    Ingin Jadi Penulis Ini Dia Cara Menjadi Seorang Penulis untuk Pemula </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">10 November 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/alltrivia/c'">
                                <img class="blog-read-pic"
                                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/Hal_Yang_Bisa_Kamu_Lakukan_Setelah_Lulus_SMA.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1"><router-link :to="'/Blog/trivia'">Trivia</router-link></div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/alltrivia/c'"
                                    class="mtext-101 cl2 hov-cl1 trans-04">
                                    Setelah Lulus Mau Ngapain? Ini 5 Hal Yang Bisa Kamu Lakukan Setelah Lulus SMA </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">11 November 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/alltrivia/d'">
                                <img class="blog-read-pic"
                                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/cara_meningkatkan_daya_ingat.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1"><router-link :to="'/Blog/trivia'">Trivia</router-link></div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/alltrivia/d'"
                                    class="mtext-101 cl2 hov-cl1 trans-04">
                                    Cara Meningkatkan Daya Ingat dan Konsentrasi yang Terbukti Efektif </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">10 November 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/alltrivia/e'">
                                <img class="blog-read-pic"
                                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/Yuk,_Kenali_Kepribadian_Mu_dari_Genre_Novel_yang_Kamu_Sukai_!.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1"><router-link :to="'/Blog/trivia'">Trivia</router-link></div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/alltrivia/e'"
                                    class="mtext-101 cl2 hov-cl1 trans-04">
                                    Yuk, Kenali Kepribadian Mu dari Genre Novel yang Kamu Sukai ! </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">12 November 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/alltrivia/f'">
                                <img class="blog-read-pic"
                                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/Ini_7_Cara_Meningkatkan_Kecerdasan_Linguistik_pada_Anak_Eureka_Blog.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1"> <router-link :to="'/Blog/alltrivia/f'">Trivia</router-link></div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/alltrivia/f'"
                                    class="mtext-101 cl2 hov-cl1 trans-04">
                                    Ini 7 Cara Meningkatkan Kecerdasan Linguistik pada Anak </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">12 November 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/alltrivia/g'">
                                <img class="blog-read-pic"
                                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/1038378-riz--hari-pertama-ujian-nasional--780x390.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1"><router-link :to="'/Blog/trivia'">Trivia</router-link></div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/alltrivia/g'"
                                    class="mtext-101 cl2 hov-cl1 trans-04">
                                    Ini Dia 7 Tips Menghadapi UN, Pasti Sukses! </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">12 November 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/alltrivia/h'">
                                <img class="blog-read-pic"
                                    src="https://cdn.eurekabookhouse.co.id/ebh/blog/1544153400-un-2019.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1"><router-link :to="'/Blog/trivia'">Trivia</router-link></div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/alltrivia/h'"
                                    class="mtext-101 cl2 hov-cl1 trans-04">
                                    Ini dia Hal-hal yang wajib untuk Persiapan Ujian Nasional 2019 </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">12 November 2022</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> <!-- Footer -->
    <footer class="bgu1 p-t-15 p-b-15">
        <div class="container">
            <div class="p-t-10">
                <p class="stext-107 cl0 txt-center">
                    Copyright &copy; Dikembangkan oleh Eureka IT Developer
                </p>
            </div>
        </div>
    </footer>
    </div>
</template>
<script>
export default {
    name : "trivaPage",
}
</script>