<template>
    <div>
        <div class="bg1">
            <div class="">
                <div class="p-t-3 p-b-5">
                    <nav class="">
                        <a href="https://www.eurekabookhouse.co.id/" class="center">
                            <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                                alt="IMG-LOGO" class="center">
                        </a>
                    </nav>
                </div>
            </div>
        </div>
            <section class="bg0 p-b-20 text-left">
                <div class="container p-lr-80-lg">
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            <h1 class="ltext-102 cl2 p-t-28">
                                Wajib dibaca! Ini Dia Novel Fiksi Terbaik Indonesia </h1>
                            <div class="flex-m flex-sb m-tb-15">
                                <div class="flex-i flex-w flex-m stext-111 cl2">
                                    <span>
                                        <span class="cl4">Oleh</span> Eureka
                                        <span class="cl12 m-l-4 m-r-6">|</span>
                                    </span>
                                    <span>
                                        18 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                                    </span>
                                    <div id="share" class="m-l-50-lg">
                                        <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                            href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/wajib-dibaca--ini-dia-novel-fiksi-terbaik-indonesia-"
                                            target="_blank"><i class="fa fa-facebook"></i></a>
                                        <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                            href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/wajib-dibaca--ini-dia-novel-fiksi-terbaik-indonesia-&amp;text=Blog: Wajib dibaca! Ini Dia Novel Fiksi Terbaik Indonesia  &amp;hashtags=eurekabookhouse"
                                            title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                        <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                            href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/wajib-dibaca--ini-dia-novel-fiksi-terbaik-indonesia-"
                                            title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                                    </div>
                                </div>
                                <div class="flex-r-m">
                                    <span class="stext-107 text-center"><i class="fa fa-eye"></i> 9697</span>
                                </div>
                            </div>
                            <div class="wrap-pic-w how-pos5-parent">
                                <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/Wajib_dibaca!_Ini_Dia_Novel_Fiksi_Terbaik_Indonesia.jpg"
                                    alt="IMG-BLOG" class="blog-read-pic">
                                <div class="flex-col-c-m size-123 bg9 how-pos5">
                                    <span class="ltext-107 cl2 txt-center">
                                        18 </span>
            
                                    <span class="stext-109 cl3 txt-center">
                                        11 2022 </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                    <div class="row p-t-35 p-b-80">
                        <div class="col-lg-2">
                        </div>
                        <div class="col-md-8 col-lg-8">
                            <div class="p-r-0-lg">
                                <div>
                                    <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                                        <p>Kamu penggemar buku-buku fiksi? Ini dia novel fiksi terbaik Indonesia yang wajib kamu
                                            baca. Untuk kamu yang menyukai buku-buku fiksi, terkadang akan mengalami kebingungan
                                            untuk menentukan buku apalagi yang harus dibaca. Nah, ini dia beberapa rekomendasi buku
                                            fiksi terbaik yang bisa kamu dapatkan untuk menjadi buku bacaanmu selanjutnya.</p>
            
                                        <p>&nbsp;</p>
            
                                        <h2><strong>Laut Bercerita karya Leila S. Chudori</strong></h2>
            
                                        <p>Laut Bercerita mengisahkan tentang sosok lelaki bernama Biru Laut dan kawan-kawannya
                                            sesama aktivis untuk menyuarakan pandangan mereka. Novel yang dilaunching pada akhir
                                            2017 ini mengambil latar belakang kisah tahun 1998 dimana kebebasan berpendapat masih
                                            sangat dibatasi oleh pihak yang berkuasa.&nbsp; Namun, perlawanan yang mereka lakukan
                                            berakhir dengan pengejaran dan penangkapan. Penulis menggambarkan kisah dalam dua sudut
                                            pandang berbeda. Bagian pertama menggunakan sudut pandang Biru Laut dan pada bagian
                                            kedua menggunakan sudut pandang adik dari Biru Laut, yaitu Asmara Jati. Penulis mampu
                                            menuangkan kisah drama dan tragedi dengan nada nostalgik yang akan membuat siapapun yang
                                            membacanya hanyut dalam kisah mereka yang dihilangkan.</p>
            
                                        <p>&nbsp;</p>
            
                                        <h2><strong>Seri Supernova karya Dee Lestari </strong></h2>
            
                                        <p>Seri Supernova terdiri dari enam buku, yaitu Supernova: Ksatria, Puteri, dan Bintang
                                            Jatuh (2001), Supernova: Akar (2002), Supernova:Petir (2004), Partikel (2012), Gelombang
                                            (2014), dan Intelegensi Embun Pagi (2016). Sejak kemunculannya pada tahun 2001,
                                            Supernova sudah diminati banyak pembaca. Seri pertama diawali dengan kisah dua orang
                                            mahasiswa yang menuntut ilmu ke negeri Paman Sam, yaitu Dhimas dan Ruben. Walaupun
                                            serinya sudah berakhir pada tahun 2016 lalu, seri Supernova masih masuk ke dalam daftar
                                            buku yang dicari dibeberapa toko buku. Beruntung untuk kamu yang baru akan membacanya,
                                            karena tidak perlu menunggu karena serialnya sudah lengkap dan bisa kamu dapatkan di
                                            toko buku-toko buku kesayanganmu.&nbsp;</p>
            
                                        <p>&nbsp;</p>
            
                                        <h2><strong>Ronggeng Dukuh Paruk karya Ahmad Tohari </strong></h2>
            
                                        <p>Ronggeng Dukuh Paruk terbit pertama kali pada tahun 1982 dengan bentuk trilogi. Namun
                                            pada tahun 2003, Ronggeng Dukuh Paruk diterbitkan dalam satu buku dengan beberapa bagian
                                            yang disensor. Dukuh Paruh merupakan nama dari sebuah desa yang terpencil juga miskin.
                                            Namun, terdapat satu hal yang menjadi kebanggaan tersendiri untuk warga Dukuh Paruh
                                            yaitu kesenian ronggeng. Tradisi tersebut hampir musnah sampai munculah Srintil seorang
                                            gadis yang dinobatkan menjadi ronggeng baru. Bagi pendukuhan tersebut, ronggeng adalah
                                            sebuah perlambang. Tanpa kehadiran ronggeng, warga Dukuh Paruk seperti kehilangan jati
                                            diri. Srintil yang menjadi primadona baru menjalani beberapa ritual sebagai ronggeng.
                                            Semua ingin mendekatinya. Sampai masalah besar muncul dan menghancurkan perdukuhan.</p>
            
                                        <p>&nbsp;</p>
            
                                        <h2><strong>Negeri 5 Menara karya A. Fuadi </strong></h2>
            
                                        <p>Cerita kehidupan pondok pesantren di Jawa menjadi latar belakang dari novel ini. A. Fuadi
                                            membawa pembacanya untuk terjun dalam kisah mengenai hubungan persahabatan, aktivitas
                                            pendidikan, serta semangat yang ditunjukan dari penggambaran para tokohnya. Negeri 5
                                            Menara diceritakan dengan kisah yang seru juga inspiratif. Di dalam novel ini, penulis
                                            menyelipkan berbagai kisah mengenai candaan khas pondok, syair-syair Islam juga lirik
                                            lagu yang berhasil memberi semangat kepada pembacanya. &nbsp;&nbsp;Siapapun yang membaca
                                            maupun menonton film Negeri 5 Menara pasti familiar dengan mantra &ldquo;Man Jadda
                                            Wajada&rdquo; yang memiliki arti, siapa yang bersungguh-sungguh, maka keberhasilan akan
                                            didapat. Dengan membaca novel Negeri 5 Menara akan membantu kita untuk memahami bahwa
                                            tidak ada yang tidak mungkin jika kita bersungguh-sungguh ingin mewujudkannya.</p>
            
                                        <p>Ini dia ulasan beberapa novel fiksi terbaik Indonesia yang wajib kamu baca. Dari daftar
                                            di atas, judul yang mana saja kah yang sudah kamu baca?</p>
                                    </div>
                                </div>
                                <div class="media ava-bloger">
                                    <img class="mr-3 rounded-circle img-thumbnail"
                                        src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                                        alt="Generic placeholder image">
                                    <div class="media-body">
                                        <p class="stext-101 m-b-8">DITULIS OLEH</p>
                                        <h5 class="mt-0 mtext-111">Andaf Iman</h5>
                                        <p class="badge badge-primary">Content Writer</p>
                                        <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <section class="sec-relate-product bg6 p-t-20 text-left">
            <div class="container p-tb-10">
                <div class="p-b-15">
                    <h3 class="session-title mtext-106 cl5">New Entry</h3>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/kisahkasih'">
                                    <img class="blog-read-pic"
                                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/rahasiadelia'">
                                    <img class="blog-read-pic"
                                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/pohonmangga'">
                                    <img class="blog-read-pic"
                                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                                    </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/malaikat'">
                                    <img class="blog-read-pic"
                                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE
                                    </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/ttgayah'">
                                    <img class="blog-read-pic"
                                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE
                                    </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/merah'">
                                    <img class="blog-read-pic"
                                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1"><a
                                        href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat
                                        Eureka</a>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE
                                    </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/jejak'">
                                    <img class="blog-read-pic"
                                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">05 September 2022</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 p-b-20">
                        <div class="blog-item bg0 bor8">
                            <div class="blog-pic hov-img0">
                                <router-link :to="'/Blog/pejuang'">
                                    <img class="blog-read-pic"
                                        src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                                </router-link>
                            </div>
                            <div class="p-all-15">
                                <div class="stext-112 flex-w p-b-14 cl1">
                                    <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                                </div>
                                <h4 class="p-b-32">
                                    <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                        PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA
                                        BOOKHOUSE
                                    </router-link>
                                </h4>
                                <span class="stext-102 m-t-20">04 September 2022</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <footer class="bgu1 p-t-15 p-b-15">
            <div class="container">
                <div class="p-t-10">
                    <p class="stext-107 cl0 txt-center">
                        Copyright &copy; Dikembangkan oleh Eureka IT Developer
                    </p>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
    name: "fiksiterbaikPage"
}
</script>