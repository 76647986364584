<template>
  <div class="login-container">
    <img
      class="logo-left d-none d-md-block"
      src="../../assets/images/login/b.png"
      alt="Left Logo"
    />
    <img
      class="logo-right d-none d-md-block"
      src="../../assets/images/login/a.png"
      alt="Right Logo"
    />

    <div class="login-form">
      <div>
        <img
          src="../../assets/images/login/EBH.png"
          style="width: 234px"
          alt=""
        />
        <p
          style="
            color: #000;

            /* Style/Body */
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 15.4px; /* 110% */
            letter-spacing: -0.14px;
          "
        >
          Masukkan akun pribadi Eureka anda
        </p>
        <br />
        <div class="login-box-body">
          <form class="form-signin" @submit.prevent="login">
            <span class="text-label">Email</span>

            <input
              v-model="email"
              type="email"
              id="inputEmail"
              class="text-form mt-3"
              placeholder="Masukan Email"
              required
            />
            <span class="text-label">Password</span>

            <input
              v-model="password"
              type="password"
              id="inputEmail"
              class="text-form mt-3"
              placeholder="Masukan Password"
              required
            />
            <!-- <div>
              <router-link :to="'/UserEBH/Forgot'"
                ><span style="float: right">Lupa Password?</span></router-link
              >
            </div> -->
            <br />
            <div class="mt-3">
              <button
                class="btn btn-lg btn-primary btn-block btn-signin"
                type="submit"
                style="
                  display: flex;
                  height: 50px;
                  padding: 10px;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  align-self: stretch;
                  border-radius: 50px;
                  background: var(--primary-05, #244786);
                "
              >
                Masuk
              </button>
              <!-- <br /> -->
              <!-- <img
                src="../../assets/images/login/atau.png"
                style="width: 350px"
                alt=""
              /> -->
            </div>
            <!-- <br /> -->
            <!-- <span><b>Masuk dengan </b></span> -->
            <!-- <div class="row mt-3">
              <div class="col d-flex justify-content-center">
                <img
                  src="../../assets/images/login/go.png"
                  style="width: 45px"
                  class="mr-3"
                  alt=""
                />
                <img
                  src="../../assets/images/login/fb.png"
                  style="width: 45px"
                  alt=""
                />
              </div>
            </div> -->
          </form>
          <!-- /form -->
        </div>
        <div style="margin-bottom: 10px">
          <br />
          <!-- <br /> -->

          <span>Tidak punya akun? </span
          ><router-link :to="'/UserEBH/Daftar'"
            ><b style="color: #244786">Daftar</b></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
// import loaderImage from "../../assets/images/login/loader.gif";
export default {
  name: "loginPage",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    login() {
      // Set loading to true when the request starts
      this.loading = true;

      const apiUrl = "https://stagingapi.eurekabookhouse.co.id/customer/login";
      const requestBody = {
        email: this.email,
        password: this.password,
      };

      // Display an overlay with a loading image
      const overlay = document.createElement("div");
      overlay.className = "loading-overlay";
      overlay.innerHTML = `
    <img src="${require("@/assets/images/login/loader_ebh.gif")}" style="width: 10%" />
    <p style="font-size: 20px; font-weight: 500;">Loading...</p>
  `;
      document.body.appendChild(overlay);

      const timeoutMillis = 10000;

      axios
        .post(apiUrl, requestBody, { timeout: timeoutMillis })
        .then((response) => {
          // Handle response
          if (response.status === 200) {
            // console.log(response.data);

            if (response.data.data && response.data.data.token) {
              localStorage.setItem("token", response.data.data.token);
              localStorage.setItem(
                "id_customer",
                response.data.data.id_customer
              );

              // Redirect user to the home page
              this.$router.push({ name: "home" });
            } else {
              console.error("Token not found in the response data");
              this.showLoginFailedAlert();
            }
          } else {
            console.error(
              `Response not successful, status: ${response.status}`
            );
            this.showLoginFailedAlert();
          }
        })
        .catch((error) => {
          console.error(error);
          this.showLoginFailedAlert();
        })
        .finally(() => {
          // Set loading to false when the request is complete, whether successful or not
          this.loading = false;

          // Remove the overlay after completion
          document.body.removeChild(overlay);
        });
    },

    showLoginFailedAlert() {
      Swal.fire({
        confirmButtonColor: "#3860A8",
        icon: "error",
        title: "Login Gagal",
        text: "Terjadi kesalahan saat login. Silakan coba lagi.",
      });
    },
  },
};
</script>
<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.logo-left {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 400px; /* Sesuaikan dengan ukuran gambar Anda */
}

.logo-right {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 400px; /* Sesuaikan dengan ukuran gambar Anda */
}

.login-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 400px;
}
.text-form {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  background: var(--neutral-02, #efefef);
}
.text-label {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: -0.16px;
  float: left;
}
input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
}

button:hover {
  background-color: #0056b3;
}
</style>
