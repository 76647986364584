<template>
    <div>
<div>
    <div class="bg1">
        <div class="">
            <div class="p-t-3 p-b-5">
                <nav class="">
                    <router-link :to="'/Blog/pejuang'" class="center">
                        <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                            alt="IMG-LOGO" class="center">
                    </router-link>
                </nav>
            </div>
        </div>
    </div>
<section class="bg0 p-b-20 text-left">
    <div class="container p-lr-80-lg">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <h1 class="ltext-102 cl2 p-t-28">
                    Buku SD Terlaris / Best Seller Januari 2019 </h1>
                <div class="flex-m flex-sb m-tb-15">
                    <div class="flex-i flex-w flex-m stext-111 cl2">
                        <span>
                            <span class="cl4">Oleh</span> Eureka
                            <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <span>
                            17 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <div id="share" class="m-l-50-lg">
                            <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/buku-sd-terlaris---best-seller-januari-2019"
                                target="_blank"><i class="fa fa-facebook"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/buku-sd-terlaris---best-seller-januari-2019&amp;text=Blog: Buku SD Terlaris / Best Seller Januari 2019 &amp;hashtags=eurekabookhouse"
                                title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/buku-sd-terlaris---best-seller-januari-2019"
                                title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                        </div>
                    </div>
                    <div class="flex-r-m">
                        <span class="stext-107 text-center"><i class="fa fa-eye"></i> 3973</span>
                    </div>
                </div>
                <div class="wrap-pic-w how-pos5-parent">
                    <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/Siswa-Belajar.png" alt="IMG-BLOG"
                        class="blog-read-pic">
                    <div class="flex-col-c-m size-123 bg9 how-pos5">
                        <span class="ltext-107 cl2 txt-center">
                            17 </span>

                        <span class="stext-109 cl3 txt-center">
                            11 2022 </span>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row p-t-35 p-b-80">
            <div class="col-lg-2">
            </div>
            <div class="col-md-8 col-lg-8">
                <div class="p-r-0-lg">
                    <div>
                        <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                            <p>&nbsp;</p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/14231/bupena-bk-penilaian-4c-k13n">1.&nbsp;&nbsp;
                                        &nbsp;BUPENA (BK. PENILAIAN) 4C/K13N</a></strong><br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0023713130.jpg"
                                    style="height:250px; width:201px" /><br />
                                <br />
                                BUPENA Kurikulum 2013 Revisi berisi rangkaian proses pembelajaran yang disajikan dalam
                                urutan yang sesuai dengan buku teks tematik pemerintah dengan mengakomodasi:
                            </p>

                            <p>Pendalaman Materi, berisi materi tambahan untuk mempertajam pemahaman siswa terhadap
                                materi yang sedang dipelajari.<br />
                                Kegiatan, disajikan bervariasi yang meliputi kinerja praktik, kinerja produk, proyek,
                                atau portofolio. Disajikan pula contoh kegiatan terpilih yang dapat dijadikan instrument
                                penilaian keterampilan dan sikap.<br />
                                Ayo Berlatih, berisi soal-soal latihan untuk mengakomodasi penilaian aspek
                                pengetahuan.<br />
                                Tes Tertulis, berisi soal-soal pengetahuan yang disajikan sebagai contoh instrument
                                penilaian ulangan harian.<br />
                                Penugasan, berisi tugas untuk siswa yang dapat dijadikan sebagai komponen instrument
                                penilaian ulangan harian.</p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/5288/panduan-tematik-jl-1b-ktsp">2.&nbsp;&nbsp;
                                        &nbsp;PANDUAN TEMATIK JL.1B/KTSP</a></strong><br />
                                <img alt="" src=" https://cdn.eurekabookhouse.co.id/ebh/product/all/02-34-014-0.jpg"
                                    style="height:250px; width:189px" />
                            </p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/13939/esps-matematika-sd-mi-kls-iv-k13n">3.&nbsp;&nbsp;
                                        &nbsp;ESPS: MATEMATIKA SD/MI KLS.IV/K13N</a></strong><br />
                                <br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0025100470.jpg"
                                    style="height:250px; width:190px" />
                            </p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/15701/tematik-terpadu-6f-tema-6-k213n">4.&nbsp;&nbsp;
                                        &nbsp;TEMATIK TERPADU 6F: TEMA 6/K213N</a></strong><br />
                                <br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-0023712571.jpg"
                                    style="height:250px; width:202px" />
                            </p>

                            <p><br />
                                Seri Buku Tematik Terpadu merupakan buku teks yang disusun berdasarkan Kurikulum 2013
                                (Revisi 2016). Dalam buku ini, muatan pelajaran disajikan secara terpadu dengan jam
                                pelajaran sesuai porsinya. Buku Tematik Terpadu Kelas 1 - 3 mengakomodasi muatan
                                pelajaran Bahasa Indonesia, Matematika, PPKn, PJOK, dan SBdP. Buku Tematik Terpadu Kelas
                                4 - 6 mengakomodasi muatan pelajaran Bahasa Indonesia, PPKn, SBdP, IPA, dan IPS.</p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/14907/tematik-terpadu-panas-danamp-perpindahannya-jl-5f-k13n">5.&nbsp;&nbsp;
                                        &nbsp;TEMATIK TERPADU: PANAS &amp; PERPINDAHANNYA JL.5F/K13N</a></strong><br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0023711431.jpg"
                                    style="height:250px; width:200px" /><br />
                                <br />
                                Seri Buku Tematik Terpadu merupakan buku teks yang disusun berdasarkan Kurikulum 2013
                                (Revisi 2016). Dalam buku ini, muatan pelajaran disajikan secara terpadu dengan jam
                                pelajaran sesuai porsinya. Buku Tematik Terpadu Kelas 1 - 3 mengakomodasi muatan
                                pelajaran Bahasa Indonesia, Matematika, PPKn, PJOK, dan SBdP. Buku Tematik Terpadu Kelas
                                4 - 6 mengakomodasi muatan pelajaran Bahasa Indonesia, PPKn, SBdP, IPA, dan IPS.
                            </p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/14420/tematik-terpadu-jl-4g-indahnya-keragaman-dinegeriku-k13n">6.&nbsp;&nbsp;
                                        &nbsp;TEMATIK TERPADU: JL.4G INDAHNYA KERAGAMAN DINEGERI</a></strong><br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0023710561.jpg"
                                    style="height:250px; width:202px" /><br />
                                <br />
                                Seri Buku tematik Terpadu merupakan buku teks yang disusun berdasarkan Kurikulum 2013
                                revisi 2016.&nbsp; Dalam buku ini, tujuh mata pelajaran (PPKn, Bahasa Indonesia,
                                Matematika, SBdP, IPA, IPS, dan PJOK) disajikan secara terpadu dengan jam pelajaran
                                sesuai porsinya. Materi muatan pelajaran disajikan secara menarik dengan berbagai
                                kegiatan yang mengakomodasi penilaian keterampilan dan sikap. Pada akhir setiap
                                pembelajaran, tersedia soal-soal latihan yang mengakomodasi penilaian pengetahuan setiap
                                muatan pelajaran pada hari itu.
                            </p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/14911/tematik-terpadu-peristiwa-dlm-kehidupan-jl-5g-k13n">7.&nbsp;&nbsp;
                                        &nbsp;TEMATIK TERPADU: PERISTIWA DLM KEHIDUPAN JL.5G/K13</a></strong><br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0023711441.jpg"
                                    style="height:250px; width:201px" /><br />
                                <br />
                                Seri Buku Tematik Terpadu merupakan buku teks yang disusun berdasarkan Kurikulum 2013
                                (Revisi 2016). Dalam buku ini, muatan pelajaran disajikan secara terpadu dengan jam
                                pelajaran sesuai porsinya. Buku Tematik Terpadu Kelas 1 - 3 mengakomodasi muatan
                                pelajaran Bahasa Indonesia, Matematika, PPKn, PJOK, dan SBdP. Buku Tematik Terpadu Kelas
                                4 - 6 mengakomodasi muatan pelajaran Bahasa Indonesia, PPKn, SBdP, IPA, dan IPS.
                            </p>

                            <p><a
                                    href="https://www.eurekabookhouse.co.id/product/detail/15693/esps-matematika-sd-mi-kls-vi-k13n">8.&nbsp;&nbsp;
                                    &nbsp;ESPS: MATEMATIKA SD/MI KLS.VI/K13N</a><br />
                                <br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-0025100490.jpg"
                                    style="height:250px; width:192px" />
                            </p>

                            <p>Disusun berdasarkan Kurikulum 2013 (Revisi 2016).<br />
                                Soal-soal dan kegiatan disajikan untuk mengakomodasi penilaian aspek pengetahuan,
                                keterampilan, dan sikap.<br />
                                Didukung dengan media digital sebagai sumber belajar.<br />
                                Media digital berupa animasi, video, dan audio yang dapat dibaca/didengarkan dengan cara
                                men-scan atau memindai QR Code yang tersaji di halaman isi buku.<br />
                                &nbsp;&nbsp;&nbsp; QR Code dapat diakses dengan alat pembaca, yaitu Erlangga Reader yang
                                dapat di-download atau diunduh di Play Store.</p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/5442/bina-bahasa-indonesia-jl-3b-ktsp">9.&nbsp;&nbsp;
                                        &nbsp;BINA BAHASA INDONESIA JL.3B(KTSP)</a></strong></p>

                            <p><br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/02-31-322-0.jpg"
                                    style="height:250px; width:175px" /><br />
                                &nbsp;
                            </p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/14529/esps-matematika-sd-mi-kls-v-k13n">10.&nbsp;&nbsp;
                                        &nbsp;ESPS: MATEMATIKA SD/MI KLS.V/K13N</a></strong><br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0025100480.jpg"
                                    style="height:250px; width:188px" /><br />
                                &nbsp;
                            </p>

                            <p>Sumber gambar header: <a href="http://supervba.com">supervba.com</a></p>
                        </div>
                    </div>
                    <div class="media ava-bloger">
                        <img class="mr-3 rounded-circle img-thumbnail"
                            src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                            alt="Generic placeholder image">
                        <div class="media-body">
                            <p class="stext-101 m-b-8">DITULIS OLEH</p>
                            <h5 class="mt-0 mtext-111"></h5>
                            <p class="badge badge-primary"></p>
                            <p class="stext-107"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="sec-relate-product bg6 p-t-20 text-left">
    <div class="container p-tb-10">
        <div class="p-b-15">
            <h3 class="session-title mtext-106 cl5">New Entry</h3>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/kisahkasih'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/rahasiadelia'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pohonmangga'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/malaikat'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/ttgayah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/merah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/jejak'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pejuang'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">04 September 2022</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <footer class="bgu1 p-t-15 p-b-15">
        <div class="container">
            <div class="p-t-10">
                <p class="stext-107 cl0 txt-center">
                    Copyright &copy; Dikembangkan oleh Eureka IT Developer
                </p>
            </div>
        </div>
    </footer>
</div>
    </div>
</template>
<script>
export default {
    name: "empatPage",
}
</script>