<template>
  <div>
    <section class="bg6 p-t-10 p-b-10">
      <form id="form-cart" class="p-b-85">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-xl-3 m-lr-auto m-b-50 d-none d-lg-block">
              <sidebarPage />
            </div>

            <div class="col-lg-10 col-xl-9 p-lr-0-sm text-left mt-5">
              <h6 class="title-notif">Notification Center</h6>
              <div class="mt-3" style="padding-right: 12px">
                <div class="card-notif">
                  <div class="d-flex">
                    <div class="p-2">
                      <img
                        src="../../assets/images/user/succes.png"
                        style="width: 45px; height: 45px"
                      />
                    </div>
                    <div class="p-2">
                      <span class="text-status"
                        >Pesanan anda berhasil dibuat</span
                      >
                      <p class="ket-status mt-1">
                        Yuk, Cek pesananmu sekarang!
                      </p>
                    </div>
                    <div class="ml-auto p-2">
                      <span class="tgl-status text-right">19/10/2023</span>
                      <p class="jam-status text-right mt-1">5 Hours ago</p>
                    </div>
                  </div>
                </div>
                <div class="card-notif mt-3">
                  <div class="d-flex">
                    <div class="p-2">
                      <img
                        src="../../assets/images/user/cancel.png"
                        style="width: 45px; height: 45px"
                      />
                    </div>
                    <div class="p-2">
                      <span class="text-status">Pembayaran anda gagal</span>
                      <p class="ket-status mt-1">
                        Yah, Pesananmu dibatalkan karena pembayaran tidak
                        dilakukan dalam waktu tertentu. silahk...
                      </p>
                    </div>
                    <div class="ml-auto p-2">
                      <span class="tgl-status text-right">19/10/2023</span>
                      <p class="jam-status text-right mt-1">5 Hours ago</p>
                    </div>
                  </div>
                </div>
                <div class="card-notif mt-3">
                  <div class="d-flex">
                    <div class="p-2">
                      <img
                        src="../../assets/images/user/warning.png"
                        style="width: 45px; height: 45px"
                      />
                    </div>
                    <div class="p-2">
                      <span class="text-status">Segera bayar pesanan anda</span>
                      <p class="ket-status mt-1">
                        Pesananmu hampir selesai! Yuk lakukan pembayaran untuk
                        barang impianmu.
                      </p>
                    </div>
                    <div class="ml-auto p-2">
                      <span class="tgl-status text-right">19/10/2023</span>
                      <p class="jam-status text-right mt-1">5 Hours ago</p>
                    </div>
                  </div>
                </div>
                <div class="card-notif mt-3">
                  <div class="d-flex">
                    <div class="p-2">
                      <img
                        src="../../assets/images/user/succes.png"
                        style="width: 45px; height: 45px"
                      />
                    </div>
                    <div class="p-2">
                      <span class="text-status"
                        >Pesanan anda berhasil dibuat</span
                      >
                      <p class="ket-status mt-1">
                        Yuk, Cek pesananmu sekarang!
                      </p>
                    </div>
                    <div class="ml-auto p-2">
                      <span class="tgl-status text-right">19/10/2023</span>
                      <p class="jam-status text-right mt-1">5 Hours ago</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>
<script>
import sidebarPage from "@/components/Users/sidebar.vue";
export default {
  name: "notificationprofilePage",
  components: {
    sidebarPage,
  },
};
</script>
<style>
.title-notif {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 30.8px; /* 110% */
  letter-spacing: -0.28px;
}
.card-notif {
  border-radius: 10px;
  background: var(--neutral-01, #fefefe);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: auto;
  padding: 12px;
  gap: 12px;
}
.text-status {
  color: var(--primary-05, #244786);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
}
.ket-status {
  color: var(--primary-04, #3860a8);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
  white-space: nowrap; /* Avoid text wrapping */
  overflow: hidden; /* Hide text that exceeds the maximum width */
  text-overflow: ellipsis; /* Display ellipsis if the text is too long */
}
@media only screen and (max-width: 600px) {
  .ket-status {
    color: var(--primary-04, #3860a8);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.4px; /* 110% */
    letter-spacing: -0.14px;
    white-space: nowrap; /* Avoid text wrapping */
    overflow: hidden; /* Hide text that exceeds the maximum width */
    text-overflow: ellipsis; /* Display ellipsis if the text is too long */
    max-width: 200px; /* Set the maximum width as needed */
  }
}

.tgl-status {
  color: var(--primary-05, #244786);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}
.jam-status {
  color: var(--primary-04, #3860a8);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}
.text-right {
  text-align: right;
}
</style>
