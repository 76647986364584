<template>
<div>
    <section class="bg6 m-t-15">
        <div class="container p-lr-0 bg0 bor8">
          <div class="manufacturer-banner">
          </div>
          <div class="manufacturer-detail p-all-15">
            <div class="flex-c">
              <div class="logo-manufacturer bs1 p-all-5">
               <img src="https://www.eurekabookhouse.co.id/assets/uplod/merk/Buku-Erlangga_merk10_57_38.jpg" class="img-fluid img-logo-manu">
             </div>  
           </div>
           <div class="flex-c">
            <div class="detail-manufacturer mt-2 mb-2">
              <h4 class="mtext-108">
                <span class="m-t-10"><b>Buku Erlangga</b></span>
              </h4> 
            </div>
          </div>
                <div class="flex-c">
        <a href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/manufacturer/detail" target="_blank" class="p-lr-15 p-tb-8 bg5 hov-bg1 cl0 hov-cl0 m-r-8 bor4 customer share"><i class="fa fa-facebook"></i></a>
        <a href="https://twitter.com/share?u=https://www.eurekabookhouse.co.id/manufacturer/detail" target="_blank" class="p-lr-13 p-tb-8 bg5 hov-bg1 cl0 hov-cl0 m-r-8 bor4 customer share"><i class="fa fa-twitter"></i></a>
        <a href="https://plus.google.com/share?u=https://www.eurekabookhouse.co.id/manufacturer/detail" target="_blank" class="p-lr-15 p-tb-8 bg5 hov-bg1 cl0 hov-cl0 bor4 customer share"><i class="fa fa-google-plus"></i></a>
      </div>  
        </div>
      </div>
    </section>

    <section>
    <aside class="wrap-sidebar js-sidebar">
        <div class="s-full js-hide-sidebar"></div>
      
        <div class="sidebar flex-col-l p-t-22 p-b-25">
      
          <div class="sidebar-content flex-w w-full  js-pscroll">
           <div class="pad-1">
      
                  <div class="p-b-18">
              <p class="mtext-101 cl2 p-b-10">Kategori Toko</p>
            </div>
            <div class="scrollbar" id="ex4">
              <div class="content">
                <div id="menuwrapper">
                  <ul>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-agama">Buku Agama</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=agama-islam">Agama Islam</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-pelajaran">Buku Pelajaran</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-sd">Buku SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-smp">Buku SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-sma">Buku SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-smk">Buku SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-tk">Buku TK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=perguruan-tinggi">Perguruan Tinggi</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=administrasi">Administrasi</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=akuntansi">Akuntansi</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=at-a-glance">At a Glance</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa-dan-sastra">Bahasa &amp; Sastra</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ekonomi">Ekonomi</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=hukum">Hukum</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kedokteran">Kedokteran</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=keperawatan">Keperawatan</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=manajamen">Manajamen</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=mipa">MIPA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=arsitektur">Arsitektur</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=psikologi">Psikologi</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-umum">Buku Umum</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=masakan-dan-minuman">Masakan dan Minuman</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-soal">Buku Soal</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=soal-sd">Soal SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=soal-smp">Soal SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=soal-sma">Soal SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=komputer">Komputer</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-tematik">Buku Tematik</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=novel">Novel</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-anak">Buku Anak</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa--indonesia-sma">Bahasa  Indonesia SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kurikulum-2013-sd">Kurikulum 2013,K13N SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kurikulum-2013-smp">Kurikulum 2013,K13N SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=agama-islam-sd">Agama Islam SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-seri">Buku Seri</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=creativity-series">Creativity Series</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=cerita-anak">Cerita Anak</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=edukasi-anak">Edukasi Anak</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-anak-lainnya">Buku Anak Lainnya</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=mainan-dan-hobi">Mainan & Hobi</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=membaca-dan-menulis">Membaca Dan Menulis</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=edukasi-anak">Edukasi Anak</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-anak-lainnya">Buku Anak Lainnya</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=akuntansi-sma">Akuntansi SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=matematika-sd">Matematika SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=antropologi-sma">Antropologi SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=agama-islam-smp">Agama Islam SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=agama-islam-sma">Agama Islam SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=sma-kurikulum-2013-/-k13n">SMA Kurikulum 2013 / K13N</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=matematika-smp">Matematika SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=biologi-sma">Biologi SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ekonomi-sma">Ekonomi SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=geografi-sma">Geografi SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=fisika-smp">Fisika SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa--indonesia-smk">Bahasa  Indonesia SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=keterampilan-anak">Keterampilan Anak</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=matematika-sma">Matematika SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=fisika-sma">Fisika SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kimia-smp">Kimia SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kimia-sma">Kimia SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-budaya-sd">Seni Budaya SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=biologi-smk">Biologi SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-budaya-sma">Seni Budaya SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kewarganegaraan-smp">Kewarganegaraan SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=sejarah-sma">Sejarah SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=penjas-smp">Penjas SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kewarganegaraan-sd">Kewarganegaraan SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-rupa-sd">Seni Rupa SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=gaya-hidup">Gaya Hidup</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=tas">Tas</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa--inggris-sma">Bahasa  Inggris SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=menggambar-dan-mewarnai">Menggambar Dan Mewarnai</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=smk-kurikulum-2013">Kurikulum 2013,K13N SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=fisika-smk">Fisika SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa-dan-sastra-smp">Bahasa & Sastra SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa--inggris-smk">Bahasa  Inggris SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kamus-dan-bahasa">Kamus dan Bahasa</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-musik-smp">Seni Musik SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=sejarah-smp">Sejarah SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ipa-smk">IPA SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ilmu-komputer-smk">Ilmu Komputer SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kewarganegaraan-sma">Kewarganegaraan SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-budaya-smp">Seni Budaya SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kewarganegaraan-smk">Kewarganegaraan SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kewirausahaan-smk">Kewirausahaan SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kimia-smk">Kimia SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-soal-ujian">Buku Soal Ujian UN</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ensiklopedia">Ensiklopedia</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=penjas-sd">Penjas SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=manajemen">Manajemen</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ti-dan-komunikasi-sd">TI & Komunikasi SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=motivasi-dan-inspirasi">Motivasi Dan Inspirasi</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=pendidikan-umum">Pendidikan Umum</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=perti-lainnya">PERTI Lainnya</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-musik-sma">Seni Musik SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ti-dan-komunikasi-smp">Ti & Komunikasi SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=matematika-smk">Matematika SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=agama-islam-smk">Agama Islam SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=komputer-akuntansi-smk">Komputer Akuntansi SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=penjaskes-smk">Penjaskes SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=soal-smk">Soal SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bhs-inggris-smp">Bhs Inggris SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=penjaskes-sma">Penjaskes SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=fashion-dan-kecantikan">Fashion dan kecantikan</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-umum-lainnya">Buku Umum Lainnya</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seri-ulysses-moore">Seri Ulysses Moore</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kompetensi-inti-dan-kompetensi-dasar-(-kikd17-)">Kompetensi Inti dan Kompetensi Dasar ( KIKD17 )</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=promo-buku-paket-25">Promo Buku Paket 25%</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=perlengkapan-kantor">Perlengkapan Kantor</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=novel-remaja">Novel Remaja</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=novel-romance">Novel Romance</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ips-sd">IPS SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ipa-sd">IPA SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa-inggris-sd">Bahasa Inggris SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bilingual-sd">Bilingual SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa-indonesia-sd">Bahasa Indonesia SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=sains-sd">SAINS SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=plh-sd">PLH SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=plbj-sd">PLBJ SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=mandarin-sd">MANDARIN SD</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ipa-smp">IPA SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ips-smp">IPS SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bimb-konseling-smp">Bimb.Konseling SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bilingual-smp">Bilingual SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=plh-smp">PLH SMP</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bilingual-sma">Bilingual SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bimb-konseling-sma">Bimb.Konseling SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bhs-mandarin-sma">Bhs Mandarin SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=sosiologi-sma">Sosiologi SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ti-dan-komunikasi-sma">Ti & Komunikasi SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-rupa-sma">Seni Rupa SMA</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=modul-smk">Modul SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ips-smk">IPS SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-budaya-smk">Seni Budaya SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=sejarah-smk">Sejarah SMK</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=serba-15rb">Buku Flat</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=atk-lainnya">Lainnya</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ebook">eBook</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=majalah">Majalah</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-sekolah">Buku Sekolah</a>
                      </li>
                                    <li>
                        <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-umum-elib">Buku Umum</a>
                      </li>
                                </ul>  
                </div>
              </div>
            </div>
      
          </div> 
      
        </div>
      </div>
      </aside>
      </section>

      <section class="bg6 p-t-15 p-b-10">
        <div class="container p-lr-0">
          <div class="row">
            <div class="col-lg-12 col-xl-12 m-lr-auto">
              <div class="bg0 bor10 p-all-15 m-b-10 p-lr-15-sm">
                <div class="row">
      
                              <div class="col-lg-3 col-md-3" id="menu_side">
                    <div class="p-all-15 bg0">
      
      
                      <div class="p-b-18">
                        <p class="mtext-101 cl2 p-b-10">Kategori Toko</p>
                      </div>
                      <div class="scrollbar" id="ex4">
                        <div class="content">
                          <div id="cc">
                            <div class="flex-w flex-t bor12 p-b-13">
                              <ul id="tree2" class="tree">
                                                            <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-agama" >Buku Agama</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=agama-islam" >Agama Islam</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-pelajaran" >Buku Pelajaran</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-sd" >Buku SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-smp" >Buku SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-sma" >Buku SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-smk" >Buku SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-tk" >Buku TK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=perguruan-tinggi" >Perguruan Tinggi</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=administrasi" >Administrasi</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=akuntansi" >Akuntansi</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=at-a-glance" >At a Glance</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa-dan-sastra" >Bahasa &amp; Sastra</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ekonomi" >Ekonomi</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=hukum" >Hukum</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kedokteran" >Kedokteran</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=keperawatan" >Keperawatan</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=manajamen" >Manajamen</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=mipa" >MIPA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=arsitektur" >Arsitektur</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=psikologi" >Psikologi</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-umum" >Buku Umum</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=masakan-dan-minuman" >Masakan dan Minuman</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-soal" >Buku Soal</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=soal-sd" >Soal SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=soal-smp" >Soal SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=soal-sma" >Soal SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=komputer" >Komputer</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-tematik" >Buku Tematik</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=novel" >Novel</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-anak" >Buku Anak</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa--indonesia-sma" >Bahasa  Indonesia SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kurikulum-2013-sd" >Kurikulum 2013,K13N SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kurikulum-2013-smp" >Kurikulum 2013,K13N SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=agama-islam-sd" >Agama Islam SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-seri" >Buku Seri</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=creativity-series" >Creativity Series</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=cerita-anak" >Cerita Anak</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=edukasi-anak" >Edukasi Anak</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-anak-lainnya" >Buku Anak Lainnya</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=mainan-dan-hobi" >Mainan & Hobi</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=membaca-dan-menulis" >Membaca Dan Menulis</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=edukasi-anak" >Edukasi Anak</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-anak-lainnya" >Buku Anak Lainnya</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=akuntansi-sma" >Akuntansi SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=matematika-sd" >Matematika SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=antropologi-sma" >Antropologi SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=agama-islam-smp" >Agama Islam SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=agama-islam-sma" >Agama Islam SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=sma-kurikulum-2013-/-k13n" >SMA Kurikulum 2013 / K13N</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=matematika-smp" >Matematika SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=biologi-sma" >Biologi SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ekonomi-sma" >Ekonomi SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=geografi-sma" >Geografi SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=fisika-smp" >Fisika SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa--indonesia-smk" >Bahasa  Indonesia SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=keterampilan-anak" >Keterampilan Anak</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=matematika-sma" >Matematika SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=fisika-sma" >Fisika SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kimia-smp" >Kimia SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kimia-sma" >Kimia SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-budaya-sd" >Seni Budaya SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=biologi-smk" >Biologi SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-budaya-sma" >Seni Budaya SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kewarganegaraan-smp" >Kewarganegaraan SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=sejarah-sma" >Sejarah SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=penjas-smp" >Penjas SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kewarganegaraan-sd" >Kewarganegaraan SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-rupa-sd" >Seni Rupa SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=gaya-hidup" >Gaya Hidup</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=tas" >Tas</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa--inggris-sma" >Bahasa  Inggris SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=menggambar-dan-mewarnai" >Menggambar Dan Mewarnai</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=smk-kurikulum-2013" >Kurikulum 2013,K13N SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=fisika-smk" >Fisika SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa-dan-sastra-smp" >Bahasa & Sastra SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa--inggris-smk" >Bahasa  Inggris SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kamus-dan-bahasa" >Kamus dan Bahasa</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-musik-smp" >Seni Musik SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=sejarah-smp" >Sejarah SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ipa-smk" >IPA SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ilmu-komputer-smk" >Ilmu Komputer SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kewarganegaraan-sma" >Kewarganegaraan SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-budaya-smp" >Seni Budaya SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kewarganegaraan-smk" >Kewarganegaraan SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kewirausahaan-smk" >Kewirausahaan SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kimia-smk" >Kimia SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-soal-ujian" >Buku Soal Ujian UN</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ensiklopedia" >Ensiklopedia</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=penjas-sd" >Penjas SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=manajemen" >Manajemen</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ti-dan-komunikasi-sd" >TI & Komunikasi SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=motivasi-dan-inspirasi" >Motivasi Dan Inspirasi</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=pendidikan-umum" >Pendidikan Umum</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=perti-lainnya" >PERTI Lainnya</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-musik-sma" >Seni Musik SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ti-dan-komunikasi-smp" >Ti & Komunikasi SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=matematika-smk" >Matematika SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=agama-islam-smk" >Agama Islam SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=komputer-akuntansi-smk" >Komputer Akuntansi SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=penjaskes-smk" >Penjaskes SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=soal-smk" >Soal SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bhs-inggris-smp" >Bhs Inggris SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=penjaskes-sma" >Penjaskes SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=fashion-dan-kecantikan" >Fashion dan kecantikan</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-umum-lainnya" >Buku Umum Lainnya</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seri-ulysses-moore" >Seri Ulysses Moore</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=kompetensi-inti-dan-kompetensi-dasar-(-kikd17-)" >Kompetensi Inti dan Kompetensi Dasar ( KIKD17 )</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=promo-buku-paket-25" >Promo Buku Paket 25%</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=perlengkapan-kantor" >Perlengkapan Kantor</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=novel-remaja" >Novel Remaja</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=novel-romance" >Novel Romance</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ips-sd" >IPS SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ipa-sd" >IPA SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa-inggris-sd" >Bahasa Inggris SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bilingual-sd" >Bilingual SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bahasa-indonesia-sd" >Bahasa Indonesia SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=sains-sd" >SAINS SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=plh-sd" >PLH SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=plbj-sd" >PLBJ SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=mandarin-sd" >MANDARIN SD</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ipa-smp" >IPA SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ips-smp" >IPS SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bimb-konseling-smp" >Bimb.Konseling SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bilingual-smp" >Bilingual SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=plh-smp" >PLH SMP</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bilingual-sma" >Bilingual SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bimb-konseling-sma" >Bimb.Konseling SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=bhs-mandarin-sma" >Bhs Mandarin SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=sosiologi-sma" >Sosiologi SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ti-dan-komunikasi-sma" >Ti & Komunikasi SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-rupa-sma" >Seni Rupa SMA</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=modul-smk" >Modul SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ips-smk" >IPS SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=seni-budaya-smk" >Seni Budaya SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=sejarah-smk" >Sejarah SMK</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=serba-15rb" >Buku Flat</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=atk-lainnya" >Lainnya</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=ebook" >eBook</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=majalah" >Majalah</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-sekolah" >Buku Sekolah</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                           <li class="0 branch">
                                    <a style="width: 80%;" href="https://www.eurekabookhouse.co.id/manufacturer/detail?manufacturer=buku-erlangga&category=buku-umum-elib" >Buku Umum</a>
                                    <ul id="tree1">
                                                                   </ul>
                                 </li>
                                                      </ul>  
                           </div>
                         </div>
                       </div>
                     </div>
      
      
      
                   </div>
                 </div>  
      
      
                 <div class="col-lg-9 col-md-9 bg0  p-tb-15"  id="content_side">
                  <div class="p-b-18">
                     
                                                                                                                                              
              <!-- parameter keyword -->
                                              
      
              <!-- parameter keyword -->
                      
      
              <!-- parameter category -->
                   
      
              <!-- parameter mall -->
              
              <!-- parameter author -->
                
      
              <!-- parameter manufacturer -->
                                                               
      
              <!-- parameter tag -->
               
      
              <!-- parameter min price & max price -->
                
      
      
              <!-- parameter min price & max price -->
                
      
              <!-- parameter diskon -->
               
              
      
                                  
                              <p><i class="fa fa-list-alt"></i> Pencarian untuk '<b style="color:red;">Buku Erlangga</b>' sebanyak <b style="color:red;">3140</b> item</p>
                                      
      
                              <div class="row">
                                
                     <input type="hidden" id="coloumn" value="4" />
      
      
      
      
      
      
                     <div class="col-lg-12 col-md-12 col-sm-12">
                         <div class="pull-right">
      
      <!-- 				 				<div class="icon-header-item cl0 hov-cl2 trans-04 p-r-11 p-l-10 open-panel" data-notify="2" id="js-show-left-side">
                                          <i class="fa fa-bars"></i>
                                      </div> -->
                                      <button type="button" id="hide_show_menu" class="btn btn-success hide-menu"><i class="fa fa-arrow-left" aria-hidden="true"></i></button>
                                      <!-- <button type="button" id="show_menu"  class="btn btn-warning hide_show_menu"><i class="fa fa-arrow-right" aria-hidden="true"></i></button> -->
                                      <button type="button" id="filter" class="btn btn-warning js-show-sidebar"><i class="fa fa-filter" aria-hidden="true"></i></button>
                                  </div>
                                  <div class="pull-left">
      
                                          
                                                                                                          
                                                                                                                                                                                                              
                                                                                                              
                                                                                                                  <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?sortby=ctime&manufacturer=buku-erlangga" class="btn btn-info" role="button" aria-pressed="true">Terbaru</a>
                                                                                                              
                                                                                                                  
                                                                                                                      <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?sortby=sales&manufacturer=buku-erlangga" class="btn btn-info" role="button" aria-pressed="true">Terlaris</a>
                                                                                                                  
      
                                                                                                                      
                                                                                                                          <a href="https://www.eurekabookhouse.co.id/manufacturer/detail?sortby=price&order=desc&manufacturer=buku-erlangga" class="btn btn-info" role="button" aria-pressed="true">Harga <i class="fa fa-sort-amount-desc"></i></a>
                                                                                                                      
                                                                                                                      
      
                                                                                                                      
      
                                                                                                              </div></div></div>

      
                                                                                                                          </div>
                  <div id="postList" class="row">
                                    
      
      
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22253" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/kartu-angka--huruf-hijaiah-erlangga-efk-eurekabookhouse.co.id" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2014005020.jpg" alt="kartu-angka-dan-huruf-hijaiah">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/kartu-angka--huruf-hijaiah-erlangga-efk-eurekabookhouse.co.id" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Kartu Angka & Huruf Hijaiah									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.65.000</small></s><strong class="cl13"> Rp.52.000</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22251" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/kartu-angka-erlangga-eurekabookhouse.co.id" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2014005000.jpg" alt="kartu-angka">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/kartu-angka-erlangga-eurekabookhouse.co.id" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Kartu Angka									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.65.000</small></s><strong class="cl13"> Rp.52.000</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22249" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/kartu-bentuk--warna-erlangga-eurekabookhouse.co.id" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2014005040.jpg" alt="kartu-bentuk-dan-warna">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/kartu-bentuk--warna-erlangga-eurekabookhouse.co.id" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Kartu Bentuk & Warna									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.65.000</small></s><strong class="cl13"> Rp.52.000</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22231" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/ips-smp-mts-kls7-km-erlangga-eurekabookhouse.co.id-N. Suparno-T.D. Haryo Tamtomo" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0033000640.jpg" alt="ips-smp-mts-kls-7-km">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/ips-smp-mts-kls7-km-erlangga-eurekabookhouse.co.id-N. Suparno-T.D. Haryo Tamtomo" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Ips Smp/Mts Kls.7/Km									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.98.000</small></s><strong class="cl13"> Rp.78.400</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22229" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/ips-smp-klsviii-k13n-revisi, erlangga, eurekabookhouse.co.id, T.D. Haryo Tamtomo-N. Suparno" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/81-33-077-2.jpg" alt="ips-smp-kls-viii-k13n-revisi">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/ips-smp-klsviii-k13n-revisi, erlangga, eurekabookhouse.co.id, T.D. Haryo Tamtomo-N. Suparno" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Ips Smp Kls.Viii/K13N/Revisi									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.111.000</small></s><strong class="cl13"> Rp.88.800</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22197" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/pend-agama-islam--budi-pekerti-sd-kls4-km-merdeka-erlangga-eurekabookhouse.co.id" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0022970440.jpg" alt="pend-agama-islam-dan-budi-pekerti-sd-kls-4-km">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/pend-agama-islam--budi-pekerti-sd-kls4-km-merdeka-erlangga-eurekabookhouse.co.id" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Pend. Agama Islam & Budi Pekerti Sd Kls.4/Km									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.62.000</small></s><strong class="cl13"> Rp.49.600</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22195" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/grow-with-english-4-km-merdeka-online-erlangga-eurekabookhouse.co.id-mukarto" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0024200350.jpg" alt="grow-with-english-4-km">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/grow-with-english-4-km-merdeka-online-erlangga-eurekabookhouse.co.id-mukarto" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Grow With English 4/Km									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.91.000</small></s><strong class="cl13"> Rp.72.800</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22193" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/esps-pendidikan-pancasila-sd-mi-kls4-km-merdeka-erlangga-dwi-tyas-eurekabookhouse.co.id" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0023230160.jpg" alt="esps-pendidikan-pancasila-sd-mi-kls-4-km">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/esps-pendidikan-pancasila-sd-mi-kls4-km-merdeka-erlangga-dwi-tyas-eurekabookhouse.co.id" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Esps Pendidikan Pancasila Sd/Mi Kls.4/Km									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.79.000</small></s><strong class="cl13"> Rp.63.200</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22191" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/esps-pendidikan-pancasila-sd-mi-kls1-km-merdeka-erlangga-buku-online-eurekabookhouse.co.id" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0023230130.jpg" alt="esps-pendidikan-pancasila-sd-mi-kls-1-km">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/esps-pendidikan-pancasila-sd-mi-kls1-km-merdeka-erlangga-buku-online-eurekabookhouse.co.id" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Esps Pendidikan Pancasila Sd/Mi Kls.1/Km									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.76.000</small></s><strong class="cl13"> Rp.60.800</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22189" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/esps-matematika-sd-mi-kls1-km-merdeka" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0025100700.jpg" alt="esps-matematika-sd-mi-kls-1-km">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/esps-matematika-sd-mi-kls1-km-merdeka" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Esps Matematika Sd/Mi Kls.1/Km									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.88.000</small></s><strong class="cl13"> Rp.70.400</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22167" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/esps-ipas-sd-mi-kls1-km" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0025000960.jpg" alt="esps-ipas-sd-mi-kls-1-km">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/esps-ipas-sd-mi-kls1-km" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Esps Ipas Sd/Mi Kls.1/Km									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.88.000</small></s><strong class="cl13"> Rp.70.400</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22165" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/pend-jasmani-orkes-smp-mts-kls7-km" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0036130190.jpg" alt="pend-jasmani-orkes-smp-mts-kls-7-km">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/pend-jasmani-orkes-smp-mts-kls7-km" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Pend. Jasmani Orkes Smp/Mts Kls.7/Km									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.97.000</small></s><strong class="cl13"> Rp.77.600</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22163" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/pend-agama-islam--budi-pekerti-smp-kls7-km" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0032970470.jpg" alt="pend-agama-islam-dan-budi-pekerti-smp-kls-7-km">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/pend-agama-islam--budi-pekerti-smp-kls7-km" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Pend. Agama Islam & Budi Pekerti Smp Kls.7/Km									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.81.000</small></s><strong class="cl13"> Rp.64.800</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22161" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/informatika-smp-mts-klsvii-km" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0030040040.jpg" alt="informatika-smp-mts-kls-vii-km">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/informatika-smp-mts-klsvii-km" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Informatika Smp/Mts Kls.Vii/Km									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.98.000</small></s><strong class="cl13"> Rp.78.400</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22159" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/bright-1-an-english-course-for-smp-mts-km" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0034200080.jpg" alt="bright-1-an-english-course-for-smp-mts-km">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/bright-1-an-english-course-for-smp-mts-km" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Bright 1 An English Course For Smp/Mts/Km									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.58.000</small></s><strong class="cl13"> Rp.46.400</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22157" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/bina-sejarah-keb-islam-jl4-klsvi-k2019-agama-islam, madrasah-pesantren" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0092970161.jpg" alt="bina-sejarah-keb-islam-jl-4-kls-vi-k2019">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/bina-sejarah-keb-islam-jl4-klsvi-k2019-agama-islam, madrasah-pesantren" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Bina Sejarah Keb. Islam Jl.4 Kls.Vi/K2019									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.53.000</small></s><strong class="cl13"> Rp.42.400</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22155" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/bina-sejarah-keb-islam-jl3-kls-v-k2019-islam-madrasah-pesantren" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0092970151.jpg" alt="bina-sejarah-keb-islam-jl-3-kls-v-k2019">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/bina-sejarah-keb-islam-jl3-kls-v-k2019-islam-madrasah-pesantren" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Bina Sejarah Keb. Islam Jl.3 Kls V/K2019									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.59.000</small></s><strong class="cl13"> Rp.47.200</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22153" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/bina-sejarah-keb-islam-jl1-klsiii-k2019-agama-islam, pesantren, madrasah" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0092970131.jpg" alt="bina-sejarah-keb-islam-jl-1-kls-iii-k2019">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/bina-sejarah-keb-islam-jl1-klsiii-k2019-agama-islam, pesantren, madrasah" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Bina Sejarah Keb. Islam Jl.1 Kls.Iii/K2019									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.64.000</small></s><strong class="cl13"> Rp.51.200</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22151" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls6-k2019-islam-agama-madrasah-pesantren" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0022970151.jpg" alt="bina-fiqih-mi-kls-6-k2019">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls6-k2019-islam-agama-madrasah-pesantren" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Bina Fiqih Mi Kls.6/K2019									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.49.000</small></s><strong class="cl13"> Rp.39.200</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22149" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls5-k2019-madrasah-islam-pesantren" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0022970141.jpg" alt="bina-fiqih-mi-kls-5-k2019">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls5-k2019-madrasah-islam-pesantren" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Bina Fiqih Mi Kls.5/K2019									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.51.000</small></s><strong class="cl13"> Rp.40.800</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22147" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls3-k2019-islam-agama-madrasah-pesantren" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0022970121.jpg" alt="bina-fiqih-mi-kls-3-k2019">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls3-k2019-islam-agama-madrasah-pesantren" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Bina Fiqih Mi Kls.3/K2019									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.81.000</small></s><strong class="cl13"> Rp.64.800</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22145" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls2-k2019-agama-islam-madrasah-pesantren" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0022970111.jpg" alt="bina-fiqih-mi-kls-2-k2019">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls2-k2019-agama-islam-madrasah-pesantren" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Bina Fiqih Mi Kls.2/K2019									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.51.000</small></s><strong class="cl13"> Rp.40.800</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22143" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/bina-belajar-al-quran--hadis-mi-kls6-k2019-agama-islam-madrasah" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0092970121.jpg" alt="bina-belajar-al-quran-dan-hadis-mi-kls-6-k2019">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/bina-belajar-al-quran--hadis-mi-kls6-k2019-agama-islam-madrasah" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Bina Belajar Al-Quran & Hadis Mi Kls.6/K2019									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.51.000</small></s><strong class="cl13"> Rp.40.800</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                                              <div class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5" id="22141" style="margin-bottom: 10px;">
                              <div class="block2 bg0">
                                  <div class="block2-pic hov-img0 label-new text-center" data-label="20%">
                                      <div class="label-original badge badge-primary">100% Original</div>
                                      <img class="img-official" src="https://www.eurekabookhouse.co.id/assets/image/mall_badge.png">
                                      <a href="https://www.eurekabookhouse.co.id/product/bina-belajar-al-quran--hadis-mi-kls5-k2019-madrasah-agama-islam" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                          <img class="lazy" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0092970111.jpg" alt="bina-belajar-al-quran-dan-hadis-mi-kls-5-k2019">
                                      </a>
                                                                                                      </div>
                                  <div class="block2-txt flex-w flex-t p-all-8">
                                      <div class="block2-txt-child1 flex-col-l">
                                          <a href="https://www.eurekabookhouse.co.id/product/bina-belajar-al-quran--hadis-mi-kls5-k2019-madrasah-agama-islam" class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6">
                                              Bina Belajar Al-Quran & Hadis Mi Kls.5/K2019									</a>
                                          <div class="stext-105">
                                                                                          <s><small class="fs-9">Rp.57.000</small></s><strong class="cl13"> Rp.45.600</strong>																			</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                       
                                                                  <div class="show_more_main" id="show_more_main22141">
                                  <button id="22141" class="show_load_more flex-c-m stext-101 cl0 size-103 bg1 bor1 hov-btn1 p-lr-15 trans-04" style="margin: 0px auto;">
                                      Lainnya
                                  </button>
                                  <span class="loding" style="display: none;"><span class="loding_txt">Loading...</span></span>
                              </div>
                           
                                                  </div>
                </div>     
                
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>   
      
</div>
</template>

<script scope>
    export default {
      name: "detailprodukPage",
    }
    </script>
    
