<template>
        <div>
            <div class="bg1">
                <div class="">
                    <div class="p-t-3 p-b-5">
                        <nav class="">
                            <router-link :to="'/Blog/pejuang'" class="center">
                                <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                                    alt="IMG-LOGO" class="center">
                            </router-link>
                        </nav>
                    </div>
                </div>
            </div>
                <section class="bg0 p-b-20 text-left">
                    <div class="container p-lr-80-lg">
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-10">
                                <h1 class="ltext-102 cl2 p-t-28">
                                    MAU FRIED CHICKEN - JUARA 1 LOMBA CERPEN </h1>
                                <div class="flex-m flex-sb m-tb-15">
                                    <div class="flex-i flex-w flex-m stext-111 cl2">
                                        <span>
                                            <span class="cl4">Oleh</span> Eureka
                                            <span class="cl12 m-l-4 m-r-6">|</span>
                                        </span>
                                        <span>
                                            12 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                                        </span>
                                        <div id="share" class="m-l-50-lg">
                                            <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                                href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/mau-fried-chicken---juara-1-lomba-cerpen"
                                                target="_blank"><i class="fa fa-facebook"></i></a>
                                            <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                                href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/mau-fried-chicken---juara-1-lomba-cerpen&amp;text=Blog: MAU FRIED CHICKEN - JUARA 1 LOMBA CERPEN &amp;hashtags=eurekabookhouse"
                                                title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                            <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                                href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/mau-fried-chicken---juara-1-lomba-cerpen"
                                                title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                                        </div>
                                    </div>
                                    <div class="flex-r-m">
                                        <span class="stext-107 text-center"><i class="fa fa-eye"></i> 1056</span>
                                    </div>
                                </div>
                                <div class="wrap-pic-w how-pos5-parent">
                                    <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/1-min.jpg" alt="IMG-BLOG"
                                        class="blog-read-pic">
                                    <div class="flex-col-c-m size-123 bg9 how-pos5">
                                        <span class="ltext-107 cl2 txt-center">
                                            12 </span>
                
                                        <span class="stext-109 cl3 txt-center">
                                            11 2022 </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1"></div>
                        </div>
                        <div class="row p-t-35 p-b-80">
                            <div class="col-lg-2">
                            </div>
                            <div class="col-md-8 col-lg-8">
                                <div class="p-r-0-lg">
                                    <div>
                                        <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                                            <p>Di sebuah Kota Metropolitan<em>, </em>kemacetan lalu lintas menjadi pemandangan biasa
                                                bagi Pak Udin. Lelaki bertubuh kurus dan berkulit hitam itu setiap hari menelusuri
                                                jalanan bersama gerobaknya. Langkah kakinya berhenti, setiap kali melihat tempat sampah.
                                                Ia mencari barang rongsok seperti botol, kaleng, ember plastik pecah, koran, dan barang
                                                bekas lainnya. Tidak jarang, kakinya terluka akibat terkena pecahan beling di jalan.
                                                Sendal yang ia pakai tidak dapat melindungi kakinya. Maklum saja, sendal semata wayang
                                                itu sudah cacat disetiap sudut, dan sudah layak diwafatkan tapi masih saja dipakai.
                                                Jangankan untuk membeli sendal, baju dan celana yang ia miliki juga hanya beberapa
                                                pasang, dengan dasar kain yang sudah sobek disana sini.</p>
                
                                            <p>Pak Udin setiap hari selalu berangkat pagi, dan pulang hingga larut malam. Tidak lupa
                                                sebelum pulang, ia menukarkan barang bekas tersebut dengan rupiah di tempat pengepul
                                                rongsokan yang tidak jauh dari kediamannya. Penghasilan pria berusia 45 tahun itu jarang
                                                mencapai puluhan ribu per hari. Uang yang didapat selalu ia berikan kepada istrinya
                                                untuk kebutuhan makan sehari-hari, bagi keluarga kecil yang telah dikaruniai dua buah
                                                hati. Azam, nama anak pertama Pak Udin sudah berusia 10 tahun, dan anak keduanya bernama
                                                Asih berusia 5 tahun.</p>
                
                                            <p>&ldquo;Mas, mulai besok Tini dapat kerjaan, setiap dua hari sekali nyuci dan gojok baju
                                                di rumah ibu RT. Azam kan sekolah, sementara Asih bagaimana kalau pagi ikut mas dulu
                                                naik gerobak, dan siang bisa mas antar pulang&rdquo; ujar istri Pak Udin.</p>
                
                                            <p>&ldquo;Bagaimana Asih, kamu mau tidak ikut bapak jalan-jalan naik gerobak, kita cari
                                                rongsokan?&rdquo;</p>
                
                                            <p>&ldquo;Asih mau ikut Ayah. Asih juga bosan main di rumah terus&rdquo;</p>
                
                                            <p>&ldquo;Baiklah kalau begitu&rdquo;</p>
                
                                            <p>Esok harinya, Asih begitu riang duduk di atas gerobak yang didorong Ayahnya. Asih juga
                                                senang menggunakan jilbab, selain makin terlihat cantik, juga melindungi kepalanya dari
                                                angin,&nbsp;sinar matahari, dan debu. Saat di perjalanan, pandangan Asih terfokus pada
                                                sebuah restoran makanan cepat saji.</p>
                
                                            <p>&ldquo;Ayah...&rdquo; suara Asih memanggil.</p>
                
                                            <p>&ldquo;Iya Asih, ada apa?&rdquo;</p>
                
                                            <p>&ldquo;Asih mau...&rdquo;</p>
                
                                            <p>&ldquo;Asih mau apa?&rdquo;</p>
                
                                            <p>Asih terdiam, dia ragu-ragu untuk berkata.</p>
                
                                            <p>&ldquo;Asih mau pipis?&rdquo;</p>
                
                                            <p>Asih menggeleng kepala, ayahnya bertanya lagi. &ldquo;Asih lapar?&rdquo;</p>
                
                                            <p>Asih mengangguk, Pak Udin lalu mengambil kotak bekal makanan yang sudah disiapkan
                                                istrinya. Tapi Asih menolak untuk makan pisang rebus, ubi goreng, dan singkong goreng
                                                yang ada di dalam kotak bekal tersebut.</p>
                
                                            <p>&ldquo;Katanya lapar, kenapa gak mau makan?&rdquo;</p>
                
                                            <p>&ldquo;Asih mau itu?&rdquo; ucap Asih sambil menunjuk banner bergambar ayam goreng yang
                                                terpasang di depan sebuah restoran.</p>
                
                                            <p>&ldquo;Itu apa?&rdquo;</p>
                
                                            <p>&ldquo;Fried Chicken&rdquo;</p>
                
                                            <p>&ldquo;Kok Asih sudah bisa baca?&rdquo;</p>
                
                                            <p>&ldquo;Ih.. ayah ini. Asih tau itu, kan sering muncul iklannya di tv (televisi)&rdquo;
                                            </p>
                
                                            <p>&ldquo;Tapi uang ayah belum cukup Asih untuk beli itu&rdquo;</p>
                
                                            <p>Muka Asih seketika cemberut.</p>
                
                                            <p>&ldquo;Sabar ya nak, ayah kumpulkan uang dulu. Ayah janji kalau uangnya sudah terkumpul,
                                                ayah akan belikan <em>Fried Chicken </em>untuk Asih dan kakak Azam&rdquo;</p>
                
                                            <p>&ldquo;Janji?&rdquo;</p>
                
                                            <p>&ldquo;Iya janji&rdquo;</p>
                
                                            <p>*</p>
                
                                            <p>Satu bulan kemudian, Pak Udin sudah mengumpulkan uang untuk membeli Fried Chicken, ia
                                                ingin menepati janjinya kepada Asih. Istrinya juga mengetahui rencana itu, Tini
                                                mendukung Pak Udin membelikan Fried Chicken untuk Asih dan Azam.</p>
                
                                            <p>&ldquo;Sekali-sekali kita senangin anak ya pak. Lagi pula ibu sudah gajian dari upah
                                                nyuci gosok di rumah bu RT. Alhamdulillah, rezeki kita bertambah untuk memenuhi
                                                kebutuhan rumah tangga ini&rdquo;</p>
                
                                            <p>&ldquo;Iya ibu terimakasih sudah membantu ayah mencari rezeki. Ayah dan Asih pamit pergi
                                                dulu ya, mau mencari barang rongsokan. Nanti kami pulang bawa Fried Chicken untuk makan
                                                siang bersama,&rdquo;</p>
                
                                            <p>&ldquo;Asik...&rdquo; sahut Azam.</p>
                
                                            <p>Ayah dan Asih bersalaman dengan Ibu dan Azam.</p>
                
                                            <p>&ldquo;Assalamualaikum&rdquo;</p>
                
                                            <p>&ldquo;Walaikumsalam&rdquo;</p>
                
                                            <p>*</p>
                
                                            <p>&ldquo;Asih, tunggu disini ya. Duduk di dalam gerobak saja, jangan kemana-mana&rdquo;
                                                ujar Pak Udin.</p>
                
                                            <p>&ldquo;Hati-hati ayah&rdquo;</p>
                
                                            <p>Pak Udin berjalan kaki menyebrangi jalan raya, menuju restoran makanan cepat saji untuk
                                                membeli Fried Chicken. Asih duduk di dalam gerobak, pandangannya tidak terlepas dari
                                                pintu restoran, tidak sabar menunggu ayahnya keluar dari sana. Tidak lama, ayahnya
                                                keluar sambil membawa plastik berisi Fried Chicken. Sebelum menyebrangi jalan, Pak Udin
                                                melambaikan tangan ke arah Asih. Asih membalas lambaian tangan itu, sambil tersenyum
                                                riang, dan merasa sangat gembira melihat ayahnya membawa makanan yang sudah lama ia
                                                idam-idamkan. Namun, betapa terkejutnya Asih saat melihat ada sebuah mobil yang melaju
                                                kencang, lalu menyerempet tubuh ayahnya. Plastik berisi Fried Chicken itu terpental
                                                entah kemana, sementara Pak Udin jatuh tergeletak di aspal dengan tubuhnya yang terluka
                                                dan berdarah dibeberapa bagian. &ldquo;Ayah...&rdquo; Asih keluar dari gerobak, ia hanya
                                                bisa menangis dan berteriak histeris. Orang-orang di sekitar mengangkat tubuh ayahnya ke
                                                tepian jalan. Asih dituntun seseorang untuk menghampiri ayahnya. Ia langsung memeluk
                                                tubuh ayahnya, lalu menunggu hingga ambulans datang. (***)</p>
                
                                            <p><strong>Biodata Penulis</strong></p>
                
                                            <p>Citra Ayu Santhika, lahir di Bandar Lampung, 21 Juni 1993. Citra, begitu akrab disapa
                                                merupakan lulusan Politeknik Negeri Lampung. Penulis bisa dihubungi melalui
                                                email&nbsp;<a href="mailto:citra.ayusanthika@gmail.com">citra.ayusanthika@gmail.com</a>,
                                                dan Instagram @citra.ayusanthika. Tidak perlu menjadi manusia yang terus hidup hingga
                                                tak bisa mati. Tapi buatlah karya yang bermakna, meski raga sudah tiada.</p>
                                        </div>
                                    </div>
                                    <div class="media ava-bloger">
                                        <img class="mr-3 rounded-circle img-thumbnail"
                                            src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                                            alt="Generic placeholder image">
                                        <div class="media-body">
                                            <p class="stext-101 m-b-8">DITULIS OLEH</p>
                                            <h5 class="mt-0 mtext-111">Eureka Writer</h5>
                                            <p class="badge badge-primary">Content Writer</p>
                                            <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section class="sec-relate-product bg6 p-t-20 text-left">
                    <div class="container p-tb-10">
                        <div class="p-b-15">
                            <h3 class="session-title mtext-106 cl5">New Entry</h3>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <a
                                            href="https://www.eurekabookhouse.co.id/blog/read/kisah-kasih---juara-favorit-10-lomba-cerpen-eureka-bookhouse">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                                        </a>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                        </div>
                                        <h4 class="p-b-32">
                                            <a href="https://www.eurekabookhouse.co.id/blog/read/kisah-kasih---juara-favorit-10-lomba-cerpen-eureka-bookhouse"
                                                class="mtext-101 cl2 hov-cl1 trans-04">
                                                KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </a>
                                        </h4>
                                        <span class="stext-102 m-t-20">12 November 2022</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <a
                                            href="https://www.eurekabookhouse.co.id/blog/read/rahasia-delia---juara-favorit-9-lomba-cerpen-eureka-bookhouse">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                                        </a>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                        </div>
                                        <h4 class="p-b-32">
                                            <a href="https://www.eurekabookhouse.co.id/blog/read/rahasia-delia---juara-favorit-9-lomba-cerpen-eureka-bookhouse"
                                                class="mtext-101 cl2 hov-cl1 trans-04">
                                                RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </a>
                                        </h4>
                                        <span class="stext-102 m-t-20">12 November 2022</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <a
                                            href="https://www.eurekabookhouse.co.id/blog/read/pohon-mangga-itu-berbuah-kembali---juara-favorit-8-lomba-cerpen-eureka-bookhouse">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                                        </a>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                        </div>
                                        <h4 class="p-b-32">
                                            <a href="https://www.eurekabookhouse.co.id/blog/read/pohon-mangga-itu-berbuah-kembali---juara-favorit-8-lomba-cerpen-eureka-bookhouse"
                                                class="mtext-101 cl2 hov-cl1 trans-04">
                                                POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE </a>
                                        </h4>
                                        <span class="stext-102 m-t-20">11 November 2022</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <a
                                            href="https://www.eurekabookhouse.co.id/blog/read/malaikat-penjagaku---juara-favorit-7-lomba-cerpen-eureka-bookhouse">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                                        </a>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                        </div>
                                        <h4 class="p-b-32">
                                            <a href="https://www.eurekabookhouse.co.id/blog/read/malaikat-penjagaku---juara-favorit-7-lomba-cerpen-eureka-bookhouse"
                                                class="mtext-101 cl2 hov-cl1 trans-04">
                                                MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </a>
                                        </h4>
                                        <span class="stext-102 m-t-20">11 November 2022</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <a
                                            href="https://www.eurekabookhouse.co.id/blog/read/kenangan-tentang-ayah---juara-favorit-6-lomba-cerpen-eureka-bookhouse">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                                        </a>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                        </div>
                                        <h4 class="p-b-32">
                                            <a href="https://www.eurekabookhouse.co.id/blog/read/kenangan-tentang-ayah---juara-favorit-6-lomba-cerpen-eureka-bookhouse"
                                                class="mtext-101 cl2 hov-cl1 trans-04">
                                                KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </a>
                                        </h4>
                                        <span class="stext-102 m-t-20">11 November 2022</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <a
                                            href="https://www.eurekabookhouse.co.id/blog/read/segenggam-tanah-merah---juara-favorit-5-lomba-cerpen-eureka-bookhouse">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                                        </a>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                        </div>
                                        <h4 class="p-b-32">
                                            <a href="https://www.eurekabookhouse.co.id/blog/read/segenggam-tanah-merah---juara-favorit-5-lomba-cerpen-eureka-bookhouse"
                                                class="mtext-101 cl2 hov-cl1 trans-04">
                                                SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </a>
                                        </h4>
                                        <span class="stext-102 m-t-20">11 November 2022</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <a
                                            href="https://www.eurekabookhouse.co.id/blog/read/jejak-sandal-ayah---juara-favorit-4-lomba-cerpen-eureka-bookhouse">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                                        </a>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                                        </div>
                                        <h4 class="p-b-32">
                                            <a href="https://www.eurekabookhouse.co.id/blog/read/jejak-sandal-ayah---juara-favorit-4-lomba-cerpen-eureka-bookhouse"
                                                class="mtext-101 cl2 hov-cl1 trans-04">
                                                JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </a>
                                        </h4>
                                        <span class="stext-102 m-t-20">12 November 2022</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3 p-b-20">
                                <div class="blog-item bg0 bor8">
                                    <div class="blog-pic hov-img0">
                                        <a
                                            href="https://www.eurekabookhouse.co.id/blog/read/perjuangan-ayahku--untuk-masa-depanku---juara-favorit-3-lomba-cerpen-eureka-bookhouse">
                                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                                        </a>
                                    </div>
                                    <div class="p-all-15">
                                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                                href="https://www.eurekabookhouse.co.id/blog/category/acara-toko">Acara Toko</a></div>
                                        <h4 class="p-b-32">
                                            <a href="https://www.eurekabookhouse.co.id/blog/read/perjuangan-ayahku--untuk-masa-depanku---juara-favorit-3-lomba-cerpen-eureka-bookhouse"
                                                class="mtext-101 cl2 hov-cl1 trans-04">
                                                PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                                            </a>
                                        </h4>
                                        <span class="stext-102 m-t-20">12 November 2022</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <!-- Footer -->
                <footer class="bgu1 p-t-15 p-b-15">
                    <div class="container">
                        <div class="p-t-10">
                            <p class="stext-107 cl0 txt-center">
                                Copyright &copy; Dikembangkan oleh Eureka IT Developer
                            </p>
                        </div>
                    </div>
                </footer>
        </div>
</template>
<script>
export default {
    name: "friedPage",
}
</script>
