<template>
  <div class="no-scroll">
    <div class="row">
      <div
        class="col-12 col-md-7 d-none d-md-block"
        style="height: auto; padding-left: 0px"
      >
        <img
          src="../../assets/images/login/cek.jpg"
          alt="Left Logo"
          style="background-size: cover; height: 100%; width: 102%"
        />
      </div>
      <div
        class="col-12 col-md-5 login-background"
        style="background-color: red"
      >
        <div class="login-form">
          <img
            class="regis-left"
            src="../../assets/images/login/1.png"
            alt="Left Logo"
          />
          <img
            class="regis-right"
            src="../../assets/images/login/2.png"
            alt="Right Logo"
          />
          <div class="mt-5">
            <h1 style="color: white">Lupa Password</h1>
            <p class="sub-title">Masukkan email atau no.telp yang terdaftar</p>
            <br />
            <div class="login-box-body">
              <form class="form-signin" @submit.prevent="register">
                <span class="text-label">Email atau No. Telp</span>

                <input
                  class="text-form mt-3"
                  name="email"
                  type="email"
                  id="inputEmail"
                  placeholder="Masukan Email atau No. Telp"
                  value=""
                  required
                  v-model="email"
                />

                <div class="mt-3">
                  <button
                    class="button-submit btn btn-lg btn-primary btn-block btn-signin"
                    type="submit"
                  >
                    Lupa Password
                  </button>
                  <br />
                  <!-- <img
                        src="../../assets/images/login/atau.png"
                        style="width: 380px"
                        alt=""
                      /> -->
                  <span><b style="color: white">Atau masuk dengan </b></span>
                </div>
                <br />

                <div class="row mt-3">
                  <div class="col d-flex justify-content-center">
                    <img
                      src="../../assets/images/login/go.png"
                      style="width: 48px"
                      class="mr-3"
                      alt=""
                    />
                    <img
                      src="../../assets/images/login/fb1.png"
                      style="width: 48px"
                      alt=""
                    />
                  </div>
                </div>
              </form>
              <!-- /form -->
            </div>
            <div style="margin-bottom: 0px">
              <br />

              <span style="color: white">Sudah punya akun? </span
              ><router-link :to="'/UserEBH/Login'"
                ><b style="color: #ffc341">Masuk</b></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script scope>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "forgotPage",
  data() {
    return {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
    };
  },
  methods: {
    register() {
      const apiUrl = "http://192.168.1.44:3000/customer/register";
      const requestBody = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        password: this.password,
      };

      axios
        .post(apiUrl, requestBody)
        .then((response) => {
          // Periksa status respons
          if (response.status === 200) {
            // Tangani respons dari server di sini
            // console.log(response.data);

            // Menampilkan SweetAlert untuk registrasi berhasil
            Swal.fire({
              confirmButtonColor: "#3860A8",
              icon: "success",
              title: "Registrasi Berhasil",
              text: "Anda telah berhasil mendaftar!",
            }).then(() => {
              // Redirect ke halaman login setelah menutup alert
              this.$router.push({ name: "login" });
            });
          } else {
            // Tangani kesalahan status respons di sini
            console.error(`Respons tidak berhasil, status: ${response.status}`);
            // Menampilkan SweetAlert untuk status respons tidak berhasil
            Swal.fire({
              confirmButtonColor: "#3860A8",
              icon: "error",
              title: "Registrasi Gagal",
              text: "Terjadi kesalahan saat registrasi. Silakan coba lagi.",
            });
          }
        })
        .catch((error) => {
          // Tangani kesalahan di sini
          console.error(error);

          // Menampilkan SweetAlert untuk kesalahan
          Swal.fire({
            confirmButtonColor: "#3860A8",
            icon: "error",
            title: "Registrasi Gagal",
            text: "Terjadi kesalahan saat registrasi. Silakan coba lagi.",
          });
        });
    },
  },
};
</script>
<style scoped>
.sub-title {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px;
  letter-spacing: -0.14px;
}
.button-submit {
  display: flex;
  height: 50px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: black;
  align-self: stretch;
  border-radius: 50px;
  background: #ffc341;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.logo-left {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 400px; /* Sesuaikan dengan ukuran gambar Anda */
}
.logo-right {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 400px; /* Sesuaikan dengan ukuran gambar Anda */
}
.regis-left {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 114px; /* Sesuaikan dengan ukuran gambar Anda */
}

.regis-right {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 114px; /* Sesuaikan dengan ukuran gambar Anda */
}
.login-form {
  background-color: #091d42;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
}
.text-form {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  background: var(--neutral-02, #efefef);
}
.text-label {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: -0.16px;
  float: left;
}
input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
}

button:hover {
  background-color: #0056b3;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.logo-left {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 400px; /* Sesuaikan dengan ukuran gambar Anda */
}
.no-scroll {
  overflow: hidden;
}

.login-background {
  background-image: url("../../assets/images/login/yaya.png");
  background-size: cover; /* Mengatur gambar latar belakang agar selalu menutupi seluruh area elemen */
  background-repeat: no-repeat; /* Menghindari pengulangan gambar latar belakang */
  background-position: center center; /* Mengatur posisi gambar latar belakang ke tengah */
  height: 100vh;
}

.logo-right {
  position: absolute;
  top: 0px;
  right: 0px;

  width: 400px; /* Sesuaikan dengan ukuran gambar Anda */
}

.regis-left {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 114px; /* Sesuaikan dengan ukuran gambar Anda */
}

.regis-right {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 114px; /* Sesuaikan dengan ukuran gambar Anda */
}
.login-form {
  background-color: #091d42;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
}
.text-form {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  background: var(--neutral-02, #efefef);
}
.text-label {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: -0.16px;
  float: left;
}
input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
}

button:hover {
  background-color: #0056b3;
}
</style>
