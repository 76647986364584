<template>
    <div>
<div>
    <div class="bg1">
        <div class="">
            <div class="p-t-3 p-b-5">
                <nav class="">
                    <a href="https://www.eurekabookhouse.co.id/" class="center">
                        <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                            alt="IMG-LOGO" class="center">
                    </a>
                </nav>
            </div>
        </div>
    </div>
    <section class="bg0 p-b-20 text-left">
        <div class="container p-lr-80-lg">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <h1 class="ltext-102 cl2 p-t-28">
                        Ini 7 Cara Meningkatkan Kecerdasan Linguistik pada Anak </h1>
                    <div class="flex-m flex-sb m-tb-15">
                        <div class="flex-i flex-w flex-m stext-111 cl2">
                            <span>
                                <span class="cl4">Oleh</span> Eureka
                                <span class="cl12 m-l-4 m-r-6">|</span>
                            </span>
                            <span>
                                18 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                            </span>
                            <div id="share" class="m-l-50-lg">
                                <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                    href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/ini-7-cara-meningkatkan-kecerdasan-linguistik-pada-anak"
                                    target="_blank"><i class="fa fa-facebook"></i></a>
                                <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                    href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/ini-7-cara-meningkatkan-kecerdasan-linguistik-pada-anak&amp;text=Blog: Ini 7 Cara Meningkatkan Kecerdasan Linguistik pada Anak &amp;hashtags=eurekabookhouse"
                                    title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                    href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/ini-7-cara-meningkatkan-kecerdasan-linguistik-pada-anak"
                                    title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                            </div>
                        </div>
                        <div class="flex-r-m">
                            <span class="stext-107 text-center"><i class="fa fa-eye"></i> 9921</span>
                        </div>
                    </div>
                    <div class="wrap-pic-w how-pos5-parent">
                        <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/Ini_7_Cara_Meningkatkan_Kecerdasan_Linguistik_pada_Anak_Eureka_Blog.jpg"
                            alt="IMG-BLOG" class="blog-read-pic">
                        <div class="flex-col-c-m size-123 bg9 how-pos5">
                            <span class="ltext-107 cl2 txt-center">
                                18 </span>
    
                            <span class="stext-109 cl3 txt-center">
                                11 2022 </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
            <div class="row p-t-35 p-b-80">
                <div class="col-lg-2">
                </div>
                <div class="col-md-8 col-lg-8">
                    <div class="p-r-0-lg">
                        <div>
                            <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                                <p>Setiap anak terlahir dengan keunikannya. Dalam dunia psikologi dan pendidikan, sebutan
                                    &ldquo;golden age&rdquo; sudah sangat poluler ketika membahas mengenai tumbuh kembang
                                    anak. Istilah &ldquo;golden age&rdquo; atau usia emas terjadi pada anak berusia 0-5
                                    tahun. Pada masa-masa tersebut otak anak akan sangat cepat memproses apapun yang ia
                                    lihat, dengar, dan pelajari. Di masa-masa ini merupakan peluang orang tua untuk
                                    mengenali dan mengembangkan kecerdasan yang dimiliki anak.</p>
    
                                <p>Terdapat beberapa jenis kecerdasan pada anak. Salah satunya adalah kecerdasan linguistik.
                                    Kecerdasan linguistik merupakan kecerdasan yang berkaitan dengan kemampuan anak dalam
                                    berbahasa, baik itu lisan maupun tulisan. Kecerdasan berbahasa merupakan hal yang sangat
                                    penting dimiliki oleh anak. Mengapa? Karena kecerdasan linguistik akan selalu digunakan
                                    anak seumur hidupnya saat ia bersosialisasi. Dengan melatih kecerdasan lingustiknya
                                    kemampuan anak dalam bersosialisasi baik dengan lisan maupun tulisan akan menjadi lebih
                                    mudah dan baik. Ini dia beberapa cara meningkatkan kecerdasan linguistik pada anak.</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>1. Bacakan Cerita yang Menarik</strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><img alt="Bacakan-Cerita-yang-Menarik-Eureka-Blog"
                                        src="https://i.ibb.co/wN2cTZ8/Bacakan-Cerita-yang-Menarik-Eureka-Blog.jpg"
                                        style="height:426px; width:640px" /></p>
    
                                <p>Hal pertama yang dapat dilakukan untuk meningkatkan kecerdasan lingustik pada anak adalah
                                    dengan meningkatkan minat bacanya terlebih dahulu. Hal ini dapat dilakukan dengan cara
                                    menceritakan buku-buku yang menarik. Orang tua dapat membuat jadwal membacakan cerita
                                    yang rutin untuk anak, misalnya membacakan cerita sebelum tidur. Dengan membacakan
                                    cerita, akan menumbuhkan rasa penasaran si anak untuk mendengar cerita-cerita
                                    berikutnya.</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>2. Berikan Buku Bacaan</strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><img alt="Berikan-Buku-Bacaan-Eureka-Blog"
                                        src="https://i.ibb.co/W23Y5pB/Berikan-Buku-Bacaan-Eureka-Blog.jpg" /></p>
    
                                <p>Langkah berikutnya untuk meningkatkan kecerdasan linguistik pada anak adalah dengan
                                    memberikan buku-buku bacaan yang menarik. Kenalkan sedini mungkin buku-buku kepada anak.
                                    Anda bisa mengajak anak Anda ke toko buku untuk memilih buku pilihannya sendiri.</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>3. Dorong Anak untuk Bercerita</strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><img alt="Dorong-Anak-untuk-Bercerita-Eureka-Blog"
                                        src="https://i.ibb.co/v4wwG37/Dorong-Anak-untuk-Bercerita-Eureka-Blog.jpg" /></p>
    
                                <p>Setelah anak memiliki buku bacaan yang ia pilih di toko buku, Anda dapat memantaunya.
                                    Pikikanlah cara-cara kreatif sehingga anak termotivasi untuk menyelesaikan buku
                                    bacaannya, misalnya dengan memberikan hadiah berupa stiker sebagai pembatas bukunya
                                    maupun <em>reward</em> menarik lainnya. Kemudian, buatlah kesepakatan dengan anak Anda
                                    untuk menentukan waktu dimana anak akan menceritakan kembali buku yang ia baca.</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>4. Sediakan Permainan Menarik </strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><img alt="Sediakan-Permainan-Menarik-Eureka-Blog"
                                        src="https://i.ibb.co/4mnG0hc/Sediakan-Permainan-Menarik-Eureka-Blog.jpg" /></p>
    
                                <p>Untuk meningkatkan kecerdasan lingustik pada anak bisa dilakukan dengan berbagai cara,
                                    salah satunya dengan permainan. Ada beberapa jenis permainan yang mengandalkan kemampuan
                                    penguasaan kosa kata, seperti kartu bergambar dengan kosa kata didalamnya, scrable,
                                    teka-teki silang, dan sebagainya.</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>5. Dorong Anak untuk Berani Bersosialisasi</strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><img alt="Dorong-Anak-untuk-Berani-Bersosialisasi-Eureka-Blog"
                                        src="https://i.ibb.co/2s0HLT9/Dorong-Anak-untuk-Berani-Bersosialisasi-Eureka-Blog.jpg" />
                                </p>
    
                                <p>Meningkatkan kecerdasan lingustik pada anak juga dapat dilakukan dengan cara mendorong
                                    anak untuk berani bersosialisai denga banyak orang. Ini dilakukan untuk meningkatkan
                                    intensitas si anak dalam berinteraksi. Dengan begitu kemampuan verbal anak akan
                                    meningkat.</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>6. Biasakan Anak untuk Menulis</strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><img alt="Biasakan-Anak-untuk-Menulis-Eureka-Blog"
                                        src="https://i.ibb.co/sbcPrbd/Biasakan-Anak-untuk-Menulis-Eureka-Blog.jpg" /></p>
    
                                <p>Kemampuan linguistik pada anak akan meningkat dengan menulis. Ajak anak untuk
                                    mengungkapkan perasaannya juga pengalamannya melalui kata-kata dalam bentuk tulisan.
                                    Anda dapat memberikan pilihan kepada anak untuk menulis apapun yang ia sukai, seperti
                                    menulis buku harian ataupun puisi.</p>
    
                                <p>&nbsp;</p>
    
                                <h2><strong>7. Berikan Contoh yang Baik </strong></h2>
    
                                <p>&nbsp;</p>
    
                                <p><img alt="Berikan-Contoh-yang-Baik-Eureka-Blog"
                                        src="https://i.ibb.co/JK0MZ5t/Berikan-Contoh-yang-Baik-Eureka-Blog.jpg" /></p>
    
                                <p>Seorang anak akan meniru apa yang orang tuanya lakukan. Oleh karena itu, orang tua
                                    menjadi kunci untuk meningkatkan kecerdasan linguistik pada anak. Anda dapat menggunakan
                                    bahasa yang baik dan sopan dalam berinteraksi dan bersosialisasi baik di dalam
                                    lingkungan keluarga maupun lingkungan luar rumah.</p>
                            </div>
                        </div>
                        <div class="media ava-bloger">
                            <img class="mr-3 rounded-circle img-thumbnail"
                                src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                                alt="Generic placeholder image">
                            <div class="media-body">
                                <p class="stext-101 m-b-8">DITULIS OLEH</p>
                                <h5 class="mt-0 mtext-111">Andaf Iman</h5>
                                <p class="badge badge-primary">Content Writer</p>
                                <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="sec-relate-product bg6 p-t-20 text-left">
        <div class="container p-tb-10">
            <div class="p-b-15">
                <h3 class="session-title mtext-106 cl5">New Entry</h3>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/kisahkasih'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/rahasiadelia'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/pohonmangga'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                                </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/malaikat'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/ttgayah'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/merah'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1"><a
                                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat
                                    Eureka</a>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/jejak'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/pejuang'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA
                                    BOOKHOUSE
                                </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">04 September 2022</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer class="bgu1 p-t-15 p-b-15">
        <div class="container">
            <div class="p-t-10">
                <p class="stext-107 cl0 txt-center">
                    Copyright &copy; Dikembangkan oleh Eureka IT Developer
                </p>
            </div>
        </div>
    </footer>
</div>
    </div>
</template>
<script>
export default {
    name: "fPage",
}
</script>