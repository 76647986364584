<template>
    <div>
        <div class="bg1">
            <div class="">
                <div class="p-t-3 p-b-5">
                    <nav class="">
                        <a href="https://www.eurekabookhouse.co.id/" class="center">
                            <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                                alt="IMG-LOGO" class="center">
                        </a>
                    </nav>
                </div>
            </div>
        </div>
        <section class="bg0 p-b-20 text-left">
            <div class="container p-lr-80-lg">
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                        <h1 class="ltext-102 cl2 p-t-28">
                            JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </h1>
                        <div class="flex-m flex-sb m-tb-15">
                            <div class="flex-i flex-w flex-m stext-111 cl2">
                                <span>
                                    <span class="cl4">Oleh</span> Eureka
                                    <span class="cl12 m-l-4 m-r-6">|</span>
                                </span>
                                <span>
                                    11 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                                </span>
                                <div id="share" class="m-l-50-lg">
                                    <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                        href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/jejak-sandal-ayah---juara-favorit-4-lomba-cerpen-eureka-bookhouse"
                                        target="_blank"><i class="fa fa-facebook"></i></a>
                                    <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                        href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/jejak-sandal-ayah---juara-favorit-4-lomba-cerpen-eureka-bookhouse&amp;text=Blog: JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE &amp;hashtags=eurekabookhouse"
                                        title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                    <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                        href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/jejak-sandal-ayah---juara-favorit-4-lomba-cerpen-eureka-bookhouse"
                                        title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                                </div>
                            </div>
                            <div class="flex-r-m">
                                <span class="stext-107 text-center"><i class="fa fa-eye"></i> 1927</span>
                            </div>
                        </div>
                        <div class="wrap-pic-w how-pos5-parent">
                            <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg" alt="IMG-BLOG"
                                class="blog-read-pic">
                            <div class="flex-col-c-m size-123 bg9 how-pos5">
                                <span class="ltext-107 cl2 txt-center">
                                    11 </span>
        
                                <span class="stext-109 cl3 txt-center">
                                    11 2022 </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div class="row p-t-35 p-b-80">
                    <div class="col-lg-2">
                    </div>
                    <div class="col-md-8 col-lg-8">
                        <div class="p-r-0-lg">
                            <div>
                                <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                                    <p>Saya&nbsp; bersekolah di salah satu sekolah berasrama yang bergengsi di Kota Malang.
                                        Saya&nbsp; menjadi satu dari lima puluh siswa yang beruntung karena dapat sekolah di
                                        sana meskipun bukan dari golongan kaya. Iya,&nbsp; saya&nbsp; mendapatkan beasiswa penuh
                                        di sekolah bercitra tersebut. Suatu hari ketika liburan semester tiba, parkiran asrama
                                        dipenuhi oleh mobil &nbsp;wali murid yang hendak menjemput&nbsp; putra/putrinya,
                                        termasuk&nbsp; ayah&nbsp; saya. Dari kejauhan&nbsp; saya sudah melihat tempat&nbsp;
                                        Ayah&nbsp; saya&nbsp; memarkir mobil. Karena mobilnya antik atau lebih tepatnya
                                        tua&nbsp; sehingga sangat mencolok jika bersanding dengan mobil-mobil&nbsp; mewah di
                                        sana.&nbsp; Ayah saya menyambut dengan hangat lalu bergegas keluar dari lingkungan
                                        asrama. Di perjalanan,&nbsp; ayah&nbsp; bercerita bahwa ia bertemu dengan kepala
                                        sekolah&nbsp; saya. Raut &nbsp;muka ayah&nbsp; berubah musam saat bercerita sehingga
                                        membuat&nbsp; saya&nbsp; penasaran akan kelanjutan ceritanya.&nbsp;</p>
        
                                    <p>&nbsp;</p>
        
                                    <p>Ayah&nbsp; berkata, &ldquo;Kepala sekolah kamu&nbsp; memandang remeh&nbsp; ayah&nbsp;
                                        karena&nbsp; ayah&nbsp; hanya mengenakan sandal jepit dan mobil tua. Beliau melengos
                                        ketika&nbsp; ayah&nbsp; hendak menjabat tangan beliau&rdquo;.</p>
        
                                    <p>Mendengar hal tersebut,&nbsp; hatiku&nbsp; terasa perih seperti&nbsp; kulit yang
                                        disayat.&nbsp; Ingin &nbsp;saya&nbsp; berbalik arah dan memaki siapapun yang telah
                                        membuat&nbsp; ayah&nbsp; saya&nbsp; sedih. Namun, niat gegabah itu&nbsp; saya&nbsp;
                                        urungkan.&nbsp;</p>
        
                                    <p>Saya&nbsp; tampilkan ekspresi tertawa kecil kepada&nbsp; ayah&nbsp; sambil berkata,
                                        &ldquo;Ayah juga sih, ada sandal bagus malah pake sandal jepit haha&rdquo;.</p>
        
                                    <p>Ayah&nbsp; saya&nbsp; yang sabar, bekerja keras demi mencukupi kebutuhan keluarga. Di
                                        bela-belain bekerja di bawah terik matahari dengan keringat yang berkucur di dahi, dan
                                        kulit yang kian gelap dan keriput.</p>
        
                                    <p>&nbsp;</p>
        
                                    <p>&ldquo;Demi&nbsp; Tuhan, &nbsp;saya&nbsp; tidak rela sedikit pun&nbsp; jika&nbsp; ada
                                        orang yang menyakiti hatinya!&rdquo;</p>
        
                                    <p>Saya&nbsp; telah membulatkan tekad bahwa&nbsp; saya&nbsp; akan menjadi siswa yang
                                        berprestasi dan membawa&nbsp; ayah&nbsp; naik ke atas panggung saat graduasi nanti.
                                        Dengan begitu,&nbsp; ayah&nbsp; akan bersalaman&nbsp; dengan kepala sekolah saya.
                                        Dan,&nbsp; saya&nbsp; akan&nbsp; menunjukkan&nbsp; kepada seisi dunia ini
                                        &nbsp;bahwa&nbsp; Ayah&nbsp; saya&nbsp; hebat karena telah mendidik putrinya menjadi
                                        hebat. Hari itu adalah hari perjuangan besar saya&nbsp; dimulai, dendam itu harus&nbsp;
                                        saya&nbsp; balaskan dengan cara yang benar, &ldquo;saya&nbsp; harus
                                        berprestasi.&rdquo;&nbsp; Saya&nbsp; mulai&nbsp; bertekad, belajar lebih&nbsp; kuat,
                                        berdoa lebih giat, &nbsp;dan tak lupa selalu menanamkan kebaikan agar terus melekat.</p>
        
                                    <p>18 Mei 2019, prosesi graduasi masa putih abu-abu saya&nbsp; dilaksanakan. Di sebuah hotel
                                        mewah dengan dekorasi&nbsp; glamor, tamu undangan yang berkelas menengah ke atas hadir
                                        guna menambah pesona kemewahan duniawi ini. Acara berlangsung sesuai skenario, mulai
                                        dari pembukaan, sambutan, tarian hingga acara puncak yang ditunggu-tunggu. Kami,
                                        wasudawan dan wisudawati mengenakan toga khas sekolah kami dengan riasan wajah yang
                                        lebih dari hari biasanya. Kami, satu angkatan dengan jumlah 134 siswa naik ke atas
                                        panggung yang luas itu, berdiri di atas tamu undangan dan wali murid untuk menyanyikan
                                        &nbsp;beberapa lagu spesial untuk&nbsp; ayah&nbsp; bunda kami. Setelah itu, momen puncak
                                        yang&nbsp; saya&nbsp; tunggu-tunggu. Hasil perjuangan saya selama lebih dari dua tahun
                                        ini.</p>
        
                                    <p>&nbsp;</p>
        
                                    <p>Suara menggelegar dari MC di aula&nbsp; yang&nbsp; megah itu, &ldquo;Inilah, peraih 3
                                        benar nilai ijazah tertinggi&nbsp; dari jurusan IPS&rdquo;. Bulu kuduk saya merinding
                                        serta jantung&nbsp; berdetak lebih kencang dari biasanya. Peraih nilai tertinggi ketiga
                                        telah disebutkan, lalu kedua, namun nama saya&nbsp; belum terpanggil.&nbsp; Saya&nbsp;
                                        mulai memajamkan mata untuk menetralkan rasa gugup&nbsp; sampai</p>
        
                                    <p>&nbsp;</p>
        
                                    <p>MC bersuara lagi, &ldquo;Selamat kepada Ananda Qori&rsquo;atul Septiavin putri dari Bapak
                                        Arifin sebagai peraih nilai ijazah tertinggi dengan perolehan nilai...&rdquo;.</p>
        
                                    <p>Mata&nbsp; saya&nbsp; mulai kabur menahan rasa haru ini. Namun,&nbsp; dengan&nbsp;
                                        samar&nbsp; saya&nbsp; melihat ayah &nbsp;dan&nbsp; ibu saya&nbsp; maju di atas panggung
                                        dengan rasa haru pula. Aula bak istana nan megah di pagi itu,&nbsp; diriuhkan oleh&nbsp;
                                        gemuruh&nbsp; tepuk tangan. Mereka bisa bangga&nbsp; dengan jabatan &nbsp;ataupun
                                        penghasilan yang berlimpah. Tetapi, mereka tidak bisa merasakan kebanggan ketika
                                        &nbsp;dapat maju di tengah kemegahan aula itu dengan menyandang orang tua&nbsp; dari
                                        siswa berprestasi. Hari itu, dengan mata kepada&nbsp; saya,&nbsp; saya&nbsp; melihat
                                        sang kepala sekolah itu &nbsp;menyerahkan penghargaan kepada&nbsp; saya&nbsp; dan
                                        mengucapkan selamat kepada orang tua&nbsp; saya.</p>
        
                                    <p>Dengan jelas, saya melihat ayah dan ibu saya bangga namun dengan sedikit gemetar.
                                        &nbsp;Saya bisa merasakan&nbsp; bahwa tempat ini terlalu mewah untuk&nbsp;
                                        keluarga&nbsp; kami, untuk &nbsp;seorang&nbsp; ayah dengan pekerjaan buruh harian lepas.
                                        Tiba-tiba&nbsp; ayah&nbsp; harus berdiri di depan ratusan orang berkelas yang menyandang
                                        pangkat dan jabatan&nbsp; atau&nbsp; bisa&nbsp; dibilang&nbsp; orang yang &nbsp;bukan
                                        sekedar orang. Di situlah&nbsp; saya&nbsp; membuktikan bahwa&nbsp; Ayah&nbsp; dan&nbsp;
                                        Ibu saya&nbsp; adalah yang terhebat di antara orang-orang hebat di sana.</p>
        
                                    <p>Awalnya,&nbsp; saya&nbsp; memang benci dengan kepada kepala sekolah saya&nbsp;
                                        karena&nbsp; hanya menilai seseorang dari fisik dan materi. Namun, kini&nbsp; saya&nbsp;
                                        berterima kasih karena setidaknya &nbsp;sakit hati itu terbalaskan dengan menyenangkan
                                        hati kedua orang tua&nbsp; saya,&nbsp; ayah&nbsp; dan&nbsp; ibu.</p>
        
                                    <p>Merekalah pelita hidup saya. Janji saya&nbsp; adalah&nbsp; tidak&nbsp; akan mengecewakan
                                        perjuangan&nbsp; ayah dan ibu.</p>
        
                                    <p>&ldquo;Percayalah, Yah, Bu,&nbsp; saya&nbsp; akan&nbsp; menjadi orang hebat,
                                        seperti&nbsp; ayah&nbsp; dan ibu yang telah menjadi orang tua&nbsp; yang super&nbsp;
                                        terhebat. Suatu hari nanti, tidak ada lagi pekerjaan keras dan di tempat panas seperti
                                        yang sekarang&nbsp; ayah&nbsp; dan ibu perjuangkan.&nbsp; Bersabarlah, beberapa
                                        &nbsp;tahun lagi, saya&nbsp; bangunkan istana untuk kita. Kita,&nbsp; orang yang
                                        dipandang biasa,&nbsp; namun tidak ada suatu kemustahilan untuk kelak menjadi orang yang
                                        luar biasa.&quot;</p>
        
                                    <p>&nbsp;</p>
        
                                    <p><strong>Penulis: QORI&rsquo;ATUL SEPTIAVIN</strong></p>
        
                                    <p><strong>IG: @QALV</strong></p>
                                </div>
                            </div>
                            <div class="media ava-bloger">
                                <img class="mr-3 rounded-circle img-thumbnail"
                                    src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                                    alt="Generic placeholder image">
                                <div class="media-body">
                                    <p class="stext-101 m-b-8">DITULIS OLEH</p>
                                    <h5 class="mt-0 mtext-111">Eureka Writer</h5>
                                    <p class="badge badge-primary">Content Writer</p>
                                    <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    <section class="sec-relate-product bg6 p-t-20 text-left">
        <div class="container p-tb-10">
            <div class="p-b-15">
                <h3 class="session-title mtext-106 cl5">New Entry</h3>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/kisahkasih'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/rahasiadelia'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/pohonmangga'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                                </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/malaikat'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/ttgayah'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/merah'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1"><a
                                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/jejak'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/pejuang'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                                </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">04 September 2022</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </div>
</template>
<script>
export default {
    name: "jejakPage",
}
</script>