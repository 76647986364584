<template>
  <div>
    <section
      class="bg-img1 txt-center p-lr-15 p-t-50 p-b-30"
      style="
        /*			background-image:url('');*/
        background-repeat: no-repeat;
        background-size: 100%;
        background-color: #f4d747;
      "
    >
      <h2 class="ltext-105 cl5 txt-center">Pengiriman</h2>
    </section>
    <section class="bg6 p-t-75 p-b-120 pengiriman">
      <div class="container">
        <div class="p-all-30 bg0 stext-107 text-left">
          <p>
            Kami berusaha agar pemesanan anda dapat segera diproses dan barang
            dapat dikirimkan secepat mungkin. Saat ini buku dan barang yang
            dijual di Toko Online kami sangat banyak dan mencapai ratusan ribu
            buku dan barang.
          </p>

          <p>
            Lama pengiriman yang tercantum adalah perkiraan kami dengan catatan
            semua buku yang Anda pesan dapat kami sediakan dari gudang kami.
            Dalam beberapa hal kadangkala bisa terjadi ketika anda melakukan
            order, buku yang Anda pesan ada, tetapi karena banyak pembeli lain
            yang membeli buku yang sama dan langsung membayar lunas maka stok
            gudang kami habis dan harus menunggu proses administrasi kembali.
            Hal ini dapat menyebabkan lama pengiriman bertambah. Oleh karena itu
            mohon bantuannya untuk segera melakukan proses pembayaran dan
            konfirmasi setelah Anda melakukan order.
          </p>

          <br /><br />
          <p class="mtext-101 cl2">TIPS BERBELANJA</p>

          <p>
            Setelah anda melakukan pemesanan silahkan lakukan konfirmasi
            pembayaran pada hari yang sama atau sebelum jam 15.00 wib, karena
            jika lewat dari jam 15.00 wib baik pemesanan maupun konfirmasi
            pembayaran, proses pemesanan akan kami lakukan keesokan harinya
          </p>
          <p>
            Berikan alamat yang jelas dan no telepon yang bisa di hubungi di
            field tempat pengiriman, lebih baik jika di berikan ciri-ciri lokasi
            tempat tinggal pengiriman pesanan anda. Karena ini akan memudahkan
            baik kurir kami maupun kurir ekspedisi yang sudah bekerjasama dengan
            kami
          </p>
          <p>
            Jika memang ada pesanan yang belum sampai, sampai dengan batas waktu
            yang di tentukan. Harap langsung mengkonfirmasikan kode belanja
            anda. Karena jika sudah lewat terlalu lama kami agak kesulitan untuk
            melakukan pelacakan pesanan anda
          </p>
          <p>
            Di mohon untuk mengaktifkan handphone anda selama proses pemesanan
            berlangsung, karena baik kurir maupun pihak ekspedisi kami selalu
            mengkonfirmasi pengiriman anda jika mengalami kesulitan dalam
            pencarian alamat anda. Jika Pemesan/Penerima belum menerima
            pesanannya padahal Lama Pengiriman sudah lewat, silahkan tanyakan
            kepada bagian Pengiriman melalui Customer Service kami di 0878 8833
            7555
          </p>

          <i>*belanja baru diproses setelah ada konfirmasi pembayaran</i><br />
          <i>*Pengiriman dihitung sejak pembayaran Anda lunas</i>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "shippingPage",
  components: {},
  data() {
    return {};
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  methods: {},
};
</script>

<style>
</style>