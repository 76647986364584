<template>
  <div>
    <div>
      <section
        class="bg-img1 txt-center p-lr-15 p-t-50 p-b-30"
        style="
          /*background-image:url('');*/
          background-repeat: no-repeat;
          background-size: 100%;
          background-color: #f4d747;
        "
      >
        <h2 class="ltext-105 cl5 txt-center">Return Policy</h2>
        <h5>Return Policy Eurekabookhouse</h5>
      </section>
      <section class="bg0 p-t-75 p-b-120">
        <div class="container">
          <div class="row p-b-50">
            <div class="stext-105 m-b-30 text-left">
              <ol>
                <li>
                  Periode pengembalian produk adalah 14 hari, terhitung dari
                  produk diterima dari tanggal kirim. Pastikan Anda masih berada
                  dalam periode pengembalian barang tersebut.
                </li>
                <li>
                  Semua produk bisa diretur, jika :
                  <ul>
                    <p>
                      - Produk cacat/rusak di bagian dalam (bukan di bagian
                      luar)
                    </p>
                    <p>
                      - Produk tidak sesuai dengan pesanan di awal
                      (ukuran/warna/bentuk)
                    </p>
                    <p>- Produk tidak original dan/atau produk kw</p>
                  </ul>
                </li>
                <li>
                  Semua produk bisa diretur, kecuali pengembalian produk dengan
                  alasan&nbsp;<strong>berubah pikiran</strong>&nbsp;dan<strong
                    >&nbsp;penukaran produk.
                  </strong>
                </li>
                <li>
                  Semua produk bisa diretur, kecuali produk dengan berat
                  <strong>lebih dari 50 kg</strong> dan/atau perlu treatment
                  khusus
                </li>
                <li>
                  Pengembalian produk atau pengajuan retur bisa ditolak jika
                  tidak sesuai dengan syarat dan ketentuan pengembalian produk
                  Eureka BookHouse. Kami akan memberikan konfirmasi dan alasan
                  kenapa pengajuan retur ditolak melalui email.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "returnPolicy",
  components: {},
  data() {
    return {};
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  methods: {},
};
</script>
  
  <style>
</style>