<template>
  <div>
    <div>
      <section
        class="bg-img1 txt-center p-lr-15 p-t-50 p-b-30"
        style="
          /*background-image:url('');*/
          background-repeat: no-repeat;
          background-size: 100%;
          background-color: #f4d747;
        "
      >
        <h2 class="ltext-105 cl5 txt-center">Official Store</h2>
        <h5>Daftar Official Store Eurekabookhouse</h5>
      </section>
      <section class="bg0 p-t-75 p-b-120">
        <div class="container">
          <hr />
          <p>APA ITU EUREKA OFFICIAL STORE</p>
          <hr />
          <p class="text-left">
            “Official Store merupakan ruang khusus yang disediakan bagi brand
            resmi atau pemegang hak merek yang sah untuk menjual produk mereka
            secara online melalui website Eureka Bookhouse.”
          </p>
          <hr />
          <p>SYARAT & KETENTUAN</p>
          <hr />
          <div class="row p-b-50">
            <div class="stext-105 m-b-30 text-left">
              <ol>
                <li>
                  Produk yang diperjualbelikan harus barang resmi dan 100%
                  original dengan kondisi baru.
                </li>
                <li>
                  Toko merek resmi, penjual resmi, atau toko yang memegang
                  lisensi penunjukan resmi untuk berjualan dengan menggunakan
                  nama brand terkait.
                </li>

                <li>
                  Memiliki dokumen-dokumen legal yang dibutuhkan :

                  <ul>
                    <p>- NPWP Perusahaan*</p>
                    <p>- KTP Pemilik Perusahaan*</p>
                    <p>- SIUP (Surat Izin Usaha Perdagangan)*</p>
                    <p>- Hak Merk Dagang (HAKI)*</p>
                    <p>
                      SPPKP (Surat Pengukuhan Perusahaan Kena Pajak) jika
                      perusahaan kena pajak*
                    </p>
                    <p>
                      - Akta Perusahaan/Anggaran Dasar Terakhir Perusahaan
                      (optional)
                    </p>
                    <p>- SKT (Surat Keterangan Terdaftar) (optional)</p>
                  </ul>
                </li>
                <li>
                  Memiliki setidaknya 10 produk dengan sku yang berbeda-beda.
                </li>
                <li>Bersedia membayarkan segala biaya layanan yang berlaku.</li>
                <li>
                  Menyetujui segala syarat & ketentuan Eureka Bookhouse yang
                  tertera pada halaman ini
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "daftarOfficial",
  components: {},
  data() {
    return {};
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  methods: {},
};
</script>
  
  <style>
</style>