<template>
  <div>
    <section class="bg6 p-t-15 p-b-10">
      <form id="form-cart" class="p-b-85">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-xl-3 m-lr-auto m-b-50 d-none d-lg-block">
              <sidebarPage />
            </div>

            <div class="col-lg-10 col-xl-9 m-lr-auto m-b-50 text-left">
              <div
                class="mt-5 p-all-15 m-b-10 p-lr-15-sm cl0"
                style="border-radius: 15px; background-color: #091d42"
              >
                <h4 class="stext-110">
                  <i class="fa fa-truck"></i> Akun anda aktif
                </h4>
                <p>Tidak terjadi masalah dengan akun anda</p>
                <p>Kode User: 328493AS3</p>
              </div>
              <div
                class="bg0 bor10 p-all-15 m-b-10 p-lr-14-sm"
                style="min-height: 240px; border-radius: 15px"
              >
                <p class="mtext-101 cl2 p-b-10">Metode Pembayaran</p>
                <div class="text-center m-t-30">
                  <i class="fa fa-credit-card fa-2x"></i>
                  <p>Anda belum memiliki metode pembayaran</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>
<script>
import sidebarPage from "@/components/Users/sidebar.vue";
export default {
  name: "metodebayarPage",
  components: {
    sidebarPage,
  },
};
</script>
