<template>
  <div>
    <section
      class="bg-img1 txt-center p-lr-15 p-t-50 p-b-30"
      style="
        /*background-image:url('');*/
        background-repeat: no-repeat;
        background-size: 100%;
        background-color: #f4d747;
      "
    >
      <h2 class="ltext-105 cl5 txt-center">Lowongan Kerja</h2>
    </section>
    <section class="bg0 p-t-75 p-b-120">
      <div class="container">
        <div class="row p-b-50">
          <div class="col-md-7 col-lg-8">
            <div class="p-t-7 p-r-85 p-r-15-lg p-r-0-md text-left">
              <h3 class="mtext-111 cl2 p-b-16">
                1. Android Developer (React-Native/Java)
              </h3>

              <p class="stext-113 cl5 p-b-26"></p>
              <h5><strong>Tanggung jawab:</strong></h5>

              <ul>
                <li>
                  - Mampu menerjemahkan dan merancang mock-up storyboard ke kode
                </li>
                <li>
                  - Mampu mengevaluasi dan mengintegrasikan API dan SDK Pihak
                  Ketiga
                </li>
                <li>
                  - Mampu membantu merancang, membangun, dan mengintegrasikan
                  REST API
                </li>
              </ul>
              <p></p>
              <p class="stext-113 cl5 p-b-26"></p>
              <h5><strong>Persyaratan:</strong></h5>
              <ul>
                <li>
                  - Kandidat harus memiliki setidaknya Diploma, Gelar Sarjana di
                  bidang apapun.
                </li>
                <li>
                  - Kemampuan yang harus dimiliki:
                  <b>React Native/ Java/ Kotlin, SQLite, XML, JSON </b>.
                </li>
                <li>- Menguasai dasar GIT dan mampu bekerja dalam Team.</li>
                <li>
                  - Setidaknya memiliki 2&nbsp;tahun pengalaman belajar dan
                  kerja dalam bidang yang sesuai untuk posisi ini.
                </li>
                <li>
                  - Lebih disukai Pegawai (non-manajemen &amp; non-supervisor)
                  khusus dalam IT/Komputer - Perangkat Lunak atau setara.
                </li>
                <li>- Update: 20 Aug 2024.</li>
              </ul>
              <p></p>
            </div>
          </div>

          <div class="col-11 col-md-5 col-lg-4 m-lr-auto">
            <div class="how-bor1">
              <div class="hov-img0" style="height: 100% !important">
                <img
                  src="https://www.eurekabookhouse.co.id/assets/front/images/androDev.gif"
                  alt="IMG"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="order-md-2 col-md-7 col-lg-8 p-b-30">
            <div class="p-t-7 p-l-85 p-l-15-lg p-l-0-md text-left">
              <h3 class="mtext-111 cl2 p-b-16">
                2. iOS Developer (Swift/React-Native)
              </h3>

              <p class="stext-113 cl5 p-b-26"></p>
              <h5><strong>Tanggung jawab:</strong></h5>

              <ul>
                <li>
                  - Mampu menerjemahkan dan merancang mock-up storyboard ke kode
                </li>
                <li>
                  - Mampu mengevaluasi dan mengintegrasikan API dan SDK Pihak
                  Ketiga
                </li>
                <li>
                  - Mampu membantu merancang, membangun, dan mengintegrasikan
                  REST API
                </li>
              </ul>
              <p></p>
              <p class="stext-113 cl5 p-b-26"></p>
              <h5><strong>Persyaratan:</strong></h5>
              <ul>
                <li>
                  - Kandidat harus memiliki setidaknya Diploma, Gelar Sarjana di
                  bidang apapun.
                </li>
                <li>
                  - Kemampuan yang harus dimiliki:
                  <b>React-Native, Swift, XML, JSON, SQL, CRUD. </b>
                </li>
                <li>- Menguasai dasar GIT dan mampu bekerja dalam Team.</li>
                <li>
                  - Setidaknya memiliki 2 tahun pengalaman belajar dan kerja
                  dalam bidang yang sesuai untuk posisi ini.
                </li>
                <li>
                  - Lebih disukai Pegawai (non-manajemen &amp; non-supervisor)
                  khusus dalam IT/Komputer - Perangkat Lunak atau setara.
                </li>
                <li>- Update: 20 Aug 2024.</li>
              </ul>
              <p></p>
            </div>
          </div>

          <div class="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
            <div class="how-bor2">
              <div class="hov-img0" style="height: 100% !important">
                <img
                  src="https://www.eurekabookhouse.co.id/assets/front/images/iOSdev.gif"
                  alt="IMG"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row p-b-50">
          <div class="col-md-7 col-lg-8">
            <div class="p-t-7 p-r-85 p-r-15-lg p-r-0-md text-left">
              <h3 class="mtext-111 cl2 p-b-16">
                3. Web Developer (CodeIgniter/Laravel/ReactJS)
              </h3>

              <p class="stext-113 cl5 p-b-26"></p>
              <h5><strong>Tanggung jawab:</strong></h5>

              <ul>
                <ul>
                  <li>
                    - Membuat sistem informasi berbasis website menggunakan PHP
                    sesuai dengan rancangan yang telah dibuat
                  </li>
                  <li>
                    - Melakukan troubleshooting jika ada bug atau kesalahan
                    program
                  </li>
                  <li>
                    - Melakukan pencatatan / dokumentasi yang jelas atas
                    kegiatan programming yang dikerjakan
                  </li>
                </ul>
                <p></p>
                <p class="stext-113 cl5 p-b-26"></p>
                <h5><strong>Persyaratan:</strong></h5>
                <ul>
                  <li>
                    - Kandidat harus memiliki setidaknya Diploma, Gelar Sarjana
                    di bidang apapun.
                  </li>
                  <li>
                    - Kemampuan yang harus dimiliki:
                    <b>ReactJS, CodeIgniter, Laravel, JSON, JQuery , CRUD. </b>
                  </li>
                  <li>
                    - Dapat membuat website dengan layout responsive/mobile
                    friendly.
                  </li>
                  <li>
                    - Setidaknya memiliki 1 tahun pengalaman belajar dan kerja
                    dalam bidang yang sesuai untuk posisi ini.
                  </li>
                  <li>
                    - Lebih disukai Pegawai (non-manajemen &amp; non-supervisor)
                    khusus dalam IT/Komputer - Perangkat Lunak atau setara.
                  </li>
                  <li>- Update: 20 Aug 2024.</li>
                </ul>
                <p></p>
              </ul>
            </div>
          </div>

          <div class="col-11 col-md-5 col-lg-4 m-lr-auto">
            <div class="how-bor1">
              <div class="hov-img0" style="height: 100% !important">
                <img
                  src="https://www.eurekabookhouse.co.id/assets/front/images/webdev.gif"
                  alt="IMG"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row p-b-50">
          <div class="col-11 col-md-5 col-lg-4 m-lr-auto">
            <div class="how-bor1">
              <div class="hov-img0" style="height: 100% !important">
                <img
                  src="https://www.eurekabookhouse.co.id/assets/front/images/desainer.gif"
                  alt="IMG"
                />
              </div>
            </div>
          </div>
          <div class="col-md-7 col-lg-8">
            <div class="p-t-7 p-r-85 p-r-15-lg p-r-0-md text-left">
              <h3 class="mtext-111 cl2 p-b-16">4. UI/UX Designer</h3>

              <p class="stext-113 cl5 p-b-26"></p>
              <h5><strong>Tanggung jawab:</strong></h5>

              <ul>
                <ul>
                  <li>
                    - Membuat sistem informasi berbasis website menggunakan PHP
                    sesuai dengan rancangan yang telah dibuat
                  </li>
                  <li>
                    - Melakukan troubleshooting jika ada bug atau kesalahan
                    program
                  </li>
                  <li>
                    - Melakukan pencatatan / dokumentasi yang jelas atas
                    kegiatan programming yang dikerjakan
                  </li>
                </ul>
                <p></p>
                <p class="stext-113 cl5 p-b-26"></p>
                <h5><strong>Persyaratan:</strong></h5>
                <ul>
                  <li>
                    - Kandidat harus memiliki setidaknya Diploma, Gelar Sarjana
                    di bidang apapun.
                  </li>
                  <li>
                    - Kemampuan yang harus dimiliki: React Js, JavaScript,
                    TypeScript, Node Js, HTML 5 dan CSS.
                  </li>
                  <li>
                    - Dapat membuat website dengan layout responsive/mobile
                    friendly.
                  </li>
                  <li>
                    - Setidaknya memiliki 1 tahun pengalaman belajar dan kerja
                    dalam bidang yang sesuai untuk posisi ini.
                  </li>
                  <li>
                    - Lebih disukai Pegawai (non-manajemen &amp; non-supervisor)
                    khusus dalam IT/Komputer - Perangkat Lunak atau setara.
                  </li>
                  <li>- Update: 20 Aug 2024.</li>
                </ul>
                <p></p>
              </ul>
            </div>
          </div>
        </div>
        <div class="row p-b-50">
          <div class="col-md-7 col-lg-8">
            <div class="p-t-7 p-r-85 p-r-15-lg p-r-0-md text-left">
              <h3 class="mtext-111 cl2 p-b-16">5. IT SEO Specialist</h3>

              <p class="stext-113 cl5 p-b-26"></p>
              <h5><strong>Tanggung jawab:</strong></h5>

              <ul>
                <li>
                  - Berpartisipasi dalam proses kreatif produk-produk Eureka
                  Digital (Aplikasi Selular dan Web Eureka Digital)
                </li>
                <li>
                  - Membuat dan mengelola spesifikasi desain dan elemen/set UI
                  untuk tim Developer.
                </li>
                <li>
                  - Berkolaborasi dengan tim Developer untuk menentukan
                  persyaratan teknis dan membantu mereka dalam proses
                  implementasi.
                </li>
                <li>
                  - Mampu membuat prototipe, wireframe dan mockup untuk banyak
                  aset UI Eureka Digital dengan Cepat.
                </li>
              </ul>
              <p></p>
              <p class="stext-113 cl5 p-b-26"></p>
              <h5><strong>Persyaratan:</strong></h5>
              <ul>
                <li>- Usia 20-30 Tahun.</li>
                <li>- Memiliki Portofolio.</li>
                <li>
                  - Mampu bekerjasama dalam tim dan lintas fungsional untuk
                  mendapatkan produk terbaik.
                </li>
                <li>
                  - Memiliki kemampuan mengoperasikan Adobe (Photoshop,
                  Illustrator, XD), HTML5/CSS
                </li>
                <li>
                  - Point Tambahan jika Anda mampu mengoperasikan Zepline dan
                  Marvel sera memiliki pengalaman yang terbukti dalam ilustrasi
                  digital.
                </li>
                <li>- Update: 20 Aug 2024.</li>
              </ul>
              <p></p>
            </div>
          </div>

          <div class="col-11 col-md-5 col-lg-4 m-lr-auto">
            <div class="how-bor1">
              <div class="hov-img0" style="height: 100% !important">
                <img
                  src="https://www.eurekabookhouse.co.id/assets/front/images/seo-analitycs.gif"
                  alt="IMG"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-12 text-center">
        <div class="hov-img0">
          <h6>LAMARAN DAPAT DIKIRIM KE EMAIL:</h6>
          <h4><strong>loker@eurekabookhouse.co.id</strong></h4>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
export default {
  name: "karirPage",
  components: {},
  data() {
    return {};
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  methods: {},
};
</script>
  
  <style>
</style>