<template>
    <div>
        <div class="bg1">
            <div class="">
                <div class="p-t-3 p-b-5">
                    <nav class="">
                        <router-link :to="'/Blog/pejuang'" class="center">
                            <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                                alt="IMG-LOGO" class="center">
                        </router-link>
                    </nav>
                </div>
            </div>
        </div>
        <section class="bg0 p-b-20 text-left">
            <div class="container p-lr-80-lg">
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                        <h1 class="ltext-102 cl2 p-t-28">
                            Buku SMP Best seller Januari 2019 </h1>
                        <div class="flex-m flex-sb m-tb-15">
                            <div class="flex-i flex-w flex-m stext-111 cl2">
                                <span>
                                    <span class="cl4">Oleh</span> Eureka
                                    <span class="cl12 m-l-4 m-r-6">|</span>
                                </span>
                                <span>
                                    17 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                                </span>
                                <div id="share" class="m-l-50-lg">
                                    <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                        href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/buku-smp-best-seller-januari-2019"
                                        target="_blank"><i class="fa fa-facebook"></i></a>
                                    <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                        href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/buku-smp-best-seller-januari-2019&amp;text=Blog: Buku SMP Best seller Januari 2019 &amp;hashtags=eurekabookhouse"
                                        title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                    <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                        href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/buku-smp-best-seller-januari-2019"
                                        title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                                </div>
                            </div>
                            <div class="flex-r-m">
                                <span class="stext-107 text-center"><i class="fa fa-eye"></i> 2786</span>
                            </div>
                        </div>
                        <div class="wrap-pic-w how-pos5-parent">
                            <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/0035100331.jpg" alt="IMG-BLOG"
                                class="blog-read-pic">
                            <div class="flex-col-c-m size-123 bg9 how-pos5">
                                <span class="ltext-107 cl2 txt-center">
                                    17 </span>
        
                                <span class="stext-109 cl3 txt-center">
                                    11 2022 </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div class="row p-t-35 p-b-80">
                    <div class="col-lg-2">
                    </div>
                    <div class="col-md-8 col-lg-8">
                        <div class="p-r-0-lg">
                            <div>
                                <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                                    <h2><a
                                            href="https://www.eurekabookhouse.co.id/product/detail/14895/matematika-smp-jl-2b-k13n"><strong>Matematika
                                                Smp Jl.2B/K13N</strong></a></h2>
        
                                    <p><strong><img alt=""
                                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0035100331.jpg"
                                                style="height:448px; width:312px" /></strong></p>
        
                                    <p>Buku Matematika SMP/MTs Jilid 2B ini disusun berdasarkan Kurikulum 2013 Edisi Revisi
                                        2016. Buku ini disusun untuk memenuhi kebutuhan peserta didik dalam mempelajari
                                        matematika secara&nbsp;<em>mudah</em>,&nbsp;<em>lengkap</em>,
                                        dan&nbsp;<em>berstruktur</em>.</p>
        
                                    <p><strong>Keunggulan produk:</strong></p>
        
                                    <p>Sistematika penyajian buku ini penjabarannya mengacu kepada
                                        prinsip&nbsp;<strong><em>belajar bermakna</em></strong>, yaitu belajar
                                        yang&nbsp;<em>mengutamakan pengertian&nbsp;</em>atau&nbsp;<em>pemahaman konsep</em>dan
                                        ditekankan kepada tiga hal penting dalam mempelajari dan memahami matematika, yaitu:</p>
        
                                    <ul>
                                        <li>Pengenalan&nbsp;<strong><em>fakta</em></strong>,
                                            penanaman&nbsp;<strong><em>konsep</em></strong>, dan
                                            pembuktian&nbsp;<strong><em>teorema&nbsp;</em></strong>atau&nbsp;<strong><em>rumus</em></strong>.
                                        </li>
                                        <li><strong><em>Penerapan konsep&nbsp;</em></strong>dan&nbsp;<strong><em>penggunaan
                                                    teorema&nbsp;</em></strong>atau&nbsp;<strong><em>rumus</em></strong>,
                                            disajikan dalam bentuk&nbsp;<em>contoh
                                                soal&nbsp;</em>dan&nbsp;<em>penyelesaiannya&nbsp;</em>yang dikemas
                                            secara&nbsp;<em>bervariasi</em>.</li>
                                        <li>Soal-soal latihan disajikan secara&nbsp;<strong><em>terstruktur</em></strong>,
                                            dimulai dari yang mudah sampai yang sulit, dan juga disertai dengan
                                            soal&nbsp;<strong><em>pemecahan masalah&nbsp;</em></strong>(<strong><em>problem
                                                    solving</em></strong>).</li>
                                        <li>Dalam setiap bab disajikan&nbsp;<strong>&ldquo;<em>Kegiatan
                                                    Siswa</em>&rdquo;&nbsp;</strong>yang
                                            berisi&nbsp;<em>pengalaman&nbsp;</em>peserta didik
                                            dalam&nbsp;<em>menemukan&nbsp;</em>atau&nbsp;<em>memperoleh
                                                konsep-konsep&nbsp;</em>maupun&nbsp;<em>sifat-sifat</em>dalam matematika sebagai
                                            pengertian dasar dalam mempelajari matematika.
                                            Sedangkan&nbsp;<strong>&ldquo;<em>Tugas Siswa</em>&rdquo;&nbsp;</strong>disajikan
                                            untuk penerapan konsep dalam&nbsp;<em>kontekstual
                                                pembelajaran&nbsp;</em>(<em>contextual teaching learning</em>)
                                            maupun&nbsp;<em>pemecahan masalah&nbsp;</em>(<em>problem solving</em>).</li>
                                    </ul>
        
                                    <p>&nbsp;</p>
        
                                    <h2><a
                                            href="https://www.eurekabookhouse.co.id/product/detail/15711/bupena-bhs-inggris-smp-mts-jl-2-k13n"><strong>Bupena
                                                Bhs Inggris Smp/Mts Jl.2/K13N</strong></a></h2>
        
                                    <p><strong><img alt=""
                                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-0033730061.jpg"
                                                style="height:415px; width:336px" /></strong></p>
        
                                    <p><strong>Bupena (Buku Penilaian Autentik) English for SMP/MTs Grade VIII </strong>adalah
                                        buku kerja berbasis aktivitas yang digunakan sebagai pendamping buku sekolah elektronik
                                        (BSE) yang diterbitkan oleh pemerintah. Buku ini membantu guru menilai aspek
                                        pengetahuan, keterampilan, dan sikap siswa selama proses pembelajaran. Selain itu, buku
                                        ini menggunakan penilaian autentik di mana kegiatan dilengkapi dengan rubrik penilaian,
                                        yang mengukur kompetensi siswa dan sejauh mana siswa mencapai tujuan pembelajaran.</p>
        
                                    <p><strong>&nbsp;Keunggulan Produk:</strong></p>
        
                                    <ul>
                                        <li>Menggunakan penilaian autentik (<em>authentic assessment</em>) dan pendekatan ilmiah
                                            (<em>scientific approach</em>)</li>
                                        <li>Mencakup tata bahasa dan ekspresi yang dibutukan dalam bercakap-cakap dan menulis
                                        </li>
                                        <li>Mencakup teks genre</li>
                                        <li>Tahapan aktivitas yang membimbing siswa untuk aktif berbicara dan menulis secara
                                            mandiri</li>
                                        <li>Mencakup refleksi diri mengenai kemajuan belajar dan rubrik penilaian</li>
                                        <li>Beberapa bab memiliki fitur <em>Culture Corner </em>yang menyajikan pengetahuan
                                            tentang materi dari sisi budaya.</li>
                                        <li>Terdapat soal evaluasi atau uji kompetensi pada akhir setiap bab.</li>
                                    </ul>
        
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                            <div class="media ava-bloger">
                                <img class="mr-3 rounded-circle img-thumbnail"
                                    src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                                    alt="Generic placeholder image">
                                <div class="media-body">
                                    <p class="stext-101 m-b-8">DITULIS OLEH</p>
                                    <h5 class="mt-0 mtext-111">Arif Hendras</h5>
                                    <p class="badge badge-primary">Content Writer</p>
                                    <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

<section class="bg0 p-b-20 text-left">
    <div class="container p-lr-80-lg">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <h1 class="ltext-102 cl2 p-t-28">
                    Lalui Februari dengan novel cinta nan romantis ini </h1>
                <div class="flex-m flex-sb m-tb-15">
                    <div class="flex-i flex-w flex-m stext-111 cl2">
                        <span>
                            <span class="cl4">Oleh</span> Eureka
                            <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <span>
                            17 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                        </span>
                        <div id="share" class="m-l-50-lg">
                            <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/lalui-februari-dengan-novel-cinta-nan-romantis-ini"
                                target="_blank"><i class="fa fa-facebook"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/lalui-februari-dengan-novel-cinta-nan-romantis-ini&amp;text=Blog: Lalui Februari dengan novel cinta nan romantis ini &amp;hashtags=eurekabookhouse"
                                title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                            <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/lalui-februari-dengan-novel-cinta-nan-romantis-ini"
                                title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                        </div>
                    </div>
                    <div class="flex-r-m">
                        <span class="stext-107 text-center"><i class="fa fa-eye"></i> 2827</span>
                    </div>
                </div>
                <div class="wrap-pic-w how-pos5-parent">
                    <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/valentines-day.jpg" alt="IMG-BLOG"
                        class="blog-read-pic">
                    <div class="flex-col-c-m size-123 bg9 how-pos5">
                        <span class="ltext-107 cl2 txt-center">
                            17 </span>

                        <span class="stext-109 cl3 txt-center">
                            11 2022 </span>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row p-t-35 p-b-80">
            <div class="col-lg-2">
            </div>
            <div class="col-md-8 col-lg-8">
                <div class="p-r-0-lg">
                    <div>
                        <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                            <p>Bulan kedua di tahun 2019, bulan februari yang identik dengan hari kasih sayang. Untuk
                                itu, berikut adalah daftar novel yang bisa jadi temanmu melalui februari dengan penuh
                                cinta.</p>

                            <p>&nbsp;</p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/15863/jika-kita-tak-pernah-jatuh-cinta">1.
                                        Jika Kita Tak Pernah Jatuh Cinta</a></strong></p>

                            <p><br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/1002211900074.jpg"
                                    style="height:250px; width:172px" /><br />
                                <br />
                                Jika kita tak pernah jatuh cinta, kita tak akan banyak belajar dari masa lalu. Bagaimana
                                ia mengajari kita untuk tetap kuat ketika hati terserak. Kita tak akan menjadi tangguh.
                                Jika kita tak pernah jatuh cinta, mungkinkah kita bisa lebih menghargai diri sendiri
                                dengan melepaskan dia yang selalu menyakiti? Jika kita tak pernah jatuh cinta, akankah
                                kita pernah merasa berharganya waktu bersama dengan seseorang yang kita cinta?
                                Terkadang, cinta memang sakit dan rumit. Namun, bisa pula membuat bahagia dan senyum
                                tidak ada habisnya. Keduanya bersimpangan, tetapi pasti kita rasakan. Jika Kita Tak
                                Pernah Jatuh Cinta dituliskan untukmu yang pernah merasa terpuruk karena cinta, lalu
                                bangkit lagi disebabkan hal yang sama.
                            </p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/15825/kata-rintik-sedu">2.
                                        Kata</a></strong><br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-ga185804.jpg"
                                    style="height:250px; width:174px" /><br />
                                <br />
                                <strong>Nugraha</strong><br />
                                Andai bisa sesederhana itu, aku tidak akan pernah mencintaimu sejak awal. Aku tidak akan
                                mengambil risiko, mengorbankan perasaanku. Namun, semua ini di luar kendaliku.<br />
                                <strong>Biru</strong><br />
                                Banda Neira adalah hari-hari terakhirku bersamamu. Kutitipkan segala rindu, cerita, dan
                                perasaan yang tak lagi kubawa, lewat sebuah ciuman perpisahan. Berjanjilah kau akan
                                melanjutkan hidupmu bersama laki-laki yang bisa menjaga dan menyayangimu lebih baik
                                dariku.<br />
                                <strong>Binta</strong><br />
                                Cinta pertama seorang perempuan yang didapat dari laki-laki adalah dari ayahnya. Dan
                                cinta pertama itu, telah mematahkan hatiku. Ayahku sendiri membuatku berhenti percaya
                                dengan yang namanya cinta.<br />
                                Nugraha, Biru, dan Binta saling membelakangi dan saling pergi. Mereka butuh kata-kata
                                untuk menjelaskan perasaan. Mereka harus bicara dan berhenti menyembunyikan kata hati
                                serta mencari jawaban dari sebuah perasaan.
                            </p>

                            <p><strong><a
                                        href="https://www.eurekabookhouse.co.id/product/detail/15761/perfect-couple">3.
                                        Perfect Couple</a></strong><br />
                                &nbsp;</p>

                            <p><img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-1068211201725.jpg"
                                    style="height:250px; width:171px" /></p>

                            <p>Jika kamu jomlo, kamu pantas iri dengan hubungan Anna dan Angga. Mereka adalah tetangga
                                sekaligus peraih predikat #couplegoal di sekolah.<br />
                                Bagi Anna, Angga adalah cowok yang sangat pengertian. Yah, meskipun kadang tetap saja
                                cowok itu jadi korban omelan Anna. Bagi Angga, Anna adalah satu-satunya cewek di
                                dunianya. Yah, meskipun temen-teman cowok sering meledek Angga karena ia nggak pernah
                                melirik cewek lain.<br />
                                Sikap Angga yang satu itu jadi masalah buat Anna. Bukannya cemburu kepada para penggemar
                                Angga, Anna justru nggak suka cowok itu terlalu menjaga jarak dengan teman-teman cewek
                                selain dirinya. Anna nggak mau dicap sebagai cewek overprotective! Namun, bagaimana jika
                                akhirnya Angga mau membuka diri dan berteman dengan cewek? Benarkah Anna akan membiarkan
                                Angga menikmati kebebasannya?<br />
                                Jika kamu jomlo, kamu pantas iri dengan hubungan Anna dan Angga. Mereka adalah tetangga
                                sekaligus peraih predikat #couplegoal di sekolah.<br />
                                Bagi Anna, Angga adalah cowok yang sangat pengertian. Yah, meskipun kadang tetap saja
                                cowok itu jadi korban omelan Anna. Bagi Angga, Anna adalah satu-satunya cewek di
                                dunianya. Yah, meskipun temen-teman cowok sering meledek Angga karena ia nggak pernah
                                melirik cewek lain.<br />
                                Sikap Angga yang satu itu jadi masalah buat Anna. Bukannya cemburu kepada para penggemar
                                Angga, Anna justru nggak suka cowok itu terlalu menjaga jarak dengan teman-teman cewek
                                selain dirinya. Anna nggak mau dicap sebagai cewek overprotective! Namun, bagaimana jika
                                akhirnya Angga mau membuka diri dan berteman dengan cewek? Benarkah Anna akan membiarkan
                                Angga menikmati kebebasannya?</p>

                            <p><a href="https://www.eurekabookhouse.co.id/product/detail/15967/the-book-of-almost"><strong>4.
                                        The Book of Almost</strong></a><br />
                                <img alt="" src="https://cdn.eurekabookhouse.co.id/ebh/product/all/1002211200123.jpg"
                                    style="height:250px; width:173px" /><br />
                                Tak ada yang lebih menyakitkan dalam jatuh cinta kecuali kata hampir. Aku hampir merasa
                                kau yang selama ini kucari. Kau hampir membuatku berhenti berlari. Mesti pada akhirnya,
                                kita berdua hanya sebatas hampir; hampir seperti sepasang kekasih. Memang, melupakan
                                adalah fase terberat dari perpisahan. Karenanya, hati hanya perlu mengikhlaskan, bukan
                                melupakan.
                            </p>

                            <p>&nbsp;</p>

                            <hr />
                            <p>Itulah novel-novel yang bisa dibagikan. Barangkali setidaknya satu diantara lima novel
                                tadi bisa jadi target jajan buku anda berikutnya. Sekian, teriring cinta dan kasih untuk
                                anda, dan salam literasi.</p>

                            <p>Sumber Gambar Header: https://www.newsghana.com.gh</p>
                        </div>
                    </div>
                    <div class="media ava-bloger">
                        <img class="mr-3 rounded-circle img-thumbnail"
                            src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                            alt="Generic placeholder image">
                        <div class="media-body">
                            <p class="stext-101 m-b-8">DITULIS OLEH</p>
                            <h5 class="mt-0 mtext-111"></h5>
                            <p class="badge badge-primary"></p>
                            <p class="stext-107"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<section class="sec-relate-product bg6 p-t-20 text-left">
    <div class="container p-tb-10">
        <div class="p-b-15">
            <h3 class="session-title mtext-106 cl5">New Entry</h3>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/kisahkasih'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/rahasiadelia'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pohonmangga'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/malaikat'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/ttgayah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/merah'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1"><a
                                href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat Eureka</a>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/jejak'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">05 September 2022</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 p-b-20">
                <div class="blog-item bg0 bor8">
                    <div class="blog-pic hov-img0">
                        <router-link :to="'/Blog/pejuang'">
                            <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                        </router-link>
                    </div>
                    <div class="p-all-15">
                        <div class="stext-112 flex-w p-b-14 cl1">
                            <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                        </div>
                        <h4 class="p-b-32">
                            <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA BOOKHOUSE
                            </router-link>
                        </h4>
                        <span class="stext-102 m-t-20">04 September 2022</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <!-- Footer -->
    <footer class="bgu1 p-t-15 p-b-15">
        <div class="container">
            <div class="p-t-10">
                <p class="stext-107 cl0 txt-center">
                    Copyright &copy; Dikembangkan oleh Eureka IT Developer
                </p>
            </div>
        </div>
    </footer>

</section>
    </div>
</template>
<script>
export default {
    name: "tujuhPage",
}
</script>