<template>
  <div>
    <section
      class="bg-img1 txt-center p-lr-15 p-t-50 p-b-30"
      style="
        background-image: url('https://www.eurekabookhouse.co.id/assets/front/images/book-red.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-color: #f4d747;
      "
    >
      <h2 class="ltext-105 cl5 txt-center">Kontak</h2>
    </section>
    <form class="bg0 p-t-75 p-b-85">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-xl-4 col-sm-12">
            <div class="contact row">
              <div class="contact-icon col-lg-2 col-md-@ col-xl-2 col-sm-12">
                <img
                  src="https://www.eurekabookhouse.co.id/assets/front/images/icons/location.svg"
                />
              </div>
              <div
                class="contact-content col-lg-10 col-md-10 col-xl-10 col-sm-12"
              >
                PT. Eureka Bookhouse<br />
                Jl. H. Baping Raya No. 100 Ciracas, Jakarta Timur<br />
                Indonesia 13740
              </div>
            </div>

            <div class="contact row">
              <div class="contact-icon col-lg-2 col-md-@ col-xl-2 col-sm-12">
                <img
                  src="https://www.eurekabookhouse.co.id/assets/front/images/icons/call.svg"
                />
              </div>
              <div
                class="contact-content col-lg-10 col-md-10 col-xl-10 col-sm-12"
              >
                021-8779 6010
              </div>
            </div>

            <div class="contact row">
              <div class="contact-icon col-lg-2 col-md-@ col-xl-2 col-sm-12">
                <img
                  src="https://www.eurekabookhouse.co.id/assets/front/images/icons/fax.svg"
                />
              </div>
              <div
                class="contact-content col-lg-10 col-md-10 col-xl-10 col-sm-12"
              >
                021-8779 0903
              </div>
            </div>
          </div>

          <div class="col-lg-8 col-md-8 col-xl-8 col-sm-8">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.568441924457!2d106.86993710039052!3d-6.320276328064736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ed0c85224af9%3A0x813b317eb97a4d7b!2sEureka*21+Bookhouse!5e0!3m2!1sid!2sid!4v1536114747808"
              width="100%"
              height="500px"
              frameborder="0"
              style="border: 0"
              allowfullscreen=""
            ></iframe>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
  
  <script>
export default {
  name: "hubungiKami",
  components: {},
  data() {
    return {};
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  },
  methods: {},
};
</script>
  
  <style>
.contact {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  padding: 20px;
  border: 4px dashed #b2b2b2;
}
</style>