<template>
  <div>
    <section
      class="bg-img1 txt-center p-lr-15 p-t-50 p-b-30"
      style="
        /*background-image:url('');*/
        background-repeat: no-repeat;
        background-size: 100%;
        background-color: #f4d747;
      "
    >
      <h2 class="ltext-105 cl5 txt-center">Syarat dan Ketentuan</h2>
      <h5>Syarat dan Ketentuan Eurekabookhouse</h5>
    </section>
    <section class="bg0 p-t-75 p-b-120">
      <div class="container">
        <div class="row p-b-50">
          <div class="col-md-12 col-lg-12">
            Anda harus membaca, memahami, menerima dan menyetujui semua
            persyaratan dan ketentuan dalam Perjanjian ini sebelum menggunakan
            aplikasi dan/atau menerima konten yang terdapat di dalamnya. Dengan
            mengakses atau menggunakan situs eurekabookhouse.co.id, Pengguna
            dianggap telah memahami dan menyetujui seluruh isi dalam syarat dan
            ketentuan di bawah ini. Syarat dan ketentuan dapat diubah atau
            diperbaharui sewaktu-waktu tanpa ada pemberitahuan terlebih dahulu.
            Perubahan syarat dan ketentuan akan segera berlaku setelah
            dicantumkan di dalam situs eurekabookhouse.co.id. Jika Pengguna
            merasa keberatan terhadap syarat dan ketentuan yang kami ajukan
            dalam Perjanjian ini, maka kami anjurkan untuk tidak menggunakan
            situs ini.
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
export default {
  name: "syaratKetentuan",
  components: {},
  data() {
    return {};
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  methods: {},
};
</script>
  
  <style>
</style>