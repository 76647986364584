<template>
  <div class="loading-overlay">
    <img
      style="width: 300px"
      src="../assets/images/notfound.png"
      alt="Error Image"
    />
    <span
      class="mt-5"
      style="
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
      "
      >Halaman tidak tersedia, silahkan periksa lagi pencarian anda.</span
    >
  </div>
</template>
  
  <script>
  
export default {
  name: "errorPage",
};
</script>
  
  <style>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Set a high z-index to ensure it's above other elements */
}
</style>
  