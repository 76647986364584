<template>
    <div>
<div>
    <div class="bg1">
        <div class="">
            <div class="p-t-3 p-b-5">
                <nav class="">
                    <a href="https://www.eurekabookhouse.co.id/" class="center">
                        <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                            alt="IMG-LOGO" class="center">
                    </a>
                </nav>
            </div>
        </div>
    </div>
    <section class="bg0 p-b-20 text-left">
        <div class="container p-lr-80-lg">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <h1 class="ltext-102 cl2 p-t-28">
                        Ini dia Hal-hal yang wajib untuk Persiapan Ujian Nasional 2019 </h1>
                    <div class="flex-m flex-sb m-tb-15">
                        <div class="flex-i flex-w flex-m stext-111 cl2">
                            <span>
                                <span class="cl4">Oleh</span> Eureka
                                <span class="cl12 m-l-4 m-r-6">|</span>
                            </span>
                            <span>
                                18 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                            </span>
                            <div id="share" class="m-l-50-lg">
                                <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                    href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/ini-dia-hal-hal-yang-wajib-untuk-persiapan-ujian-nasional-2019"
                                    target="_blank"><i class="fa fa-facebook"></i></a>
                                <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                    href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/ini-dia-hal-hal-yang-wajib-untuk-persiapan-ujian-nasional-2019&amp;text=Blog: Ini dia Hal-hal yang wajib untuk Persiapan Ujian Nasional 2019 &amp;hashtags=eurekabookhouse"
                                    title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                    href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/ini-dia-hal-hal-yang-wajib-untuk-persiapan-ujian-nasional-2019"
                                    title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                            </div>
                        </div>
                        <div class="flex-r-m">
                            <span class="stext-107 text-center"><i class="fa fa-eye"></i> 2765</span>
                        </div>
                    </div>
                    <div class="wrap-pic-w how-pos5-parent">
                        <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/1544153400-un-2019.jpg" alt="IMG-BLOG"
                            class="blog-read-pic">
                        <div class="flex-col-c-m size-123 bg9 how-pos5">
                            <span class="ltext-107 cl2 txt-center">
                                18 </span>
    
                            <span class="stext-109 cl3 txt-center">
                                11 2022 </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
            <div class="row p-t-35 p-b-80">
                <div class="col-lg-2">
                </div>
                <div class="col-md-8 col-lg-8">
                    <div class="p-r-0-lg">
                        <div>
                            <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                                <h3>Sudah tinggal menghitung hari Ujian Nasional (UN) akan dilaksanakan. Sudah berapa persen
                                    kah persiapan yang temen-temen lakukan?</h3>
    
                                <p>&nbsp;</p>
    
                                <p>Untuk mendapatkan hasil yang baik, tentu dibutuhkan presiapan matang dan strategi yang
                                    tepat. Sebelum itu, kamu juga harus mengetahui hal-hal wajib apa saja yang harus kamu
                                    persiapkan untuk menghadapi UN 2019 mendatang. Sudah sejauh apa kamu mempersiapkan
                                    dirimu menghadapi Ujian Nasional nanti?</p>
    
                                <p>Ini dia hal-hal wajib yang harus kamu persiapkan untuk Ujian Nasional 2019&hellip;</p>
    
                                <p>&nbsp;</p>
    
                                <p><strong>Ketahui tanggal ujiannya, jangan sampai lupa tanggal </strong></p>
    
                                <p>Tandai kalender rumah mu sekarang! Ditanggal berapa saja kamu akan melaksanakan ujian.
                                    Lalu, tandai juga mata pelajaran apa yang akan kamu kerjakan di tanggal tersbut.
                                    Usahakan kamu sudah <a
                                        href="https://www.eurekabookhouse.co.id/cari/result/?category=buku-soal-ujian">memperiapkan
                                    </a>segala suatunya dengan baik sampai ke hal-hal kecil sekalipun.</p>
    
                                <p>&nbsp;</p>
    
                                <p><strong>Rekomendasi:</strong> <a
                                        href="https://www.eurekabookhouse.co.id/cari/result/?category=buku-soal-ujian">Sukses
                                        dengan persiapan UN lebih dini</a></p>
    
                                <p>&nbsp;</p>
    
                                <p><strong>Persiapkan data diri dan Ketahui Proses Pengerjaan UN</strong></p>
    
                                <p>Sejak tahun 2016 lalu, pengerjaan UN sudah beralih dari proses manual, dengan kertas dan
                                    alat tulis, menjadi berbasis komputer. Sebelum ujian dimulai, kamu akan dipersilahkan
                                    untuk mengisi data pribadi pada komputer dihadapanmu. Termasuk di dalamnya nomer ujian
                                    dan data dirimu. Jadi, persiapkan data dirimu jangan sampai tertinggal!</p>
    
                                <p>Setelah selesai dan waktu dimulai, kamu akan mulai dihadapkan dengan <a
                                        href="https://www.eurekabookhouse.co.id/cari/result/?category=buku-soal-ujian">soal
                                    </a>dengan empat kunci jawaban , A, B, C, dan D. Baca soal dengan cepat namun tetap
                                    seksama, lalu pilih jawaban yang kamu yakini itu benar. Setelah kamu sudah yakin, kamu
                                    bisa melanjutkan untuk menjawab soal berikutnya. Jika kamu belum yakin, kamu bisa
                                    &ldquo;klik&rdquo; tombol ragu-ragu atau &ldquo;klik&rdquo; tombol selanjutnya untuk
                                    melihat soal berikutnya.</p>
    
                                <p>&nbsp;</p>
    
                                <p><strong>Persiapkan Strategi Mengerjakan soal UN </strong></p>
    
                                <p>Satu <a
                                        href="https://www.eurekabookhouse.co.id/cari/result/?category=buku-soal-ujian">paket
                                        soal UN</a> memiliki bobot soalnya sendiri, dari soal yang mudah hingga sulit untuk
                                    dijawab. Jika kamu menemukan soal sulit, yang bisa kamu lakukan adalah tidak terpaku
                                    dengan soal tersebut. Segera beralih dan kerjakan soal berikutnya. Tenang, kamu tidak
                                    perlu khawatir soal tersebut akan hangus jika tidak dijawab. Kamu bisa kembali lagi
                                    kepada soal-soal yang kamu lewati, asal masih ada waktu pengerjaan yang tersisa. Ingat
                                    waktumu terbatas untuk menjawab semua soalnya. Oleh karena itu, latihan mengerjakan
                                    soal-soal dari sekarang akan sangat membantumu untuk mengerjakan <a
                                        href="https://www.eurekabookhouse.co.id/cari/result/?category=buku-soal-ujian">soal
                                        UN</a>. Dengan berlatih mengerjakan soal, akan melatih kecepatanmu dalam membaca
                                    soal, memahami soal, juga menjawabnya.</p>
    
                                <p>&nbsp;</p>
    
                                <p>Itu dia beberapa hal yang wajib kamu persiapkan sebelum menghadapi UN 2019. Ingat, usaha
                                    dan persiapan yang matang tidak akan menghianati. Sukses selalu untuk teman-teman!</p>
                            </div>
                        </div>
                        <div class="media ava-bloger">
                            <img class="mr-3 rounded-circle img-thumbnail"
                                src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                                alt="Generic placeholder image">
                            <div class="media-body">
                                <p class="stext-101 m-b-8">DITULIS OLEH</p>
                                <h5 class="mt-0 mtext-111">Eureka Writer</h5>
                                <p class="badge badge-primary">Content Writer</p>
                                <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="sec-relate-product bg6 p-t-20 text-left">
        <div class="container p-tb-10">
            <div class="p-b-15">
                <h3 class="session-title mtext-106 cl5">New Entry</h3>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/kisahkasih'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/rahasiadelia'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/pohonmangga'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                                </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/malaikat'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/ttgayah'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/merah'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1"><a
                                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat
                                    Eureka</a>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/jejak'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/pejuang'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA
                                    BOOKHOUSE
                                </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">04 September 2022</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer class="bgu1 p-t-15 p-b-15">
        <div class="container">
            <div class="p-t-10">
                <p class="stext-107 cl0 txt-center">
                    Copyright &copy; Dikembangkan oleh Eureka IT Developer
                </p>
            </div>
        </div>
    </footer>
</div>
    </div>
</template>
<script>
export default {
    name: "hPage",
}
</script>