<template>
  <div>
    <section class="bg6 p-t-15 p-b-10">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-xl-3 m-lr-auto m-b-50 d-none d-lg-block">
            <sidebarPage />
          </div>

          <div class="col-lg-10 col-xl-9 m-lr-auto m-b-50 text-left">
            <div
              class="mt-5 bg0 bor10 p-all-15 m-b-10 p-lr-15-sm"
              style="border-radius: 15px"
            >
              <h3 class="ltext-101 cl1 m-b-10" style="color: #091d42">
                Edit Alamat Pengiriman
              </h3>
              <hr />
              <form class="mt-5">
                <div class="row">
                  <div class="col-md-4">
                    <div class="p-b-15">
                      <p class="text-disabled">Nama Depan</p>
                      <input
                        v-model="formData.firstname"
                        name="firstname"
                        class="textonly stext-104 cl2 plh3 bor8 p-lr-20 m-r-10 m-tb-5"
                        type="text"
                        style="height: 36px; width: 100%"
                        required
                        autofocus
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="p-b-15">
                      <p class="text-disabled">Nama Belakang</p>
                      <input
                        v-model="formData.lastname"
                        name="lastname"
                        class="textonly stext-104 cl2 plh3 bor8 p-lr-20 m-r-10 m-tb-5"
                        type="text"
                        style="height: 36px; width: 100%"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="p-b-15">
                      <p class="text-disabled">Telepon</p>
                      <input
                        v-model="formData.telephone"
                        name="telephone"
                        class="textonly stext-104 cl2 plh3 bor8 p-lr-20 m-r-10 m-tb-5"
                        type="tel"
                        style="height: 36px; width: 100%"
                        placeholder="+62"
                        @input="autoPrependCountryCode"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <div class="p-b-15">
                      <p class="text-disabled stext-107">Provinsi</p>
                      <select
                        v-model="selectedProvinsi"
                        class="form-control select2"
                        style="width: 100%"
                        @change="getKota"
                      >
                        <option value="" selected disabled>
                          Pilih Provinsi
                        </option>
                        <option
                          v-for="(province, provinceName) in provinces"
                          :key="province.province_id"
                          :value="provinceName"
                        >
                          {{ province.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="p-b-15">
                      <p class="text-disabled stext-107">Kabupaten</p>
                      <select
                        v-model="selectedKota"
                        class="form-control select2"
                        style="width: 100%"
                        @change="getKecamatan"
                      >
                        <option value="" selected disabled>Pilih Kota</option>
                        <option
                          v-for="city in kotaOptions"
                          :key="city.city_id"
                          :value="city.city_name"
                        >
                          {{ city.city_name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="p-b-15">
                      <p class="text-disabled stext-107">Kecamatan</p>
                      <select
                        v-model="selectedKecamatan"
                        class="form-control select2"
                        style="width: 100%"
                        @change="getKodePos"
                      >
                        <option value="" selected disabled>
                          Pilih Kecamatan
                        </option>
                        <option
                          v-for="kecamatan in kecamatanOptions"
                          :key="kecamatan.subdistrict_id"
                          :value="kecamatan.subdistrict"
                        >
                          {{ kecamatan.subdistrict }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="p-b-15">
                      <p class="text-disabled stext-107">Kode Pos</p>
                      <input
                        v-model="selectedKodePos"
                        class="stext-104 cl2 numberonly plh3 bor8 p-lr-20 m-r-10 m-tb-5"
                        id="kodepos"
                        type="number"
                        name="kodepos"
                        style="height: 36px; width: 100%"
                        placeholder="Kode Pos"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="p-b-15" id="locationField">
                  <p class="text-disabled stext-107">
                    <i class="fa fa-home"></i> Alamat Lengkap (Nama Jalan, No
                    Rumah, RT/RW)
                  </p>
                  <input
                    v-model="formData.address_2"
                    class="stext-104 cl2 textonly plh3 bor8 p-lr-20 m-r-10 m-tb-5"
                    type="text"
                    name="address_2"
                    placeholder="Nama Jalan, No Rumah"
                    required
                    style="height: 36px; width: 100%"
                  />
                </div>

                <br />
                <button
                  id="submit-bayar"
                  type="button"
                  class="btn btn-primary btn-block stext-104"
                  style="background-color: #091d42"
                  @click="updateAddress"
                >
                  <i class="fa fa-pencil"></i> Update Alamat
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sidebarPage from "@/components/Users/sidebar.vue";
export default {
  name: "editalamatPage",
  components: {
    sidebarPage,
  },
  data() {
    return {
      formData: {
        firstname: "",
        lastname: "",
        telephone: "+62",
        postcode: "",
        address_id: "",
        address_2: "",
      },
      data: {},
      selectedProvinsi: "",
      selectedKota: "",
      selectedKecamatan: "",
      selectedKodePos: "",
      provinces: {},
    };
  },

  methods: {
    autoPrependCountryCode() {
      // Check if the input value does not start with "+62"
      if (!this.formData.telephone.startsWith("+62")) {
        // If not, prepend "+62" to the input value
        this.formData.telephone = "+62" + this.formData.telephone;
      }
    },
    async updateAddress() {
      try {
        const token = localStorage.getItem("token");
        const addressId = localStorage.getItem("selectedAddressId");
        // console.log("Address ID to Update:", addressId);

        const idCustomer = localStorage.getItem("id_customer");

        // Ensure you have the address data to update
        const updatedAddressData = {
          address_id: addressId,
          id_rajaongkir: 0,
          customer_id: idCustomer,
          firstname: this.formData.firstname,
          lastname: this.formData.lastname,
          telephone: this.formData.telephone,
          address_2: this.formData.address_2,
          country_id: 0,
          country: "Indonesia",
          province: this.selectedProvinsi,
          city: this.selectedKota,
          postcode: this.selectedKodePos,
          province_id: this.provinces[this.selectedProvinsi]?.province_id,
          city_id:
            this.provinces[this.selectedProvinsi]?.cities[this.selectedKota][0]
              .city_id,
          zone_id:
            this.provinces[this.selectedProvinsi]?.cities[this.selectedKota][0]
              .ro_city.subdistrict_id,
          zone_1:
            this.provinces[this.selectedProvinsi]?.cities[this.selectedKota][0]
              .ro_city.subdistrict,
        };

        const response = await fetch(
          `https://stagingapi.eurekabookhouse.co.id/shipping/edit/alamat`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(updatedAddressData),
          }
        );

        if (response.ok) {
          // Show a success message using SweetAlert2
          Swal.fire({
            confirmButtonColor: "#3860A8",
            icon: "success",
            title: "Alamat diperbarui",
            text: "Alamat Anda telah berhasil diperbarui!",
          }).then(() => {
            // Redirect to another page
            this.$router.push({ path: "/Users/alamat" });
          });
        } else {
          const result = await response.json();
          // Check if the response has a specific error message
          if (result && result.message) {
            Swal.fire({
              confirmButtonColor: "#3860A8",
              icon: "error",
              title: "Update Failed",
              text: "Error updating address: " + result.message,
            });
          } else {
            Swal.fire({
              confirmButtonColor: "#3860A8",
              icon: "error",
              title: "Update Failed",
              text: "An error occurred while updating the address.",
            });
          }
        }
      } catch (error) {
        console.error("Error updating address:", error);
        // Handle unexpected errors using SweetAlert2
        Swal.fire({
          confirmButtonColor: "#3860A8",
          icon: "error",
          title: "Update Failed",
          text: "An error occurred while updating the address.",
        });
      }
    },

    async getProvinces() {
      const response = await axios.get(
        "https://stagingapi.eurekabookhouse.co.id/shipping/newProvince"
      );
      this.provinces = response.data.data;
    },
    getKota() {
      const selectedProvince = this.provinces[this.selectedProvinsi];
      const kotaOptions = Object.keys(selectedProvince.cities).map(
        (cityName) => {
          return {
            city_id: selectedProvince.cities[cityName][0].city_id,
            city_name: cityName,
          };
        }
      );
      this.selectedKota = ""; // Reset selected Kota
      this.kotaOptions = kotaOptions;
    },
    getKecamatan() {
      const selectedCity = this.selectedKota
        ? this.selectedKota
        : Object.keys(this.provinces[this.selectedProvinsi].cities)[0];
      const kecamatanOptions = this.provinces[this.selectedProvinsi].cities[
        selectedCity
      ].map((subdistrict) => {
        return {
          subdistrict_id: subdistrict.subdistrict_id,
          subdistrict: subdistrict.subdistrict,
        };
      });
      this.selectedKecamatan = ""; // Reset selected Kecamatan
      this.kecamatanOptions = kecamatanOptions;
      this.selectedKodePos = ""; // Reset selected Kode Pos
    },
    getKodePos() {
      const selectedCity = this.selectedKota
        ? this.selectedKota
        : Object.keys(this.provinces[this.selectedProvinsi].cities)[0];
      const selectedSubdistrict = this.selectedKecamatan
        ? this.selectedKecamatan
        : this.provinces[this.selectedProvinsi].cities[selectedCity][0]
            .subdistrict;

      this.selectedKodePos =
        this.provinces[this.selectedProvinsi].cities[
          selectedCity
        ][0].ro_city.postal_code;
    },
  },
  mounted() {
    this.getProvinces();
  },
  created() {
    // Retrieve the data from the route params
    this.formData = this.$route.params.formData;
  },
};
</script>
