<template>
    <div>
<div>
    <div class="bg1">
        <div class="">
            <div class="p-t-3 p-b-5">
                <nav class="">
                    <a href="https://www.eurekabookhouse.co.id/" class="center">
                        <img src="https://www.eurekabookhouse.co.id/assets/front/images/icons/ebhcom-header_new.png"
                            alt="IMG-LOGO" class="center">
                    </a>
                </nav>
            </div>
        </div>
    </div>
    <section class="bg0 p-b-20 text-left">
        <div class="container p-lr-80-lg">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <h1 class="ltext-102 cl2 p-t-28">
                        Ini Dia 7 Tips Menghadapi UN, Pasti Sukses! </h1>
                    <div class="flex-m flex-sb m-tb-15">
                        <div class="flex-i flex-w flex-m stext-111 cl2">
                            <span>
                                <span class="cl4">Oleh</span> Eureka
                                <span class="cl12 m-l-4 m-r-6">|</span>
                            </span>
                            <span>
                                18 Nov, 2022 <span class="cl12 m-l-4 m-r-6">|</span>
                            </span>
                            <div id="share" class="m-l-50-lg">
                                <a class="fs-14 badge p-all-5 hov-cl0 facebook customer share"
                                    href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/blog/read/ini-dia-7-tips-menghadapi-un--pasti-sukses-"
                                    target="_blank"><i class="fa fa-facebook"></i></a>
                                <a class="fs-14 badge p-all-5 hov-cl0 twitter customer share"
                                    href="http://twitter.com/share?url=https://www.eurekabookhouse.co.id/blog/read/ini-dia-7-tips-menghadapi-un--pasti-sukses-&amp;text=Blog: Ini Dia 7 Tips Menghadapi UN, Pasti Sukses! &amp;hashtags=eurekabookhouse"
                                    title="Twitter share" target="_blank"><i class="fa fa-twitter"></i></a>
                                <a class="fs-14 badge p-all-5 hov-cl0 googleplus customer share"
                                    href="https://plus.google.com/share?url=https://www.eurekabookhouse.co.id/blog/read/ini-dia-7-tips-menghadapi-un--pasti-sukses-"
                                    title="Google Plus Share" target="_blank"><i class="fa fa-google-plus"></i></a>
                            </div>
                        </div>
                        <div class="flex-r-m">
                            <span class="stext-107 text-center"><i class="fa fa-eye"></i> 2480</span>
                        </div>
                    </div>
                    <div class="wrap-pic-w how-pos5-parent">
                        <img src="https://cdn.eurekabookhouse.co.id/ebh/blog/1038378-riz--hari-pertama-ujian-nasional--780x390.jpg"
                            alt="IMG-BLOG" class="blog-read-pic">
                        <div class="flex-col-c-m size-123 bg9 how-pos5">
                            <span class="ltext-107 cl2 txt-center">
                                18 </span>
    
                            <span class="stext-109 cl3 txt-center">
                                11 2022 </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
            <div class="row p-t-35 p-b-80">
                <div class="col-lg-2">
                </div>
                <div class="col-md-8 col-lg-8">
                    <div class="p-r-0-lg">
                        <div>
                            <div class="isi_blog ctext-101 fs-18 cl2 p-b-26">
                                <h3>Tenang, semua bisa kamu lewati jika kamu tahu cara mengatasinya. Simak baik-baik
                                    beberapa tips menghadapi UN untuk membuatmu dapat melewatinya dengan sukses.</h3>
    
                                <p>&nbsp;</p>
    
                                <p>Ujian Nasional (UN) tinggal sebentar lagi&nbsp;<em>nih</em>. Dalam mempersiapkan diri
                                    menghadapi UN pasti ada saja hal-hal yang membuat mu terhambat. Faktornya bisa apa saja,
                                    bisa faktor kesehatan, materi yang sulit dipahami, sulit menemukan tempat yang kondusif
                                    untuk belajar, sampai masalah pertemanan.Tenang, semua bisa kamu lewati jika kamu tahu
                                    cara mengatasinya. Simak baik-baik beberapa tips menghadapi UN untuk membuatmu dapat
                                    melewatinya dengan sukses.</p>
    
                                <p>&nbsp;</p>
    
                                <p><strong>1. Cari Lingkugan Belajar yang Nyaman</strong></p>
    
                                <p><img alt="" src="https://ak3.picdn.net/shutterstock/videos/18648833/thumb/1.jpg"
                                        style="height:250px; width:444px" /></p>
    
                                <p>Untuk mendapatkan lingkungan <a
                                        href="https://www.eurekabookhouse.co.id/cari/result/?category=buku-soal-ujian">belajar
                                    </a>yang nyaman, pertama-tama kamu harus mengetahui kamu orang yang seperti apa. Tipe
                                    pertama, apakah kamu lebih nyaman belajar dengan keadaan sunyi tanpa gangguan, atau tipe
                                    dua, kamu adalah orang yang bisa belajar di tengah keramaian.</p>
    
                                <p>Jika kamu adalah tipe pertama, kamar mu bisa menjadi pilihan nyaman untuk mu <a
                                        href="https://www.eurekabookhouse.co.id/cari/result/?category=buku-soal-ujian">belajar</a>.
                                    Kamu bisa pasang tanda &ldquo;sttt, aku lagi serius nih&rdquo; di depan pintu kamarmu
                                    agar orang rumahmu mengetahui bahwa kamu sedang serius belajar untuk menghadapi UN.
                                    Tempat lainnya yang dapat kamu pilih adalah perpustakaan.</p>
    
                                <p>Nah, jika kamu adalah tipe kedua, yang bisa belajar di tempat ramai, banyak pilihan
                                    tempat yang dapat kamu jadikan tempat untuk belajar, salah satunya <em>coffee shop</em>.
                                    Bagaimana?</p>
    
                                <p>&nbsp;</p>
    
                                <p><strong><em>2. Be a Creative Person</em></strong></p>
    
                                <p><img alt=""
                                        src="https://image.freepik.com/free-photo/hanging-yellow-bulb-with-other-white-bulbs_1205-363.jpg?1"
                                        style="height:250px; width:375px" /></p>
    
                                <p>Ada saja materi yang sulit untuk dipahami apalagi kalau mata <a
                                        href="https://www.eurekabookhouse.co.id/cari/result/?category=bahasa--inggris-sma">pelajaran
                                    </a>tersebut bukan yang kamu suka. Jika kamu menghadapi situasi ini, selalu ingat
                                    pepatah yang mengatakan &ldquo;di mana ada kemauan, di situ ada jalan&rdquo;, terus
                                    berusaha!</p>
    
                                <p>Kamu dapat membuat cara mu sendiri untuk membuat mata <a
                                        href="https://www.eurekabookhouse.co.id/cari/result/?category=bahasa--inggris-sma">pelajaran
                                    </a>itu mudah, misalnya buat catatan dengan gambar maupun stabilo yang akan
                                    membudahkanmu untuk mengingat juga memahami materi. Hal lainnya yang bisa kamu lakukan
                                    adalah mencari penjabaran dari sumber-sumber lain, seperti web-web belajar
                                    maupun&nbsp;<em>youtube</em>.</p>
    
                                <p>Bisa jadi kamu sulit memahami karena belum mendapatkan penjelasan yang mudah dimengerti.
                                    <em>So keep trying and be a creative person</em> mencari celah belajar untuk menghadapi
                                    UN 2019 mendatang!</p>
    
                                <p>&nbsp;</p>
    
                                <p><strong>3. Miliki Grup Belajar Mu</strong></p>
    
                                <p><img alt=""
                                        src="https://image.freepik.com/free-photo/multi-ethnic-group-young-men-women-studying-indoors_1139-989.jpg"
                                        style="height:250px; width:375px" /></p>
    
                                <p>Jika sendiri terasa membosankan, kamu bisa belajar dengan grup belajarmu. Dengan adanya
                                    grup belajar, kamu bisa mengerjakan soal dan membahasnya bersama-sama. Namun, kamu harus
                                    tetap memiliki jadwal sesuai kesepakatan grup belajarmu agar waktu belajarmu tetap
                                    terarah dan <a
                                        href="https://www.eurekabookhouse.co.id/cari/result/?category=matematika-sma">berkualitas</a>.
                                </p>
    
                                <p>&nbsp;</p>
    
                                <p><strong>4. Bahas Soal-soal Sebelumnya</strong></p>
    
                                <p><img alt=""
                                        src="https://image.freepik.com/free-photo/hand-with-pen-application-form_1232-4334.jpg"
                                        style="height:250px; width:375px" /></p>
    
                                <p>&nbsp;</p>
    
                                <p>Biasakan dirimu dengan mengerjakan <a
                                        href="https://www.eurekabookhouse.co.id/cari/result/?category=buku-soal-ujian">soal-soal</a>
                                    dari yang mudah sampai soal dengan bobot yang lebih sulit. Jika terasa sulit, lakukan
                                    secara bertahap. Kamu bisa mengerjakan dan mempelajari soal-soal dari ujian nasional
                                    tahun-tahun sebelumnya. Percayalah bahwa soal-soal ujian memiliki kesamaan setiap
                                    tahunnya.</p>
    
                                <p>&nbsp;</p>
    
                                <p><strong>5. Refreshing Itu Penting!</strong></p>
    
                                <p><img alt=""
                                        src="https://image.freepik.com/free-photo/dreamy-beautiful-woman-enjoying-city-life_1262-4989.jpg"
                                        style="height:250px; width:375px" /></p>
    
                                <p>Ayo santai dulu santai. Di tengah-tengah ketegangan dan waktu belajarmu yang padat,
                                    baiknya kamu juga menikmati waktu santaimu. Kamu bisa melakukan kegiatan-kegiatan yang
                                    kamu sukai. Tidak harus jauh-jauh dan sulit, kamu bisa membaca komik kesukaanmu di
                                    rumah, olahraga di Minggu pagi, atau sekedar jalan-jalan santai di sekitar rumah.
                                    Intinya, lakukan apa yang kamu sukai untuk <a
                                        href="https://www.eurekabookhouse.co.id/cari/result/?category=novel"><em>refreshing</em></a>!
                                    <em>Me time</em> itu penting!</p>
    
                                <p>&nbsp;</p>
    
                                <p><strong>6. Persiapkan Segala Sesuatunya Sebelum Ujian</strong></p>
    
                                <p><img alt=""
                                        src="https://image.freepik.com/free-photo/hands-with-notebook-workplace_23-2147844859.jpg"
                                        style="height:250px; width:375px" /></p>
    
                                <p>Sebelum &ldquo;perang&rdquo;, kamu harus siap perbekalan dan mengetahui medannya terlebih
                                    dahulu. Sama hal nya dengan menghadapi UN. Selain mempersiapkan dirimu dengan belajar
                                    kamu harus mengetahui apa saja yang harus kamu siapkan saat hari H tiba. Usahakan datang
                                    saat diadakan <a
                                        href="https://www.eurekabookhouse.co.id/cari/result/?category=buku-soal-ujian">simulasi
                                        UN</a>. Ini akan memberikan gambaran bagaimana UN akan terlaksana nantinya.</p>
    
                                <p>&nbsp;</p>
    
                                <p><strong>7. Berdoa dan Minta Restu Orang tua</strong></p>
    
                                <p><img alt=""
                                        src="https://image.freepik.com/free-photo/spiritual-prayer-hands-sun-shine-with-blurred-beautiful-sunset_1150-7216.jpg"
                                        style="height:250px; width:375px" /></p>
    
                                <p>Iringi semua usahamu dengan doa dan minta restu orang tua mu! Berdoa untuk dirimu dan
                                    juga teman-temanmu agar Tuhan memberikan hasil terbaik untuk semua usaha yang sudah
                                    kalian lakukan.</p>
    
                                <p>Itu dia tujuh&nbsp;Tips Menghadapi UN yang ampuh untuk&nbsp;kamu&nbsp;lakukan. Kerjakan
                                    dengan percaya diri ya teman-teman! Percaya diri timbul jika kamu sudah cukup berlatih
                                    dan <a
                                        href="https://www.eurekabookhouse.co.id/cari/result/?category=buku-agama">berdoa</a>.
                                    Yuk, persiapkan segala sesuatunya dari sekarang. Sukses untuk ujian mendatang ya
                                    teman-teman!</p>
                            </div>
                        </div>
                        <div class="media ava-bloger">
                            <img class="mr-3 rounded-circle img-thumbnail"
                                src="https://eurekabookhouse.co.id/assets/uplod/profile/04112020_1586576240.png"
                                alt="Generic placeholder image">
                            <div class="media-body">
                                <p class="stext-101 m-b-8">DITULIS OLEH</p>
                                <h5 class="mt-0 mtext-111">Eureka Writer</h5>
                                <p class="badge badge-primary">Content Writer</p>
                                <p class="stext-107">Content Writter eurekabookhouse.co.id</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="sec-relate-product bg6 p-t-20 text-left">
        <div class="container p-tb-10">
            <div class="p-b-15">
                <h3 class="session-title mtext-106 cl5">New Entry</h3>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/kisahkasih'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/13-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/kisahkasih'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/kisahkasih'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    KISAH KASIH - JUARA FAVORIT 10 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/rahasiadelia'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/12-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/rahasiadelia'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/rahasiadelia'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    RAHASIA DELIA - JUARA FAVORIT 9 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/pohonmangga'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/11-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/pohonmangga'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/pohonmangga'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    POHON MANGGA ITU BERBUAH KEMBALI - JUARA FAVORIT 8 LOMBA CERPEN EUREKA BOOKHOUSE
                                </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/malaikat'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/10-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/malaikat'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/malaikat'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    MALAIKAT PENJAGAKU - JUARA FAVORIT 7 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/ttgayah'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/9-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/ttgayah'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/ttgayah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    KENANGAN TENTANG AYAH - JUARA FAVORIT 6 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/merah'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/8-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1"><a
                                    href="https://www.eurekabookhouse.co.id/blog/category/sahabat-eureka">Sahabat
                                    Eureka</a>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/merah'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    SEGENGGAM TANAH MERAH - JUARA FAVORIT 5 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/jejak'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/7-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/jejak'">Sahabat Eureka</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/jejak'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    JEJAK SANDAL AYAH - JUARA FAVORIT 4 LOMBA CERPEN EUREKA BOOKHOUSE </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">05 September 2022</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 p-b-20">
                    <div class="blog-item bg0 bor8">
                        <div class="blog-pic hov-img0">
                            <router-link :to="'/Blog/pejuang'">
                                <img class="blog-read-pic" src="https://cdn.eurekabookhouse.co.id/ebh/blog/6-min.jpg">
                            </router-link>
                        </div>
                        <div class="p-all-15">
                            <div class="stext-112 flex-w p-b-14 cl1">
                                <router-link :to="'/Blog/pejuang'">Acara Toko</router-link>
                            </div>
                            <h4 class="p-b-32">
                                <router-link :to="'/Blog/pejuang'" class="mtext-101 cl2 hov-cl1 trans-04">
                                    PERJUANGAN AYAHKU, UNTUK MASA DEPANKU - JUARA FAVORIT 3 LOMBA CERPEN EUREKA
                                    BOOKHOUSE
                                </router-link>
                            </h4>
                            <span class="stext-102 m-t-20">04 September 2022</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer class="bgu1 p-t-15 p-b-15">
        <div class="container">
            <div class="p-t-10">
                <p class="stext-107 cl0 txt-center">
                    Copyright &copy; Dikembangkan oleh Eureka IT Developer
                </p>
            </div>
        </div>
    </footer>
</div>
    </div>
</template>
<script>
export default {
    name: "gPage",
}
</script>