<template>
  <div>
    <div>
      <section
        class="bg-img1 txt-center p-lr-15 p-t-50 p-b-30"
        style="
          /*background-image:url('');*/
          background-repeat: no-repeat;
          background-size: 100%;
          background-color: #f4d747;
        "
      >
        <h2 class="ltext-105 cl5 txt-center">Kebijakan Privasi</h2>
        <h5>Kebijakan Privasi Eurekabookhouse</h5>
      </section>
      <section class="bg0 p-t-75 p-b-120">
        <div class="container">
          <div class="row p-b-50">
            <div class="stext-105 m-b-30 text-left">
              <!-- Pendahuluan -->
              <h3>1. Pendahuluan</h3>
              <br>
              <p>
                1.1 Selamat datang di platform Eureka BookHouse, Kebijakan Privasi ini ("Kebijakan Privasi" atau
"Kebijakan") dirancang untuk membantu Anda memahami bagaimana kami mengumpulkan,
menggunakan, mengungkapkan dan/atau mengolah data pribadi yang telah Anda percayakan
kepada kami dan/atau kami miliki tentang Anda, baik di masa sekarang maupun di masa mendatang,
serta untuk membantu Anda membuat keputusan yang tepat sebelum memberikan data pribadi Anda
kepada kami. Silakan baca Kebijakan Privasi ini dengan cermat. Apabila Anda memiliki pertanyaan
tentang informasi ini atau praktik privasi kami, silakan lihat bagian yang berjudul "Pertanyaan,
Masalah atau Keluhan? Hubungi Kami" di akhir Kebijakan Privasi ini.

              </p>
              <br>
              <p>
                1.2 "Data Pribadi" berarti data, baik benar maupun tidak, tentang individu yang dapat diidentifikasi
dari data tersebut, atau dari data dan informasi lainnya yang dapat atau kemungkinan dapat diakses
oleh suatu organisasi. Contoh umum data pribadi dapat mencakup nama, nomor identifikasi dan
informasi kontak.

              </p>
              <br>
              <p>
                1.3 Dengan menggunakan Layanan, mendaftarkan akun pada kami, mengunjungi situs web kami,
atau mengakses Layanan, Anda mengakui dan setuju bahwa Anda menerima praktik, persyaratan,
dan/atau kebijakan yang diuraikan dalam Kebijakan Privasi ini, dan Anda dengan ini mengizinkan
kami untuk mengumpulkan, menggunakan, mengungkapkan dan/atau mengolah data pribadi Anda
seperti yang dijelaskan di sini. APABILA ANDA TIDAK MENGIZINKAN PENGOLAHAN DATA
PRIBADI ANDA SEPERTI YANG DIJELASKAN DALAM KEBIJAKAN PRIVASI INI, MOHON
JANGAN MENGGUNAKAN LAYANAN KAMI ATAU MENGAKSES SITUS WEB KAMI. Apabila kami
mengubah Kebijakan Privasi kami, kami akan memposting perubahan tersebut atau Kebijakan Privasi
yang telah diubah pada situs web kami. Kami berhak untuk mengubah Kebijakan Privasi ini setiap
saat.
              </p>
            
              <br>
              <h3>2. KAPAN EUREKA BOOKHOUSE MENGUMPULKAN DATA PRIBADI?
              </h3>
              <br>
              <p>
                2.1 Kami akan/mungkin mengumpulkan data pribadi Anda:
              </p>
              <br/>
              <div class="ml-2">  <p>
                (a) saat Anda mendaftar dan/atau menggunakan Layanan atau Situs kami, atau membuka sebuah
                akun dengan kami.
              </p>
              <p>
                (b) saat Anda membuat perjanjian atau memberikan dokumen atau informasi lainnya sehubungan
                dengan interaksi Anda dengan kami, atau saat Anda menggunakan produk dan layanan kami;

              </p>
              <p>
                (c) saat Anda mengirimkan formulir apapun, termasuk, tetapi tidak terbatas pada, formulir
permohonan atau formulir lainnya yang berkaitan dengan produk dan layanan kami, baik secara
online maupun dalam bentuk fisik;
              </p>
              <p>
                (d) saat Anda menggunakan layanan elektronik kami, atau berinteraksi dengan kami melalui aplikasi
kami atau menggunakan layanan di situs web kami. Ini termasuk, dengan tidak terbatas pada, melalui
cookies yang mungkin kami gunakan saat Anda berinteraksi dengan aplikasi atau situs web kami;
              </p>
              <p>
                (e) saat Anda berinteraksi dengan kami, seperti melalui sambungan telepon (yang mungkin direkam),
                surat, faks, pertemuan tatap muka, platform media sosial dan email;
              </p>
              <p>
                (f) saat Anda mengirimkan data pribadi Anda kepada kami dengan alasan apapun.

              </p>
              <p>
                (g) saat Anda melakukan transaksi melalui Layanan kami;

              </p>
              <p>
                (h) saat Anda menyampaikan kritik dan saran atau keluhan kepada kami;

              </p>
              <p>
                (i) saat Anda mendaftar untuk suatu kontes; atau daftar di atas tidak dimaksudkan sebagai suatu daftar yang lengkap dan hanya menetapkan
                beberapa contoh umum tentang kapan data pribadi Anda mungkin diambil.

              </p></div>
              <br/>
              <p>
                2.2 Saat Anda mengunjungi, menggunakan atau berinteraksi dengan aplikasi mobile atau Situs kami,
kami mungkin mengumpulkan informasi tertentu secara otomatis atau pasif dengan menggunakan
berbagai teknologi, di mana teknologi tersebut mungkin diunduh ke perangkat Anda dan mungkin
menetapkan atau mengubah pengaturan pada perangkat Anda. Informasi yang kami kumpulkan
dapat termasuk, dengan tidak terbatas pada, alamat Internet Protocol (IP), sistem operasi
komputer/perangkat mobile, jenis perangkat mobile, karakteristik perangkat mobile, unique device
identifier (UDID) atau mobile equipment identifier (MEID) untuk perangkat mobile Anda, alamat situs
perujuk (jika ada), dan halaman yang Anda kunjungi pada situs web dan aplikasi mobile kami serta
jumlah kunjungan. Kami hanya dapat mengumpulkan, menggunakan, mengungkapkan dan/atau
mengolah informasi ini untuk Tujuan (didefinisikan di bawah).2.2 Saat Anda mengunjungi, menggunakan atau berinteraksi dengan aplikasi mobile atau Situs kami,
kami mungkin mengumpulkan informasi tertentu secara otomatis atau pasif dengan menggunakan
berbagai teknologi, di mana teknologi tersebut mungkin diunduh ke perangkat Anda dan mungkin
menetapkan atau mengubah pengaturan pada perangkat Anda. Informasi yang kami kumpulkan
dapat termasuk, dengan tidak terbatas pada, alamat Internet Protocol (IP), sistem operasi
komputer/perangkat mobile, jenis perangkat mobile, karakteristik perangkat mobile, unique device
identifier (UDID) atau mobile equipment identifier (MEID) untuk perangkat mobile Anda, alamat situs
perujuk (jika ada), dan halaman yang Anda kunjungi pada situs web dan aplikasi mobile kami serta
jumlah kunjungan. Kami hanya dapat mengumpulkan, menggunakan, mengungkapkan dan/atau
mengolah informasi ini untuk Tujuan (didefinisikan di bawah).
              </p>
              <br>
              <p>2.4 Aplikasi mobile kami mungkin mengumpulkan informasi yang tepat tentang lokasi perangkat
mobile Anda dengan menggunakan teknologi seperti GPS, Wi-Fi, dsb. Kami mengumpulkan,
menggunakan, mengungkapkan dan/atau mengolah informasi ini untuk satu Tujuan atau lebih
termasuk, dengan tidak terbatas pada, layanan berbasis lokasi yang Anda minta atau untuk
mengirimkan konten yang relevan dengan Anda berdasarkan lokasi Anda atau untuk memungkinkan
Anda berbagi lokasi Anda dengan Pengguna lainnya sebagai bagian dari layanan aplikasi mobile
kami. Untuk kebanyakan perangkat mobile, Anda dapat menarik izin Anda untuk kami memperoleh
informasi tentang lokasi Anda ini melalui pengaturan perangkat Anda. Apabila Anda memiliki
pertanyaan tentang bagaimana cara menonaktifkan layanan lokasi perangkat mobile Anda, silakan
hubungi penyedia layanan perangkat mobile atau produsen perangkat Anda.
</p>
              <br>
              <h3>3. DATA PRIBADI APA YANG AKAN DIKUMPULKAN OLEH EUREKA BOOKHOUSE?</h3>
              <br>
              <p>
                             3.1 Data pribadi yang mungkin dikumpulkan Eureka BookHouse termasuk tetapi tidak terbatas pada
              </p>
              <br>
              <div class="ml-2">  <p>
                • nama;

              </p>
              <p>
              • alamat email;

              </p>
              <p>
                • nomor telepon;

              </p>
              <p>
                • tanggal lahir;


              </p>
              <p>
                • jenis kelamin;



              </p>
              <p>
                • alamat tagihan;



              </p>
              <p>
                • rekening bank dan informasi pembayaran;

               

              </p>
              <p>

                • informasi lain apapun tentang Pengguna saat Pengguna mendaftarkan diri untuk menggunakan
Layanan atau situs web kami, dan saat Pengguna menggunakan Layanan atau situs web, serta
informasi yang berkaitan dengan bagaimana Pengguna menggunakan Layanan atau situs web kami;
dan

              </p>
              </div>
              <br/>
              <p>
                3.2 Apabila Anda tidak ingin kami mengumpulkan informasi/data pribadi yang disebutkan di atas,
Anda dapat memilih keluar setiap saat dengan memberitahu Petugas Perlindungan Data kami secara
tertulis tentang hal tersebut. Informasi lebih lanjut tentang memilih keluar dapat ditemukan di bagian
di bawah ini yang berjudul " Bagaimana Anda dapat memilih keluar, menghapus, meminta akses atau
mengubah informasi yang telah Anda berikan kepada kami?". Akan tetapi harap diperhatikan bahwa
memilih keluar dari kami mengumpulkan data pribadi Anda atau menarik izin Anda untuk kami
mengumpulkan, menggunakan atau mengolah data pribadi Anda dapat memengaruhi penggunaan
Layanan oleh Anda. 
              </p>

              <br>
              <h3>4. MEMBUAT AKUN           </h3>
              <br>
              <p>
                Untuk menggunakan fungsi tertentu dari Layanan, Anda harus membuat akun pengguna yang
mengharuskan Anda untuk menyerahkan data pribadi tertentu. Saat Anda mendaftar dan membuat
akun, kami mewajibkan Anda untuk memberikan nama dan alamat email Anda serta nama pengguna
yang Anda pilih kepada kami. Kami juga menanyakan informasi tertentu tentang diri Anda seperti
nomor telepon, alamat email, alamat pengiriman, identifikasi foto, rincian rekening bank, umur,
tanggal lahir, jenis kelamin dan minat Anda. Setelah mengaktifkan akun, Anda akan memilih nama
pengguna dan kata sandi. Nama pengguna dan kata sandi Anda akan digunakan agar Anda dapat
mengakses dan mengelola akun Anda dengan aman.
</p>
              <br>
              <h3>5. MELIHAT HALAMAN WEB        </h3>
              <br>
              <p>
                Sama seperti kebanyakan situs web, komputer Anda mengirimkan informasi yang mungkin termasuk
data pribadi tentang Anda yang dicatat oleh server web saat Anda menjelajahi Situs kami. Ini
biasanya termasuk dengan tidak terbatas pada alamat IP komputer, sistem operasi, nama/versi
peramban Anda, halaman web perujuk, halaman yang diminta, tanggal/waktu, dan terkadang
"cookie" (yang dapat dinonaktifkan dengan menggunakan preferensi peramban Anda) guna
membantu situs mengingat kunjungan terakhir Anda. Apabila Anda login, informasi ini dikaitkan
dengan akun pribadi Anda. Informasi juga disertakan dalam statistik anonim untuk memungkinkan
kami memahami bagaimana pengunjung menggunakan situs kami.
</p>
              <br>
              <h3>6. COOKIES
              </h3>
              <br>
              <p>
                Dari waktu ke waktu kami mungkin mengimplementasikan "cookies" atau fitur lainnya guna
memungkinkan kami atau pihak ketiga untuk mengumpulkan atau berbagi informasi yang akan
membantu kami meningkatkan Situs kami dan Layanan yang kami tawarkan, atau membantu kami
menawarkan layanan dan fitur baru. “Cookies” adalah pengidentifikasi yang kami transfer ke
komputer atau perangkat lunak Anda yang memungkinkan kami mengenali komputer atau perangkat
Anda dan memberi tahu kami bagaimana dan kapan Layanan atau situs web digunakan atau
dikunjungi, oleh berapa banyak orang serta melacak pergerakan dalam situs web kami. Kami dapat menautkan informasi cookie ke data pribadi. Cookies juga tertaut pada informasi tentang barang yang
telah Anda pilih untuk dibeli dan halaman yang telah Anda lihat. Informasi ini misalnya digunakan
untuk memantau keranjang belanja Anda. Cookies juga digunakan untuk mengirimkan konten yang
sesuai dengan minat Anda dan memantau penggunaan situs web
</p>
              <br>
              <h3>7. MELIHAT DAN MENGUNDUH KONTEN DAN IKLAN
              </h3>
              <br>
              <p>
                Sama seperti ketika menjelajahi halaman web, saat Anda melihat konten dan iklan serta mengakses
perangkat lunak lainnya di Situs kami atau melalui Layanan, sebagian dari informasi yang sama
dikirimkan kepada kami (termasuk, dengan tidak terbatas pada, Alamat IP, sistem operasi, dsb.);
tetapi bukannya tentang berapa kali halaman dilihat, komputer Anda mengirimkan informasi kepada
kami tentang konten, iklan yang dilihat dan/atau perangkat lunak yang dipasang oleh Layanan dan
situs web dan waktu.

</p>
              <br>
              <h3>8. LAYANAN

              </h3>
              <br>
              <p>
                Kami menyediakan dukungan layanan pelanggan melalui email, SMS dan formulir umpan balik.
Dalam rangka menyediakan dukungan pelanggan, kami akan meminta alamat email Anda dan nomor
ponsel Anda. Kami hanya akan menggunakan informasi yang diterima dari permintaan dukungan
pelanggan, termasuk, dengan tidak terbatas pada, alamat email, untuk layanan dukungan pelanggan
dan kami tidak mengalihkan atau membagikan informasi ini dengan pihak ketiga mana pun.
</p>
              <br>
              <h3>9. SURVEI


</h3>
<br>
<p>
  Dari waktu ke waktu, kami mungkin meminta informasi dari Pengguna melalui survei. Partisipasi
dalam survei ini sepenuhnya bersifat sukarela dan karenanya Anda memiliki pilihan untuk
mengungkapkan informasi Anda kepada kami atau tidak. Informasi yang diminta dapat mencakup,
dengan tidak terbatas pada, informasi kontak (seperti alamat email Anda), dan informasi demografis
(seperti minat atau tingkat usia). Informasi survei akan digunakan untuk tujuan memantau atau
meningkatkan penggunaan dan kepuasan Layanan serta tidak akan dialihkan ke pihak ketiga, selain
kontraktor kami yang membantu kami mengelola atau menindaklanjuti survei.
</p>
<br>
              <h3>10. BAGAIMANA KAMI MENGGUNAKAN INFORMASI YANG ANDA BERIKAN KEPADA KAMI?
              </h3>
              <br/>
              <p>

                <ul>
                  <br/> 
                  <li> 10.1 Kami dapat mengumpulkan, menggunakan, mengungkapkan dan/atau mengolah data pribadi
                    Anda untuk satu atau lebih dari tujuan-tujuan berikut ini:</li>
<br/>
                  <li>(a) mempertimbangkan dan/atau mengolah aplikasi/transaksi Anda dengan kami atau transaksi
                    maupun komunikasi Anda dengan pihak ketiga melalui Layanan;
</li>
<br/>
<li>(b) mengelola, mengoperasikan, menyediakan dan/atau mengurus penggunaan dan/atau akses Anda
ke Layanan kami dan situs web kami, serta hubungan Anda dan akun pengguna Anda dengan kami.
</li>
<br/>
<li>(d) menyesuaikan pengalaman Anda melalui Layanan dengan menampilkan konten sesuai dengan (d) menyesuaikan pengalaman Anda melalui Layanan dengan menampilkan konten sesuai dengan minat dan preferensi Anda, menyediakan metode yang lebih cepat untuk Anda mengakses akun
Anda dan mengirimkan informasi kepada kami serta mengizinkan kami untuk menghubungi Anda,
jika perlu;

</li>
<br/>
<li>(e) menanggapi, mengolah, berurusan dengan atau menyelesaikan transaksi dan/atau memenuhi
permintaan Anda untuk produk dan layanan tertentu serta memberi tahu Anda tentang masalah
layanan dan tindakan akun yang tidak lazim;


</li>
<br/>
<li>(f) memberlakukan Persyaratan Layanan atau perjanjian lisensi pengguna akhir apapun yang
berlaku;


</li>
<br/>
<li>(g) melindungi keselamatan pribadi dan hak, milik atau keselamatan pihak lainnya;



</li>
<br/>
<li>(h) untuk identifikasi dan/atau verifikasi;



</li>
<br/>
<li>(i) mempertahankan dan memberikan setiap pembaruan perangkat lunak dan/atau pembaruan
lainnya serta dukungan yang mungkin diperlukan dari waktu ke waktu untuk memastikan kelancaran
Layanan kami;
</li>
<br/>
<li>(j) berurusan dengan atau memfasilitasi layanan pelanggan, melaksanakan instruksi Anda, berurusan
dengan atau menanggapi setiap pertanyaan yang diajukan oleh (atau konon diajukan oleh) Anda
atau atas nama Anda;
</li>
<br/>
<li>(k) menghubungi Anda atau berkomunikasi dengan Anda melalui panggilan telepon, pesan teks
dan/atau pesan faks, email dan/atau surat pos atau cara lainnya untuk tujuan mengurus dan/atau
mengelola hubungan Anda dengan kami atau penggunaan Layanan kami oleh Anda, seperti tetapi
tidak terbatas pada mengomunikasikan informasi administratif kepada Anda yang berkaitan dengan
Layanan kami. Anda mengakui dan setuju bahwa komunikasi semacam itu oleh kami dapat dilakukan
dengan mengirimkan surat, dokumen atau pemberitahuan kepada Anda, yang dapat melibatkan
pengungkapan data pribadi tertentu tentang Anda untuk melakukan pengiriman tersebut serta
tercantum juga pada sampul luar amplop/paket pos;
</li>
<br/>
<li>(l) memberi tahu Anda saat Pengguna lain telah mengirimkan pesan pribadi kepada Anda atau
memposting komentar untuk Anda di Situs;
</li>
<br/>
<li>(m) mengadakan kegiatan penelitian, analisis dan pengembangan (termasuk, tetapi tidak terbatas
pada, analisis data, survei, pengembangan produk dan layanan dan/atau pembuatan profil), untuk
menganalisis bagaimana Anda menggunakan Layanan kami, meningkatkan Layanan atau produk
kami dan/atau meningkatkan pengalaman pelanggan Anda;
</li>
<br/>
<li>(n) memungkinkan audit dan survei untuk, antara lain, memvalidasi ukuran dan komposisi audiens
  sasaran kami, serta memahami pengalaman mereka dengan Layanan Eureka BookHouse;
</li>
<br/>
<li>(o) apabila Anda memberikan persetujuan Anda sebelumnya kepada kami untuk pemasaran dan
dalam hal ini, untuk mengirimkan kepada Anda surat pos, email, layanan berbasis lokasi atau kalau
tidak, informasi dan materi pemasaran dan promosi yang berkaitan dengan produk dan/atau layanan
(termasuk, dengan tidak terbatas pada, produk dan/atau layanan pihak ketiga yang mungkin bekerja
sama atau terikat dengan Eureka BookHouse) yang mungkin dijual, dipasarkan atau dipromosikan
oleh Eureka BookHouse (dan/atau afiliasi atau perusahaan terkaitnya), baik apakah produk atau
layanan tersebut sudah ada sekarang atau diciptakan di masa mendatang. </li>
<br/>
<li>(p) mengaudit Layanan kami;</li>
<br/>
<li>(q) mencegah atau menyelidiki setiap penipuan, kegiatan yang melanggar hukum, pembiaran atau
kesalahan, baik yang berhubungan dengan penggunaan Layanan kami oleh Anda atau setiap hal lain
apapun yang timbul dari hubungan Anda dengan kami, dan baik apakah ada kecurigaan tentang hal
tersebut di atas atau tidak;</li>
<br/>
<li>(r) menyimpan, menyelenggarakan, membuat cadangan (baik untuk pemulihan setelah bencana atau
  hal lainnya) data pribadi Anda;</li>
<br/>
<li>(r) menyimpan, menyelenggarakan, membuat cadangan (baik untuk pemulihan setelah bencana atau
  hal lainnya) data pribadi Anda;</li>
<br/>
<li>(secara bersama-sama disebut sebagai “Tujuan”)</li>
<br/>
<li>10.2 Karena tujuan kami akan/mungkin mengumpulkan, menggunakan, mengungkapkan atau
mengolah data pribadi Anda tergantung pada situasi yang dihadapi, tujuan tersebut mungkin tidak
tertera di atas. Akan tetapi, kami akan memberi tahu Anda tentang tujuan lain semacam itu pada saat
memperoleh persetujuan Anda.</li>
<br/>
                </ul>
              </p>
              <br/>
              <h3>11. BERBAGI INFORMASI DARI LAYANAN
              </h3>
              <br/>
              <p>Layanan kami memungkinkan Pengguna untuk berbagi informasi pribadi mereka kepada satu sama
lain, dalam hampir semua kesempatan tanpa keterlibatan Eureka BookHouse, untuk menyelesaikan
transaksi. Dalam transaksi biasa, Pengguna dapat memiliki akses ke nama, ID pengguna, alamat
email serta informasi kontak dan pos lainnya dari pengguna lainnya
              </p>
<br/>
<h3>12. BAGAIMANA EUREKA BOOKHOUSE MELINDUNGI INFORMASI PELANGGAN?

              </h3>
              <br/>
              <p>Kami menerapkan berbagai langkah pengamanan untuk memastikan keamanan data pribadi Anda di
sistem kami. Data pribadi pengguna berada di belakang jaringan yang aman dan hanya dapat
diakses oleh sejumlah kecil karyawan yang memiliki hak akses khusus ke sistem tersebut. Apabila
Anda berhenti menggunakan Situs, atau izin Anda untuk menggunakan Situs dan/atau Layanan
diakhiri, kami dapat terus menyimpan, menggunakan dan/atau mengungkapkan data pribadi Anda
sesuai dengan Kebijakan Privasi. Dengan tunduk pada hukum yang berlaku, kami dapat membuang
data pribadi Anda secara aman tanpa pemberitahuan sebelumnya kepada Anda.
              </p>
<br/>
<h3>13. APAKAH EUREKA BOOKHOUSE MENGUNGKAPKAN INFORMASI YANG
  DIKUMPULKANNYA DARI PENGUNJUNG KEPADA PIHAK LUAR?

</h3>

<br/>
<span>13.1 Dalam menjalankan bisnis kami, kami akan/mungkin perlu mengungkapkan data pribadi Anda
kepada penyedia layanan pihak ketiga, agen dan/atau afiliasi atau perusahaan terkait kami, dan/atau
pihak ketiga lainnya, untuk satu atau lebih Tujuan yang disebutkan di atas. Penyedia layanan pihak
ketiga, agen dan/atau afiliasi atau perusahaan terkait dan/atau pihak ketiga lainnya tersebut akan
mengolah data pribadi Anda atas nama kami atau pihak lainnya, untuk satu atau lebih Tujuan yang
disebutkan di atas. Pihak ketiga tersebut termasuk, dengan tidak terbatas pada:
  <div class="ml-4">   <br/> <p>(a) anak perusahaan, afiliasi dan perusahaan terkait kami;</p>
<br/>
<p>(b) kontraktor, agen, penyedia layanan dan pihak ketiga lainnya yang kami gunakan untuk
mendukung bisnis kami. Ini termasuk tetapi tidak terbatas pada mereka yang menyediakan layanan
administratif atau lainnya kepada kami seperti, jasa pos, perusahaan telekomunikasi, perusahaan
teknologi informasi dan pusat data;</p>
  <br/>
  <p>(c) pembeli atau penerus lainnya dalam hal terjadi penggabungan, divestasi, restrukturisasi,
reorganisasi, pembubaran atau penjualan atau pengalihan lainnya atas beberapa atau semua aset
Eureka BookHouse, baik secara berkelanjutan atau sebagai bagian dari kepailitan, likuidasi atau
proses serupa, di mana data pribadi yang dimiliki oleh Eureka BookHouse tentang Pengguna
Layanan kami adalah salah satu aset yang dialihkan; atau kepada rekanan dalam suatu transaksi
aset bisnis yang melibatkan Eureka BookHouseatau salah satu afiliasi atau perusahaan terkaitnya; 
  </p>
  <br/>
  <p>(d) pihak ketiga kepada siapa kami mengungkapkan data tersebut untuk satu Tujuan atau lebih dan
pihak ketiga tersebut pada gilirannya akan mengumpulkan dan mengolah data pribadi Anda untuk
satu Tujuan atau lebih
  </p>
  </div>
<br/>
  <p>13.2 Ini mungkin memerlukan, antara lain, berbagi informasi statistik dan demografis tentang
Pengguna kami dan penggunaan Layanan oleh mereka kepada pemasok iklan dan pemrograman. Ini
tidak akan termasuk hal apapun yang dapat digunakan untuk mengidentifikasi Anda secara khusus
atau menemukan informasi pribadi tentang diri Anda.</p>
<br/>
<p>13.3 Pihak ketiga mungkin menyadap atau mengakses data pribadi yang dikirimkan ke atau
tersimpan di situs tanpa izin, teknologi mungkin mengalami kerusakan fungsi atau tidak bekerja
seperti yang diharapkan, atau seseorang mungkin mengakses, memanfaatkan atau
menyalahgunakan informasi tanpa ada kesalahan dari pihak kami. Meski demikian kami akan
menggunakan pengaturan keamanan yang wajar untuk melindungi data pribadi Anda; akan tetapi
tidak dapat dihindari, tidak ada jaminan keamanan mutlak seperti tetapi tidak terbatas pada
pengungkapan tanpa izin yang timbul dari peretasan yang berbahaya dan canggih oleh pihak yang
tidak senang atau memusuhi tanpa adanya kesalahan dari pihak kami.
</p>
<br/>

  </span>
              <h3>14. INFORMASI YANG DIKUMPULKAN OLEH PIHAK KETIGA
              </h3>
              <br/>
              <p>14.1 Situs kami menggunakan Google Analytics, sebuah layanan analisis web yang disediakan oleh
Google, Inc. ("Google"). Google Analytics menggunakan cookies, yaitu file teks yang ditaruh di
komputer Anda, untuk membantu situs web menganalisis bagaimana Pengguna menggunakan Situs.
Informasi yang hasilkan oleh cookie tentang penggunaan situs web oleh Anda (termasuk alamat IP
Anda) akan dikirimkan ke dan disimpan oleh Google di server di Amerika Serikat. Google akan
menggunakan informasi ini untuk tujuan mengevaluasi penggunaan situs web oleh Anda, menyusun
laporan tentang kegiatan situs web untuk operator situs web serta menyediakan layanan lainnya yang
berkaitan dengan kegiatan situs web dan penggunaan Internet. Google juga dapat mengalihkan
informasi ini kepada pihak ketiga bila diwajibkan untuk melakukannya oleh hukum, atau bila pihak
ketiga tersebut mengolah informasi tersebut atas nama Google. Google tidak akan mengaitkan
alamat IP Anda dengan data lain apapun yang dimiliki oleh Google.
</p>
<br/>
              <p>14.2 Kami, dan pihak ketiga, dari waktu ke waktu dapat menyediakan unduhan aplikasi perangkat lunak untuk Anda gunakan di atau melalui Layanan. Aplikasi ini dapat mengakses secara terpisah,
dan memungkinkan pihak ketiga untuk melihat informasi identifikasi Anda, seperti nama Anda, ID
pengguna Anda, Alamat IP komputer Anda atau informasi lainnya seperti cookies yang mungkin
sebelumnya telah Anda pasang atau dipasang untuk Anda oleh aplikasi perangkat lunak atau situs
web pihak ketiga. Selain itu, aplikasi ini dapat meminta Anda untuk memberikan informasi tambahan
secara langsung kepada pihak ketiga. Produk atau layanan pihak ketiga yang disediakan melalui
aplikasi ini tidak dimiliki atau dikendalikan oleh Eureka BookHouse. Anda disarankan untuk membaca
syarat dan ketentuan yang diterbitkan oleh pihak ketiga tersebut di situs web mereka atau di tempat
lainnya.</p>
<br/>
<h3>16. PENAFIAN TENTANG KEAMANAN DAN SITUS PIHAK KETIGA

              </h3>
              <br/>
              <p>16.1 KAMI TIDAK MENJAMIN KEAMANAN DATA PRIBADI DAN/ATAU INFORMASI LAIN YANG
ANDA BERIKAN DI SITUS PIHAK KETIGA. Kami menerapkan berbagai langkah pengamanan untuk
menjaga keamanan data pribadi Anda yang kami miliki atau berada di bawah kendali kami. Data
pribadi Anda berada di belakang jaringan yang aman dan hanya dapat diakses oleh sejumlah kecil
orang yang memiliki hak akses khusus ke sistem tersebut, dan diwajibkan untuk menjaga
kerahasiaan data pribadi tersebut. Saat Anda melakukan pesanan atau mengakses data pribadi
Anda, kami menawarkan penggunaan server yang aman. Semua data pribadi atau informasi sensitif
yang anda berikan dienkripsi ke dalam database kami hanya untuk diakses seperti yang disebutkan
di atas.

</p>
<br/>
              <p>16.2 Dalam upaya untuk memberikan nilai yang lebih baik kepada Anda, kami mungkin memilih
berbagai situs web pihak ketiga untuk ditautkan dan dibingkai dalam Situs. Kami juga dapat
berpartisipasi dalam pencitraan merek bersama dan hubungan lainnya untuk menawarkan ecommerce serta layanan dan fitur lainnya kepada pengunjung kami. Situs tautan ini memiliki
kebijakan privasi serta pengaturan keamanan yang terpisah dan berdiri sendiri. Bahkan jika pihak
ketiga tersebut terafiliasi dengan kami, kami tidak memiliki kendali atas situs tautan tersebut, dan
masing-masing situs tersebut memiliki praktik privasi dan pengumpulan data yang terpisah dari kami
dan berdiri sendiri. Data yang dikumpulkan oleh mitra kami dalam pencitraan merek bersama atau
situs web pihak ketiga (bahkan jika ditawarkan di atau melalui Situs kami) mungkin tidak diterima oleh
kami.</p>
<br/>
              <p>16.3 Karenanya kami tidak bertanggung jawab atau memiliki kewajiban apapun atas konten,
pengaturan keamanan (atau tidak adanya hal tersebut) dan kegiatan situs tertaut ini. Situs tertaut ini
hanya untuk kenyamanan Anda dan karenanya Anda mengaksesnya dengan risiko Anda sendiri.
Meski demikian, kami berupaya untuk melindungi integritas Situs kami dan masing-masing tautan
yang ditempatkan dan karenanya kami menyambut setiap umpan balik tentang situs tertaut tersebut
(termasuk, dengan tidak terbatas pada, bila tautan tertentu tidak berfungsi).
</p>
<br/>
<h3>17. BAGAIMANA ANDA DAPAT MEMILIH KELUAR, MENGHAPUS, MEMINTA AKSES ATAU
  MENGUBAH INFORMASI YANG TELAH ANDA BERIKAN KEPADA KAMI?

</h3>
<br/>
<p>17.1 Memilih Keluar dan Mencabut Persetujuan

</p>
<br/>
<p>17.1.1 Untuk mengubah langganan email Anda, silakan beri tahu kami dengan mengirimkan email ke
eurekabookhouse@gmail.com Harap diperhatikan bahwa karena adanya jadwal produksi email,
Anda mungkin masih menerima email yang sudah diproduksi.</p>
<br/>
<p>17.1.2 Anda dapat mencabut persetujuan Anda untuk pengumpulan, penggunaan dan/atau
pengungkapan data pribadi Anda yang kami miliki atau berada di bawah kendali kami dengan
mengirimkan email ke eurekabookhouse@gmail.com
</p>
<br/>
<p>17.1.3 Setelah kami menerima instruksi pencabutan yang jelas dari Anda dan memverifikasi identitas
Anda, kami akan memproses permintaan Anda untuk pencabutan persetujuan, dan setelahnya kami
tidak akan mengumpulkan, menggunakan dan/atau mengungkapkan data pribadi Anda dengan cara
yang tercantum dalam permintaan Anda. Apabila kami tidak dapat memverifikasi identitas Anda atau
memahami instruksi Anda, kami akan menghubungi Anda untuk memahami permintaan Anda.
</p>
<br/>
<p>17.1.4 Akan tetapi, pencabutan persetujuan Anda dapat menyebabkan akibat hukum tertentu yang
timbul dari pencabutan tersebut. Dalam hal ini, dengan tergantung pada sejauh mana pencabutan
persetujuan untuk kami mengolah data pribadi Anda, ini dapat berarti bahwa kami tidak akan dapat
terus menyediakan Layanan kepada Anda, kami mungkin perlu mengakhiri hubungan dan/atau
kontrak yang Anda miliki dengan kami, dsb., sesuai keadaannya, yang akan kami beritahukan
kepada Anda.

</p>
<br/>
<p>17.2 Meminta Akses dan/atau Pembetulan Data Pribadi
</p>
<br/>
<p>17.2.1 Apabila Anda memiliki akun dengan kami, Anda dapat mengakses dan/atau membetulkan
sendiri data pribadi Anda yang saat ini kami miliki atau kendalikan melalui halaman Pengaturan Akun
di Situs. Apabila Anda tidak memiliki akun dengan kami, Anda dapat meminta untuk mengakses
dan/atau membetulkan data pribadi Anda yang saat ini kami miliki atau kendalikan dengan
mengirimkan permintaan tertulis kepada kami. Kami akan membutuhkan cukup informasi dari Anda
untuk memastikan identitas Anda serta sifat permintaan Anda agar kami dapat menangani
permintaan Anda. Karenanya, silakan kirim permintaan tertulis Anda dengan mengirimkan email ke
eurekabookhouse@gmail.com
</p>
<br/>
<p>17.2.2 Untuk permintaan mengakses data pribadi, setelah kami memiliki cukup informasi dari Anda
untuk menangani permintaan, kami akan berusaha untuk memberikan data pribadi yang
bersangkutan kepada Anda dalam 30 hari, kami akan memberi tahu Anda kapan paling cepat kami
dapat memberikan informasi yang diminta kepada Anda
</p>
<br/>
<p>17.2.3 Untuk permintaan membetulkan data pribadi, setelah kami memiliki cukup informasi dari Anda
untuk menangani permintaan, kami akan:

</p><br/>
<p>(a) membetulkan data pribadi Anda dalam waktu 30 menit. Apabila kami tidak dapat melakukannya
dalam jangka waktu tersebut, kami akan memberi tahu Anda kapan paling cepat kami dapat
melakukan pembetulan. Harap diperhatikan bahwa ada situasi tertentu di mana pembetulan tidak
perlu dilakukan oleh kami meski ada permintaan oleh Anda; dan
</p><br/>
<p>17.2.4 Kami juga akan/dapat mengenakan biaya yang wajar kepada Anda untuk penanganan dan
pemrosesan permintaan Anda untuk mengakses data pribadi Anda. Apabila kami memilih untuk
mengenakan biaya, kami akan memberikan perkiraan biaya yang akan kami kenakan kepada Anda
secara tertulis. Harap diperhatikan bahwa kami tidak diwajibkan untuk menanggapi atau menangani
permintaan akses Anda kecuali Anda telah setuju untuk membayar biaya tersebut.

</p><br/>
<p>17.2.5 Kami berhak untuk menolak membetulkan data pribadi Anda sesuai dengan ketentuan yang
  tercantum dalam Undang-undang Privasi.
</p>

<br/>
              <h3>18. PERTANYAAN, MASALAH ATAU KELUHAN? HUBUNGI KAMI
              </h3>
              <br/>
              <p>Apabila Anda memiliki pertanyaan atau masalah tentang praktik privasi kami atau hubungan Anda
                dengan Layanan, silakan jangan ragu-ragu untuk menghubungi: eurekabookhouse@gmail.com
</p>
        <br/>
        <h3>20. SYARAT DAN KETENTUAN

              </h3>
              <br/>
              <p>Silakan juga baca Syarat Layanan yang menetapkan penggunaan, penafian, dan batasan tanggung
                jawab yang mengatur penggunaan Situs dan Layanan serta kebijakan terkait lainnya.
</p>
        <br/>


            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "kebijakanPrivasi",
  components: {},
  data() {
    return {};
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  methods: {},
};
</script>

<style>
</style>
