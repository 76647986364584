<template>
  <div style="margin-top: 2%" class="container">
    <b-card-group columns>
      <b-card
        v-for="(columnData, colIndex) in categorizedData"
        :key="colIndex"
        class="column-card"
      >
        <div v-for="category in columnData" :key="category.category_id">
          <div
            class="category"
            style="
              border: 1px solid #ddd;
              border-radius: 8px;
              background-color: #fff;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              margin-bottom: 20px;
            "
          >
            <router-link :to="'/kategori/' + category.slug">
              <div
                class="d-flex"
                style="
                  background-color: #5178be;
                  border-radius: 10px;
                  padding: 10px;
                "
              >
                <img
                  :src="
                    category.image
                      ? `https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/${category.image}`
                      : 'https://www.eurekabookhouse.co.id/assets/front/images/icons/nerd/010-book.svg'
                  "
                  style="width: 40px"
                  alt="Icon"
                />

                <h2
                  class="mr-2"
                  style="
                    margin-top: 12px;
                    margin-left: 20px;
                    color: white;
                    cursor: pointer;
                  "
                >
                  {{ category.name }}
                </h2>
              </div>
            </router-link>

            <ul v-if="category.subcategories.length" class="subcategory-list">
              <li
                v-for="subcategory in category.subcategories"
                :key="subcategory.category_id"
                class="subcategory"
              >
                <router-link :to="'/kategori/' + subcategory.slug">
                  <h3 class="text-left" style="cursor: pointer">
                    <b-icon icon="caret-down-square-fill" class="mr-2"></b-icon
                    >{{ subcategory.name }}
                  </h3>
                </router-link>

                <ul v-if="subcategory.subcategories.length">
                  <li
                    class="text-left"
                    style="margin-left: 30px"
                    v-for="nestedSubcategory in subcategory.subcategories"
                    :key="nestedSubcategory.category_id"
                  >
                    <span
                      style="cursor: pointer"
                      @click="navigateToCategory(nestedSubcategory.slug)"
                    >
                      <b-icon icon="caret-right-fill" class="mr-2"></b-icon>
                      {{ nestedSubcategory.name }}
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </b-card>
    </b-card-group>
    <div style="margin-bottom: 2%"></div>
  </div>
</template>

  
  <script>
export default {
  name: "allKategoriPage",
  data() {
    return {
      categories: [],
    };
  },
  mounted() {
    this.fetchCategories();
  },
  computed: {
    categorizedData() {
      const groupedData = this.optimizeColumnDistribution(this.categories, 4);
      return groupedData;
    },
  },

  methods: {
    navigateToCategory(slug) {
      this.$router.push(`/kategori/${slug}`);
    },
    optimizeColumnDistribution(data, columns) {
      const columnHeights = Array.from({ length: columns }, () => 0);
      const result = Array.from({ length: columns }, () => []);

      data.forEach((item) => {
        const minColumnIndex = columnHeights.indexOf(
          Math.min(...columnHeights)
        );
        result[minColumnIndex].push(item);
        columnHeights[minColumnIndex] += this.calculateItemHeight(item);
      });

      return result;
    },

    calculateItemHeight(item) {
      return 100;
    },
    async fetchCategories() {
      try {
        const response = await fetch(
          "https://stagingapi.eurekabookhouse.co.id/category/sub"
        );
        const data = await response.json();
        if (data.status && data.status.code === 200) {
          this.categories = data.data.order;
        } else {
          console.error("Failed to fetch categories");
        }
      } catch (error) {
        console.error("Error fetching categories", error);
      }
    },
  },
};
</script>
  
  <style scoped>
.category-container {
  max-width: 800px;
  margin: 0 auto;
}

.category {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.category h2 {
  margin-bottom: 15px;
  font-size: 1.5em;
  color: #333;
}

.category-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
}

.subcategory-list {
  list-style-type: none;
  padding: 0;
}

.subcategory {
  margin-top: 15px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.subcategory h3 {
  margin-bottom: 10px;
  font-size: 1.3em;
  color: #555;
}

.subcategory-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 8px;
}

.nested-subcategory h4 {
  margin-bottom: 5px;
  font-size: 1.1em;
  color: #777;
}
</style>
  