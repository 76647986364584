<template>
  <div class="no-scroll">
    <div class="row">
      <div
        class="col-12 col-md-7 d-none d-md-block"
        style="height: 100vh; padding-left: 0px"
      >
        <img
          src="../../assets/images/login/cek.jpg"
          alt="Left Logo"
          style="background-size: cover; height: 100%; width: 102%"
        />
      </div>
      <div class="col-12 col-md-5 login-background">
        <div class="login-form">
          <img
            class="regis-left"
            src="../../assets/images/login/1.png"
            alt="Left Logo"
          />
          <img
            class="regis-right"
            src="../../assets/images/login/2.png"
            alt="Right Logo"
          />
          <div class="mt-5">
            <h1 style="color: white">Daftar Akun</h1>
            <p style="color: white">Masukkan akun pribadi Eureka anda</p>
            <br />
            <div class="login-box-body">
              <form class="form-signin" @submit.prevent="register">
                <span class="text-label">Nama depan</span>

                <input
                  name="firstname"
                  type="text"
                  id="inputEmail"
                  placeholder="Masukan Nama depan"
                  required
                  v-model="firstname"
                  class="text-form mt-3"
                />
                <span class="text-label">Nama belakang</span>

                <input
                  name="lastname"
                  type="text"
                  id="inputEmail"
                  placeholder="Masukan Nama belakang"
                  required
                  v-model="lastname"
                  class="text-form mt-3"
                />
                <span class="text-label">Email</span>

                <input
                  class="text-form mt-3"
                  name="email"
                  type="email"
                  id="inputEmail"
                  placeholder="Masukan Email"
                  value=""
                  required
                  v-model="email"
                />
                <span class="text-label">Password</span>

                <input
                  class="text-form mt-3"
                  name="password"
                  type="password"
                  id="inputEmail"
                  placeholder="Masukan Password"
                  required
                  v-model="password"
                />

                <div class="mt-3">
                  <button
                    class="button-daftar btn btn-lg btn-primary btn-block btn-signin"
                    type="submit"
                  >
                    Daftar
                  </button>
                  <br />
                </div>
                <!-- <br /> -->
                <!-- <span><b style="color: white">Atau daftar dengan </b></span> -->
                <!-- <div class="row mt-3">
                  <div class="col d-flex justify-content-center">
                    <img
                      src="../../assets/images/login/go.png"
                      style="width: 48px"
                      class="mr-3"
                      alt=""
                    />
                    <img
                      src="../../assets/images/login/fb1.png"
                      style="width: 48px"
                      alt=""
                    />
                  </div>
                </div> -->
              </form>
              <!-- /form -->
            </div>
            <div style="margin-bottom: 0px">
              <br />

              <span style="color: white">Sudah punya akun? </span
              ><router-link :to="'/UserEBH/Login'"
                ><b style="color: #ffc341">Masuk</b></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script scope>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "forgotPage",
  data() {
    return {
      loading: false,
      firstname: "",
      lastname: "",
      email: "",
      password: "",
    };
  },
  methods: {
    register() {
      // Set loading to true when the request starts
      this.loading = true;

      const apiUrl =
        "https://stagingapi.eurekabookhouse.co.id/customer/register";
      const requestBody = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        password: this.password,
      };

      // Display an overlay with a loading image
      const overlay = document.createElement("div");
      overlay.className = "loading-overlay";
      overlay.innerHTML = `
    <img src="${require("@/assets/images/login/loader_ebh.gif")}" style="width: 10%" />
    <p style="font-size: 20px; font-weight: 500;">Loading...</p>
  `;
      document.body.appendChild(overlay);

      // Set a timeout, for example, 10 seconds (10000 milliseconds)
      const timeoutMillis = 10000;

      axios
        .post(apiUrl, requestBody, { timeout: timeoutMillis })
        .then((response) => {
          // Handle response
          if (response.status === 200) {
            // console.log(response.data);

            // Show SweetAlert for successful registration
            Swal.fire({
              icon: "success",
              confirmButtonColor: "#3860A8",
              title: "Registrasi Berhasil",
              text: "Anda telah berhasil mendaftar!",
            }).then(() => {
              // Redirect to the login page after closing the alert
              this.$router.push({ name: "login" });
            });
          } else {
            // Handle errors in the response status here
            console.error(
              `Response not successful, status: ${response.status}`
            );

            // Show SweetAlert for unsuccessful response status
            Swal.fire({
              icon: "error",
              confirmButtonColor: "#3860A8",
              title: "Registrasi Gagal",
              text: "Terjadi kesalahan saat registrasi. Silakan coba lagi.",
            });
          }
        })
        .catch((error) => {
          // Handle errors here
          console.error(error);

          // Show SweetAlert for errors
          Swal.fire({
            icon: "error",
            title: "Registrasi Gagal",
            confirmButtonColor: "#3860A8",
            text: "Terjadi kesalahan saat registrasi. Silakan coba lagi.",
          });
        })
        .finally(() => {
          // Set loading to false when the request is complete, whether successful or not
          this.loading = false;

          // Remove the overlay after completion
          document.body.removeChild(overlay);
        });
    },
  },
};
</script>
<style scoped>
.button-daftar {
  display: flex;
  height: 50px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: black;
  align-self: stretch;
  border-radius: 50px;
  background: #ffc341;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.logo-left {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 400px; /* Sesuaikan dengan ukuran gambar Anda */
}
.no-scroll {
  overflow: hidden;
}

.login-background {
  background-image: url("../../assets/images/login/yaya.png");
  background-size: cover; /* Mengatur gambar latar belakang agar selalu menutupi seluruh area elemen */
  background-repeat: no-repeat; /* Menghindari pengulangan gambar latar belakang */
  background-position: center center; /* Mengatur posisi gambar latar belakang ke tengah */
  height: 100vh;
}

.logo-right {
  position: absolute;
  top: 0px;
  right: 0px;

  width: 400px; /* Sesuaikan dengan ukuran gambar Anda */
}

.regis-left {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 114px; /* Sesuaikan dengan ukuran gambar Anda */
}

.regis-right {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 114px; /* Sesuaikan dengan ukuran gambar Anda */
}
.login-form {
  background-color: #091d42;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
}
.text-form {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  background: var(--neutral-02, #efefef);
}
.text-label {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: -0.16px;
  float: left;
}
input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
}

button:hover {
  background-color: #0056b3;
}
</style>
